import React, { useMemo, useContext, useState, useEffect, useRef } from "react";

import UIVote from "ui/UIVote";
import UIPersonaFlag from "ui/UIPersonaFlag";
import Linkify from "linkify-react";
import DatabaseContext from "data/contextDatabase";
import { useHistory } from "react-router-dom";
import { randomString } from "hooks/helper";
import { getImage64, storeImage } from "data/descriptors";
import { s3ToImage } from "connectivity/s3";
import ServiceMsg from "ui/ServiceMsg";
import TopicContext from "contexts/contextTopic";
import { FaFileDownload } from "react-icons/fa";
import GetInitials from "utils/GetInitials";
import GlobalContext from "contexts/context";
import ServiceMessageStateProvider from "contexts/ServiceMessageStateProvider";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IconContext } from "react-icons";
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import useClickPreventionOnDoubleClick from "../utils/useClickPreventionOnDoubleClick";
import { linkProps } from "utils/urlTools";
import CalcWhispColor from "../utils/CalcWhispColor";
import InverseColor from "../utils/InverseColor";
import { convHrsMins } from "../utils/UtilsUniversal";
import UIQuote from "./UIQuote";
import UIReceipt from "./UIReceipt";
// import SkeletonImage from "./skeletons/SkeletonImage";
import Modals from "./Modals";
import "./modals.css";
import UIProfileModalNew from "./UIProfileModalNew";
import GetFileExt from "./UIGetFileExt";
import { isMobileTablet } from "hooks/helper";
import {
  left_or_right,
  timeFormat,
  timestamp,
  sortJoin,
  downloadAttachment,
  bubbleColour,
  textColour,
  getFontSize,
  isEmoji
} from "utils/Message";
import { MdCountertops } from "react-icons/md";
import { dex_action } from "data/dexUtils";

const isEqual = require("react-fast-compare");

const UIMessage = (props) => {
  const f = () => {
    let inBetArr = [];
    if (props.message?.parameters?.images?.length > 0) {
      for (
        let count = 0;
        count < props.message?.parameters?.images?.length;
        count++
      ) {
        inBetArr = [...inBetArr, props.message?.parameters?.images[count]];
      }
    } else {
      if (props.message?.parameters?.image) {
        inBetArr = [
          ...inBetArr,
          { image: props.message?.parameters?.image, width: 150, height: 150 }
        ];
        //  && newMsgImg.length > 0) {
        // inBetArr = [...inBetArr, newMsgImg[0]]
      }
    }
    if (inBetArr && inBetArr.length > 0) {
      return inBetArr;
    }
    return [];
  };
  const { databaseState } = useContext(DatabaseContext);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const { globalState } = useContext(GlobalContext);
  const history = useHistory();
  const [msgImg, setMsgImg] = useState([]);
  const [open, setOpen] = React.useState(false);
  const menuRef = useRef();
  const msgRef = useRef();
  const msgVessel = useRef();
  const ui_msgVessel = useRef();
  const [menuHgt, setMenuHgt] = useState(0);
  const [colWidth, setColWidth] = useState();
  const [skeletonMsgImg, setSkeletonMsgImg] = useState(f());
  const [showImg, setShowImg] = useState({});
  const [msgImgLoaded, setMsgImgLoaded] = useState(false);
  const maxH = 500;
  let osH;
  let osW;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const side = useMemo(
    () =>
      left_or_right(
        props.you,
        props.message.mpersona,
        props.message.parameters?.reverse_side,
        props.message.parameters?.side
      ),
    [
      props.message.mpersona,
      props.message.parameters?.reverse_side,
      props.message.parameters?.side,
      props.you
    ]
  );

  const colorBasis = useMemo(
    () =>
      // props.message?.parameters?.reply_to &&
      // props.message?.parameters?.reply_to.length > 0
      //   ? props.message?.parameters?.reply_to
      //     :
      props.message?.personas_rx && props.message?.personas_rx.length > 1
        ? props.message?.personas_rx
        : undefined,
    [props.message?.personas_rx]
  );

  const infoMsg = useMemo(() => {
    if (
      props.message?.parameters?.information === true ||
      props.message?.parameters?.information === "true"
    )
      return true;
    else if (
      props.message?.parameters?.information === false ||
      props.message?.parameters?.information === "false"
    )
      return false;
    else return false;
  }, [props.message?.parameters?.information]);

  useEffect(() => {
    let isMounted = true;
    // console.log("[UIMessage] imageMed", props.message?.parameters?.imageMed);

    let retryTime = 500;

    let fimg = (jImage, large, thumbnail, name, width, height, index) => {
      retryTime = retryTime < 10000 ? 2 * retryTime : 0;
      // console.log("[UIMessage] retryTime", retryTime);
      getImage64(databaseState.dexUser, jImage.digest)
        .then((image) => {
          // console.log("[UIMessage] imageMed getImage64", imageMed);
          if (image) {
            if (msgImg.filter((mi) => isEqual(mi.image, image))?.length <= 0) {
              isMounted &&
                setSkeletonMsgImg((msgImg) => [
                  ...msgImg.filter((i) => i.index !== index),
                  {
                    ...msgImg.reduce(
                      (acc, i) => (i.index === index ? i : acc),
                      {}
                    ),
                    image: image,
                    large: large,
                    thumbnail: thumbnail,
                    name: name,
                    width: width,
                    height: height,
                    index: index
                  }
                ]);
            }
          } else {
            s3ToImage(jImage.scope, jImage.digest).then((i) => {
              // console.log("[UIMessage] s3ToImage", i);
              if (i?.b64 || i?.i64) {
                // console.log("[UIMessage] i", i);
                storeImage(databaseState.dexUser, i);
                if (
                  msgImg.filter((mi) => isEqual(mi.image, image))?.length <= 0
                ) {
                  isMounted &&
                    setSkeletonMsgImg((msgImg) => [
                      ...msgImg.filter((i) => i.index !== index),
                      {
                        ...msgImg.reduce(
                          (acc, i) => (i.index === index ? i : acc),
                          {}
                        ),
                        image: i?.b64,
                        large: large,
                        thumbnail: thumbnail,
                        name: name,
                        index: index,
                        width: width,
                        height: height
                      }
                    ]);
                }
              } else
                setTimeout(
                  () => isMounted && retryTime && fimg(jImage),
                  retryTime
                );
            });
          }
          // setMsgImgLoaded(true);
        })
        .catch((err) => {
          console.log("[UIMessage] CATCH", err);
        });
    };

    if (props.message?.parameters?.images?.length > 0) {
      props.message?.parameters?.images?.map((items) => {
        if (items.medium) {
          if (items.width && items.height)
            fimg(
              items.medium,
              items.large,
              items.thumbnail,
              items.name,
              items.width,
              items.height,
              items.index
            );
          else
            fimg(
              items.medium,
              items.large,
              items.thumbnail,
              items.name,
              null,
              null,
              items.index
            );
        }
      });
    } else if (props.message?.parameters?.imageMed?.digest) {
      fimg(props.message?.parameters?.imageMed, null, null, null, null);
    } else if (props.message?.parameters?.image?.digest)
      fimg(props.message?.parameters?.image, null, null, null, null);

    return () => {
      isMounted = false;
    };
  }, [databaseState.dexUser, props.message]);

  useEffect(() => {
    //for Skeleton screens show thumbnail
    let inBetArr = [];
    if (props.message?.parameters?.images?.length > 0) {
      for (
        let count = 0;
        count < props.message?.parameters?.images?.length;
        count++
      ) {
        inBetArr = [...inBetArr, props.message?.parameters?.images[count]];
      }
    } else {
      if (props.message?.parameters?.image) {
        inBetArr = [
          ...inBetArr,
          { image: props.message?.parameters?.image, width: 150, height: 150 }
        ];
        //  && newMsgImg.length > 0) {
        // inBetArr = [...inBetArr, newMsgImg[0]]
      }
    }
    if (inBetArr && inBetArr.length > 0) {
      setSkeletonMsgImg(inBetArr);
    }
  }, []);

  useEffect(() => {
    if (menuRef.current) {
      let msghgt;
      if (msgRef.current) {
        msghgt = msgRef.current.clientHeight;
      }
      let spc = 0;
      if ((!props.prevOriginatorSame || props.sda) && side !== "right") {
        // if (!props.prevOriginatorSame && side !== "right") {
        // come back to this
        // if (!props.avatar && side !== "right") {
        if (props.message?.personas_rx?.length > 1) spc = 90;
        else spc = 60;
      } else spc = 40;
      if (menuRef.current.clientHeight + spc > msghgt) {
        if (props.message?.personas_rx?.length > 1) {
          setMenuHgt(menuRef.current.clientHeight + 30);
        } else {
          setMenuHgt(menuRef.current.clientHeight);
        }
      } else setMenuHgt(0);
    } else setMenuHgt(0);
  }, [open]);

  const menuClickDialog = useMemo(
    () => () => {
      let d = {
        dialog: props.message.persona,
        got_voice: true,
        mdialog: sortJoin(props.you, props.message.mpersona, "_"),
        mpersona: props.you,
        persona: props.persona,
        peer: props.message.mpersona,
        peerpersona: props.message.persona,
        // topic_display_name: "",
        visibility: "PRIVATE_DIALOG"
      };
      history.push("/UIMessageList", {
        dialog: d,
        size: "medium"
      });
    },
    [
      databaseState.dexUser,
      props.message.mpersona,
      props.message.persona,
      props.muid,
      props.persona,
      props.you
    ]
  );

  const menuEditMessage = useMemo(
    () => () => {
      // populate the send area with
      // - message text
      // - attachment
      // - image
      // - subgroup
      // - smid
      // - parameter "edited" = "true"
      topicDispatch({
        type: "SET_MESSAGE",
        values: { message: props.message }
      });
      topicDispatch({
        type: "SET_QUOTE",
        values: { quote: null }
      });
      topicDispatch({
        type: "SET_PERSONAS_RX",
        values: { personas_rx: props.message.personas_rx }
      });
      topicDispatch({
        type: "SET_REPLY_TO",
        values: { reply_to: props.message?.parameters?.reply_to }
      });
    },
    [props, topicDispatch]
  );

  const menuQuoteMessage = useMemo(
    () => () => {
      topicDispatch({
        type: "SET_MESSAGE",
        values: {}
      });
      topicDispatch({
        type: "SET_QUOTE",
        values: { quote: props.message }
      });
      // default that quote is sent to reply_to
      topicDispatch({
        type: "SET_PERSONAS_RX",
        values: { personas_rx: props.message.personas_rx }
      });
      topicDispatch({
        type: "SET_REPLY_TO",
        values: { reply_to: props.message?.parameters?.reply_to }
      });
    },
    [props, topicDispatch]
  );

  const menuDeleteMessage = useMemo(
    () => () => {
      let newParameters = { display: false }; // can be true, but then need to handle the message order
      let j = { ...props.message };
      j.mdialog && j.peer === props.you && (j.peer = j.mpersona); // if other person was sender, they become the peer
      j.mpersona = props.you;
      j.persona = props.persona;
      j.content = {
        body: "Message deleted",
        msgtype: "w.deleted"
      };
      j.thumbnail = "";
      j.parameters = { ...newParameters };
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
    },
    [databaseState.dexUser, props.message, props.persona, props.you]
  );

  const menuDeleteMsgForMe = useMemo(
    () => () => {
      globalState.logging && console.log("[UIMessage] props:", props);
      let j = { ...props.message };
      j.mpersona = props.you;
      j.mpersona_rx = [props.you];
      //j.parameters = { ...newParameters };
      j.parameters = { display: false };
      globalState.logging && console.log("[UIMessage] menuDeleteMessage j", j);
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
    },
    [databaseState.dexUser, props]
  );

  const menuEvict = useMemo(
    () => () => {
      let j = {
        type: "w.t.evict",
        version: props.version,
        smid: randomString(8),
        ts_sender: timestamp(),
        mtopic: props.message.mtopic,
        mpersona: props.you,
        bparty: props.message.mpersona
      };
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
    },
    [
      databaseState.dexUser,
      props.message.mpersona,
      props.message.mtopic,
      props.version,
      props.you
    ]
  );

  const screenH = useMemo(
    () => props.containerWidth * 0.8,
    [props.containerWidth]
  );
  const screenW = useMemo(
    () => props.containerWidth * 0.8,
    [props.containerWidth]
  );

  const heightStyle = useMemo(
    () => (h) => {
      if (screenH > maxH) return { height: `${maxH}px` };
      else
        return {
          height: `${screenH}px`
        };
    },
    [screenH]
  );

  const widthStyle = useMemo(
    (hgt, wdth) => () => {
      let w = screenW;
      if ((hgt * w) / wdth > maxH) w = (maxH * w) / ((hgt * w) / wdth);
      return {
        width: `${w}px`
      };
    },
    [screenW]
  );

  useEffect(() => {
    if (showImg && Object.keys(showImg)?.length > 0) {
      window.addEventListener("popstate", handleClose);
      window.history.pushState({ id: 2 }, null, "modal");
    }
    return () => {
      window.removeEventListener("popstate", handleClose);
    };
  }, [showImg]);

  const handleImageSize = useMemo(
    () => (wdth, hgt) => {
      let ts = convHrsMins(
        timeFormat(
          timestamp(
            props.message.ts_server,
            props.message.ts_origin_server,
            props.message.ts_sender
          )
        ),
        "HH:mm"
      );
      if (wdth < hgt) {
        return heightStyle();
      } else {
        return widthStyle();
      }
    },
    [heightStyle, widthStyle]
  );

  // useEffect(() => {
  //   newMsgImg.sort(function (a, b) {
  //     let nameA
  //     let nameB
  //     if (a.name && b.name) {
  //       nameA = a.name.toLowerCase()
  //       nameB = b.name.toLowerCase()
  //     }
  //     if (nameA < nameB) //sort string ascending
  //       return -1
  //     if (nameA > nameB)
  //       return 1
  //     return 0 //default return value (no sorting)
  //   })
  // }, [newMsgImg]);

  // useEffect(() => {
  //   if (props.message?.parameters?.images?.length > 0) {
  //     props.message?.parameters?.images.map((imgs, index) => {
  //       if (imgs.width && imgs?.height) {
  //         // setNewMsgImg(msgImg);
  //       } else {
  //         let wid = 0;
  //         let hei = 0;
  //         if (imgs.width && imgs.width > 0) wid = imgs.width;
  //         if (imgs.height && imgs.height > 0) hei = imgs.height;
  //         if (
  //           !msgImg.filter((i) => {
  //             return i.large === imgs.large;
  //           }).length > 0 &&
  //           !wid > 0 &&
  //           !hei > 0) {
  //           let i = new Image();
  //           i.onload = function () {
  //             msgImg.map((el) => {
  //               if (isEqual(el.thumbnail, imgs.thumbnail)) {
  //                 el.imagethumbnail = "data:image/png;base64," + imgs.thumbnail;
  //                 el.wdth = i.width;
  //                 el.hgt = i.height;
  //               }
  //             });
  //             if (wid === 0) wid = i.width;
  //             if (hei === 0) hei = i.height;
  //             if (
  //               !(newMsgImg.filter((mi) => mi.image === imgs.image).length > 0)
  //             ) {
  //               // setNewMsgImg((newMsgImg) => [
  //               //   ...newMsgImg,
  //               //   {
  //               //     image: "data:image/png;base64," + imgs.thumbnail,
  //               //     wdth: wid,
  //               //     hgt: hei
  //               //   }
  //               // ]);
  //             }
  //             // else setNewMsgImg(msgImg)
  //           };
  //         }
  //         // else setNewMsgImg(msgImg);
  //       }
  //     });
  //   } else if (props.message.thumbnail) {
  //     let i = new Image();
  //     i.onload = function () {
  //       // setNewMsgImg([
  //       //   { image: msgImg[0]?.image, width: i.width, height: i.height }
  //       ]);
  //     };
  //     i.src = "data:image/png;base64," + props.message.thumbnail;
  //   }
  // }, [props.message?.parameters?.images, props.message.thumbnail, msgImg]);

  const menuItems = useMemo(
    () => [
      // !!(props.message?.parameters?.allow_whisper !== "false") &&
      // props.message.mpersona !== props.you && {
      //   text: "Whisper",
      //   callback: menuClick,
      // },
      // process.env.REACT_APP_MODE === "hidden" &&
      //   props.message.mpersona !== props.you && {
      //     text: "Go to private chat",
      //     callback: menuClickDialog
      //   },
      // props.message.mpersona !== props.you && {
      //   text: "Report user",
      //   callback: menuClick,
      // },
      // props.message.mpersona !== props.you && {
      //   text: "Block user",
      //   callback: menuClick2,
      // },
      (props.roles?.includes("admin") ||
        props.roles?.includes("owner") ||
        props.message.mpersona === props.you) && {
        text: "Edit",
        callback: () => menuEditMessage()
      },
      {
        text: "Quote",
        callback: () => menuQuoteMessage()
      },
      // process.env.REACT_APP_MODE === "dev" &&
      {
        text: "Sticky note",
        callback: () => {
          topicDispatch({
            type: "SET_PERSONAS_RX",
            values: {
              personas_rx: [
                {
                  mpersona: props.you,
                  persona: props.persona
                }
              ]
            }
          });
        }
      },
      {
        text: "Delete for me",
        callback: menuDeleteMsgForMe
      },
      // If ! a sticky note
      !(
        props.message?.personas_rx?.length === 1 &&
        props.message.mpersona === props.message.personas_rx[0]?.mpersona
      ) &&
        (props.roles?.includes("admin") ||
          props.roles?.includes("owner") ||
          props.message.mpersona === props.you) && {
          text: "Delete for everyone",
          callback: menuDeleteMessage
        }
    ],
    [
      menuDeleteMessage,
      menuDeleteMsgForMe,
      menuEditMessage,
      menuQuoteMessage,
      props.message.mpersona,
      props.message?.msg_idx,
      props.message.personas_rx,
      props.persona,
      props.roles,
      props.you
    ]
  );

  const menuBparty = useMemo(
    () => [
      // props.message.mpersona !== props.you && {
      //   text: "Evict",
      //   callback: menuEvict,
      // },
      props.message.mpersona !== props.you &&
        props.topicType !== "dialog" && {
          // process.env.REACT_APP_MODE === "dev" &&
          text: "Whisper",
          callback: () => {
            topicDispatch({
              type: "SET_PERSONAS_RX",
              values: {
                personas_rx: [
                  {
                    mpersona: props.message.mpersona,
                    persona: props.message.persona
                  },
                  {
                    mpersona: props.you,
                    persona: props.persona
                  }
                ]
              }
            });
          }
        },
      process.env.REACT_APP_MODE === "hidden" &&
        props.message.mpersona !== props.you && {
          text: "Go to private chat",
          callback: menuClickDialog
        },
      {
        text: "Quote",
        callback: () => menuQuoteMessage()
      },
      // process.env.REACT_APP_MODE === "dev" &&
      {
        text: "Sticky note",
        callback: () => {
          topicDispatch({
            type: "SET_PERSONAS_RX",
            values: {
              personas_rx: [
                {
                  mpersona: props.you,
                  persona: props.persona
                }
              ]
            }
          });
        }
      },
      {
        text: "Delete for me",
        callback: menuDeleteMsgForMe
      },
      // If ! a sticky note
      !(
        props.message?.personas_rx?.length === 1 &&
        props.message.mpersona === props.message.personas_rx[0]?.mpersona
      ) &&
        props.roles &&
        (props.roles.includes("admin") ||
          props.roles.includes("owner") ||
          props.message.mpersona === props.you) && {
          text: "Delete for everyone",
          callback: menuDeleteMessage
        }
    ],
    [
      menuClickDialog,
      menuDeleteMessage,
      menuDeleteMsgForMe,
      menuQuoteMessage,
      props.message.mpersona,
      props.message?.msg_idx,
      props.message.personas_rx,
      props.message.persona,
      props.persona,
      props.roles,
      props.topicType,
      props.you
    ]
  );

  const withEmojis = /\p{Extended_Pictographic}/gu;

  const ClickableBox = ({ onClick, onDoubleClick }) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
      onClick,
      onDoubleClick
    );

    return (
      <div onClick={handleClick} onDoubleClick={handleDoubleClick}>
        <img
          id={"avatar_" + props.message.smid}
          // or should we use msgid?
          key={"avatar_" + props.message.smid}
          className="avatar"
          src={props.avatar}
          alt={props.message.persona}
          // onClick={() => {
          //   getAndShowImage();
          // }}
        />
        {/* Click or double click */}
      </div>
    );
  };

  const handleOnclick = useMemo(
    () => () => {
      topicDispatch({
        type: "SET_PERSONAS_RX",
        values: { personas_rx: props.message.personas_rx }
      });
      topicDispatch({
        type: "SET_REPLY_TO",
        values: { reply_to: props.message.parameters?.reply_to }
      });
    },
    [props.message.personas_rx, props.message.parameters?.reply_to]
  );

  const calcTransparency = () => {
    let root = document.getElementById("root");
    let tVal = root.style.getPropertyValue("--whisper_background_transparency");
    if (tVal === undefined || !tVal) tVal = 0.5;
    return 1 - tVal;
  };

  const getArrOfEmojis = (str) => {
    str.match(withEmojis);
    let theArr = "";
    let exitLoop = false;
    for (let x = 0; !exitLoop && x < str.length; x++) {
      if (str.charCodeAt(x) !== 32) {
        if (str.charCodeAt(x) === 9998) theArr = theArr + str[x];
        else exitLoop = true;
      } else if (x + 1 < str.length && isEmoji(str[x + 1]))
        theArr = theArr + str[x];
    }
    return theArr;
  };

  const Emoji = (props) => (
    <span
      className="emoji"
      style={{
        fontSize: "1.5rem",
        height: "24px",
        width: "24px"
      }}
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
    >
      {props.symbol}
    </span>
  );

  const whispBackColor = (opac) => {
    let wCol;
    let trans;
    if (opac) trans = opac;
    else trans = calcTransparency();
    let retStr = {};
    if (colorBasis) {
      wCol = CalcWhispColor(colorBasis);
      retStr =
        "rgba(" + wCol.r + "," + wCol.g + "," + wCol.b + "," + trans + ")";
    }
    return retStr;
  };

  const msgStyles = {
    msg: {
      borderColor: props?.message?.parameters?.at
        ?.map((a) => a?.mpersona)
        .includes(props?.message?.recipient)
        ? "var(--topic_at_symbol_color)"
        : {},
      backgroundColor:
        bubbleColour(props.message?.parameters?.bubbcolor) !== ""
          ? bubbleColour(props.message?.parameters?.bubbcolor)
          : {},
      maxWidth: infoMsg
        ? !props.message.parameters?.edited
          ? "94%"
          : "100%"
        : colWidth && colWidth > 0
        ? colWidth * 0.9
        : {},
      width: infoMsg
        ? !props.message.parameters?.edited
          ? "94%"
          : "100%"
        : {},
      paddingTop: props.message.parameters?.service_msg
        ? // ? props.prevOriginatorSame
          // ? "0.5rem"
          // : "1rem"
          props.avatar
          ? "1rem"
          : props.prevOriginatorSame
          ? "0.5rem"
          : "0rem"
        : {},
      paddingRight: props.message.parameters?.service_msg ? "0.5rem" : {}
    },
    menu: {
      height:
        // props.prevOriginatorSame
        !props.avatar
          ? props.message?.personas_rx?.length > 1 &&
            props.pinsPresent === false &&
            !infoMsg
            ? `${menuHgt + 20}px`
            : `${menuHgt + 40}px`
          : props.message?.personas_rx?.length > 1 &&
            props.pinsPresent === false &&
            !infoMsg
          ? `${menuHgt + 40}px`
          : `${menuHgt + 70}px`,
      width:
        props.message.parameters?.service_msg &&
        (globalState.devMode === "true" || globalState.devMode === true) &&
        menuHgt > 0
          ? ui_msgVessel.current?.clientWidth * 0.96
          : {},
      backgroundColor:
        colorBasis && props.pinsPresent === false && !infoMsg
          ? whispBackColor()
          : {},
      flexDirection:
        props.message.parameters?.service_msg &&
        (globalState.devMode === "true" || globalState.devMode === true) &&
        menuHgt > 0
          ? {}
          : infoMsg
          ? "column"
          : {}
    },
    menuNot: {
      backgroundColor:
        colorBasis && props.pinsPresent === false && !infoMsg
          ? whispBackColor()
          : props.message.parameters?.service_msg
          ? "white"
          : {},
      flexDirection: infoMsg ? "column" : {}
    }
  };

  const onImgLoad = ({ target: img }) => {
    setDimensions({
      height: img.offsetHeight,
      width: img.offsetWidth
    });
  };

  // const getWidth = (w, h) => {
  //   if (w > h) return `${screenW}px`;
  //   else if (screenH > maxH) return `${maxH / (h / w)}px`;
  //   else return `${screenH / (h / w)}px`;
  // };

  // const getHeight = (w, h) => {
  //   if (w > h) return `${screenW * (h / w)}px`;
  //   else if (screenH > maxH) return `${maxH}px`;
  //   else return `${screenH}px`;
  // };

  const handleClickOutside = () => {};

  const handleClose = () => {
    setShowImg({});
    if (window.location?.href?.includes("modal"))
      if (history.length > 1) history.goBack();
  };

  const dispAtParameters = (data) => {
    let atArr = [];
    props.message?.parameters?.at?.forEach((element) => {
      let name = element?.name || element?.persona;
      if (name) {
        let regex = new RegExp("@" + name + "((?=[,\\s\\p{P}\\p{S}]|$))", "g");
        let matches = [...data.matchAll(regex)];
        matches.forEach((match) => {
          let obj = {
            persona: name,
            start: match.index,
            end: match.index + match[0].length
          };
          atArr = [...atArr, obj];
        });
      }
    });
    let finStr = [];
    if (atArr.length > 0) {
      let boldOn = data[0] === "@" && atArr[0].start === 0;
      for (let count = 0; count < data.length - 1; ) {
        let tmpObj = {};
        let ANStrt = atArr.filter((x) => x?.start > count);
        let ANEnd = atArr.filter((x) => x?.end > count);
        if (!boldOn && ANStrt?.length > 0) {
          tmpObj.txt = data.slice(count, ANStrt[0]?.start - 1);
          tmpObj.form = "normal";
          boldOn = true;
          count = ANStrt[0]?.start - 1;
        } else if (boldOn && ANEnd?.length > 0) {
          tmpObj.txt = data.slice(count, ANEnd[0]?.end);
          tmpObj.form = "bold";
          boldOn = false;
          count = ANEnd[0]?.end;
        } else if (
          count < data.length - 1 &&
          ANStrt?.length === 0 &&
          ANEnd?.length === 0
        ) {
          tmpObj.txt = data.slice(count, data.length);
          tmpObj.form = "normal";
          count = data.length;
        }
        finStr.push(tmpObj);
      }
    }
    return finStr;
  };

  // ---------------------------------------- //
  // --- swiper ------------------------------//
  // unimplement because touch-action -> none prevents scrolling in some scenarios
  // let checkSwipes = true;

  // // Disable swiper for service messages
  // if (props.message?.parameters?.service_msg === true) {
  //   checkSwipes = false;
  // }

  // const leftSwipe = (h, v) => {
  //   let swipeArea = document.getElementById("swiper").getBoundingClientRect();
  //   process.env.REACT_APP_DEBUG_THIS?.includes("dbl") &&
  //     console.log(
  //       "swipe?",
  //       "horizontal",
  //       h,
  //       "swipe w",
  //       swipeArea.width / 4,
  //       "vertical",
  //       v,
  //       "swipe h",
  //       swipeArea.height
  //     );
  //   h >= swipeArea.width / 4 && v <= swipeArea.height && menuQuoteMessage();
  // };

  // let xStart = 0;
  // let xEnd = 0;
  // let yStart = 0;
  // let yEnd = 0;

  // const handlePointerDown = (e) => {
  //   if (!checkSwipes) {
  //     return;
  //   }
  //   xStart = e.clientX;
  //   yStart = e.clientY;
  // };

  // const handlePointerUp = (e) => {
  //   if (!checkSwipes) {
  //     return;
  //   }
  //   xEnd = e.clientX;
  //   yEnd = e.clientY;
  //   xStart !== xEnd && leftSwipe(xStart - xEnd, yEnd - yStart);
  //   xStart = 0;
  //   xEnd = 0;
  //   yStart = 0;
  //   yEnd = 0;
  // };
  //
  // use this to debug in case swipe does not work when it should
  // const handlePointerCancel = (e) => {
  //   console.log("xxx canned", e);
  // };

  // -- end swiper ---------------------------//
  // ---------------------------------------- //

  useEffect(() => {
    if (isMobileTablet()) {
      setColWidth(props.containerWidth * 0.9);
    } else setColWidth(msgRef.current?.parentElement?.offsetWidth);
  }, []);

  const loadMenu = (thismtopic, thismpersona, thisyou, msgtype) => {
    return (
      <ClickOutsideDetector
        caller="dropDownCard"
        listen
        onClickOutside={() => {
          setOpen(false);
        }}
        onClick={() => {}}
      >
        <ul
          ref={menuRef}
          className={
            side === "left" ? "UI-dropdown-list-left" : "UI-dropdown-list-right"
          }
          style={
            menuRef.current?.clientWidth + 30 >
              msgVessel.current?.clientWidth && side === "left"
              ? {
                  transform: `translateX(${
                    menuRef.current?.clientWidth -
                    msgVessel.current?.clientWidth +
                    10
                  }px)`
                }
              : {}
          }
        >
          {(thismtopic?.startsWith("t_")
            ? thismpersona === thisyou
              ? menuItems.filter((m) => m)
              : menuBparty.filter((m) => m)
            : menuItems.filter((m) => m)
          ).map((item, i) => (
            <li
              className="UI-dropdown-item"
              key={i}
              onClick={() => {
                setOpen(false);
                item.callback();
              }}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </ClickOutsideDetector>
    );
  };

  let content = (
    <div>
      <div
        //id="swiper"
        className="UI-message-container"
        // style={menuHgt > 0 ? msgStyles.menu : msgStyles.menuNot}
        style={
          props.message.parameters?.service_msg &&
          (globalState.devMode === "true" || globalState.devMode === true)
            ? msgStyles.menuNot
            : menuHgt > 0
            ? msgStyles.menu
            : msgStyles.menuNot
        }
        type={props.message.parameters?.system_msg ? "system" : side}
        //onPointerDown={handlePointerDown}
        //onPointerUp={handlePointerUp}
        //onPointerCancel={handlePointerCancel}
      >
        <div
          className="UI-message-avatar-name-container"
          style={
            props.pinsPresent || infoMsg
              ? { maxWidth: "100%", width: "100%" }
              : {}
          }
          ref={msgRef}
          type={
            props.message.parameters?.system_msg
              ? "system"
              : props.message.parameters?.service_msg
              ? "service"
              : side
          }
        >
          <div
            className={
              props.message.parameters?.system_msg || props.pinsPresent
                ? "UImessage-display-none"
                : (props.avatar && side === "left") || props.sda
                ? // : props.avatar && side === "left"
                  "avatar-frame"
                : props.prevOriginatorSame || side === "right"
                ? "avatar-frame-none"
                : "avatar-frame"
            }
            type={side}
          >
            <span
              className={
                side === "left" && props.avatar
                  ? "flag-left"
                  : // : side === "left" && !props.prevOriginatorSame
                  side === "left" && (!props.prevOriginatorSame || props.sda)
                  ? "flag-left"
                  : "UImessage-display-none"
              }
              style={infoMsg ? { display: "none" } : {}}
            >
              {(globalState.devMode === "true" ||
                globalState.devMode === true) &&
                props.message.mpersona && (
                  // (globalState.idFlag === "true" || globalState.idFlag === true) && (
                  <UIPersonaFlag
                    mpersona={props.message.mpersona}
                    left_right={side}
                  />
                )}
            </span>
            {props.avatar && side === "left" ? (
              // &&
              // !props.prevOriginatorSame
              // || timeDiff > avatarTimeLapse)
              <div style={infoMsg ? { display: "none" } : {}}>
                <ClickableBox
                  onClick={() => {
                    history.push("/imageDisplay", {
                      avatar: true,
                      picAttach: false,
                      thumb: props.avatar,
                      descriptor: props.descriptor,
                      scope: props.message.mpersona,
                      imgdigest: props.descriptor.imgdigest
                    });
                  }}
                  onDoubleClick={() => {
                    if (props.topicType?.toLowerCase() === "dialog") {
                    } else
                      topicDispatch({
                        type: "SET_PERSONAS_RX",
                        values: {
                          personas_rx: [
                            {
                              mpersona: props.message.mpersona,
                              persona: props.message.persona
                            },
                            {
                              mpersona: props.you,
                              persona: props.persona
                            }
                          ]
                        }
                      });
                  }}
                />
              </div>
            ) : side === "left" && (!props.prevOriginatorSame || props.sda) ? (
              // ) : side === "left" && !props.prevOriginatorSame ? (
              // !props.prevOriginatorSame
              <div
                id={"avatar_string_" + props.message.smid}
                // className="avatar-string"
                onDoubleClick={() => {
                  if (props.topicType?.toLowerCase() === "dialog") {
                  } else {
                    topicDispatch({
                      type: "SET_PERSONAS_RX",
                      values: {
                        personas_rx: [
                          {
                            mpersona: props.message.mpersona,
                            persona: props.message.persona
                          },
                          {
                            mpersona: props.you,
                            persona: props.persona
                          }
                        ]
                      }
                    });
                  }
                }}
              >
                <GetInitials
                  head_list={true}
                  str={props?.dialog?.dialog || props?.message?.name}
                ></GetInitials>
              </div>
            ) : (
              <span className="UImessage-display-none" />
            )}
            <span
              className={
                props.message.name === "World"
                  ? "UImessage-display-none"
                  : props.avatar
                  ? "UI-persona-name"
                  : !props.prevOriginatorSame || props.sda
                  ? // : !props.prevOriginatorSame
                    "UI-persona-name"
                  : "UImessage-display-none"
                // props.message.name === "World"
                //   || props.prevOriginatorSame
                //   ? "UImessage-display-none"
                //   : "UI-persona-name"
              }
              style={infoMsg ? { display: "none" } : {}}
            >
              {side !== "right" && props.message.name}
            </span>
          </div>
          {props.message.parameters?.service_msg ? (
            <div
              className={"UI-message"}
              ref={ui_msgVessel}
              type="service"
              // type={left_or_right(props)}
              style={
                props.message.parameters?.service_msg &&
                (globalState.devMode === "true" ||
                  globalState.devMode === true) &&
                menuHgt > 0
                  ? msgStyles.menu
                  : msgStyles.msg
              }
              // style={msgStyles.msg}
            >
              <ServiceMessageStateProvider>
                <ServiceMsg
                  roles={props.roles}
                  you={props.you}
                  persona={props.persona}
                  message={props.message}
                  type={side}
                ></ServiceMsg>
                {(globalState.devMode === "true" ||
                  globalState.devMode === true) && (
                  <BsThreeDotsVertical
                    className="UI-message-menu"
                    onClick={() => setOpen((open) => !open)}
                  />
                )}
                {(globalState.devMode === "true" ||
                  globalState.devMode === true) &&
                  open &&
                  loadMenu(
                    props.message.mtopic,
                    props.message.mpersona,
                    props.you,
                    "service"
                  )}
              </ServiceMessageStateProvider>
            </div>
          ) : (
            <div
              ref={msgVessel}
              style={
                props.message.parameters?.edited ||
                (props.message.parameters?.at &&
                  props.message.parameters?.at?.length > 0)
                  ? infoMsg
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "100%",
                        maxWidth: "100%"
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        minWidth: "50%"
                      }
                  : infoMsg && !props.message.parameters?.edited
                  ? { width: "100%", maxWidth: "100%" }
                  : { minWidth: "50%" }
              }
            >
              <span
                style={
                  side === "right"
                    ? { textAlign: "right" }
                    : {
                        display: "none",
                        width: "100%"
                      }
                }
              >
                {props.message.parameters?.at &&
                  props.message.parameters?.at?.length > 0 &&
                  props.message.parameters?.at
                    ?.map((a) => a?.mpersona)
                    .includes(props.message?.recipient) && (
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "var(--topic_at_symbol_color)"
                      }}
                    >
                      @
                    </span>
                  )}
                {props.message.parameters?.edited && (
                  <Emoji label="pencil" symbol="✎" />
                )}
              </span>
              <div
                className={
                  props.message.personas_rx
                    ? props.message.personas_rx?.length === 1 &&
                      props.message.mpersona ===
                        props.message.personas_rx[0]?.mpersona
                      ? "sticky-note"
                      : "UI-message-whisper UI-message-subgroup"
                    : "UI-message"
                }
                type={
                  infoMsg
                    ? "left"
                    : props.message.parameters?.system_msg
                    ? "systemcenter"
                    : side
                }
                style={msgStyles.msg}
              >
                {!!(props.message?.parameters?.show_menu !== "false") &&
                  (props.message.mtopic?.startsWith("t_")
                    ? props.message.mpersona === props.you
                      ? menuItems.filter((m) => m).length
                      : menuBparty.filter((m) => m).length // exclude undefined menu items
                    : menuItems.filter((m) => m).length) > 0 && (
                    <div
                      className="UI-buttondropdown"
                      type="right"
                      style={
                        side === "left"
                          ? {
                              width: `transform:translateX(-${menuRef.current?.clientWidth}px)`
                            }
                          : {}
                      }
                    >
                      <div
                        style={
                          props.message.parameters?.system_msg
                            ? { display: "none" }
                            : {}
                        }
                      >
                        <BsThreeDotsVertical
                          className="UI-message-menu"
                          onClick={() => setOpen((open) => !open)}
                        />
                        {open &&
                          loadMenu(
                            props.message.mtopic,
                            props.message.mpersona,
                            props.you
                          )}
                      </div>
                    </div>
                  )}
                <div
                  className={
                    props.message.parameters?.system_msg
                      ? "content-system"
                      : "content"
                  }
                >
                  <p
                    className={
                      props.message.parameters?.system_msg
                        ? props.message.mpersona_rx?.length > 1 &&
                          props.pinsPresent === false
                          ? "UI-message-whisper-subtitle"
                          : "UImessage-display-none"
                        : props.message.mpersona_rx?.length > 1 &&
                          props.pinsPresent === false
                        ? "UI-message-whisper-subtitle"
                        : "subtitle"
                    }
                    style={{
                      color: textColour(props.message?.parameters?.bubbcolor)
                    }}
                  >
                    {props.message.parameters?.subtitle}
                  </p>
                  {props.message.parameters?.quote && (
                    <UIQuote
                      message={props.message.parameters?.quote}
                      setShowImg={setShowImg}
                      dexUser={databaseState.dexUser}
                    />
                  )}
                  {showImg && Object.keys(showImg)?.length > 0 && (
                    <Modals
                      mainStyle={{
                        width: "100%",
                        height: "100%"
                      }}
                      overWritemncn={true}
                      style={{
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%"
                      }}
                      onClickOutside={() => handleClickOutside()}
                    >
                      <div>
                        <UIProfileModalNew
                          image={
                            showImg.large || props.message?.parameters?.image
                          }
                          // props.message?.parameters?.images &&
                          // props.message?.parameters?.images?.length > 0
                          // ? items.large
                          // : props.message?.parameters?.image}
                          // : props.message?.parameters?.image}
                          avatar={false}
                          picAttach={true}
                          thumb={`data:image/png;base64,${showImg.image}`}
                          descriptor={showImg}
                          onClose={() => handleClose()}
                        />
                        <div className="UI-login-container"></div>
                      </div>
                    </Modals>
                  )}
                  {/* skeleton screens sjow thumbnail */}
                  {
                    //     (() => {
                    //   console.log(
                    //     "!!!image items",
                    //     props.message?.msg_idx,
                    //     msgImgLoaded,
                    //     skeletonMsgImg,
                    //     msgImgLoaded,
                    //     msgImg
                    //   );
                    //   return true;
                    // })() &&
                    // !msgImgLoaded &&
                    skeletonMsgImg &&
                      skeletonMsgImg
                        .sort((a, b) =>
                          a.index >= 0 && b.index >= 0
                            ? a.index > b.index
                              ? 1
                              : -1
                            : a.name > b.name
                            ? 1
                            : -1
                        )
                        .map((items, index) => {
                          return props.message?.parameters?.images?.length >
                            0 ? (
                            <img
                              className="thumbnail"
                              key={items.index}
                              style={handleImageSize(items.width, items.height)}
                              src={
                                "data:image/png;base64," +
                                (items.image || items.thumbnail)
                              }
                              alt={props.message.name}
                              onClick={() => {
                                setShowImg(items);
                              }}
                            />
                          ) : (
                            <img
                              onLoad={onImgLoad}
                              className="thumbnail"
                              key={items.index}
                              style={handleImageSize(
                                dimensions.width,
                                dimensions.height
                              )}
                              src={
                                "data:image/png;base64," +
                                (items.image || items.thumbnail)
                              }
                              alt={props.message.name}
                              onClick={() => {
                                setShowImg(items);
                              }}
                            />
                          );
                        })
                  }
                  {
                    false &&
                      msgImgLoaded &&
                      msgImg
                        .sort((a, b) =>
                          a.index >= 0 && b.index >= 0
                            ? a.index > b.index
                              ? 1
                              : -1
                            : a.name > b.name
                            ? 1
                            : -1
                        )
                        // msgImg.sort((a, b) => a.name > b.name ? 1 : -1)
                        .map((items, index) => (
                          <div key={index}>
                            {props.message?.parameters?.images?.length > 0 ? (
                              <img
                                className="thumbnail"
                                key={items.index}
                                style={handleImageSize(
                                  items.width,
                                  items.height
                                )}
                                src={"data:image/png;base64," + items.image}
                                alt={props.message.name}
                                onClick={() => {
                                  setShowImg(items);
                                }}
                              />
                            ) : (
                              <div>
                                <img
                                  className="thumbnail"
                                  key={items.index}
                                  onLoad={onImgLoad}
                                  style={handleImageSize(
                                    dimensions.width,
                                    dimensions.height
                                  )}
                                  src={"data:image/png;base64," + items.image}
                                  alt={props.message.name}
                                  onClick={() => {
                                    setShowImg(items);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          // <Link
                          //   key={index}
                          //   // ref={ref}
                          //   onClick={() => {
                          //     // topicDispatch({
                          //     //   type: "SET_COORDS",
                          //     //   values: { ycoord: scrolled }
                          //     // });
                          //     sessionStorage.setItem("imgycoord", scrolled);
                          //   }
                          //   }
                          //   to={{
                          //     pathname: "/imageDisplay",
                          //     state: {
                          //       image: props.message?.parameters?.images &&
                          //         props.message?.parameters?.images?.length > 0
                          //         ? items.large
                          //         : props.message?.parameters?.image,
                          //         // props.message?.parameters?.image,
                          //       // image: items.large,
                          //       // (props.message?.parameters?.images &&
                          //       //   props.message?.parameters?.images?.length > 0 &&
                          //       //   props.message?.parameters?.images[0]?.large) ||
                          //       // props.message?.parameters?.image,
                          //       avatar: false,
                          //       picAttach: true,
                          //       thumb: "data:image/png;base64," + items.image,
                          //       descriptor: descriptor
                          //     }
                          //   }}
                          // >
                          //   {props.message?.parameters?.images?.length > 0 ? (
                          //     <img
                          //       className="thumbnail"
                          //       style={newImgArr
                          //         // ? {
                          //         //   width: getWidth(items.width, items.height),
                          //         //   height: getHeight(items.width, items.height)
                          //         // }
                          //         ? handleImageSize(items.wdth, items.hgt)
                          //         : {}}
                          //       src={"data:image/png;base64," + items.image}
                          //       alt={props.message.name}
                          //     />
                          //   ) : (
                          //     <img
                          //       className="thumbnail"
                          //         style={{
                          //           width: getWidth(items.width, items.height),
                          //           height: getHeight(items.width, items.height)
                          //         }}
                          //       src={"data:image/png;base64," + items.image}
                          //       alt={props.message.name}
                          //     />
                          //   )}
                          // </Link>
                        ))
                    // : (props.message?.parameters?.image && msgImgLoaded) &&
                    // <Link
                    //     ref={ref}
                    //     onClick={() => {
                    //       topicDispatch({
                    //         type: "SET_COORDS",
                    //         values: { ycoord: scrolled }
                    //       });
                    //       sessionStorage.setItem("imgycoord", scrolled);
                    //     }}
                    //   to={{
                    //     pathname: "/imageDisplay",
                    //     state: {
                    //       image:
                    //         (props.message?.parameters?.images &&
                    //           props.message?.parameters?.images.length > 0 &&
                    //           props.message?.parameters?.images[0]?.large) ||
                    //         props.message?.parameters?.image,
                    //       avatar: false,
                    //       picAttach: true,
                    //       thumb: "data:image/png;base64," + msgImgOld,
                    //       descriptor: descriptor
                    //     }
                    //   }}
                    // >
                    //   <img
                    //     className="thumbnail"
                    //     style={picStyle}
                    //     src={"data:image/png;base64," + msgImgOld}
                    //     alt={props.message.name}
                    //   />
                    //   </Link>
                  }

                  {props &&
                  props.message &&
                  props.message.parameters &&
                  props.message.parameters.choices ? (
                    <UIVote
                      message={props.message}
                      persona={props.persona}
                      mpersona={props.you}
                      muid={props.muid}
                    />
                  ) : (
                    <div></div>
                  )}
                  {props &&
                  props.message &&
                  props.message.parameters &&
                  ((props.message.parameters.attachments &&
                    props.message.parameters.attachments[0]) ||
                    props.message.parameters.attachment) ? (
                    <div className="attachment-incl">
                      <span style={{ fontSize: "0.8rem" }}>
                        {props.message?.parameters?.attachments?.length > 0
                          ? props.message?.parameters?.attachments
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((item) => (
                                <div
                                  className="attachment-item"
                                  // style={{
                                  //   display: "grid",
                                  //   gridTemplateColumns: "10% 80%",
                                  //   gap: "0.5rem",
                                  //   alignContent: "center",
                                  //   alignItems: "center",
                                  //   position: "relative",
                                  //   fontSize: "0.9rem"
                                  // }}
                                >
                                  {/* <FaFileDownload
                                    style={{
                                      fontSize: "1rem"
                                    }}
                                  /> */}
                                  <IconContext.Provider
                                    value={{
                                      size: "1.2rem"
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "center"
                                      }}
                                    >
                                      <GetFileExt fName={item.name} />
                                    </div>
                                  </IconContext.Provider>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      marginLeft: "0.25rem",
                                      wordBreak: "normal"
                                    }}
                                    onClick={() => {
                                      downloadAttachment(item);
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              ))
                          : props.message?.parameters?.attachment?.name}
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {props.message.parameters?.system_msg ? (
                    <div
                      className="UI-message-content-body-system"
                      type={
                        props.avatar
                          ? "false"
                          : props.prevOriginatorSame.toString()
                      }
                      // props.prevOriginatorSame.toString()}
                      style={
                        props.message.personas_rx &&
                        props.message.personas_rx?.length === 1 &&
                        props.message.mpersona ===
                          props.message.personas_rx[0]?.mpersona
                          ? { whiteSpace: "pre-wrap" }
                          : {
                              whiteSpace: "pre-wrap",
                              fontSize: getFontSize(
                                props.message.content?.body
                              ),
                              wordBreak: "break-word"
                            }
                      }
                    >
                      {props.message.content?.body}
                    </div>
                  ) : (
                    <Linkify options={linkProps}>
                      <div
                        className={
                          props.message.personas_rx &&
                          props.message.personas_rx.length === 1 &&
                          props.message.mpersona ===
                            props.message.personas_rx[0]?.mpersona
                            ? "sticky-note-body"
                            : "UI-message-content-body"
                        }
                        type={
                          props.avatar
                            ? "false"
                            : props.prevOriginatorSame.toString()
                        }
                        // props.prevOriginatorSame.toString()}
                        style={
                          props.message.personas_rx &&
                          props.message.personas_rx.length === 1 &&
                          props.message.mpersona ===
                            props.message.personas_rx[0]?.mpersona
                            ? props.message.parameters?.quote
                              ? {
                                  whiteSpace: "pre-wrap",
                                  marginLeft: "0.25rem",
                                  marginRight: "0.25rem"
                                }
                              : { whiteSpace: "pre-wrap" }
                            : props.message.parameters?.quote
                            ? {
                                whiteSpace: "pre-wrap",
                                fontSize: getFontSize(
                                  props.message.content?.body
                                ),
                                marginLeft: "0.25rem",
                                marginRight: "0.25rem"
                              }
                            : props.message.parameters?.edited
                            ? {
                                whiteSpace: "pre-wrap"
                              }
                            : {
                                whiteSpace: "pre-wrap",
                                fontSize: getFontSize(
                                  props.message.content?.body
                                )
                              }
                        }
                      >
                        {props.message.parameters?.edited ? (
                          <div>
                            <span
                              style={{
                                fontSize: "1.5rem"
                              }}
                            >
                              {getArrOfEmojis(props.message.content?.body)}
                            </span>
                            <span
                              className="UI-message-content-body"
                              style={
                                props.message.personas_rx &&
                                props.message.personas_rx.length === 1 &&
                                props.message.mpersona ===
                                  props.message.personas_rx[0]?.mpersona
                                  ? { whiteSpace: "pre-wrap" }
                                  : {
                                      whiteSpace: "pre-wrap"
                                    }
                              }
                            >
                              {props.message?.parameters?.at?.length > 0 ? (
                                <span>
                                  {dispAtParameters(
                                    props.message?.content?.body
                                  )?.length > 0
                                    ? dispAtParameters(
                                        props.message?.content?.body
                                      ).map((x) => (
                                        <span
                                          style={
                                            x?.form === "bold"
                                              ? { fontWeight: "bold" }
                                              : {}
                                          }
                                        >
                                          {x?.txt}
                                        </span>
                                      ))
                                    : props.message?.content?.body}
                                </span>
                              ) : (
                                props.message.content?.body.substr(
                                  getArrOfEmojis(props.message.content?.body)
                                    .length,
                                  props.message.content?.body.length -
                                    getArrOfEmojis(props.message.content?.body)
                                      .length
                                )
                              )}
                            </span>
                          </div>
                        ) : props.message?.parameters?.at?.length > 0 ? (
                          <span>
                            {dispAtParameters(props.message?.content?.body)
                              ?.length > 0
                              ? dispAtParameters(
                                  props.message?.content?.body
                                ).map((x) => (
                                  <span
                                    style={
                                      x?.form === "bold"
                                        ? { fontWeight: "bold" }
                                        : {}
                                    }
                                  >
                                    {x?.txt}
                                  </span>
                                ))
                              : props.message?.content?.body}
                          </span>
                        ) : (
                          props.message?.content?.body
                        )}
                      </div>
                    </Linkify>
                  )}
                  {colorBasis && !infoMsg && (
                    <button
                      className="UI-button-confined"
                      style={{
                        marginLeft: "auto",
                        maxHeight: "1.3rem",
                        backgroundColor: whispBackColor(1),
                        color: InverseColor(whispBackColor(1))
                      }}
                      onClick={() => handleOnclick()}
                    >
                      {" "}
                      {props.message.personas_rx &&
                        props.message.personas_rx.length > 1 &&
                        // props.message.personas_rx[0].mpersona === props.message.mpersona
                        // ? "Sticky Note"
                        // : props.message.personas_rx &&
                        "Whisper"}
                    </button>
                  )}
                </div>
                {/* {modal} */}
              </div>
              <span
                style={
                  side === "left"
                    ? {
                        textAlign: "right"
                      }
                    : { display: "none" }
                }
              >
                {props.message.parameters?.edited && (
                  <Emoji label="pencil" symbol="✎" />
                )}
                {props.message.parameters?.at
                  ?.map((a) => a?.mpersona)
                  .includes(props.message?.recipient) && (
                  <span
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "var(--topic_at_symbol_color)"
                    }}
                  >
                    @
                  </span>
                  // <Emoji label="at" symbol="@" />
                )}
              </span>
            </div>
          )}
        </div>
        <div
          className="time-style"
          style={
            infoMsg
              ? {
                  display: "flex",
                  justifyContent: "flex-end"
                }
              : props.message?.parameters?.service_msg?.id ===
                "community:status"
              ? { marginLeft: "auto" }
              : {}
          }
          type={
            // infoMsg ? "right" :
            props.message?.parameters?.system_msg ? "system" : side
          }
        >
          {!(props.message.ts_origin_server || props.message.confirmed) &&
            `sending...     `}
          {globalState.devMode === true || globalState.devMode === "true"
            ? convHrsMins(
                timeFormat(
                  timestamp(
                    props.message.ts_server,
                    props.message.ts_origin_server,
                    props.message.ts_sender
                  )
                ),
                "HH:mm:ss"
              )
            : convHrsMins(
                timeFormat(
                  timestamp(
                    props.message.ts_server,
                    props.message.ts_origin_server,
                    props.message.ts_sender
                  )
                ),
                "HH:mm"
              )}
        </div>
      </div>
      {!props.message?.parameters?.system_msg &&
        !props.message?.parameters?.service_msg && (
          <div className="time-style" type={side}>
            {
              <UIReceipt
                msg_idx={props.message.msg_idx}
                receipts={topicState.receipts}
                recipient={props.message?.recipient}
                sender={props.message.mpersona}
              ></UIReceipt>
            }
          </div>
        )}
    </div>
  );
  return content;
};

export default React.memo(UIMessage, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
