import React, { useContext } from "react";
import { timestamp, randomString } from "hooks/helper";

import DatabaseContext from "data/contextDatabase";
import { dex_action } from "data/dexUtils";

const UIPersona = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const sortJoin = (A, B, Delim) => {
    return A < B ? A + Delim + B : B + Delim + A;
  };

  const createTopic = () =>
    // topic
    // description,
    // tag,
    // suppress,
    // topictype,
    // visibility,
    // history,
    // mpersona,
    // minvitees
    {
      if (props.mpersona !== props.rpersona.mpersona) {
        let suppress = true;
        let defaultroles = ["admin", "designer", "tx", "rx"];
        let tag = "dialog";
        let topictype = "dialog";
        let visibility = "private";
        let topicHistory = true;
        let version = props.version;
        let mpersona = props.mpersona;
        let minvitees = [props.rpersona.mpersona];
        let d = {
          topic: `t_${
            props.mpersona < props.rpersona.mpersona
              ? props.mpersona
              : props.rpersona.mpersona
          }_${
            props.mpersona < props.rpersona.mpersona
              ? props.rpersona.mpersona
              : props.mpersona
          }`,
          got_voice: true,
          mtopic: sortJoin(props.mpersona, props.rpersona.mpersona, "_"),
          mpersona: props.mpersona,
          persona: props.persona,
          peer: props.rpersona.mpersona,
          peerpersona: props.rpersona.persona,
          visibility: "PRIVATE_DIALOG"
        };
        let j = {
          type: "w.t.create",
          topic: `t_${
            props.mpersona < props.rpersona.mpersona
              ? props.mpersona
              : props.rpersona.mpersona
          }_${
            props.mpersona < props.rpersona.mpersona
              ? props.rpersona.mpersona
              : props.mpersona
          }`,
          mpersona: mpersona,
          // identifier: identifier,
          defaultroles: defaultroles,
          // description: description,
          tag: tag,
          version: version,
          ts_sender: timestamp(),
          smid: randomString(8)
        };
        if (topictype) j.topictype = topictype;
        if (topicHistory) j.history = topicHistory;
        if (suppress) j.suppress = suppress;
        if (visibility) j.visibility = visibility;
        if (minvitees && minvitees.length > 0) j.minvitees = minvitees;

        // console.log("CREATE TOPIC", j);
        // clear();
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "send",
              doc: j
            }
          });
        // if (window.location?.href?.includes("modal")) {
        //   history.replace("/UIMessageList", {
        //     topic: d
        //   });
        // }
        // else history.push("/UIMessageList", {
        //   topic: d
        // });
        props.onClose();
      } else {
        alert(
          "It appears you are trying to start a conversation with yourself."
        );
      }
    };

  const createDialog = () => {
    props.onClose();
    // if (window.location?.href?.includes("modal")) {
    //   history.replace("/UIMessageList", {
    //     dialog: d,
    //   });
    // }
    // history.push("/UIMessageList", {
    //   dialog: d,
    // });
  };

  const noBorderStyle = {
    borderBottom: "none",
    cursor: "pointer"
  };

  let content = (
    <div>
      <div
        className="UI-topic"
        style={props.noBorder ? noBorderStyle : {}}
        onClick={
          () =>
            props.mtopic
              ? props.mtopic.startsWith("t_")
                ? console.log("New topic, do nothing")
                : alert("Cannot invite to old topics")
              : createTopic() // createDialog() // createTopic()
        }
      >
        <div className="UI-persona-search-list--flex">
          <div
            className="UI-persona-search-list-item-name--flex"
            style={props.roles?.length > 0 ? { cursor: "auto" } : {}}
          >
            {props.rpersona.persona}
          </div>
          <div>
            <p className="UI-persona-search-list-item-last-seen--flex">
              Last online: {props.rpersona.last_seen}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UIPersona, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
