import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { AiOutlineAntDesign, AiOutlineTool } from "react-icons/ai";
import { BiMessageRoundedX, BiMessageRoundedCheck } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  FaArrowRight,
  FaFlagCheckered,
  FaLessThanEqual,
  FaPalette,
  FaRegClock,
  FaShareAlt
} from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { GoMute, GoUnmute, GoPerson } from "react-icons/go";
import { ImBin } from "react-icons/im";
import { IoExitOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineEdit, MdEdit } from "react-icons/md";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";
import DropdownTopicMenu from "ui/Dropdown/dropdownTopicMenu";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import TopicsContext from "contexts/contextTopics";
// import DialogsContext from "contexts/contextDialogs";
import SubscriptionsContext from "contexts/contextSubscriptions";
import LiveCacheContext from "data/contextLiveCache";
import {
  randomString,
  timestamp,
  timestampZero,
  secondsSinceEpoch,
  timestampCutoff
} from "hooks/helper";
import GetInitials from "../utils/GetInitials";
import UICreateTopicModal from "ui/UICreateTopicModal";
import "ui/UI.css";
import Modals from "./Modals";
import "ui/modals.css";
import UIInviteModal from "ui/UIInviteModal";
import UIParticipantsModal from "ui/UIParticipantsModal";
import UISubGroupModal from "ui/UISubGroupModal";
import logo from "../../src/person_outline2.png";
import TopicContext from "contexts/contextTopic";
import UIShareModal from "ui/UIShareModal";
import { getImage64, storeImage } from "data/descriptors";
import { s3ToImage } from "connectivity/s3";
import StyleContext from "contexts/contextStyle";
import { useLiveQuery } from "dexie-react-hooks";
import { dex_action } from "data/dexUtils";

const UITopicHeader = (props) => {
  const size = props.size;
  const topic = props.topic;
  const topicAbout = props.description;
  const label =
    topic?.topic_display_name ||
    topic?.props?.topic_display_name ||
    topic?.topic ||
    topic?.peerpersona;

  const { databaseState } = useContext(DatabaseContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { styleState } = useContext(StyleContext);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const { topicsState } = useContext(TopicsContext);
  const { subscriptionsState } = useContext(SubscriptionsContext);
  const { liveCacheState } = useContext(LiveCacheContext);
  let history = useHistory();
  const [imagePres, setImagePres] = useState(false);
  const liveTopics = topicsState.topics;
  const isOwner = () => topic?.roles?.includes("owner");
  const isDesigner = () => topic?.roles?.includes("designer");
  const [open, setOpen] = useState(false);
  const targetRef = useRef(null);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const topictitleRef = useRef(null);
  const avatarRef = useRef(null);
  // const [avatarWidth, setAvatarWidth] = useState();
  const [topicThemeState, setTopicThemeState] = useState(false);
  const refLogo = useRef();
  const [fontFam, setFontFam] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [modalClose, setModalClose] = useState(true);
  // const [tagVal, setTagVal] = useState();
  const [isArchived, setIsArchived] = useState(false);
  const [hvr, setHvr] = useState(false);
  const [muteHvr, setMuteHvr] = useState(false);

  const showTopicThemeModal = () => {
    setTopicThemeState(!topicThemeState);
  };
  const [contentModal, setContentModal] = useState();

  const getCountSubs = () => {
    if (topic?.topic) {
      if (topic?.mtopic.charAt(0) === "t") {
        for (let i = 0; i < liveTopics?.length; i++) {
          if (liveTopics[i]?.mtopic === topic?.mtopic) {
            return liveTopics[i]?.count_subs;
          }
        }
      } else {
        for (let i = 0; i < subscriptionsState.subscriptions?.length; i++) {
          if (subscriptionsState.subscriptions[i]?.mtopic === topic?.mtopic) {
            return subscriptionsState.subscriptions[i]?.count_subs;
          }
        }
      }
    }
    return -1;
  };

  const liveDescriptorB = useLiveQuery(() => {
    const s = topic.mtopic.split("_");
    if (s.length !== 3) return undefined;
    let bmpersona = s[1];
    if (bmpersona === topic.mpersona) bmpersona = s[2];
    return (
      databaseState.dexUser &&
      databaseState.dexUser.descriptor
        ?.filter((d) => d.mpersona === bmpersona)
        ?.toArray((d) =>
          d.reduce(
            (acc, d) => (d.storedate > (acc?.storedate || "") ? d : acc),
            undefined
          )
        )
    );
  }, [databaseState.dexUser]);

  const liveDescriptor = useLiveQuery(() => {
    const s = topic.mtopic.split("_");
    if (s.length !== 2) return undefined;
    return databaseState.dexUser.topic_descriptor.get({
      mtopic: topic.mtopic
    });
  }, [databaseState.dexUser]);
  useEffect(() => {
    // do we have the assets in the descriptor? if not, then fetch them
    console.log("liveDescriptor", liveDescriptor);
    return () => {};
  }, [liveDescriptor]);

  const liveLogo = useLiveQuery(() => {
    return (
      databaseState.dexUser &&
      databaseState.dexUser.topic_logo.get({
        mtopic:
          topic.mtopic.split("_").length < 3
            ? topic.mtopic
            : `${topic.mtopic}_${topic.mpersona}`
      })
    );
  }, [databaseState.dexUser, `${topic.mtopic}_${topic.mpersona}`]);

  const getSubgroup = () => {
    let j = {
      type: "w.t.participants",
      // mtopic: props.sub.mtopic,
      mtopic: topic?.mtopic,
      /* as below this props.sub.mtopic must be changed  */
      version: globalState.version,
      smid: `${"participants"}|${topic?.mtopic}`,
      ts_sender: timestamp()
    };
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: { ...j }
        }
      });
    setContentModal(
      <UISubGroupModal
        onClose={() => setContentModal(undefined)}
        sub={props.topic}
      ></UISubGroupModal>
    );
  };

  useEffect(() => {
    props.topic?.subprops?.archived === "true" ||
    props.topic?.subprops?.archived === true
      ? setIsArchived(true)
      : setIsArchived(false);
    // setIsArchived(props.topic?.subprops?.archived === "true" ? true : false);
  }, []);

  useEffect(() => {
    const storeLogo = (mtopic, digest, image64, storedate) => {
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "cache",
            doc: {
              scope: "logo",
              mtopic: mtopic,
              digest: digest,
              b64: image64,
              storedate: storedate
            }
          }
        });
    };
    let mounted = true;
    // console.log("!_!_!_!_top", props, topicsState.descriptorsB);
    // split the topic
    let p = topic?.mtopic?.split("_");
    if (p && p.length > 2 && p[0] === "t") {
      setImagePres(liveLogo?.i64 || liveLogo?.b64);

      // // This is a new topic dialog
      // let peer = p.filter((t) => t !== topic.mpersona && t !== "t")[0];
      // // console.log("!_!_!_!top peer", topicsState?.descriptorsB, peer);
      // let descriptors = topicsState?.descriptorsB?.filter(
      //   (d) => d.mpersona === peer
      // );
      // // there might be multiple descriptors for the mpersona, pick the "newest"
      // // !!! TODO: we need to add a timestamp to the descriptor (from s3?)
      // // otherwise the list cannot be ordered
      // if (descriptors?.length > 0) {
      //   let d = descriptors.sort((a, b) => {
      //     let c = b.storedate || "1970";
      //     let d = a.storedate || "1970";
      //     return c.localeCompare(d);
      //   })[0];
      //   let thumbdigest = d.thumbdigest || d.imgdigest;
      //   if (thumbdigest) {
      //     async function fetchThumb() {
      //       let image64 = await getImage64(databaseState.dexUser, thumbdigest);
      //       if (!image64) {
      //         let img = await s3ToImage(peer, thumbdigest);
      //         if (mounted && img?.b64) {
      //           topicsDispatch({
      //             type: "SET_LOGO",
      //             values: { mtopic: topic.mtopic, logo: img.b64 }
      //           });
      //           topicDispatch({
      //             type: "SET_LOGO_IMAGE",
      //             values: {
      //               logo_image: img.b64
      //             }
      //           });
      //           setImagePres(img.b64);
      //           storeImage(databaseState.dexUser, img);
      //           storeLogo(topic.mtopic, thumbdigest, img, d.storedate);
      //         }
      //       } else if (mounted) {
      //         // storeLogo(topic.mtopic, thumbdigest, image64, d.storedate);
      //         topicsDispatch({
      //           type: "SET_LOGO",
      //           values: { mtopic: topic.mtopic, logo: image64 }
      //         });
      //         topicDispatch({
      //           type: "SET_LOGO_IMAGE",
      //           values: {
      //             logo_image: image64
      //           }
      //         });
      //         setImagePres(image64);
      //       }
      //     }
      //     fetchThumb();
      //   }
      // }
    } else {
      let img = topicState?.logo?.thumb64 || topicState?.logo_image;
      if (img && img !== logo) {
        setImagePres(img);
      } else {
        setImagePres(false);
      }
    }
    return () => {
      mounted = false;
    };
  }, [
    databaseState.dexUser,
    topic?.mtopic,
    // topicsState.descriptorsB,
    topicState?.logo?.thumb64,
    topicState?.logo_image,
    liveLogo?.i64,
    liveLogo?.b64
  ]);

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_topic_bar_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState?.theme]);

  useEffect(() => {
    // console.log("!_!_!_!logo", topic?.mtopic, topicsState?.logos);
    try {
      if (
        topicsState?.logos &&
        topicsState?.logos[
          topic?.mtopic.split("_").length < 3
            ? topic.mtopic
            : `${topic.mtopic}_${topic.mpersona}`
        ]
      ) {
        topicDispatch({
          type: "SET_LOGO_IMAGE",
          values: {
            logo_image:
              topicsState.logos[
                topic.mtopic.split("_").length < 3
                  ? topic.mtopic
                  : `${topic.mtopic}_${topic.mpersona}`
              ]
          }
        });
      }
    } catch (error) {
      globalState.logging && console.log(error);
    }
    return () => {};
  }, [topic?.mtopic, topicDispatch, topicsState?.logos]);

  const getIcon = (groupType) => {
    if (groupType !== undefined) {
      switch (groupType.toLowerCase()) {
        case "private_dialog":
          return "private";
        case "public":
          return "public";
        case "private":
          return "private";
        case "whisper":
          return "whisper";
        case "hidden":
          return "hidden";
        default:
          break;
      }
      // return icon
    }
    return undefined;
  };

  const leaveT = () => {
    if (
      window.confirm(
        'Are you sure you want to leave "' +
          (props.topic.topic_display_name ||
            props.topic.props?.topic_display_name ||
            props.topic.topic) +
          '"?\nIt will only be deleted for you.\nOthers will still be able to use it.\n'
      )
    ) {
      let j = {
        type: "w.t.leave",
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        version: globalState.version,
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.topic.mtopic &&
                  t.mpersona === props.topic.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
      history.push("/");
      // if (history.length >= 1) history.goBack();
      // else history.push("/");
    } else {
    }
  };

  const destroyTopic = () => {
    if (
      window.confirm(
        'Are you sure you want to leave "' +
          (props.topic.topic_display_name ||
            props.topic.props?.topic_display_name ||
            props.topic.topic) +
          '"?\nIf you are the topic owner, it will be deleted for all users.\nOtherwise it will only be deleted for you.\n'
      )
    ) {
      let j = {
        type: "w.topic.destroy",
        mtopic: props.topic.mtopic,
        persona: props.topic.persona,
        version: globalState.version,
        smid: randomString(8)
      };
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });

      history.push("/");
    } else {
    }
  };

  let muteFCM = (Seconds, Mtopic) => {
    let j = {
      type: "w.user.fcmtime", // for new/old topics and dialogs
      cid: globalState.cid,
      seconds: Seconds, // how long to mute this topic
      mtopic: Mtopic,
      version: globalState.version,
      smid: randomString(8)
    };
    // console.log("MUTE w.user.fcmtime", j);
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
  };

  let muteTopic = () => {
    setOpen(false);
    muteFCM(60 * 60 * 24 * 365 * 10, props.topic.mtopic); // mute for 10 years
    history.goBack();
  };

  let unmuteTopic = () => {
    setOpen(false);
    muteFCM(0, props.topic.mtopic); // mute for 10 years
    history.goBack();
  };

  // let toggleIDFlag = () => {
  //   let tempFlag;
  //   if (globalState.idFlag) tempFlag = globalState.idFlag;
  //   else tempFlag = false;
  //   globalDispatch({
  //     type: "SET_IDFLAG",
  //     values: { idFlag: !tempFlag }
  //   });
  // };

  // let toggleTimeFormat = () => {
  //   let tempTimeFormat;
  //   if (globalState.timeFormat)
  //     if (globalState.timeFormat === "HH:mm")
  //       tempTimeFormat = "HH:mm:ss";
  //     else tempTimeFormat = "HH:mm"
  //   else tempTimeFormat = "HH:mm:ss";
  //   globalDispatch({
  //     type: "SET_TIMEFORMAT",
  //     values: { timeFormat: tempTimeFormat }
  //   });
  // }

  let topicTheme = () => {
    setOpen(false);
    setTopicThemeState(true);
  };

  let report = () => {
    console.log("Dropdown menu report");
  };

  const deleteT = () => {
    if (
      window.confirm(
        'Are you sure you want to destroy "' +
          (props.topic.topic_display_name ||
            props.topic.props?.topic_display_name ||
            props.topic.topic) +
          '"?\nIt will be deleted for all users\n'
      )
    ) {
      let j = {
        type: "w.t.delete",
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        version: globalState.version,
        ts_sender: timestamp(),
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.topic.mtopic &&
                  t.mpersona === props.topic.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
      history.push("/");
    } else {
    }
  };

  const archiveToggle = () => {
    if (true) {
      let archivedNewVal =
        props.topic?.subprops?.archived === "true" ? "false" : "true";
      let j = {
        type: "w.t.subprops.set",
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        new_props: { archived: archivedNewVal },
        version: globalState.version,
        smid: randomString(8),
        ts_sender: timestampZero(),
        status: "1",
        origin_sender: "registered"
      };

      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value?.forEach(
            (t) =>
              t.mpersona === props.topic.mpersona &&
              t.mtopic === props.topic.mtopic &&
              (t.subprops.archived = archivedNewVal)
          );
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
        // WAPP-1607 - mark all msgs read upon archiving
        !isArchived && // if it was not archived when we started but now it is
          ReadAll();
        // whatever option the user chose, they must be directed to active topics screen upon exit.
        // WAPP-1521
        isArchived && // it was when we started
          globalState?.displaying_archive === true &&
          globalDispatch({
            type: "TOGGLE_ARCHIVE"
          });
      }

      // Depending on the previous state (opposite of isArchived),
      // close topic and exit to main(active) screen, or keep topic open
      // WAPP-1521
      setOpen(false);
      if (window.location?.href?.includes("menu") && history.length > 1)
        history.goBack();
      if (history.length > 1) history.goBack();

      // isArchived ? history.goBack() : history.push("/");
    }
  };

  useEffect(() => {
    if (!modalClose) {
      window.addEventListener("popstate", closeModal);
      if (window.location?.href?.includes("menu")) {
        window.history.replaceState({ id: 2 }, null, "modal");
      } else {
        window.history.pushState({ id: 2 }, null, "modal");
      }
      return () => {
        window.removeEventListener("popstate", closeModal);
      };
    }
  }, [modalClose]);

  const closeModal = () => {
    setModalClose(true);
    setContentModal(undefined);
    if (history.length >= 1) history.goBack();
  };

  const handleClickOutside = () => {
    setModalClose(true);
    setContentModal(undefined);
    if (history.length >= 1) {
      history.goBack();
    }
  };

  const HideAll = () => {
    setOpen(false);
    setModalClose(false);
    setContentModal(
      <Modals
        // noBckgrnd={true}
        title="Delete all Messages"
        onClose={() => closeModal()}
        onClickOutside={() => handleClickOutside()}
        footer={
          <div>
            <button
              className="modal-button-new"
              type="button"
              onClick={() => {
                dex_action({
                  type: "DEX_HIDE_TOPIC_MESSAGES",
                  values: {
                    db: databaseState.dexUser,
                    mtopic: topic.mtopic,
                    mpersona: topic.mpersona
                  }
                });
                setContentModal(undefined);
                if (history.length >= 1) history.goBack();
              }}
            >
              Delete
            </button>
          </div>
        }
      >
        <label className="modal-label-new">
          Deleted messages cannot be recovered.
          <br /> <br />
          Are you sure you want to delete all messages?
        </label>
      </Modals>
    );
  };

  const ReadAll = () => {
    setOpen(false);
    //console.log("uith: ReadAll: topic: ", topic);
    dex_action({
      type: "DEX_MULTI_MARK_READ",
      values: {
        db: databaseState.dexUser,
        global: true,
        mtopics: [topic.mtopic ? topic.mtopic : topic.mdialog]
      }
    });
  };

  const onlyPublicHidden = () => {
    let oPH = false;
    if (topic?.props?.topictype?.length > 0) {
      if (
        topic?.props?.topictype === "public" ||
        topic?.props?.topictype === "hidden"
      )
        oPH = true;
    } else if (
      topic?.props?.visibility === "public" ||
      topic?.props?.visibility === "hidden"
    )
      oPH = true;
    return oPH;
  };

  const menuItems = [
    {
      leftIcon:
        (liveCacheState?.mute &&
          liveCacheState?.mute[topic?.mtopic]?.mute &&
          liveCacheState?.mute[topic?.mtopic].mute) >
        secondsSinceEpoch() + 60 ? (
          <GoUnmute />
        ) : (
          <GoMute />
        ),
      text:
        (liveCacheState?.mute &&
          liveCacheState?.mute[topic?.mtopic]?.mute &&
          liveCacheState?.mute[topic?.mtopic].mute) >
        secondsSinceEpoch() + 60
          ? "Unmute"
          : "Mute",
      callback:
        (liveCacheState?.mute &&
          liveCacheState?.mute[topic?.mtopic]?.mute &&
          liveCacheState?.mute[topic?.mtopic].mute) >
        secondsSinceEpoch() + 60
          ? unmuteTopic
          : muteTopic,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <GoPerson />,
      text: "Invite Persona",
      callback: () => {
        if (props.topic?.mtopic?.startsWith("t_")) {
          setOpen(false);
          setContentModal(
            <UIInviteModal
              topic={topic}
              onClose={() => setContentModal(undefined)}
            ></UIInviteModal>
          );
        } else alert("Cannot invite to old topics");
      },
      permissionLevels:
        topic?.props?.topictype === "dialog" ||
        topic?.props?.visibility === "dialog"
          ? []
          : topic?.props?.visibility === "private"
          ? // || topic?.props?.visibility === "hidden"
            ["admin", "owner"]
          : ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    onlyPublicHidden() && {
      // process.env.REACT_APP_MODE === "dev" && {
      leftIcon: <FaShareAlt />,
      text: "Share",
      callback: () => {
        setOpen(false);
        setContentModal(
          <UIShareModal
            onClose={() => setContentModal(undefined)}
            title={props.topic?.topic_display_name || props.topic?.topic}
            link={
              props.topic.mtopic.startsWith("t_")
                ? window.location.origin + "/" + props.topic?.mtopic
                : window.location.origin + "/topic/" + props.topic?.mtopic
            }
          ></UIShareModal>
        );
      },
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <IoIosPeople />,
      text: "Participants",
      callback: () => {
        setOpen(false);

        if (topic?.mtopic && topic?.mtopic.startsWith("t_")) {
          setContentModal(
            <UIParticipantsModal
              onClose={() => setContentModal(undefined)}
              sub={props.topic}
            ></UIParticipantsModal>
          );
          // getParticipants();
        } else {
          alert("Not yet for older topics");
        }
      },
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    // {
    //   leftIcon: <IoIosPeople />,
    //   text: "Whisper Subgroup",
    //   callback: () => {
    //     setOpen(false);
    //     if (topic?.mtopic && topic?.mtopic.startsWith("t_")) {
    //       getSubgroup();
    //     } else {
    //       alert("Not yet for older topics");
    //     }
    //   },
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   goToMenu: ""
    // },
    {
      leftIcon: <BiMessageRoundedCheck />,
      text: "Mark all as Read",
      callback: ReadAll,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <BiMessageRoundedX />,
      text: "Delete all Messages",
      callback: HideAll,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    // process.env.REACT_APP_MODE === "dev" && {
    //   leftIcon: <AiOutlineAntDesign />,
    //   rightIcon: <FaArrowRight />,
    //   text: "Advanced",
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   // callback: design,
    //   goToMenu: "secondary"
    // },
    {
      leftIcon: <MdEdit />,
      text: "Edit Topic",
      callback: topicTheme,
      permissionLevels:
        (topic.mdialog ||
          topic.props?.tag?.toLowerCase().includes("-job") ||
          topic.props?.topictype?.toLowerCase().includes("job") ||
          //tag id for "job"  is deprecated note end feb to check status
          topic.props?.topictype?.toLowerCase() === "dialog") &&
        !(globalState.devMode === "true" || globalState.devMode === true)
          ? []
          : ["admin", "owner", "designer"],
      // permissionLevels: ["designer"],
      goToMenu: ""
    },
    {
      leftIcon: isArchived ? <RiInboxUnarchiveLine /> : <RiInboxArchiveLine />,
      text: isArchived ? "Unarchive Topic" : "Archive Topic",
      callback: archiveToggle,
      title: isArchived ? "retrieve" : "archive",
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <IoExitOutline size={20} />,
      text: "Leave Topic",
      callback: leaveT,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <ImBin />,
      text: "Delete Topic",
      callback: deleteT,
      permissionLevels: ["owner"],
      goToMenu: ""
    }
  ];

  topic?.props?.topictype === "dialog" && menuItems.splice(-1, 1);

  const secMenuItems = [
    // {
    //   leftIcon: <FaPalette />,
    //   text: "Design",
    //   callback: topicTheme,
    //   permissionLevels:
    //     topic.mdialog ||
    //     topic.props?.tag?.toLowerCase() === "job" ||
    //     //tag id for "job"  is deprecated note end feb to check status
    //     topic.props?.topictype?.toLowerCase() === "job" ||
    //     topic.props?.topictype?.toLowerCase() === "dialog"
    //       ? []
    //       : ["designer"],
    //   // permissionLevels: ["designer"],
    //   goToMenu: ""
    // }
    // {
    //   leftIcon: <FaFlagCheckered />,
    //   text: "Identity flag",
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   callback: toggleIDFlag,
    //   goToMenu: ""
    // },
    // {
    //   leftIcon: <FaRegClock />,
    //   text: "Time-toggle format",
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   callback: toggleTimeFormat,
    //   goToMenu: ""
    // }
  ];

  const getMuteIcon = () => {
    if (
      (liveCacheState?.mute &&
        liveCacheState?.mute[topic?.mtopic]?.mute &&
        liveCacheState?.mute[topic?.mtopic].mute) >
      secondsSinceEpoch() + 60
    )
      return true;
    else return false;
  };

  const hgtStyle = {
    height: "0px"
  };

  const handleOpen = (e) => {
    setOpen(!open);
    setCoords({ x: e.clientX, y: e.clientY });
  };

  // useEffect(() => {
  // if (topictitleRef?.current)
  //   console.log("ttref:", topictitleRef.current.clientWidth);
  //   if (avatarRef?.current) {
  //     setAvatarWidth(avatarRef.current?.offsetWidth.toString() + "px")
  //     console.log("avatarref:", avatarRef.current?.offsetWidth);
  //   }
  //   // console.log("innerWidth=", window.innerWidth);
  //   return avatarRef.current?.offsetWidth
  // }, [refLogo, size, topicState?.logo?.thumb64, topicState?.logo_image]
  // )

  const getHeadColor = (groupType) => {
    let root = document.getElementById("root");
    if (groupType !== undefined) {
      switch (groupType.toLowerCase()) {
        case "private":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_private_background_color"
          );
        case "public":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_public_background_color"
          );
        case "dialog":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_dialog_background_color"
          );
        case "hidden":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_hidden_background_color"
          );
        case "community":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_community_background_color"
          );
        case "job":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_job_background_color"
          );
        default:
          if (groupType.toLowerCase().includes("-job"))
            return root.style.getPropertyValue(
              "--main_screen_topic_type_job_background_color"
            );
          break;
      }
    }
    return root.style.getPropertyValue("--topic_header_background_color");
  };

  const tagOrVis = () => {
    if (props.topic?.props?.tag) return props.topic.props.tag;
    else return getIcon(topic?.props?.visibility) || getIcon(topic?.visibility);
  };

  const DispArc = () => {
    const [hover, setHover] = useState(true);
    //   const toggleHover = () => {
    //     setHover(!hover)
    // }
    return (
      <div
      // style={{
      //   // display: "block",
      //   position: "absoloute"
      // }
      // }
      >
        "this is archived"
      </div>
    );
  };

  const content = (
    <div ref={targetRef} style={size === "none" ? hgtStyle : {}}>
      <div
        className="UI-topic-header"
        style={{
          backgroundColor: getHeadColor(
            props?.topic?.props?.tag ||
              getIcon(topic?.props?.visibility) ||
              getIcon(topic?.visibility)
          )
        }}
        type={topicAbout?.length > 0 ? size + "withdescription" : size}
        onClick={() =>
          props.selectTopic &&
          props.selectTopic(topic?.mtopic || topic?.mdialog)
        }
        key={"topicHeader"}
      >
        {props.showback && (
          <div //back arrow
            onClick={() => {
              // sessionStorage.removeItem("imgycoord")
              history.goBack();
            }}
          >
            <FiArrowLeft
              className={
                size?.toLowerCase() === "none"
                  ? "UI-back-arrow-no-header"
                  : "UI-back-arrow"
              }
            />
          </div>
        )}
        {/* {props.showback && (
          <div  //back arrow
            onClick={() => {
              history.push("/");
            }}
          >
            <FiArrowLeft
              className={
                size?.toLowerCase() === "none"
                  ? "UI-back-arrow-no-header"
                  : "UI-back-arrow"
              }
            />
          </div>
        )} */}
        {size?.toLowerCase() !== "none" && (
          <div //avatar
            ref={avatarRef}
            className="UI-topic-avatar"
            type={size}
          >
            {/*wallpaper or avatar to go here*/}
            {/* image */}
            {(size?.toLowerCase() === "xlarge" ||
              size?.toLowerCase() === "large" ||
              size?.toLowerCase() === "medium") &&
              (imagePres ? (
                <img
                  style={
                    size?.toLowerCase() === "medium"
                      ? {
                          borderRadius: "50%",
                          width: "4rem",
                          height: "4rem"
                        }
                      : size?.toLowerCase() === "large"
                      ? { height: "4.6rem" }
                      : size?.toLowerCase() === "xlarge"
                      ? { height: "6.5rem" }
                      : { height: "3rem" }
                  }
                  ref={refLogo}
                  id={
                    "logo_" +
                    (props.topic.mtopic.split("_").length < 3
                      ? props.topic.mtopic
                      : `${props.topic.mtopic}_${props.topic.mpersona}`)
                  }
                  src={imagePres}
                  alt="Logo"
                  height={40}
                  onClick={() => {
                    if (liveDescriptor) {
                      history.push("/imageDisplay", {
                        avatar: true,
                        picAttach: false,
                        thumb: topicState.logo_image,
                        descriptor: liveDescriptor.logo,
                        scope: liveDescriptor.logo?.imgpath?.split("/")[1],
                        imgdigest: liveDescriptor.logo?.imgdigest
                      });
                    } else if (liveDescriptorB) {
                      history.push("/imageDisplay", {
                        avatar: true,
                        picAttach: false,
                        thumb: topicState.logo_image,
                        descriptor: liveDescriptorB,
                        scope: liveDescriptorB?.imgpath?.split("/")[1],
                        imgdigest: liveDescriptorB?.imgdigest
                      });
                    }
                  }}
                />
              ) : props.topic?.props?.tag &&
                props.topic?.props?.tag.toLowerCase().includes("-job") ? (
                <AiOutlineTool
                  style={{
                    fontSize: "3rem",
                    borderRadius: "50%",
                    backgroundColor: "#008080",
                    color: "white",
                    width: "4.0rem",
                    height: "4.0rem"
                  }}
                />
              ) : (
                <GetInitials
                  type="job"
                  head_list={false}
                  str={
                    props?.topic?.topic_display_name ||
                    props?.topic?.props?.topic_display_name ||
                    props?.topic?.topic
                  }
                  // str={props?.topic?.topic || props?.topic?.peerpersona}
                  // str={props?.topic?.topic}
                ></GetInitials>
              ))}
          </div>
        )}
        <div className="UI-unread-topics-container" type={size}>
          {size?.toLowerCase() !== "none" && (
            <div className="UI-topic-header-names">
              <label
                id="test"
                ref={topictitleRef}
                className="UI-topic-title"
                style={{
                  fontFamily: fontFam,
                  fontWeight: fontWeight
                }}
                type={size}
              >
                {label}
              </label>
              <div>
                {getMuteIcon() ? (
                  <span
                    className={
                      size?.toLowerCase() === "none" ? "mute-icon" : {}
                    }
                    style={
                      size?.toLowerCase() === "none"
                        ? { display: "none" }
                        : {
                            fontSize: "1.4rem",
                            padding: "0 0.2rem"
                          }
                    }
                    onMouseEnter={() => setMuteHvr(true)}
                    onMouseLeave={() => setMuteHvr(false)}
                  >
                    <GoMute />
                  </span>
                ) : (
                  <span
                    className={
                      size?.toLowerCase() === "none" ? "mute-icon" : {}
                    }
                    style={
                      size?.toLowerCase() === "none" ? { display: "none" } : {}
                    }
                  ></span>
                )}
                {muteHvr && <div className="mute-tooltip">Muted</div>}
              </div>
              <div>
                {isArchived ? (
                  <div
                    className={
                      size?.toLowerCase() === "none" ? "mute-icon" : ""
                    }
                    style={
                      size?.toLowerCase() === "none"
                        ? { display: "none" }
                        : {
                            fontSize: "1.4rem",
                            padding: "0 0.2rem"
                          }
                    }
                    onMouseEnter={() => setHvr(true)}
                    onMouseLeave={() => setHvr(false)}
                  >
                    <RiInboxArchiveLine />
                  </div>
                ) : (
                  <span
                    className={
                      size?.toLowerCase() === "none" ? "mute-icon" : {}
                    }
                    style={
                      size?.toLowerCase() === "none" ? { display: "none" } : {}
                    }
                  ></span>
                )}
                {hvr && <div className="mute-tooltip">Archived</div>}
              </div>

              {/* <ClickOutsideDetector
                // caller="DropdownTopicMenu"
                listen
                onClickOutside={() => setOpen(false)}
                onClick={() => {}}
                className="dots-icon-button"
              > */}
              <div>
                <BsThreeDotsVertical
                  onClick={handleOpen}
                  style={{ fontSize: "1.3rem" }}
                />
                {open && (
                  <DropdownTopicMenu
                    roles={
                      topic?.roles
                        ? topic?.roles
                        : ["admin", "owner", "designer", "tx", "whisper", "rx"]
                    }
                    menuItems={menuItems}
                    secMenuItems={secMenuItems}
                    mainscreen={false}
                    onClose={() => setOpen(false)}
                    coords={coords}
                  ></DropdownTopicMenu>
                )}
              </div>
              {/* </ClickOutsideDetector> */}
            </div>
          )}

          <div
          // className="Ui-topic-header-names"
          // style={{
          //   display: "grid",
          //   gridTemplateColumns: "auto auto auto"
          // }}
          >
            {(size?.toLowerCase() === "xlarge" ||
              size?.toLowerCase() === "large" ||
              size?.toLowerCase() === "medium") && (
              <div
                className="UI-topic-header-names-persona"
                // className="UI-topic-header-persona-type"
              >
                <label className="UI-topic-title-persona">
                  {props.topic.persona}
                </label>
                <label className="UI-unread-topics">
                  {topic?.topic ? getCountSubs() : 2}
                </label>

                {(size?.toLowerCase() === "xlarge" ||
                  size?.toLowerCase() === "large" ||
                  size?.toLowerCase() === "medium") && (
                  <div>
                    <label
                      className="UI-topic-header-type"
                      style={{
                        fontSize: "1rem"
                      }}
                    >
                      {getIcon(topic?.props?.visibility) ||
                        getIcon(topic?.visibility)}
                    </label>
                    <label className="UI-topic-header-type" type="tag">
                      {tagOrVis()}
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <span>
          {topicThemeState && (
            <UICreateTopicModal
              topic={topic}
              size={size}
              origHeaderSize={props.origHeaderSize}
              origAbout={props.origDescription}
              origLogo={topicState.logo_image}
              preview={props.preview}
              // description={topicDescription}
              onClose={showTopicThemeModal}
              show={topicThemeState}
              callback={props.callback}
              mtopic={topic?.mtopic}
              tag={topic.tag}
              // timeFormatHMS={props.timeFormatHMS}
            />
          )}
        </span>
        {size?.toLowerCase() === "none" && (
          // <ClickOutsideDetector
          //   // caller="DropdownTopicMenu"
          //   listen
          //   onClickOutside={() => setOpen(false)}
          //   onClick={() => {}}
          //   className="dots-icon-button"
          // >
          <li
            className={"UI-topic-dots-menu-no-header"}
            style={{
              position: "absolute",
              fontSize: "1.2rem",
              right: "0",
              marginTop: "1px",
              marginRight: "2px"
            }}
          >
            <BsThreeDotsVertical
              onClick={handleOpen}
              className={"UI-topic-dots-icon"}
            />
            {open && (
              <DropdownTopicMenu
                roles={
                  topic?.roles
                    ? topic?.roles
                    : ["admin", "owner", "designer", "tx", "whisper", "rx"]
                }
                menuItems={menuItems}
                secMenuItems={secMenuItems}
                mainscreen={false}
                onClose={() => setOpen(false)}
                coords={coords}
              ></DropdownTopicMenu>
            )}
          </li>
          // </ClickOutsideDetector>
        )}
        {contentModal}
      </div>
      <div
        className="UI-topic-header-description"
        type={topicAbout?.length > 0 ? "" : "none"}
      >
        {topicAbout?.length > 0 ? topicAbout : ""}
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicHeader, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
