import React, { useEffect } from "react";

import UserInterface from "ui/UserInterface";
import Connectivity from "connectivity/Connectivity";
import Wss from "connectivity/Wss";
import FCM from "connectivity/FCM";
import Database from "data/Database";
import UserStateProvider from "contexts/UserStateProvider";
import GlobalStateProvider from "contexts/GlobalStateProvider";
import SubscriptionsStateProvider from "contexts/SubscriptionsStateProvider";
import DatabaseStateProvider from "data/DatabaseStateProvider";
import LiveCacheStateProvider from "data/LiveCacheStateProvider";
import WebsocketStateProvider from "connectivity/WebsocketStateProvider";
import LocationStateProvider from "geospatial/LocationStateProvider";
// import Location from "geospatial/Location";
import StyleStateProvider from "contexts/StyleStateProvider";
import DialogsStateProvider from "contexts/DialogsStateProvider";
import TopicsStateProvider from "contexts/TopicsStateProvider";
import ThumbsStateProvider from "contexts/ThumbsStateProvider";
// import SuitesStateProvider from "contexts/SuitesStateProvider";
import PersonasStateProvider from "contexts/PersonasStateProvider";
import CountStateProvider from "contexts/CountStateProvider";
import LiveData from "data/LiveData";
import { shouldLog } from "utils/shouldLog";
import FocusStateProvider from "contexts/FocusStateProvider";
import ThumbSetter from "data/ThumbSetter";
import Uploader from "data/Uploader";
import DescriptorSetter from "data/DescriptorSetter";
import ListFetcher from "data/ListFetcher";

// localStorage.setItem("use_keycloak", "true");

const App = (props) => {
  // console.log("Running App");
  // Anything here acts as componentWillMount

  useEffect(() => {
    let f = async () => {
      if (navigator.storage?.persist)
        navigator.storage.persist().then(function (persistent) {
          navigator.storage.estimate().then(function (estimate) {
            if (persistent)
              estimate.quota &&
                estimate.usage &&
                shouldLog() &&
                console.log(
                  `Storage allowed: ${Math.round(
                    estimate.quota / 1024 / 1024
                  )}MB, used: ${Math.round(estimate.usage / 1024 / 1024)}MB`
                );
            else shouldLog() && console.log("Storage may be cleared.");
          });
        });
    };
    f();
    return () => {};
  }, []);

  let content = (
    <UserStateProvider>
      <GlobalStateProvider>
        <PersonasStateProvider>
          <ThumbsStateProvider>
            <SubscriptionsStateProvider>
              <DialogsStateProvider>
                <TopicsStateProvider>
                  {/* <SuitesStateProvider> */}
                  <CountStateProvider>
                    <LiveCacheStateProvider>
                      <DatabaseStateProvider>
                        <LocationStateProvider>
                          <Connectivity
                            short_poll_interval={1000}
                            poll_interval={60000}
                            poll_address="https://google.com"
                          />
                          {/* <Location poll_interval={600000} /> */}
                          <WebsocketStateProvider>
                            <Wss url="wss://wss1.reaches.me:8443/websocket" />
                          </WebsocketStateProvider>
                          <Database />
                          <FCM />
                          <ThumbSetter />
                          <DescriptorSetter />
                          <Uploader />
                          <ListFetcher />
                          <StyleStateProvider>
                            <LiveData />
                            <FocusStateProvider>
                              <UserInterface />
                            </FocusStateProvider>
                          </StyleStateProvider>
                        </LocationStateProvider>
                      </DatabaseStateProvider>
                    </LiveCacheStateProvider>
                  </CountStateProvider>
                  {/* </SuitesStateProvider> */}
                </TopicsStateProvider>
              </DialogsStateProvider>
            </SubscriptionsStateProvider>
          </ThumbsStateProvider>
        </PersonasStateProvider>
      </GlobalStateProvider>
    </UserStateProvider>
  );

  return content;
};

export default App;
