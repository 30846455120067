import React, { useContext, useState, useEffect, useRef, forwardRef } from "react";

import { Link } from "react-router-dom";

import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import LiveCacheContext from "data/contextLiveCache";
import IconDropDown from "ui/Dropdown/iconDropDown";
import { ImShare2, ImBin } from "react-icons/im";

import { IoExitOutline } from "react-icons/io5";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";

import { AiOutlineTool, AiOutlinePushpin, AiFillPushpin } from "react-icons/ai";
import {
  randomString,
  timestamp,
  timestampZero,
  timestampCutoff
} from "hooks/helper";
//import UIParticipantsModal from "./UIParticipantsModal";
import UIMessagePreview from "ui/UIMessagePreview";
import { GoMute } from "react-icons/go";
import { secondsSinceEpoch } from "hooks/helper";
import CountContext from "contexts/contextCount";
import TopicsContext from "contexts/contextTopics";
import GetInitials from "../utils/GetInitials";
import { useHistory } from "react-router-dom";

import {
  applyTopicDescriptors,
  getImage64,
  storeImage
} from "data/descriptors";
import { s3ToImage } from "connectivity/s3";
import StyleContext from "contexts/contextStyle";
import ServiceMsgPreview from "./ServiceMsgPreview";
import ServMsgPview from "./ServMsgPview";
import { useLiveQuery } from "dexie-react-hooks";
import { dex_action } from "data/dexUtils";

const isEqual = require("react-fast-compare");

const UITopic = (props) => {
  // console.log("testestW uitopic props=", props)
  // const UITopic = forwardRef((props, ref) => {

  const { globalState } = useContext(GlobalContext);
  const { topicsState } = useContext(TopicsContext);
  const { databaseState } = useContext(DatabaseContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  const { styleState } = useContext(StyleContext);

  const [previewMsg, setPreviewMsg] = useState(undefined);
  const [unreadCount, setUnreadCount] = useState(0);

  const [colArr, setColArr] = useState([]);
  let image = undefined;
  let history = useHistory();
  const [imagePres, setImagePres] = useState(false);
  const refLogo = useRef();
  const [fontFam, setFontFam] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [redraw, setRedraw] = useState(false);
  const [modal, setModal] = useState(<div></div>);
  // const [codeExecuted, setCodeExecuted] = useState(false);

  //const [thisDescriptor, setThisDescriptor] = useState();

  // use the following in case of loading topics in a recycler view (rather than loading them all into memory)
  // const livePreview = useLiveQuery(() => {
  //   if (databaseState.dexUser) {
  //     return databaseState.dexUser.message
  //       .where(["mtopic", "recipient"])
  //       .equals([props.sub?.mtopic, props.sub?.mpersona])
  //       .toArray((array) => {
  //         let a = array.map((d) => {
  //           return {
  //             ...d,
  //             sort: `${d.parameters?.pin || 0}_${d.ts_origin_server}`
  //           };
  //         });
  //         a.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  //         return a.slice(-1);
  //       });
  //   } else {
  //     return undefined;
  //   }
  // }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  // useEffect(() => {
  //   livePreview && livePreview.length > 0 && setPreviewMsg(livePreview[0]);
  // }, [livePreview]);

  const liveDescriptor = useLiveQuery(() => {
    return databaseState.dexUser.topic_descriptor.get({
      mtopic: props.sub?.mtopic
    });
  }, [databaseState.dexUser]);

  useEffect(() => {
    let mounted = true;

    let f = async (topic, descriptor) => {
      let topic_logo = await databaseState.dexUser.topic_logo.get({
        mtopic: topic.mtopic
      });
      if (
        topic_logo?.digest !== descriptor?.logo?.imgdigest &&
        topic_logo?.digest !== descriptor?.logo?.thumbdigest
      ) {
        globalState.logging &&
          console.log("about to applyTopicDescriptors", topic, descriptor);
        mounted &&
          descriptor &&
          applyTopicDescriptors(databaseState.dexUser, [descriptor]);
      }
    };
    let pf = async (topic) => {
      //get the latest descriptor that matches the bparty
      let s = topic.mtopic.split("_");
      let bmpersona = s[1];
      if (bmpersona === topic.mpersona) bmpersona = s[2];
      let latest_descriptors = await databaseState.dexUser.descriptor
        .filter((d) => d.mpersona === bmpersona)
        .toArray();
      let latest_descriptor = latest_descriptors.reduce(
        (acc, d) => ((d.storedate || "1970") > (acc.storedate || "") ? d : acc),
        {}
      );
      let topic_logo =
        mounted &&
        (await databaseState.dexUser.topic_logo.get({
          mtopic: `${topic.mtopic}_${topic.mpersona}`
        }));
      if (
        latest_descriptor.digest &&
        topic_logo?.digest !== latest_descriptor.digest &&
        mounted
      ) {
        globalState.logging &&
          console.log(
            "about to mpersona applyTopicDescriptors",
            topic,
            latest_descriptor,
            latest_descriptors
          );
        if (latest_descriptor?.thumbdigest || latest_descriptor?.imgdigest) {
          let b64 = await getImage64(
            databaseState.dexUser,
            latest_descriptor.thumbdigest || latest_descriptor?.imgdigest
          );
          if (!b64 && mounted) {
            let i = await s3ToImage(
              bmpersona,
              latest_descriptor.thumbdigest || latest_descriptor?.imgdigest
            );
            if (i?.b64 || i?.i64) {
              b64 = i?.b64 || i?.i64;
              storeImage(databaseState.dexUser, i);
            }
          }
          if (b64 && mounted) {
            let topic_logo_final = {
              i64: b64,
              digest: latest_descriptor.digest,
              mtopic: `${topic.mtopic}_${topic.mpersona}`,
              storedate: latest_descriptor.storedate
            };
            globalState.logging &&
              console.log(
                "storing logo",
                topic_logo,
                topic,
                latest_descriptor,
                latest_descriptors
              );
            databaseState.dexUser.topic_logo.put(topic_logo_final);
          }
        }
      }
    };

    // do we have the assets in the descriptor? if not, then fetch them
    let s = props.sub?.mtopic.split("_");
    if (s.length < 3) {
      liveDescriptor &&
        f(
          JSON.parse(JSON.stringify(props.sub)),
          JSON.parse(JSON.stringify(liveDescriptor))
        );
    } else if (s.length >= 3) {
      // create a logo with the user's thumbnail
      pf(JSON.parse(JSON.stringify(props.sub)));
    }
    return () => {
      mounted = false;
    };
  }, [databaseState.dexUser, globalState.logging, liveDescriptor, props.sub]);

  const livePreview = useLiveQuery(() => {
    if (databaseState.dexUser) {
      return databaseState.dexUser.latest_message.get({
        mtopic: props.sub?.mtopic,
        recipient: props.sub?.mpersona
      });
    } else {
      return undefined;
    }
  }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  const liveMeta = useLiveQuery(() => {
    if (databaseState.dexUser) {
      let metadata = databaseState.dexUser.topic_metadata_buffer.get({
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona
      });
      return metadata || undefined;
    } else {
      return undefined;
    }
  }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  useEffect(() => {
    setUnreadCount(liveMeta?.unreadCount || 0);
  }, [liveMeta?.unreadCount]);

  useEffect(() => {
    livePreview &&
      setPreviewMsg((p) => (isEqual(p, livePreview) ? p : livePreview));
  }, [livePreview?.msg_idx]);

  const liveLogo = useLiveQuery(() => {
    return (
      databaseState.dexUser &&
      databaseState.dexUser.topic_logo.get({
        mtopic:
          props?.sub?.mtopic?.split("_").length < 3
            ? props.sub?.mtopic
            : `${props.sub?.mtopic}_${props.sub?.mpersona}`
      })
    );
  }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  let shareLink = () => {
    let link = props.sub?.mtopic?.startsWith("t_")
      ? window.location.origin + "/" + props.sub?.mtopic
      : window.location.origin + "/topic/" + props.sub?.mtopic;

    props.share(
      props.sub?.topic_display_name ||
        props.sub?.props?.topic_display_name ||
        props.sub?.topic,
      link
    );
  };

  const isDialog = () => props.sub?.props?.topictype === "dialog";
  const isOwner = () => props.sub?.roles?.includes("owner");
  const visibility =
    props.sub?.visibility?.toLowerCase() ||
    props.sub?.props?.visibility?.toLowerCase();

  process.env?.REACT_APP_DEBUG_THIS?.includes("UITopic") &&
    console.log(
      "[UITopic] topic: ",
      props.sub?.topic_display_name || props.sub?.topic,
      "\n\tisDialog: ",
      isDialog(),
      "\n\tisOwner: ",
      isOwner(),
      "\n\tvisibility",
      visibility
    );

  const toggleCollectionState = (topiccollection, mpersona) => {
    let collectionState = { ...liveCacheState?.collectionState } || {};
    collectionState[topiccollection] = collectionState[topiccollection] || {};
    collectionState[topiccollection][mpersona] = {
      open: !collectionState[topiccollection][mpersona]?.open
    };
    liveCacheDispatch({
      type: "SET",
      values: { cache: { collectionState: collectionState } }
    });
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: { key: "collectionstate", value: collectionState }
        }
      });
  };

  const deleteT = () => {
    if (
      window.confirm(
        'Are you sure you want to destroy "' +
          (props.sub?.topic_display_name ||
            props.sub?.props?.topic_display_name ||
            props.sub?.topic) +
          '"?\nIt will be deleted for all users\n'
      )
    ) {
      let j = {
        type: "w.t.delete",
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona,
        version: globalState.version,
        ts_sender: timestamp(),
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.sub?.mtopic &&
                  t.mpersona === props.sub?.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
    } else {
    }
  };

  // Calling parent's toggle here seems to calm the re-render loop
  // when the parent unpins a topic. Minimum renders:
  // 1 for the new pin
  // 2 for the unpinned
  // 3 for the re-ordering according to pinned date
  const togglePin = () => {
    props.togglePin(props.sub);
  };

  const archiveToggle = () => {
    if (true) {
      let archivedNewVal =
        props.sub?.subprops?.archived === "true" ? "false" : "true";
      let j = {
        type: "w.t.subprops.set",
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona,
        new_props: { archived: archivedNewVal },
        version: globalState.version,
        smid: randomString(8),
        ts_sender: timestampZero(), // !!! TODO: this needs to be converted to UTC+0
        status: "1",
        origin_sender: "registered"
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value?.forEach(
            (t) =>
              t.mpersona === props.sub?.mpersona &&
              t.mtopic === props.sub?.mtopic &&
              (t.subprops.archived = archivedNewVal)
          );
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
    } else {
    }
  };

  const leaveT = () => {
    if (
      window.confirm(
        'Are you sure you want to leave "' +
          (props.sub?.topic_display_name ||
            props.sub?.props?.topic_display_name ||
            props.sub?.topic) +
          '"?\nIt will only be deleted for you.\nOthers will still be able to use it.\n'
      )
    ) {
      let j = {
        type: "w.t.leave",
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona,
        version: globalState.version,
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.sub?.mtopic &&
                  t.mpersona === props.sub?.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
    } else {
    }
  };

  const getIcon = (groupType) => {
    if (groupType !== undefined) {
      switch (groupType.toLowerCase()) {
        case "private_dialog":
          return "dialog";
        case "public":
          return "public";
        case "private":
          return "private";
        case "whisper":
          return "whisper";
        case "hidden":
          return "hidden";
        default:
          return groupType.toLowerCase();
      }
    }
    return undefined;
  };

  const menuShare = {
    icon: <ImShare2 />,
    callback: shareLink
  };

  const menuExit = {
    icon: <IoExitOutline size={20} />,
    callback: props.sub?.mtopic?.startsWith("t_") && leaveT,
    title: "leave/delete"
  };

  const menuArchive = {
    icon:
      props.sub?.subprops?.archived === "true" ? (
        <RiInboxUnarchiveLine size={20} />
      ) : (
        <RiInboxArchiveLine size={20} />
      ),
    callback: archiveToggle,
    title: props.sub?.subprops?.archived === "true" ? "retrieve" : "archive"
  };

  const menuDelete = {
    icon: <ImBin />,
    callback: props.sub?.mtopic?.startsWith("t_") ? deleteT : {},
    title: "delete"
  };

  const getMuteIcon = () => {
    if (
      (liveCacheState?.mute &&
        liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute &&
        liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute) >
      secondsSinceEpoch() + 60
    )
      return true;
    else return false;
  };

  const menuPin = {
    icon:
      props.sub?.subprops?.pinned === "true" ? (
        <AiOutlinePushpin />
      ) : (
        <AiFillPushpin />
      ),
    callback: togglePin,
    title: props.sub?.subprops?.pinned === "true" ? "unpin" : "pin"
  };

  const showMuteLayout = () => {
    return (
      <span className="mute-icon">
        <GoMute />
      </span>
    );
  };

  const isMuted = () => {
    if (
      (liveCacheState?.mute &&
        liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute &&
        liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute) >
      secondsSinceEpoch() + 60
    )
      return true;
    else return false;
  };

  const showPinnedLayout = () => {
    return (
      <span className="mute-icon">
        <AiFillPushpin />
      </span>
    );
  };

  const showCollectionTag = () => {
    return (
      <>
        <label
          className="UI-topics-icon"
          type={getVisibility()}
          onClick={(e) => {
            e.preventDefault();
            toggleCollectionState(getIdentity(), props.sub?.mpersona);
          }}
        >
          {getIcon(props.sub?.props?.tag) ||
            getIcon(props.sub?.visibility) ||
            getIcon(props.sub?.props?.visibility)}
        </label>
      </>
    );
  };

  const showDumbCollectionTag = () => {
    // remove or add type for specific background color effect
    return (
      <label
        className="UI-topics-icon"
        type={getVisibility()}
        style={{
          borderStyle: "dashed",
          cursor: "default"
        }}
      >
        {getIcon(props.sub?.props?.tag) ||
          getIcon(props.sub?.visibility) ||
          getIcon(props.sub?.props?.visibility)}
      </label>
    );
  };

  const isPinned = () => {
    if (props?.sub?.subprops?.pinned === "true") {
      return true;
    }
    return false;
  };

  const getVisibility = () => {
    return (
      (props.sub?.props?.tag && getIcon(props.sub?.props.tag)) ||
      getIcon(props.sub?.props?.tag && "user") ||
      getIcon(props.sub?.visibility) ||
      getIcon(props.sub?.props?.visibility)
    );
  };

  // const strAvatar = (
  //   props.sub?.topic_display_name ||
  //   props.sub?.props?.topic_display_name ||
  //   props.sub?.topic
  // )
  //   .substring(0, 2)
  //   .toUpperCase();

  useEffect(() => {
    let mounted = true;
    const storeLogo = (mtopic, digest, image64, storedate) => {
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "cache",
            doc: {
              scope: "logo",
              mtopic: mtopic,
              digest: digest,
              b64: image64,
              storedate: storedate
            }
          }
        });
    };
    // split the topic
    let p = props.sub?.mtopic?.split("_");
    setImagePres(liveLogo?.i64 || liveLogo?.b64);

    // if (p && p.length > 2 && p[0] === "t") {
    //   // This is a new topic dialog
    //   let peer = p.filter((t) => t !== props.sub?.mpersona && t !== "t")[0];
    //   // console.log("!_!_!_!top peer", peer);
    //   let descriptors = topicsState?.descriptorsB?.filter(
    //     (d) => d.mpersona === peer
    //   );
    //   // !!! TODO: we need to add a timestamp to the descriptor (from s3?)
    //   // otherwise the list cannot be ordered
    //   if (descriptors?.length > 0) {
    //     let d = descriptors.sort((a, b) => {
    //       let c = b.storedate || "1970";
    //       let d = a.storedate || "1970";
    //       return c.localeCompare(d);
    //     })[0];
    //     globalState.logging &&
    //       console.log(
    //         "[UITopic]descriptors",
    //         descriptors,
    //         d,
    //         props?.sub?.topic_display_name ||
    //           props?.sub?.props?.topic_display_name ||
    //           props?.sub?.topic
    //       );
    //     let thumbdigest = d.thumbdigest || d.imgdigest;
    //     if (thumbdigest) {
    //       async function fetchThumb() {
    //         let image64 = await getImage64(databaseState.dexUser, thumbdigest);
    //         globalState.logging &&
    //           console.log("[UITopic] fetchThumb image64", thumbdigest, image64);
    //         if (!image64) {
    //           let img = await s3ToImage(peer, thumbdigest);
    //           if (mounted && img?.b64) {
    //             topicsDispatch({
    //               type: "SET_LOGO",
    //               values: { mtopic: props.sub?.mtopic, logo: img.b64 }
    //             });
    //             setImagePres(img.b64);
    //             storeImage(databaseState.dexUser, img);
    //             storeLogo(props.sub?.mtopic, thumbdigest, img.b64, d.storedate);
    //           }
    //         } else if (mounted) {
    //           topicsDispatch({
    //             type: "SET_LOGO",
    //             values: { mtopic: props.sub?.mtopic, logo: image64 }
    //           });
    //           setImagePres(image64);
    //           // storeLogo(props.sub?.mtopic, thumbdigest, image64, d.storedate);
    //         }
    //       }
    //       fetchThumb();
    //     }
    //   }
    // }
    return () => {
      mounted = false;
    };
  }, [
    props.sub?.mtopic,
    // topicsState.logos,
    liveLogo,
    databaseState.dexUser
  ]);

  useEffect(
    () => {
      if (
        liveCacheState?.collectionState[getIdentity()] &&
        !liveCacheState?.collectionState[getIdentity()][props.sub?.mpersona]
          ?.open
      )
        setColArr(
          colArr.concat(
            props.sub?.topic ||
              props.sub?.topic_display_name ||
              props.sub?.props?.topic_display_name
          )
        );
      return () => {};
    },
    [liveCacheState.collectionState],
    props
  );

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_main_screen_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState?.theme, fontFam, fontWeight]);

  const getIdentity = () => {
    let theIdentity =
      props.sub?.props?.topiccollection ||
      props.sub?.props?.tag ||
      props.sub?.visibility ||
      props.sub?.props?.visibility;
    theIdentity = theIdentity?.toString();
    theIdentity = theIdentity?.toLowerCase();
    return theIdentity;
  };

  useEffect(() => {
    setRedraw(!redraw);
  }, [styleState.theme?.opacities?.logo_background_transparency]);

  // let lp =
  //   liveCacheState?.latestPinned?.[`${props.sub?.mtopic}_${props.sub?.mpersona}`];
  // let lm =
  //   liveCacheState?.latest?.[`${props.sub?.mtopic}_${props.sub?.mpersona}`];

  // useEffect(() => {
  //   globalState.logging && console.log("!!!! liveCacheState", lp, lm);
  //   setPreviewMsg(lp || lm);
  // }, [props.sub?.mtopic, props.sub?.mpersona, lp, lm]);

  // const handleSetCodeExecuted = (val) => {
  //   setCodeExecuted(val)
  // }

  const getPviewdata = () => {
    let pv = "";
    let pvType = "";
    let counter = 0;
    let inProcess = true;
    while (inProcess) {
      if (
        previewMsg?.parameters?.service_msg?.items[counter]?.type === "text"
      ) {
        if (previewMsg?.parameters?.service_msg?.items[counter]?.value)
          pv = previewMsg?.parameters?.service_msg?.items[counter]?.value;
        else if (previewMsg?.parameters?.service_msg?.items[counter]?.title)
          pv = previewMsg?.parameters?.service_msg?.items[counter]?.title;
        else pv = "";
        pvType = previewMsg?.parameters?.service_msg?.items[counter]?.type;
        inProcess = false;
      } else if (
        previewMsg?.parameters?.service_msg?.items[counter]?.type ===
          "hidden" ||
        !previewMsg?.parameters?.service_msg?.items[counter]?.type
      ) {
        counter++;
      } else if (
        previewMsg?.parameters?.service_msg?.items[counter]?.type ===
        "button_group"
      ) {
        pv = previewMsg?.parameters?.service_msg?.items[counter]?.buttons.map(
          (item) => item.title
        );
        pvType = previewMsg?.parameters?.service_msg?.items[counter]?.type;
        inProcess = false;
      }
    }
    let pvObj = {
      value: pv,
      type: pvType
    };
    return pvObj;
  };

  let content = (
    <div>
      {props.sub?.mtopic?.startsWith("t_") && modal}
      <div
        className="UI-topic_forwithpreview"
        id={props.sub?.mtopic}
        // ref={ref}
      >
        {
          <div
            className="avatar"
            style={{
              width: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {imagePres ? (
              <img
                // ref={refLogo}
                // id={`logo_${props.sub?.mtopic}`}
                style={
                  (topicsState?.logos &&
                    topicsState?.logos[
                      props.sub?.mtopic?.split("_").length < 3
                        ? props.sub?.mtopic
                        : `${props.sub?.mtopic}_${props.sub?.mpersona}`
                    ]) ||
                  liveLogo?.i64 ||
                  liveLogo?.b64
                    ? {
                        width: "3.5rem",
                        height: "3.5rem",
                        borderRadius: "50%"
                      }
                    : { display: "none" }
                }
                src={imagePres}
                alt="Logo"
                onClick={() => {
                  if (liveDescriptor) {
                    history.push("/imageDisplay", {
                      avatar: true,
                      picAttach: false,
                      thumb: imagePres,
                      descriptor: liveDescriptor.logo,
                      scope: liveDescriptor.logo?.imgpath?.split("/")[1],
                      imgdigest: liveDescriptor.logo?.imgdigest
                    });
                  }
                  if (props.sub?.mtopic.split("_") > 2) {
                  }
                }}
              />
            ) : (props.sub?.props?.tag &&
                props.sub?.props?.tag.toLowerCase().includes("job")) ||
              //tag id for "job"  is deprecated note end feb to check status
              (props.sub?.props?.topictype &&
                props.sub?.props?.topictype.toLowerCase() === "job") ? (
              <AiOutlineTool
                className="avatar"
                style={{
                  height: "3.5rem",
                  width: "3.5rem",
                  backgroundColor: "#008080",
                  color: "white"
                }}
              />
            ) : (
              <GetInitials
                type="topic"
                // head_list={false}
                str={
                  props?.sub?.topic_display_name ||
                  props?.sub?.props?.topic_display_name ||
                  props?.sub?.topic
                }
              ></GetInitials>
            )}
          </div>
        }
        <Link
          key={props.sub?.mtopic}
          className={
            props.sub?.mtopic?.startsWith("t_")
              ? "UI-topic-link-with-t"
              : "UI-topic-link"
          }
          to={{
            pathname: "/UIMessageList",
            state: {
              subscription: props.sub,
              size: "small"
            },
            size: "small"
          }}
        >
          <li
          // className="UI-topic-link"
          >
            <div className="UI-topic-inner">
              <div
                className="UI-topic-tdn hide-scrollbar"
                style={{
                  fontFamily: fontFam,
                  fontWeight: fontWeight
                }}
              >
                <span>
                  {props.sub?.topic_display_name?.replace(
                    ".",
                    "." + "\u200B"
                  ) ||
                    props.sub?.props?.topic_display_name?.replace(
                      ".",
                      "." + "\u200B"
                    ) ||
                    props.sub?.topic?.replace(".", "." + "\u200B")}
                </span>

                {/* 
                  Latest pinned message preview (only if a pinned message exists) 
                  Note: we could use a different UI component if we want to do something smarter
                */}
                {true && previewMsg?.parameters?.service_msg && (
                  <ServMsgPview
                    //     type={previewMsg?.parameters?.service_msg?.items[0]?.type
                    //       ? previewMsg?.parameters?.service_msg?.items[0]?.type
                    //   : previewMsg?.parameters?.service_msg?.items[1]?.type
                    //         ? previewMsg?.parameters?.service_msg?.items[1]?.type
                    //         :"undefined"
                    // }
                    id={previewMsg?.parameters?.service_msg?.id}
                    pv={getPviewdata().value}
                    type={getPviewdata().type}
                    //there are many variations here
                    //to get the last message (starting from the beginning of the message bubble)
                    //will require careful thinking or perhaps there is a way (easier way) using Livemessages
                    //and topicstate

                    //look at props.message?.parameters?.service_msg?.items (see servicemsgpreview.js)
                    //you look at items - ignore type=hidden goon to next item - then if text or button_group etc do whatever

                    // ?
                    //   ?
                    //   : "Community status"}

                    // : previewMsg?.parameters?.service_msg?.items[0]?.type === "button_group"
                    // ? "HIYA"
                    // : ""}
                    message={previewMsg}
                  ></ServMsgPview>
                  // <ServiceMsgPreview
                  //   message={previewMsg}
                  //   muid={globalState.muid}
                  //   version={globalState.version}
                  //   you={globalState.persona?.mpersona}
                  //   persona={globalState.persona?.persona}
                  //   codeExecuted={codeExecuted}
                  //   setCodeExecuted={(val)=>handleSetCodeExecuted(val)}
                  // />
                )}

                {/* Latest unpinned message preview (only if a pinned message doesn't exist) */}
                {true && previewMsg && !previewMsg?.parameters?.service_msg && (
                  <UIMessagePreview
                    message={previewMsg}
                    muid={globalState.muid}
                    version={globalState.version}
                    you={globalState.persona?.mpersona}
                    persona={globalState.persona?.persona}
                  />
                )}
              </div>
              {/* create a space similair to the tag button */}

              {/* <div
                // className="rgtContainer"
                // className="UI-topics-icon"
                // style={{
                //   border: "none",
                //   fontSize: "1.2rem",
                //   display: "flex",
                //   padding: "none",
                //   justifyContent: "flex-end"
                // }}
              >
                {isMuted() && showMuteLayout()}
                {isPinned() && showPinnedLayout()}
                {!isPinned() && !isMuted() && <div></div>}
              </div> */}
              {/* {isPinned() ? showDumbCollectionTag() : showCollectionTag()} */}
              {/* <div className="UI-topic-count">
                {countState.count[
                  props.sub?.mtopic?.startsWith("t_")
                    ? `${props.sub?.mtopic}_${props.sub?.mpersona}`
                    : props.sub?.mtopic
                ]?.unread > 0 &&
                  countState.count[
                  props.sub?.mtopic?.startsWith("t_")
                    ? `${props.sub?.mtopic}_${props.sub?.mpersona}`
                    : props.sub?.mtopic
                  ]?.unread} */}
              {/* &&
                      databaseState.liveCount[
                        props.sub?.mtopic?.startsWith("t_")
                          ? `${props.sub?.mtopic}_${props.sub?.mpersona}`
                          : props.sub?.mtopic
                      ]?.unread} */}
              {/* </div> */}
            </div>
          </li>
        </Link>
        <div className="rgtContainer">
          {/* <div

            className="UI-topics-icon"
            style={{
              border: "none",
              fontSize: "1.2rem",
              display: "flex",
              padding: "none",
              justifyContent: "flex-end"
            }}
          > */}
          {isMuted() ? (
            // && showMuteLayout()}
            <span className="mute-icon">
              <GoMute />
            </span>
          ) : (
            <span className="mute-icon"></span>
          )}
          {isPinned() ? (
            // showPinnedLayout()
            <span className="mute-icon">
              <AiFillPushpin />
            </span>
          ) : (
            <span className="mute-icon"></span>
          )}
          {/* {!isPinned() && !isMuted() && <div></div>} */}
          {/* </div> */}
          {isPinned() ? showDumbCollectionTag() : showCollectionTag()}
          {/* <div className="UI-topic-count"> */}
          {unreadCount > 0 ? (
            <div className="UI-topic-count">
              {unreadCount}
              {/* &&
                      databaseState.liveCount[
                        props.sub?.mtopic?.startsWith("t_")
                          ? `${props.sub?.mtopic}_${props.sub?.mpersona}`
                          : props.sub?.mtopic
                      ]?.unread} */}
            </div>
          ) : (
            <span className="UI-topic-count"></span>
          )}
        </div>
        <IconDropDown
          className=".UI-icondropdown"
          menuItems={
            isDialog()
              ? [
                  menuExit,
                  props.sub?.mtopic?.startsWith("t_") ? menuArchive : {},
                  props.sub?.mtopic?.startsWith("t_") ? menuPin : {}
                ]
              : visibility === "hidden" || visibility === "public"
              ? [
                  menuShare,
                  menuExit,
                  isOwner() ? menuDelete : {},
                  props.sub?.mtopic?.startsWith("t_") ? menuArchive : {},
                  props.sub?.mtopic?.startsWith("t_") ? menuPin : {}
                ]
              : [
                  menuExit,
                  isOwner() ? menuDelete : {},
                  props.sub?.mtopic?.startsWith("t_") ? menuArchive : {},
                  props.sub?.mtopic?.startsWith("t_") ? menuPin : {}
                ] // private
          }
        />
      </div>
    </div>
  );
  return content;
};

export default React.memo(UITopic, (prevProps, nextProps) => {
  process.env?.REACT_APP_DEBUG_THIS?.includes("UITopic") &&
    console.log("[UITopic] prevProps, nextProps: ", prevProps, nextProps);
  return isEqual(prevProps, nextProps);
});
