import { timestamp } from "hooks/helper";
import { useReducer } from "react";
import { isUnread } from "utils/isRead";

import { shouldLog } from "utils/shouldLog";
import { newDex } from "./dex";
import {
  migrateCredentials,
  setMeta,
  setMetaAll,
  updateMetaMessage
} from "./dexUtils";
// import Dexie from "dexie";

const isEqual = require("react-fast-compare");

// Note: React calls the reducer twice, so the console log repeats
// https://github.com/facebook/react/issues/16295#issuecomment-610098654

const pluck = new Audio("/icons/pluck.mp3");
const plucker = () =>
  pluck
    .play()
    .then((_) => {
      // Autoplay started!
    })
    .catch((error) => {
      // Autoplay was prevented.
      // Show a "Play" button so that user can start playback.
    });
const pluckTypes = ["w.t.msg", "w.topic.message", "w.mpersona.message"];

// In Dev mode, React will execute the reduce function twice, to highlight any side effects
// In our case, we are deliberately using the side effect to populate the database
// so when upserting a document, we break out when the existing  doc is identical to the new doc
const compareDocs = (a, b) => {
  let c = { ...a, _rev: "", _deleted: a.undeleted };
  let d = { ...b, _rev: "", _deleted: b.undeleted };
  return isEqual(c, d);
};

const timestampCoalesce = (pref1, pref2, pref3) => {
  if (pref1 && pref1 !== "" && pref1 !== "undefined") {
    return pref1;
  } else if (pref2 && pref2 !== "" && pref2 !== "undefined") {
    return pref2;
  } else if (pref3 && pref3 !== "" && pref3 !== "undefined") {
    return pref3;
  }
  return "";
};

// const update_fields_transaction = (database, table, condition, transform) => {
//   let returnVal = [];
//   return database
//     .transaction("rw", database.table(table), async () => {
//       let docs = await database.table(table).filter(condition).toArray();
//       shouldLog() && console.log("update_fields got docs", docs);
//       docs.forEach(
//         async (doc) =>
//           await database
//             .table(table)
//             .put(transform(doc))
//             .then(() => {
//               returnVal = [
//                 ...returnVal.filter(
//                   (v) =>
//                     v.mtopic !== doc.mtopic &&
//                     v.recipient !== doc.recipient &&
//                     v.smid !== doc.smid
//                 ),
//                 { mtopic: doc.mtopic, recipient: doc.recipient, smid: doc.smid }
//               ];
//             })
//       );
//     })
//     .then(() => {
//       shouldLog() && console.log("update_fields committed", returnVal);
//       return returnVal;
//     })
//     .catch((err) => {
//       console.error("update_fields error", err.stack);
//       return [];
//     });
// };

// const refresh_meta_in_transaction = (
//   database,
//   mtopic,
//   mpersona,
//   topicsRecord
// ) => {
//   console.log("topicsRecord", topicsRecord);
//   let topic = topicsRecord.value?.reduce((acc, t) => {
//     return t.mtopic === mtopic && t.mpersona === mpersona ? t : acc;
//   }, undefined);
//   if (!topic) return undefined;
//   return database.message
//     .where({ mtopic: mtopic, recipient: mpersona })
//     .toArray((doc) => doc)
//     .then((messages) => {
//       let metadata = messages.reduce(
//         (acc, message) => {
//           if (!["t", "true", true].includes(message.read)) {
//             acc.unreadCount = acc.unreadCount + 1;
//           } else {
//             message.msg_idx > acc.latestRead &&
//               (acc.latestRead = message.msg_idx);
//           }
//           acc.have_idx_list.push(message.msg_idx);
//           acc.have_idx_list.sort((a, b) => a - b);
//           acc.have_item_list.push({
//             msg_idx: message.msg_idx,
//             smid: message.smid
//           });
//           acc.have_item_list.sort((a, b) => a.msg_idx - b.msg_idx);
//           if (!message.parameters?.pin && !message.hide && !message._deleted)
//             acc.idxListUnpinned.push(message.msg_idx);
//           message.msg_idx > acc.latest && (acc.latest = message.msg_idx);
//           return acc;
//         },
//         {
//           mtopic: topic.mtopic,
//           mpersona: topic.mpersona,
//           latestRead: 0,
//           latest: 0,
//           unreadCount: 0,
//           have_idx_list: [],
//           have_item_list: [],
//           visible_idx: topic.visible_idx,
//           idxListUnpinned: [],
//           pinned: topic.pinned,
//           archived: topic?.subprops?.archived,
//           last_msg_idx: topic.last_msg_idx
//         }
//       );
//       database.topic_metadata.put(metadata);
//     });
// };



const reducer = (state, action) => {
  console.log("SET db", state, action);
  let db;
  let doc;
  // console.log("[useDatabaseState] reducer", state, action);
  switch (action.type) {
    case "CREATE_DB_ADMIN_DEX":
      let dexAdmin = newDex(action.values?.dbName || "dexAdmin");
      migrateCredentials(dexAdmin);
      return {
        ...state,
        dexAdmin: dexAdmin
      };
    case "CREATE_USER_DEX":
      shouldLog() && console.log("CREATE_USER_DEX 1");
      let dexUser =
        action.values.dbName && newDex(`dexUser_${action.values.dbName}`);
      shouldLog() && console.log("CREATE_USER_DEX 2", dexUser);
      // action.values.cb(false, action.values.dbName);
      return {
        ...state,
        dexUser: dexUser
      };
    // case "DEX_PUT":
    //   shouldLog() && console.log("DEX_PUT", Date.now(), action);
    //   action.values.db &&
    //     action.values.table &&
    //     action.values.db
    //       .table(action.values.table)
    //       .put(action.values.doc)
    //       .then(() => action.values.cb && action.values.cb());
    //   return state;
    // case "DEX_PUT_TRANS":
    //   shouldLog() && console.log("DEX_PUT_TRANS", Date.now(), action);
    //   action.values.db &&
    //     action.values.db
    //       .transaction(
    //         "rw",
    //         action.values.db.table(action.values.table),
    //         async () => {
    //           await action.values.db
    //             .table(action.values.table)
    //             .put(action.values.doc);
    //         }
    //       )
    //       .then(() => {
    //         shouldLog() && console.log("DEX_PUT_TRANS committed");
    //       })
    //       .catch((err) => {
    //         console.error("DEX_PUT_TRANS error", err.stack);
    //         action.values.db.table(action.values.table).put(action.values.doc);
    //       });
    //   return state;
    // case "DEX_UPDATE_INDICES":
    //   setMeta(
    //     state.dexUser,
    //     action.values.mtopic,
    //     action.values.recipient,
    //     "have_idx_list",
    //     (currentVal) =>
    //       [...(currentVal || []), ...(action.values.add || [])]
    //         .filter((i) => !(action.values.remove || []).includes(i))
    //         .filter((v, i, a) => a.indexOf(v) === i)
    //         .sort()
    //   );
    //   action.values.items &&
    //     setMeta(
    //       state.dexUser,
    //       action.values.mtopic,
    //       action.values.recipient,
    //       "have_item_list",
    //       (currentVal) =>
    //         [
    //           ...(currentVal || []).filter(
    //             (currItem) =>
    //               !action.values.items.some(
    //                 (newItem) =>
    //                   currItem.msg_idx === newItem.msg_idx &&
    //                   currItem.smid === newItem.smid
    //               )
    //           ),
    //           ...action.values.items
    //         ].sort()
    //     );
    //   return state;
    // case "DEX_UPSERT_MATCH": // uses a function to update all matching items. If none exist htn insert one with the matching function
    //   if (action.values.db) {
    //     action.values.db
    //       .transaction(
    //         "rw",
    //         action.values.db.table(action.values.table),
    //         async () => {
    //           let matches = await action.values.db
    //             .table(action.values.table)
    //             .where(action.values.match)
    //             .toArray((doc) => doc);
    //           shouldLog() &&
    //             console.log("DEX_UPSERT_MATCH_", Date.now(), matches);
    //           if (matches?.length > 0)
    //             matches.forEach((match) => {
    //               shouldLog() &&
    //                 console.log(
    //                   "DEX_UPSERT_MATCH_ replacement",
    //                   Date.now(),
    //                   action.values.function(match)
    //                 );
    //               action.values.db
    //                 .table(action.values.table)
    //                 .put(action.values.function(match));
    //             });
    //           else
    //             action.values.db
    //               .table(action.values.table)
    //               .put(action.values.function(action.values.match));
    //         }
    //       )
    //       .then(() => {
    //         shouldLog() && console.log("DEX_UPSERT_MATCH committed");
    //       })
    //       .catch((err) => {
    //         console.error("DEX_UPSERT_MATCH error", err.stack);
    //         action.values.db
    //           .table(action.values.table)
    //           .put(action.values.function(action.values.match));
    //       });
    //   }
    //   return state;
    // case "DEX_BULK_UPDATE_TOPIC_METADATA":
    // action.values.db.transaction(
    //   "rw",
    //   action.values.db.topic_metadata,
    //   async () => {
    //     for (let msg of action.values.messages) {
    //       let filter = { mtopic: msg.mtopic, mpersona: msg.recipient };
    //       let doc = await action.values.db.topic_metadata.get(filter);
    //       if (
    //         // msg_idx is not in have_idx_list
    //         doc &&
    //         !doc.have_idx_list.includes(msg.msg_idx)
    //       ) {
    //         // NOTE DEX: this needs to modified to consider if the removed smid was read or not...
    //         let smid_removed = doc.have_item_list.filter(
    //           (i) => i.smid !== msg.smid
    //         );
    //         let idx_removed = smid_removed.map((i) => i.msg_idx);
    //         doc.have_idx_list = [...idx_removed, msg.msg_idx].sort(
    //           (a, b) => a - b
    //         );
    //         doc.have_item_list = [
    //           ...smid_removed,
    //           { msg_idx: msg.msg_idx, smid: msg.smid }
    //         ].sort((a, b) => a.msg_idx - b.msg_idx);
    //         doc.idxListUnpinned = doc.idxListUnpinned.filter((i) =>
    //           idx_removed.includes(i)
    //         );
    //         msg.parameters?.pin &&
    //           (doc.idxListUnpinned = [
    //             ...doc.idxListUnpinned,
    //             msg.msg_idx
    //           ].sort((a, b) => a - b));
    //         doc.itemListUnread = doc.itemListUnread.filter((i) =>
    //           doc.have_idx_list.includes(i.msg_idx)
    //         );
    //         isUnread(msg) &&
    //           doc.itemListUnread.push({
    //             msg_idx: msg.msg_idx,
    //             smid: msg.smid
    //           });
    //         doc.itemListUnread.sort((a, b) => a.msg_idx - b.msg_idx);
    //         doc.unreadCount = doc.itemListUnread.length;
    //         doc.last_msg_idx = Math.max(...doc.have_idx_list);
    //         doc.latest = Math.max(...doc.have_idx_list, doc.latest);
    //         let read_idx_list = doc.have_idx_list.filter(
    //           (i) => !doc.itemListUnread.map((j) => j.msg_idx).includes(i)
    //         );
    //         doc.latestRead = Math.max(...read_idx_list);
    //         if (msg.ts_read && msg.ts_read > (doc.latestReadTime || "")) {
    //           doc.latestReadTime = msg.ts_read;
    //         }
    //         await action.values.db.topic_metadata.put({ ...doc });
    //       }
    //     }
    //   }
    // );
    // return state;

    // case "DEX_UPDATE_TOPIC_METADATA":
    //   action.values.db.transaction(
    //     "rw",
    //     [
    //       "account",
    //       "message",
    //       "topic_metadata",
    //       // "topic_metadata_buffer",
    //       "latest_message"
    //     ],
    //     async () => {
    //       //???
    //       let msg = action.values.jMessage;
    //       let filter = { mtopic: msg.mtopic, mpersona: msg.recipient };
    //       let doc = await action.values.db.topic_metadata.get(filter);
    //       if (
    //         // msg_idx is not in have_idx_list
    //         doc &&
    //         !doc.have_idx_list.includes(msg.msg_idx)
    //       ) {
    //         await updateMetaMessage(action.values.db, doc, msg, false);
    //       }
    //     }
    //   );
    //   return state;
    // case "DEX_MODIFY_TRANS":
    //   let dbM = action.values.db;
    //   let dbT = action.values.table;
    //   action.values.db.transaction("rw", dbM[dbT], async () => {
    //     let filter = action.values.filter;
    //     let transform = action.values.transform;
    //     let recs = await dbM[dbT].filter(filter).toArray((r) => r);
    //     let changedRecs = recs.reduce((acc, rec) => {
    //       let modRec = transform(rec);
    //       return !isEqual(modRec, rec) ? [...acc, modRec] : acc;
    //     }, []);
    //     dbM[dbT].bulkPut(changedRecs).then(function (lastKey) {});
    //     // add the following if we want to allow partial success
    //     // .catch(Dexie.BulkError, function (e) {
    //     //   // Explicitely catching the bulkAdd() operation makes those successful
    //     //   // additions commit despite that there were errors.
    //     //   console.error ("DEX_MODIFY_TRANS faiures", e.failures);
    //     //   });
    //   });
    //   return state;
    case "DEX_UPDATE_TOPIC_ARCHIVE":
      action.values.db.transaction("rw", action.values.db.account, async () => {
        let filter = {
          type: "topics"
        };
        let doc = await action.values.db.account.get(filter);
        if (doc && doc.value) {
          let topics = doc.value.filter(
            (t) =>
              t.mtopic === action.values.mtopic &&
              t.mpersona === action.values.mpersona
          );
          if (
            topics?.length === 1 &&
            topics[0]?.subprops?.archived !== action.values.archived
          ) {
            let newTopic = { ...topics[0] };
            newTopic.subprops = newTopic.subprops || {};
            newTopic.subprops.archived = action.values.archived;
            let newVal = doc.value.filter(
              (t) =>
                !(
                  t.mtopic === action.values.mtopic &&
                  t.mpersona === action.values.mpersona
                )
            );
            action.values.db.account.put({
              type: "topics",
              value: [...newVal, newTopic],
              time: Date.now()
            });
          }
        }
      });
      return state;

    case "DEX_UNREAD_MOD":
      setMeta(
        state.dexUser,
        action.values.mtopic,
        action.values.recipient,
        "unread",
        (currentVal) => {
          let t = (currentVal || []).filter(
            (i) => i.smid !== action.values.smid
          );
          return [false, "false", "f"].includes(action.values.read)
            ? [
                ...t,
                { smid: action.values.smid, msg_idx: action.values.msg_idx }
              ]
            : t;
        }
      );
      return state;
    case "DEX_UNREAD_MOD_ALL":
      shouldLog() && console.log("DEX_UNREAD_MOD_ALL", action);
      setMetaAll(
        state.dexUser,
        action.values.mtopic,
        "unread",
        (currentVal) => {
          let t = (currentVal || []).filter(
            (i) => i.smid !== action.values.smid
          );
          return [false, "false", "f"].includes(action.values.read)
            ? [
                ...t,
                { smid: action.values.smid, msg_idx: action.values.msg_idx }
              ]
            : t;
        }
      );
      return state;
    // case "DEX_UPSERT_KEYS_LATEST_TRANS":
    //   shouldLog() &&
    //     console.log(
    //       "DEX_UPSERT_KEYS_LATEST_TRANS",
    //       action.values.db.version,
    //       Date.now(),
    //       action
    //     );
    //   if (action.values.db) {
    //     let keysTrans = action.values.db.table(action.values.table).schema;
    //     let filterTrans = [
    //       keysTrans.primKey.name,
    //       ...keysTrans.indexes.map((idx) => idx.name)
    //     ].filter((v) => action.values.match_keys.includes(v));
    //     let filterObjTrans = Object.fromEntries(
    //       Object.entries(action.values.doc).filter(([key]) =>
    //         filterTrans.includes(key)
    //       )
    //     );
    //     shouldLog() &&
    //       console.log(
    //         "DEX_UPSERT_KEYS_LATEST_TRANS filterObj ",
    //         filterObjTrans
    //       );
    //     action.values.db
    //       .transaction(
    //         "rw",
    //         action.values.db.table(action.values.table),
    //         async () => {
    //           let doc = await action.values.db
    //             .table(action.values.table)
    //             .get(filterObjTrans);
    //           shouldLog() &&
    //             console.log("DEX_UPSERT_KEYS_LATEST_TRANS got doc", doc);
    //           shouldLog() &&
    //             console.log("DEX_UPSERT_KEYS_LATEST_TRANS new doc", {
    //               ...doc,
    //               ...action.values.doc
    //             });
    //           if (
    //             !doc ||
    //             action.values.doc[action.values.latest_key] >
    //               doc[action.values.latest_key]
    //           )
    //             await action.values.db
    //               .table(action.values.table)
    //               .put({ ...doc, ...action.values.doc });
    //         }
    //       )
    //       .then(() => {
    //         shouldLog() &&
    //           console.log("DEX_UPSERT_KEYS_LATEST_TRANS committed");
    //       })
    //       .catch((err) => {
    //         console.error("DEX_UPSERT_KEYS_LATEST_TRANS error", err.stack);
    //         action.values.db.table(action.values.table).put(action.values.doc);
    //       });
    //   }
    // return state;

    // case "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS":
    //   shouldLog() &&
    //     console.log(
    //       "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS",
    //       action.values.db.version,
    //       Date.now(),
    //       action
    //     );
    //   if (action.values.db) {
    //     let keysTrans = action.values.db.table(action.values.table).schema;
    //     action.values.db
    //       .transaction(
    //         "rw",
    //         action.values.db.table(action.values.table),
    //         async () => {
    //           for (let newDoc of action.values.docs) {
    //             let filterTrans = [
    //               keysTrans.primKey.name,
    //               ...keysTrans.indexes.map((idx) => idx.name)
    //             ].filter((v) => action.values.match_keys.includes(v));
    //             let filterObjTrans = Object.fromEntries(
    //               Object.entries(newDoc).filter(([key]) =>
    //                 filterTrans.includes(key)
    //               )
    //             );
    //             let doc = await action.values.db
    //               .table(action.values.table)
    //               .get(filterObjTrans);
    //             shouldLog() &&
    //               console.log(
    //                 "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS got doc",
    //                 doc
    //               );
    //             shouldLog() &&
    //               console.log("DEX_MULTI_UPSERT_KEYS_LATEST_TRANS new doc", {
    //                 ...doc,
    //                 ...newDoc
    //               });
    //             if (
    //               !doc ||
    //               newDoc[action.values.latest_key] >
    //                 doc[action.values.latest_key]
    //             )
    //               await action.values.db
    //                 .table(action.values.table)
    //                 .put({ ...doc, ...newDoc });

    //             shouldLog() &&
    //               console.log(
    //                 "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS notify",
    //                 newDoc.notify
    //               );
    //           }
    //         }
    //       )
    //       .then(() => {
    //         shouldLog() &&
    //           console.log("DEX_MULTI_UPSERT_KEYS_LATEST_TRANS committed");
    //       })
    //       .catch((err) => {
    //         console.error(
    //           "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS error",
    //           err.stack
    //         );
    //         action.values.db.table(action.values.table).put(action.values.doc);
    //       });
    //   }
    // return state;
    case "DEX_UPSERT_KEYS": // beware of race conditions...
      shouldLog() && console.log("DEX_UPSERT_KEYS", action);
      if (action.values.db) {
        let keys = action.values.db.table(action.values.table).schema;
        let filter = [
          keys.primKey.name,
          ...keys.indexes.map((idx) => idx.name)
        ];
        let filterObj = Object.fromEntries(
          Object.entries(action.values.doc).filter(([key]) =>
            filter.includes(key)
          )
        );
        shouldLog() && console.log("DEX_UPSERT_KEYS filterObj ", filterObj);
        action.values.db
          .table(action.values.table)
          .get(filterObj)
          .then((doc) => {
            shouldLog() && console.log("DEX_UPSERT_KEYS got doc", doc);
            shouldLog() &&
              console.log("DEX_UPSERT_KEYS new doc", {
                ...doc,
                ...action.values.doc
              });
            action.values.db
              .table(action.values.table)
              .put({ ...doc, ...action.values.doc });
          })
          .catch(() => {
            action.values.db.table(action.values.table).put(action.values.doc);
          });
      }
      return state;
    case "DEX_UPSERT_KEYS_TRANS": // gets the doc with matching keys, and updates the new items in the doc, keeping the unchanged items
      shouldLog() && console.log("DEX_UPSERT_KEYS_TRANS", Date.now(), action);
      if (action.values.db) {
        let keysTrans = action.values.db.table(action.values.table).schema;
        let filterTrans = [
          keysTrans.primKey.name,
          ...keysTrans.indexes.map((idx) => idx.name)
        ];
        let filterObjTrans = Object.fromEntries(
          Object.entries(action.values.doc).filter(([key]) =>
            filterTrans.includes(key)
          )
        );
        shouldLog() &&
          console.log("DEX_UPSERT_KEYS_TRANS filterObj ", filterObjTrans);
        action.values.db
          .transaction(
            "rw",
            action.values.db.table(action.values.table),
            async () => {
              let doc = await action.values.db
                .table(action.values.table)
                .get(filterObjTrans);
              shouldLog() && console.log("DEX_UPSERT_KEYS_TRANS got doc", doc);
              shouldLog() &&
                console.log("DEX_UPSERT_KEYS_TRANS new doc", {
                  ...doc,
                  ...action.values.doc
                });
              await action.values.db
                .table(action.values.table)
                .put({ ...doc, ...action.values.doc });
            }
          )
          .then(() => {
            shouldLog() && console.log("DEX_UPSERT_KEYS_TRANS committed");
          })
          .catch((err) => {
            console.error("DEX_UPSERT_KEYS_TRANS error", err.stack);
            action.values.db.table(action.values.table).put(action.values.doc);
          });
      }
      return state;
    // case "DEX_DELETE_RECORD":
    //   action.values.db &&
    //     action.values.table &&
    //     action.values.db
    //       .table(action.values.table)
    //       .delete(action.values.primaryKey);
    //   return state;
    // case "DEX_DELETE_WHERE":
    //   action.values.db &&
    //     action.values.table &&
    //     action.values.db
    //       .table(action.values.table)
    //       .where(action.values.where)
    //       .delete();
    //   return state;

    // case "DEX_MULTI_MARK_READ":
    //   // TODO: DEX update the topic_metadata
    //   shouldLog() && console.log("DEX_MULTI_MARK_READ action", action);
    //   let ts_read = timestamp();
    //   action.values.db &&
    //     action.values.db
    //       .transaction("rw", ["message", "topic_metadata"], async () => {
    //         let returnVal = [];
    //         if (action.values.global) {
    //           await action.values.db.message
    //             .filter(
    //               (m) =>
    //                 !["t", "true", true].includes(m.read) &&
    //                 (action.values.mtopics || [m.mtopic]).includes(m.mtopic)
    //             )
    //             .modify((m) => {
    //               m.read = "true";
    //               m.ts_read = ts_read;
    //             });
    //           await action.values.db.topic_metadata
    //             .filter((t) =>
    //               (action.values.mtopics || [t.mtopic]).includes(t.mtopic)
    //             )
    //             .modify((t) => {
    //               t.itemListUnread = [];
    //               t.unreadCount = 0;
    //               t.latestRead = t.have_idx_list
    //                 ? Math.max(...t.have_idx_list, 0)
    //                 : 0;
    //               t.latestReadTime = ts_read;
    //             });
    //           return returnVal;
    //         } else {
    //           let keys = action.values.messages.reduce((acc, message) => {
    //             let msgList = [];
    //             if (!action.values.all) {
    //               msgList = [[message.mtopic, message.recipient, message.smid]];
    //             } else {
    //               msgList = action.values.all
    //                 .filter((mpersona) => mpersona)
    //                 .map((mpersona) => [
    //                   message.mtopic,
    //                   mpersona,
    //                   message.smid
    //                 ]);
    //             }
    //             return [...acc, ...msgList];
    //           }, []);
    //           let docs = await action.values.db.message
    //             .where(["mtopic", "recipient", "smid"])
    //             .anyOf(keys)
    //             .filter((d) => !["t", "true", true].includes(d.read))
    //             .toArray();
    //           shouldLog() &&
    //             console.log("DEX_MULTI_MARK_READ updating docs", docs);
    //           for (let doc of docs) {
    //             for (let mp of [
    //               ...new Set([...action.values.all, doc.recipient])
    //             ]) {
    //               let stored_doc = await action.values.db.message.get({
    //                 mtopic: doc.mtopic,
    //                 recipient: mp,
    //                 smid: doc.smid
    //               });
    //               if (stored_doc) {
    //                 stored_doc.read = "t";
    //                 stored_doc.ts_read = ts_read;
    //                 await action.values.db.message.put({ ...stored_doc });
    //                 //???
    //                 let filter = {
    //                   mtopic: stored_doc.mtopic,
    //                   mpersona: stored_doc.recipient
    //                 };
    //                 let meta_doc = await action.values.db.topic_metadata.get(
    //                   filter
    //                 );
    //                 if (meta_doc && stored_doc.msg_idx) {
    //                   await update_meta(action.values.db, meta_doc, {
    //                     ...stored_doc
    //                   });
    //                 }
    //               }
    //             }
    //           }
    //           return returnVal;
    //         }
    //       })
    //       .then((r) => {
    //         shouldLog() && console.log("DEX_MULTI_MARK_READ committed", r);
    //         return r;
    //       })
    //       .catch((err) => {
    //         console.error("DEX_MULTI_MARK_READ error", err.stack);
    //         return [];
    //       });

    //   return state;

    // case "DEX_HIDE_TOPIC_MESSAGES":
    //   db = action.values.db;
    //   db.message
    //     .where(["mtopic", "recipient"])
    //     .equals([action.values.mtopic, action.values.mpersona])
    //     .modify((m) => (m.hide = true));
    //   db.latest_message
    //     .where(["mtopic", "recipient"])
    //     .equals([action.values.mtopic, action.values.mpersona])
    //     .delete();
    //   return state;

    case "MASTER_DB_READY":
      if (state.dbReady === action.values.ready) return state;
      else
        return {
          ...state,
          dbReady: action.values.ready
        };
    default: {
      console.log("!!!missing", action);
      return state;
    }
  }
};

const useDatabaseState = (state) => {
  const [databaseState, databaseDispatch] = useReducer(reducer, state);
  return { databaseState, databaseDispatch };
};

export default useDatabaseState;
