export const versionAdmin = 1;
export const versionUser = 3;

export const userStore = {
  user: "key", // types are user, logging, dbName (as per dbUser)
  parameter: "key", //parameters that can be set/deleted by the server (eg OTP required, logging on/off)
  cache: "digest, scope, storedate", // types are image, logo
  thumb: "mpersona",
  descriptor: "[mpersona+digest], mpersona, digest", //
  topic_descriptor: "mtopic, digest", //
  topic_logo: "mtopic, digest", //
  participants: "mtopic", // maybe include last_msg_idx
  topic_metadata: "[mtopic+mpersona], mtopic, mpersona", // unread, indices
  topic_metadata_buffer: "[mtopic+mpersona], mtopic, mpersona", // elliminating transactional delays
  notifications: "[mtopic+mpersona+msg_idx], mtopic, mpersona, msg_idx",
  message:
    "[mtopic+recipient+smid], mtopic, msg_idx, smid, recipient, read, [mtopic+recipient]",
  uploads: "[mpersona+digest], mpersona, digest", // type
  upload_descriptor: "[mpersona+digest], mpersona, digest", // type
  upload_share_image: "[scope+digest]", // type
  upload_theme: "id", // type
  upload_topic_descriptor: "mtopic, digest", // type
  latest_message: "[mtopic+recipient], mtopic, recipient",
  latest_pinned_message: "[mtopic+recipient], mtopic, recipient",
  mute: "[mtopic+recipient], mtopic, recipient",
  delivery_metadata: "[mtopic+mpersona], mtopic, mpersona",
  collection_state: "[topiccollection+mpersona],topiccollection, mpersona",
  themes: "id",
  send: "smid, type",
  account: "type" // Types are: w.t.list, r.t.list, collectionstate, count, mute, personas, subscriptions, topics, user_info
};

export const adminStore = {
  store: "key", // client, logging, user
  dexNames: "name" // list of known databases
};
