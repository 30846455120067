import { useState, useEffect, useRef } from "react";
import themes from "theme/schema.json";
import { timestamp, randomString, timestampCutoff } from "hooks/helper";
import { version } from "version.js";
import { addAttachments, b64ToStr, moveAttachments } from "utils/attach";
import { shouldLog } from "utils/shouldLog";
import { useLiveQuery } from "dexie-react-hooks";
import { isHide } from "utils/isRead";
const isEqual = require("react-fast-compare");

const immediate_1 = 100;
const immediate_2 = 1000;
const immediate_3 = 10000;

const thumbnailLength = 40000;

const timestampSelect = (pref1, pref2, pref3) => {
  if (pref1 && pref1 !== "" && pref1 !== "undefined") {
    return pref1;
  } else if (pref2 && pref2 !== "" && pref2 !== "undefined") {
    return pref2;
  } else if (pref3 && pref3 !== "" && pref3 !== "undefined") {
    return pref3;
  }
  return "";
};

// clientID
// user
// prefs

// export const useLivePreviews = (db) => {

//   const liveTopicMeta = useLiveQuery(() => {
//     return undefined
//   }, [db])
// }

export const useLiveDex = (db) => {
  const [liveDescriptorsB, setLiveDescriptorsB] = useState([]);

  const descriptors = useLiveQuery(
    () => db && db.descriptor.filter((m) => m).toArray(),
    [db]
  );

  useEffect(() => {
    shouldLog() && console.log("useLiveDex", descriptors);
    if (descriptors && !isEqual(descriptors, liveDescriptorsB))
      setLiveDescriptorsB(descriptors);
  }, [descriptors]);

  return { liveDescriptorsB: liveDescriptorsB };
};

export const useUploadsDex = (db) => {
  const [liveUploads, setLiveUploads] = useState([]);

  const uploads = useLiveQuery(
    () => db && db.uploads.filter((m) => true).toArray(),
    [db]
  );

  useEffect(() => {
    if (!isEqual(uploads, liveUploads)) setLiveUploads(uploads);
  }, [uploads]);

  return { liveUploads: liveUploads };
};

export const useImageUploadsDex = (db) => {
  const [liveImageUploads, setLiveImageUploads] = useState([]);

  const uploads = useLiveQuery(
    () => db && db.upload_share_image.filter((m) => true).toArray(),
    [db]
  );

  useEffect(() => {
    if (!isEqual(uploads, liveImageUploads)) setLiveImageUploads(uploads);
  }, [uploads]);

  return { liveImageUploads: liveImageUploads };
};

export const useUploadDescriptorsDex = (db) => {
  const [liveDescriptorUploads, setLiveDescriptorUploads] = useState([]);

  const descriptorUploads = useLiveQuery(
    () => db && db.upload_descriptor.filter((m) => true).toArray(),
    [db]
  );

  useEffect(() => {
    if (!isEqual(descriptorUploads, liveDescriptorUploads)) {
      shouldLog() && console.log("descriptorUploads", descriptorUploads);
      setLiveDescriptorUploads(descriptorUploads);
    }
  }, [descriptorUploads]);

  return { liveDescriptorUploads: liveDescriptorUploads };
};

export const useUploadTopicDescriptorsDex = (db) => {
  const [liveTopicDescriptorUploads, setLiveTopicDescriptorUploads] = useState(
    []
  );

  const topicDescriptorUploads = useLiveQuery(
    () => db && db.upload_topic_descriptor.filter((m) => true).toArray(),
    [db]
  );

  useEffect(() => {
    if (!isEqual(topicDescriptorUploads, liveTopicDescriptorUploads))
      setLiveTopicDescriptorUploads(topicDescriptorUploads);
  }, [topicDescriptorUploads]);

  return { liveTopicDescriptorUploads: liveTopicDescriptorUploads };
};

export const useUploadThemesDex = (db) => {
  const [liveThemeUploads, setLiveThemeUploads] = useState([]);

  const themeUploads = useLiveQuery(
    () => db && db.upload_theme.filter((m) => true).toArray(),
    [db]
  );

  useEffect(() => {
    if (!isEqual(themeUploads, liveThemeUploads))
      setLiveThemeUploads(themeUploads);
  }, [themeUploads]);

  return { liveThemeUploads: liveThemeUploads };
};

export const useThemesDex = (db) => {
  const [liveThemes, setLiveThemes] = useState([]);

  const themes = useLiveQuery(
    () => db && db.themes.filter((l) => true).toArray(),
    [db]
  );

  useEffect(() => {
    let objThemes = {};
    (themes || []).forEach((theme) => {
      try {
        let r = { ...theme };
        if (r.colors) objThemes[r.id] = r;
        if (r.fonts) objThemes[r.id] = r;
        if (r.opacities) objThemes[r.id] = r;
        if (r.transparency) objThemes[r.id] = r;
      } catch (err) {}
    });
    if (objThemes && !isEqual(objThemes, liveThemes)) {
      setLiveThemes(objThemes);
      // console.log("DEX_logos", Date.now(), logos);
    }

    return () => {};
  }, [themes]);

  return { liveThemes: liveThemes };
};

export const useThumbsDex = (db) => {
  const [liveThumbs, setLiveThumbs] = useState([]);

  const thumbs = useLiveQuery(
    () => db && db.thumb.filter((r) => true).toArray((doc) => doc),
    [db]
  );

  useEffect(() => {
    if (thumbs && thumbs.length > 0 && !isEqual(thumbs, liveThumbs)) {
      setLiveThumbs(thumbs);
      shouldLog() && console.log("DEX_thumbs", Date.now(), thumbs);
    }
    return () => {};
  }, [thumbs]);

  return { liveThumbs: liveThumbs };
};

export const useLogosDex = (db) => {
  const [liveLogos, setLiveLogos] = useState([]);

  const logos = useLiveQuery(
    () => db && db.topic_logo.filter((l) => true).toArray(),
    [db]
  );

  useEffect(() => {
    if (logos && !isEqual(logos, liveLogos)) {
      setLiveLogos(logos || []);
      // console.log("DEX_logos", Date.now(), logos);
    }
    return () => {};
  }, [logos]);

  return { liveLogos: liveLogos };
};

export const useTopicsDex = (db) => {
  const [liveTopics, setLiveTopics] = useState([]);
  const [liveTopicChanges, setLiveTopicChanges] = useState([]);
  const [liveArchiveChanges, setLiveArchiveChanges] = useState({
    notFirst: undefined,
    value: []
  }); // detects topics becoming archived
  const [liveDearchiveChanges, setLiveDearchiveChanges] = useState({
    notFirst: undefined,
    value: []
  }); // detects topics becoming dearchived

  const topics = useLiveQuery(
    () => db && db.account.get({ type: "topics" }),
    [db]
  );

  useEffect(() => {
    const removeMatchingObjects = (list1, list2) => {
      return list1.filter((obj1) => {
        return !list2.some((obj2) => {
          return isEqual(obj1, obj2);
        });
      });
    };

    let mounted = true;
    let newVal = topics?.value; //.slice(0, 3);
    if (newVal && !isEqual(newVal, liveTopics)) {
      shouldLog() && console.log("DEX_topics", Date.now(), topics);
      // for each item, check if it is changed
      let changedVals = removeMatchingObjects(newVal || [], liveTopics || []);
      if (changedVals && !isEqual(changedVals, liveTopicChanges)) {
        shouldLog() && console.log("DEX_topics changes", Date.now(), topics);
        mounted && setLiveTopicChanges(changedVals);
      }
      mounted && setLiveTopics((liveTopics) => newVal);

      let changedArchives = removeMatchingObjects(
        newVal.filter((t) =>
          ["t", "true", true].includes(t?.subprops?.archived)
        ) || [],
        liveTopics.filter((t) =>
          ["t", "true", true].includes(t?.subprops?.archived)
        ) || []
      );

      let changedDearchives = removeMatchingObjects(
        newVal.filter(
          (t) => !["t", "true", true].includes(t?.subprops?.archived)
        ) || [],
        liveTopics.filter(
          (t) => !["t", "true", true].includes(t?.subprops?.archived)
        ) || []
      );

      if (
        changedArchives &&
        !isEqual(changedArchives, liveArchiveChanges.value)
      ) {
        shouldLog() &&
          console.log(
            "DEX_topics archive changes",
            Date.now(),
            liveArchiveChanges,
            changedArchives
          );
        mounted &&
          setLiveArchiveChanges((liveArchiveChanges) => {
            return {
              notFirst:
                liveArchiveChanges.notFirst === undefined ? false : true,
              value: changedArchives
            };
          });
      }

      if (
        changedDearchives &&
        !isEqual(changedDearchives, liveDearchiveChanges.value)
      ) {
        shouldLog() &&
          console.log(
            "DEX_topics dearchive changes",
            Date.now(),
            liveDearchiveChanges,
            changedDearchives
          );
        mounted &&
          setLiveDearchiveChanges((liveDearchiveChanges) => {
            return {
              notFirst:
                liveDearchiveChanges.notFirst === undefined ? false : true,
              value: changedDearchives
            };
          });
      }

      // console.log("!!!newVal", newVal);

      if (newVal && newVal.length > 0 && db) {
        db.topic_metadata
          .where(["mtopic", "mpersona"])
          .noneOf(newVal.map((t) => [t.mtopic, t.mpersona]))
          .toArray((a) => a)
          .then((a) => {
            let remove = a.map((t) => [t.mtopic, t.mpersona]);
            // console.log("!!!newVal", remove);
            db.topic_metadata
              .where(["mtopic", "mpersona"])
              .anyOf(remove)
              .delete();
          });

        db.topic_metadata_buffer
          .where(["mtopic", "mpersona"])
          .noneOf(newVal.map((t) => [t.mtopic, t.mpersona]))
          .toArray((a) => a)
          .then((a) => {
            let remove = a.map((t) => [t.mtopic, t.mpersona]);
            // console.log("!!!newVal", remove);
            db.topic_metadata_buffer
              .where(["mtopic", "mpersona"])
              .anyOf(remove)
              .delete();
          });
      }
    }
    return () => {
      mounted = false;
    };
  }, [topics]);

  return {
    liveTopics: liveTopics,
    liveTopicChanges: liveTopicChanges,
    liveArchiveChanges: liveArchiveChanges,
    liveDearchiveChanges: liveDearchiveChanges
  };
};

export const useTopicDescriptorsDex = (db) => {
  const [liveTopicDescriptors, setLiveTopicDescriptors] = useState([]);
  const [liveTopicDescriptorChanges, setLiveTopicDescriptorChanges] = useState(
    []
  );

  const topicDescriptors = useLiveQuery(
    () =>
      db && db.topic_descriptor.filter((r) => r.digest).toArray((doc) => doc),
    [db]
  );

  useEffect(() => {
    if (topicDescriptors && !isEqual(topicDescriptors, liveTopicDescriptors)) {
      let oldDescriptors = JSON.parse(JSON.stringify(liveTopicDescriptors));
      setLiveTopicDescriptors(topicDescriptors || []);
      let changes =
        topicDescriptors.filter(
          (t) => !oldDescriptors.some((o) => isEqual(t, o))
        ) || [];
      if (!isEqual(changes, liveTopicDescriptorChanges)) {
        setLiveTopicDescriptorChanges(changes);
        shouldLog() &&
          console.log(
            "DEX_topicDescriptors",
            Date.now(),
            topicDescriptors,
            changes
          );
      }
    }
    return () => {};
  }, [topicDescriptors]);

  return {
    liveTopicDescriptors: liveTopicDescriptors,
    liveTopicDescriptorChanges: liveTopicDescriptorChanges
  };
};

export const usePersonasDex = (db) => {
  const [livePersonas, setLivePersonas] = useState([]);

  const personas = useLiveQuery(
    () => db && db.account.where({ type: "personas" }).toArray((doc) => doc),
    [db]
  );

  useEffect(() => {
    if (
      personas &&
      personas.length > 0 &&
      !isEqual(personas[0].value, livePersonas)
    ) {
      let persona = personas[0].value; // remove item "key" from users[0], keep everything else
      setLivePersonas(persona);
      shouldLog() && console.log("DEX_persona", Date.now(), persona);
    }
    return () => {};
  }, [personas]);

  return { livePersonas: livePersonas };
};

export const useDescriptorsDex = (db) => {
  const [liveDescriptors, setLiveDescriptors] = useState([]);

  const descriptors = useLiveQuery(
    () => db && db.user.where({ key: "descriptors" }).toArray((doc) => doc),
    [db]
  );

  useEffect(() => {
    if (
      descriptors &&
      descriptors.length > 0 &&
      !isEqual(descriptors[0].value, liveDescriptors)
    )
      setLiveDescriptors(descriptors[0].value || []);
    shouldLog() && console.log("DEX_descriptors", Date.now(), descriptors);
    return () => {};
  }, [descriptors]);

  return { liveDescriptors: liveDescriptors };
};

export const usePrefsDex = (db) => {
  // dexUser
  const [livePrefs, setLivePrefs] = useState({});

  const prefs = useLiveQuery(() => db && db.user.get({ key: "prefs" }), [db]);

  useEffect(() => {
    if (prefs && !isEqual(prefs?.value, livePrefs)) {
      setLivePrefs({ ...prefs?.value });
      shouldLog() && console.log("DEX_pref", Date.now(), prefs?.value);
    }
    return () => {};
  }, [prefs]);

  return {
    livePrefs: livePrefs
  };
};

export const useAccountDex = (db) => {
  // dexAdmin
  const [liveClient, setLiveClient] = useState(null);
  const [liveUser, setLiveUser] = useState(null);

  const clients = useLiveQuery(() => {
    if (db) {
      shouldLog() && console.log("[dexHooks]useAccountDex", db);
      return db.store.where({ key: "client" }).toArray((doc) => doc);
    }
    return undefined;
  }, [db]);

  useEffect(() => {
    if (
      clients &&
      clients.length > 0 &&
      !isEqual((({ key, ...rest }) => rest)(clients[0]), liveClient)
    ) {
      let client = (({ key, ...rest }) => rest)(clients[0]); // remove item "key" from users[0], keep everything else
      setLiveClient(client);
      shouldLog() && console.log("DEX_client", Date.now(), client);
    }
    return () => {};
  }, [clients]);

  const users = useLiveQuery(
    () => db && db.store.where({ key: "user" }).toArray((doc) => doc),
    [db]
  );

  useEffect(() => {
    if (
      users &&
      users.length > 0 &&
      !isEqual((({ key, ...rest }) => rest)(users[0]), liveUser)
    ) {
      let user = (({ key, ...rest }) => rest)(users[0]); // remove item "key" from users[0], keep everything else
      setLiveUser({ ...user });
      shouldLog() && console.log("DEX_user", Date.now(), users);
    }
    return () => {};
  }, [users]);

  return {
    liveClient: liveClient,
    liveUser: liveUser
  };
};

export const useMuteDex = (db) => {
  const [liveMuteState, setLiveMuteState] = useState({});
  const mute = useLiveQuery(() => db && db.account.get({ type: "mute" }), [db]);
  useEffect(() => {
    if (mute && !isEqual(mute.value, liveMuteState)) {
      shouldLog() && console.log("DEX_mute", Date.now(), mute);
      setLiveMuteState(mute.value || {});
    }
    return () => {};
  }, [mute]);
  return { liveMuteState: liveMuteState };
};

export const useThemeDex = (db) => {
  const [liveTheme, setLiveTheme] = useState(undefined);

  const theme = useLiveQuery(
    () => db && db.user.get({ key: "current_theme" }),
    [db]
  );

  useEffect(() => {
    if (theme && theme.value && !isEqual(theme.value, liveTheme)) {
      setLiveTheme((liveTheme) => theme.value);
    }
    return () => {};
  }, [theme]);

  return { liveTheme: liveTheme };
};

export const useCollectionStateDex = (db) => {
  const [liveCollectionState, setLiveCollectionState] = useState({});

  const collectionstate = useLiveQuery(
    () => db && db.user.get({ key: "collectionstate" }),
    [db]
  );

  useEffect(() => {
    if (
      collectionstate &&
      collectionstate.value &&
      !isEqual(collectionstate.value, liveCollectionState)
    ) {
      setLiveCollectionState((liveCollectionState) => {
        return { ...collectionstate.value };
      });
    }
    return () => {};
  }, [collectionstate]);

  return { liveCollectionState: liveCollectionState };
};

export const useTopicMetaDex = (dexUser) => {
  const [liveMetaTopics, setLiveMetaTopics] = useState([]);
  const [liveMetaBufferTopics, setLiveMetaBufferTopics] = useState([]);
  const [liveMissing, setLiveMissing] = useState([]);
  const [liveCount, setLiveCount] = useState({});
  const [liveLatestMsg, setLiveLatestMsg] = useState([]);
  const [liveLatestVisible, setLiveLatestVisible] = useState({});
  const [liveLatestPinnedMsg, setLiveLatestPinnedMsg] = useState([]);

  // what is the latest message per topic?
  // what mesages do i have?

  const metaNew = useLiveQuery(
    () =>
      dexUser &&
      dexUser.topic_metadata.filter((d) => d.mtopic).toArray((doc) => doc),
    [dexUser]
  );
  const removeMatchingObjects = (list1, list2) => {
    return list1.filter(function (obj1) {
      return !list2.some(function (obj2) {
        return isEqual(obj1, obj2);
      });
    });
  };

  useEffect(() => {
    // this simply updates the buffer table (used for evading transaction locks)
    let mounted = true;
    let meta = removeMatchingObjects(metaNew || [], liveMetaBufferTopics || []);
    let newCount = { ...liveCount };
    meta?.forEach((m) => {
      newCount[`${m.mtopic}_${m.mpersona}`] = { unread: m.unreadCount || 0 };
    });
    if (!isEqual(newCount, liveCount)) setLiveCount(newCount);
    if (!isEqual(metaNew, liveMetaBufferTopics))
      setLiveMetaBufferTopics(metaNew || []);
    return () => {
      mounted = false;
    };
  }, [metaNew, dexUser]);

  // const userRec = useLiveQuery(() => dexAdmin && dexAdmin.store.get("user"), [dexAdmin])

  useEffect(() => {
    let mounted = true;
    const firstMissing = (intList) => {
      if (!intList?.length) {
        return 1;
      }
      let sorted = intList.sort((a, b) => a - b);
      let lowest = sorted[0];
      for (let i = 1; i < intList.length; i++) {
        if (sorted[i] !== lowest + 1) {
          return lowest + 1;
        } else {
          lowest = sorted[i];
        }
      }
      return lowest + 1;
    };

    const latestPinnedIdx = (metaItem) =>
      Math.max(
        ...(metaItem.pinned || []).filter((i) =>
          metaItem.have_idx_list.includes(i)
        ),
        0
      );
    const latestMsgIdx = (metaItem) =>
      Math.max(...(metaItem.have_idx_list || []), 0);
    const previewIdx = (metaItem) =>
      latestPinnedIdx(metaItem) || latestMsgIdx(metaItem);

    const storePreview = async (db, mtopic, recipient, msg_idx) => {
      // get the message from database
      // next stage might be to request from server if not yet available
      let msg = await db.message.get({
        mtopic: mtopic,
        recipient: recipient,
        msg_idx: msg_idx
      });
      let storedLatest = await db.latest_message.get({
        mtopic: mtopic,
        recipient: recipient
      });
      // store message in "latest_message"
      if (msg && !isEqual(msg, storedLatest)) {
        await db.latest_message.put(msg);
      }
    };

    // get last sent "read time"s per topic from dexUser.delivery_metadata "w.t.read"
    // form new w.t.read
    // if they match, don't send
    // else if still mounted then send differences and store total

    if (!isEqual(metaNew, liveMetaTopics)) {
      let meta = removeMatchingObjects(metaNew || [], liveMetaTopics || []);
      shouldLog() &&
        console.log(
          "[dexHooks]DEX_missing meta",
          liveMetaTopics,
          metaNew,
          meta
        );
      // TODO: DEX change this to use the visible_idx list?
      let needed =
        meta &&
        meta.reduce((acc, topic_meta) => {
          shouldLog() &&
            console.log(
              "[dexHooks] vis",
              topic_meta.last_visible_date,
              timestampCutoff(28 * 24 * 60)
            );
          if ((topic_meta.visible_idx || []).length === 0) return acc;
          let maxNumUnpinned =
            topic_meta.last_visible_date < timestampCutoff(28 * 24 * 60)
              ? 1
              : topic_meta.visible_idx.length;
          let missingPinned =
            (topic_meta.pinned || []).filter(
              (p) =>
                // topic_meta.visible_idx?.includes(parseInt(p, 10)) &&
                !topic_meta.have_idx_list?.includes(parseInt(p, 10))
            ) || [];
          let missingUnpinned = (topic_meta.visible_idx || [])
            .filter((i) => !topic_meta.pinned.includes(i))
            .slice(-maxNumUnpinned)
            .filter((v) => !topic_meta.have_idx_list?.includes(parseInt(v, 10)))
            .sort((a, b) => a - b);
          shouldLog() &&
            console.log(
              "[dexHooks] vis",
              maxNumUnpinned,
              topic_meta.last_visible_date,
              timestampCutoff(28 * 24 * 60),
              topic_meta,
              missingUnpinned
            );
          let missingCombined = [
            ...new Set([...missingUnpinned, ...missingPinned])
          ].sort((a, b) => a - b);
          let missingItem = {
            mpersona: topic_meta.mpersona,
            mtopic: topic_meta.mtopic,
            have_item_list: topic_meta.have_item_list,
            missing: missingCombined || [],
            last_visible_date: topic_meta.last_visible_date
          };
          if (missingItem.missing.length > 0) return [...acc, missingItem];
          return acc;
        }, []);
      // TODO: This mechanism only asks for msgs that the server has said are visible
      // ...that is currently never longer than 20 msgs
      // so we need to add a mech that leads to a push or pull of more (don't rely on scrolling)

      shouldLog() && console.log("DEX_missing", Date.now(), needed);
      if (needed && !isEqual(needed, liveMissing)) {
        needed.sort((a, b) =>
          (a.last_visible_date || "1970") > (b.last_visible_date || "1970")
            ? -1
            : 1
        );
        setLiveMissing(needed);
        shouldLog() && console.log("DEX_missing", Date.now(), needed);
      }

      meta.forEach((topicMeta) => {
        let oldTopicMeta = liveMetaTopics.reduce((acc, t) => {
          if (
            t.mtopic === topicMeta.mtopic &&
            t.mpersona === topicMeta.mpersona
          )
            return t;
          return acc;
        }, {});

        shouldLog() && console.log("!!!topicMeta", topicMeta, oldTopicMeta);
        if (
          !isEqual(
            topicMeta?.have_idx_list || [],
            oldTopicMeta?.have_idx_list || []
          ) ||
          !isEqual(topicMeta?.pinned || [], oldTopicMeta?.pinned || [])
        )
          storePreview(
            dexUser,
            topicMeta.mtopic,
            topicMeta.mpersona,
            previewIdx(topicMeta)
          ).then(() => {});
      });

      let newLatestVisible = { ...liveLatestVisible };
      meta?.forEach((m) => {
        dexUser.topic_metadata_buffer
          .get({ mtopic: m.mtopic, mpersona: m.mpersona })
          .then(
            (mold) => !isEqual(mold, m) && dexUser.topic_metadata_buffer.put(m)
          );
        newLatestVisible[`${m.mtopic}_${m.mpersona}`] =
          m.last_visible_date || "1970";
      });
      if (!isEqual(newLatestVisible, liveLatestVisible))
        // keep seperate from "count" so that the topic order change does not impact on unread badges
        setLiveLatestVisible(newLatestVisible);

      meta?.forEach((m) => {
        if (mounted && m.latestReadTime && m.latestRead) {
          dexUser.delivery_metadata
            .get({ mtopic: m.mtopic, mpersona: m.mpersona })
            .then((delivery_metadata) => {
              if (
                mounted &&
                m.latestReadTime &&
                m.latestRead > (delivery_metadata?.latestRead || "")
              ) {
                // post to send
                let state = {
                  read: {
                    msg_idx: m.latestRead,
                    timestamp: m.latestReadTime
                  },
                  // obviously, if a message is read then it is also delivered
                  // server only updates this value if the msg_idx is larger than the earlier one
                  delivered: {
                    msg_idx: m.latestRead,
                    timestamp: m.latestReadTime
                  }
                };
                let j = {
                  type: "w.t.read",
                  smid: `read_${m.mtopic}_${m.mpersona}_${m.latestRead}`,
                  ts_sender: timestamp(),
                  version: version,
                  subscription_states: [
                    {
                      mtopic: m.mtopic,
                      mpersona: m.mpersona,
                      state: state
                    }
                  ]
                };
                dexUser.send.put(j);
                // update meta
                delivery_metadata = delivery_metadata || {
                  mtopic: m.mtopic,
                  mpersona: m.mpersona
                };
                let newMeta = {
                  ...delivery_metadata,
                  latestReadTime: m.latestReadTime,
                  latestRead: m.latestRead
                };

                setTimeout(() => {
                  if (mounted) {
                    dexUser.send.put(j);
                    dexUser.delivery_metadata.put(newMeta);
                  }
                }, 1000);
              }
            });
          // dexUser.topic_metadata_buffer.put(m);
        }
      });

      if (!isEqual(metaNew, liveMetaTopics)) setLiveMetaTopics(metaNew || []);
    }
    return () => {
      mounted = false;
    };
  }, [metaNew, dexUser]);

  return {
    liveMissing: liveMissing,
    liveLatestMsg: liveLatestMsg,
    liveLatestPinnedMsg: liveLatestPinnedMsg,
    liveCount: liveCount,
    liveLatestVisible: liveLatestVisible,
    liveMetaTopics: liveMetaTopics
  };
};

// liveMessages, liveItems
export const useLiveMessages = (db, state, mtopic, numMessages) => {
  const [liveMessages, setLiveMessages] = useState(undefined);
  const [liveItems, setLiveItems] = useState(undefined);

  const messagesDex = useLiveQuery(
    () =>
      db &&
      db.message
        // .where({ mtopic: mtopic, recipient: state?.mpersona })
        .where(["mtopic", "recipient"])
        .equals([mtopic, state?.mpersona])
        .toArray((array) => {
          return array;
        }),
    [db, state?.mpersona, mtopic],
    undefined
  );

  useEffect(() => {
    shouldLog() && console.log("DEX_messagesDex", messagesDex);
    if (messagesDex) {
      let keep = (messagesDex || [])
        .map((doc) => doc.msg_idx || Infinity)
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .slice(-numMessages - 200);
      let latest = keep.slice(-numMessages);
      shouldLog() && console.log("DEX_latest", latest);
      let messages = (messagesDex || [])
        .filter(
          (msg) => latest.includes(msg.msg_idx || Infinity) && !isHide(msg)
        )
        .sort((a, b) =>
          (a.parameters?.pin || Infinity) > (b.parameters?.pin || Infinity) ||
          ((a.parameters?.pin || Infinity) ===
            (b.parameters?.pin || Infinity) &&
            timestampSelect(a.ts_server, a.ts_origin_server, a.ts_sender) >
              timestampSelect(b.ts_server, b.ts_origin_server, b.ts_sender))
            ? 1
            : -1
        );
      let items = messages.map((msg) => {
        return { msg_idx: msg.msg_idx, smid: msg.smid };
      });
      // TODO: delete all messages not in keep
      if (!isEqual(messages, liveMessages)) setLiveMessages(messages);
      if (!isEqual(items, liveItems)) setLiveItems(items);
    }
    return () => {};
  }, [messagesDex, numMessages]);

  return { liveMessages: liveMessages, liveItems: liveItems };
};

export const useLiveParameters = (db, muid) => {
  const [liveParameters, setLiveParameters] = useState([]);

  const parameters = useLiveQuery(
    () => db && db.parameter.filter((key) => key).toArray((doc) => doc),
    [db]
  );

  useEffect(() => {
    if (!isEqual(parameters, liveParameters)) setLiveParameters(parameters);
    shouldLog() && console.log("DEX_parameters", Date.now(), parameters);
    return () => {};
  }, [parameters]);

  return liveParameters;
};

// otp modal
export const useLiveOTPMessages = (db) => {
  const [liveOTPMessages, setLiveOTPMessages] = useState([]);

  const messagesOTP = useLiveQuery(
    () => db && db.message.where({ mtopic: "otp" }).toArray((doc) => doc),
    [db],
    []
  );

  useEffect(() => {
    shouldLog() && console.log("DEX_messagesOTP", messagesOTP);
    setLiveOTPMessages(messagesOTP);
    return () => {};
  }, [messagesOTP]);

  return liveOTPMessages;
};

// WebRTC
// export const useLiveStreamMessages = (db, numMessages) => {
//   const [liveStreamMessages, setLiveStreamMessages] = useState([]);

//   var changes = useRef();
//   useEffect(() => {
//     let msgList;
//     let mounted = true;

//     if (db !== undefined && db !== null) {
//       const setInitialStreamMessages = () => {
//         msgList = [];
//         return db
//           .allDocs({
//             include_docs: true,
//             startkey: "54messages4stream",
//             endkey: "54messages4stream5"
//           })
//           .then((allDocs) => {
//             let keep = allDocs.rows
//               .map((row) => row.doc.msg_idx)
//               .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
//               .slice(-numMessages);
//             // let latest = keep.slice(-numMessages);
//             allDocs.rows.forEach((row) => {
//               try {
//                 // if (latest.includes(row.doc.msg_idx)) {
//                 //   msgList.push(row.doc);
//                 // }
//                 // if (!keep.includes(row.doc.msg_idx)) {
//                 row.doc._deleted = true;
//                 db.put(row.doc)
//                   .then(() => {})
//                   .catch((err) => console.log(err));
//                 // }
//               } catch (err) {}
//             });
//             // sort if necessary
//             // let ml = msgList
//             //   .sort((a, b) =>
//             //     timestamp(a.ts_server, a.ts_origin_server, a.ts_sender) >
//             //     timestamp(b.ts_server, b.ts_origin_server, b.ts_sender)
//             //       ? 1
//             //       : -1
//             //   )
//             //   .slice(-20);
//             // if (mounted) setLiveStreamMessages([...ml]);
//             if (mounted) setLiveStreamMessages([]);
//           })
//           .catch((err) => {
//             console.log("CATCH", err);
//           });
//       };

//       const reactToNewStreamMessages = () => {
//         db.setMaxListeners(1000);
//         changes.current = db
//           .changes({ live: true, since: "now", include_docs: true })
//           .on("change", (change) => {
//             if (
//               change.id > "54messages4stream" &&
//               change.id < "54messages4stream5"
//             ) {
//               try {
//                 let found = false;
//                 for (let i = 0, l = msgList.length; i < l; ++i) {
//                   if (msgList[i].smid === change.doc.smid) {
//                     if (change.deleted) {
//                       db.get(msgList[i]._id)
//                         .then((d) => {
//                           return db.remove(d._id, d._rev);
//                         })
//                         .then((result) => {})
//                         .catch((err) => console.log(err));
//                     } else {
//                       msgList[i] = change.doc;
//                       found = true;
//                     }
//                     break;
//                   }
//                 }
//                 found || change.deleted
//                   ? (found = true)
//                   : msgList.push(change.doc);
//                 let ml = msgList
//                   .sort((a, b) =>
//                     timestampSelect(
//                       a.ts_server,
//                       a.ts_origin_server,
//                       a.ts_sender
//                     ) >
//                     timestampSelect(
//                       b.ts_server,
//                       b.ts_origin_server,
//                       b.ts_sender
//                     )
//                       ? 1
//                       : -1
//                   )
//                   .slice(-numMessages);
//                 if (mounted) setLiveStreamMessages([...ml]);
//               } catch (err) {}
//             }
//           });
//       };

//       setInitialStreamMessages().then(reactToNewStreamMessages);
//     }
//     return () => {
//       if (changes.current === undefined)
//         shouldLog() && console.log("no change to cancel");
//       else {
//         changes.current.cancel();
//       }
//       mounted = false;
//     };
//   }, [db, numMessages]);
//   return liveStreamMessages;
// };

// liveParticipants
export const useLiveParticipants = (db, mtopic) => {
  const [liveParticipants, setLiveParticipants] = useState({});

  const participants = useLiveQuery(
    () => db && db.participants.where({ mtopic: mtopic }).toArray((doc) => doc),
    [db],
    []
  );

  useEffect(() => {
    if (participants && participants.length > 0) {
      let old = { ...liveParticipants };
      delete old.origin_sender;
      delete old.ts_origin_server;
      delete old.ts_sender;
      delete old.ts_server;
      delete old.ts_origin_server;
      delete old._id;
      delete old._rev;
      delete old.smid;
      let newDoc = { ...participants[0] };
      delete newDoc.origin_sender;
      delete newDoc.ts_origin_server;
      delete newDoc.ts_sender;
      delete newDoc.ts_server;
      delete newDoc.ts_origin_server;
      delete newDoc._id;
      delete newDoc._rev;
      delete newDoc.smid;
      if (!isEqual(newDoc, liveParticipants)) {
        shouldLog() && console.log("DEX_participants", Date.now(), newDoc);
        setLiveParticipants(newDoc);
      }
    }

    return () => {};
  }, [participants]);

  return liveParticipants;
};
