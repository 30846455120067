import React from "react";
import UITopicResult from "ui/UITopicResult";

const UITopicResults = (props) => {
  let content = (
    <div className="UI-topic-list-container-search">
      {/* style={{ maxHeight: "calc(100vh - 300px)" }} */}
      <div className="UI-topic-list hide-scrollbar">
        {(props.topicList === undefined || props.topicList.length === 0) ? (
          <p>No Results</p>
        ) : (
          props.topicList.map((t) => (
            <UITopicResult
              topic={t}
              key={t.mtopic}
              muid={props.muid}
              mpersona={props.mpersona}
              persona={props.persona}
              onClose={props.onClose}
            ></UITopicResult>
          ))
        )}
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicResults, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
