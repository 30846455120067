import React, { useEffect, useContext, useState, useRef, useMemo, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import TopicsContext from "contexts/contextTopics";
import GlobalContext from "contexts/context";
import SubscriptionsContext from "contexts/contextSubscriptions";
import DatabaseContext from "data/contextDatabase";
import LiveCacheContext from "data/contextLiveCache";

import { FaSearch } from "react-icons/fa";
import Modals from "./Modals";
import "./modals.css";
import { dex_action } from "data/dexUtils";

const UISearchMyTopics = (props) => {
  const { topicsState } = useContext(TopicsContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { subscriptionsState } = useContext(SubscriptionsContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modal, setModal] = useState(undefined);
  const [modalClose, setModalClose] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [topicsList, setTopicsList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const searchFor = useRef();
  const displayList = useRef();
  let history = useHistory();

  const goToTopic = useMemo(
    () => (t) => {
      // we need this info to action the rest...
      let persona = {
        mpersona: t.topic.mpersona,
        persona: t.topic.persona,
        tag: (
          t.tag ||
          t.topic.props.tag ||
          t.topic.props.topiccollection ||
          t.topic.props.visibility ||
          t.topic.props.topictype ||
          "undefined"
        ).toLowerCase()
      };
      let collectionState = liveCacheState?.collectionState || {};
      process.env.REACT_APP_DEBUG_THIS &&
        console.log(
          "[UISearchMyTopics] topic",
          t,
          "\n\tcollection",
          collectionState,
          "\n\tpersona:",
          persona
        );
      // make sure this topic's collection will be expanded
      if (
        collectionState &&
        collectionState[persona?.tag] &&
        collectionState[persona.tag][persona.mpersona]?.open &&
        collectionState[persona.tag][persona.mpersona].open !== false
      ) {
        collectionState[persona.tag][persona.mpersona].open = false;
        liveCacheDispatch({
          type: "SET",
          values: { cache: { collectionState: collectionState } }
        });
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: { key: "collectionstate", value: collectionState }
            }
          });
      }
      // and open the topic
      if (t.mpersona !== globalState.persona?.mpersona) {
        globalDispatch({
          type: "SET_PERSONA",
          values: {
            persona: persona
          }
        });
      }
      history.push("/UIMessageList", { subscription: t.topic });
      setIsActive(false);
      setModalClose(true);
      setModal(undefined);
      displayList.current = undefined;
    },
    [
      liveCacheState?.collectionState,
      globalState.persona?.mpersona,
      databaseState.dexUser
    ]
  );

  useEffect(() => {
    // do not refresh this list if the search str exists,
    // implies that a shortened list is already being displayed
    if (searchStr && searchStr.length !== 0) {
      return;
    }
    let resultsList = [];
    let shortList = [];
    if (topicsState?.topics) {
      Object.keys(topicsState.topics).forEach((t) => {
        let topicName = topicsState.topics[t].props.topic_display_name
          ? topicsState.topics[t].props.topic_display_name
          : topicsState.topics[t].topic;
        topicsState.topics[t].persona !== "unknown" &&
          shortList.push({
            topicName: topicName,
            persona: topicsState.topics[t].persona,
            tag: topicsState.topics[t].props?.tag,
            topic: topicsState.topics[t]
          });
      });
    }

    if (subscriptionsState?.subscriptions) {
      Object.keys(subscriptionsState.subscriptions).forEach((s) => {
        let topicName = subscriptionsState.subscriptions[s].topic_display_name
          ? subscriptionsState.subscriptions[s].topic_display_name
          : subscriptionsState.subscriptions[s].topic;

        subscriptionsState.subscriptions[s].persona !== "unknown" &&
          shortList.push({
            topicName: topicName,
            persona: subscriptionsState.subscriptions[s].persona,
            tag: subscriptionsState.subscriptions[s].visibility,
            topic: subscriptionsState.subscriptions[s]
          });
      });
    }
    // if (dialogsState?.dialogs) {
    //   phased out
    //   implement if retro functionality is needed
    // }

    let sortedList = [];
    if (shortList.length > 0) {
      sortedList = shortList.sort((a, b) => {
        return a.topicName?.toLowerCase() > b.topicName?.toLowerCase() ? 1 : -1;
      });
    }

    setTopicsList(sortedList);

    Object.keys(sortedList).forEach((t) => {
      resultsList.push(
        <div
          key={`${sortedList[t].mtopic + sortedList[t].mpersona}`}
          className="UI-topic"
          onClick={() => goToTopic(sortedList[t])}
        >
          <div className="UI-topic-search-list">
            <div
              className="UI-topic-search-list-item"
              style={{ textAlign: "left", maxWidth: "100%" }}
            >
              <Link
                key={t.mtopic}
                to={{
                  pathname: "/UIMessageList",
                  state: {
                    sub: t.topic,
                    size: "small"
                  },
                  size: "small"
                }}
              >
                <div>{sortedList[t].topicName}</div>
              </Link>
              <div style={{ paddingLeft: "15%" }}>
                [{sortedList[t].persona}]
              </div>
            </div>
          </div>
        </div>
      );
    });

    displayList.current = resultsList;
    return () => {};
  }, [topicsState?.topics, subscriptionsState?.subscriptions]);

  useEffect(() => {
    let resultsList = [];
    if (searchStr === undefined || searchStr === "" || searchStr.length === 0) {
      setIsActive(false);
      displayList.current = resultsList;
    } else {
      setIsActive(true);
      topicsList &&
        topicsList?.length > 0 &&
        Object.keys(topicsList).forEach((t) => {
          topicsList[t].topicName
            ?.toLowerCase()
            .includes(searchStr?.toLowerCase()) &&
            resultsList.push(
              <div
                className="UI-topic"
                onClick={() => goToTopic(topicsList[t])}
              >
                <div className="UI-topic-search-list">
                  <div
                    className="UI-topic-search-list-item"
                    style={{ textAlign: "left", maxWidth: "100%" }}
                  >
                    <div>{topicsList[t].topicName}</div>
                    <div style={{ paddingLeft: "15%" }}>
                      [{topicsList[t].persona}]
                    </div>
                  </div>
                </div>
              </div>
            );
        });
    }
    displayList.current = resultsList;
    return () => {};
  }, [searchStr]);

  useEffect(() => {
    window.addEventListener("popstate", handleClose);
    if (window.location?.href?.includes("search")) {
      window.history.replaceState({ id: 2 }, null, "/");
    } else {
      if (!modalClose) {
        window.history.pushState({ id: 2 }, null, "search");
      }
    }
    return () => {
      window.removeEventListener("popstate", handleClose);
    };
  }, [modalClose]);

  const handleClickOutside = () => {
    setModalClose(true);
    setModal(undefined);
    setIsActive(false);
    setSearchStr("");
    displayList.current = undefined;
  };

  const handleClose = () => {
    setModalClose(true);
    setModal(undefined);
    setIsActive(false);
    setSearchStr("");
    displayList.current = undefined;
  };

  const handleSearchClick = () => {
    setModalClose(false);
    setModal(undefined);
    setIsActive(true);
    let resultsList = topicsList.map((t) => {
      return (
        <div className="UI-topic" onClick={() => goToTopic(t)}>
          <div className="UI-topic-search-list">
            <div
              className="UI-topic-search-list-item"
              style={{ textAlign: "left", maxWidth: "100%" }}
            >
              <div title="topic">{t.topicName}</div>
              <div title="persona" style={{ paddingLeft: "15%" }}>
                [{t.persona}]
              </div>
            </div>
          </div>
        </div>
      );
    });

    displayList.current = resultsList;
  };

  useEffect(() => {
    setModal(
      <Modals
        title="Search your Topics"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        switchScrollOff={true}
      >
        <div className="UI-login-container">
          <h4>Tap on topic to go there</h4>
          <input
            className="UI-input"
            type="message"
            name="search"
            required
            autoFocus
            placeholder="Searching for..."
            ref={searchFor}
            id="search_topic_text"
            autoComplete="off"
            onChange={() => {
              setSearchStr(document.getElementById("search_topic_text").value);
              setIsActive(true);
            }}
          />
          {isActive && displayList.current?.length > 0 && searchStr.length > 0 && (
            <div>
              <p>
                {displayList.current?.length || "0"}{" "}
                {displayList.current?.length === 1 ? "Topic" : "Topics"} found
              </p>
            </div>
          )}
          <div id="result_list">
            <div className="UI-topic-list-container-search">
              <div className="UI-topic-list hide-scrollbar">
                {displayList.current?.length === 0 ||
                displayList.current === undefined ? (
                  <p>No Results</p>
                ) : (
                  displayList.current
                )}
              </div>
            </div>
          </div>
        </div>
      </Modals>
    );
  }, [isActive, displayList.current, searchStr]);

  const chkCtrlF = useCallback((event) => {
    if (event.keyCode === 114 || (event.ctrlKey && event.keyCode === 70)) {
      event.preventDefault();
      handleSearchClick();
    }
  });

  useEffect(() => {
    // Add listeners
    window.addEventListener("keydown", chkCtrlF);
    return () => {
      // Remove listeners
      window.removeEventListener("keydown", chkCtrlF);
    };
  });

  let content = (
    <div
      className="UI-main-screen-fab"
      title="Search my topics"
      onClick={() => handleSearchClick()}
    >
      <span>
        <FaSearch style={{ width: "1.2rem", height: "1.2rem" }} />
      </span>
      {!modalClose && modal}
    </div>
  );

  return content;
};

export default React.memo(UISearchMyTopics, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
