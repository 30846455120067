// import React from 'react'

// export function InverseColor(hex) {
//   function padZero(str, len) {
//     len = len || 2;
//     var zeros = new Array(len).join('0');
//     return (zeros + str).slice(-len);
//   }

  /*luminance*/
  // if (hex.indexOf('#') === 0) {
  //   hex = hex.slice(1);
  // }
  // convert 3-digit hex to 6-digits.
  // if (hex.length === 3) {
  //   hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  // }
  // if (hex.length !== 6) {
  //   throw new Error('Invalid HEX color.');
  // }
  // invert color components
  // let r = (255 - parseInt(hex.slice(0, 2), 16))
  // let g = (255 - parseInt(hex.slice(2, 4), 16))
  // let b = (255 - parseInt(hex.slice(4, 6), 16))
  // 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
  // pad each with zeros and return
  //   return '#' + padZero(r) + padZero(g) + padZero(b);
  // }

  export const rgbStrTorgb = (rgbStr) => {
    let rgbArray = rgbStr.replace(/[^\d,]/g, "").split(",");
    return { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2], o: rgbArray[3] };
  };

  export default function InverseColor(rgbStr) {
    let rgb = rgbStrTorgb(rgbStr);
    if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 175) return "#000000";
    else return "#ffffff";
  }
