import React from "react";
import CalcStringColor from "./CalcStringColor";


const GetInitials = (props) => {
    let root = document.getElementById("root");

    const calcLogoTransparency = () => {
        let root = document.getElementById("root");
        let tVal = root.style.getPropertyValue("--logo_background_transparency")
        if (tVal === undefined || !tVal) tVal = 0.5
        return 1 - tVal
    }

    if (props.str?.length > 0) {
        let initials = ".."
        let names = []
        let newStr = props.str
        if (newStr?.length > 1) {
          newStr = newStr.replace(/[^a-zA-Z0-9\ .@#$%^&+=_-]/g, "");
          newStr = newStr.replace(" - ", " ");
          newStr = newStr.trim();
          let range = [
            " ",
            ".",
            "@",
            "#",
            "$",
            "%",
            "^",
            "&",
            "+",
            "=",
            "_",
            "-"
          ];
          let exitLoop = false;
          for (let x = 0; !exitLoop && x < range.length; x++)
            if (newStr.includes(range[x])) {
              exitLoop = true;
              names = newStr.split(range[x]);
            }
        }
        if (names?.length > 1 && names[0] !== "" && names[1] !== "") {
          initials = names[0].substring(0, 1).toUpperCase();
          initials += names[1].substring(0, 1).toUpperCase();
        } else if (newStr?.length > 0) {
          initials = newStr.substring(0, 2).toUpperCase();
        }
        let [red, green, blue] = CalcStringColor(initials)
        return (
            <div
                // className={props.head_list ? "avatar-string" : "avatar-string-header"}
                className="avatar-string"
                type={props.type}
                style={{
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${calcLogoTransparency()})`
                    // opacity: (root.style.getPropertyValue("--logo_background_transparency"))
                }}
            >
                {initials}
            </div>
        );
    }
    else return ("..")
};

export default GetInitials