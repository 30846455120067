import React, { useState, useEffect, useRef } from "react";
import './textinput.css'
import { FaInfoCircle } from 'react-icons/fa'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { FaQuestionCircle } from 'react-icons/fa'

const TextInput = () => { }
export default TextInput;

export const TextInputSingleLine = props => {

    const onChangeValue = (event) => {
        props.callback(event.target.value);
    };

    return (
        <div
            style={props.genStyle}
        >
            <label
                className="label-cards"
                style={{
                    marginTop: props.marginTop,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                    fontSize: props.fontSizeLabel,
                }}
            >
                {props.label}
                <span
                    style={{
                        color: "red",
                        marginLeft: "12px",
                    }}
                >
                    {props.mandatory ? "*" : ""}
                </span>
                <br />
            </label>
            <div
                className="combo-cards">
                <input
                    className="combo-cards"
                    type={props.type}
                    style={{
                        fontSize: props.fontSize,
                        width: props.width,
                        height: props.height,
                        // backgroundColor: props.backgroundColor,
                        color: props.textColor,
                        paddingLeft: props.paddingLeft,
                        paddingRight: props.paddingRight,
                        paddingTop: props.paddingTop,
                        paddingBottom: props.paddingBottom,
                        marginLeft: props.marginLeft,
                        marginRight: props.marginRight,
                        marginBottom: props.marginBottom,
                        borderRadius: props.borderRadius,
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                        // backgroundColor: invertColor(props.backgroundColor),
                    }
                    }
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={onChangeValue}
                >
                </input>
                {props.addSwitch === "true" && <ToggleSwitch
                    height={props.heightSwitch}
                    // width={props.widthSwitch}
                    // marginLeft={props.marginLeftSwitch}
                    // marginRight={props.marginRightSwitch}
                    // paddingRight={props.paddingRight}
                    backgroundColor={props.backgroundColorSwitch}
                    colorselect={props.colorSelectSwitch}/*color of bground selected*/
                    borderWidth={props.borderWidthSwitch}
                    borderColor={props.borderColorSwitch}
                    borderStyle={props.borderStyleSwitch}
                    id={props.id}
                    checked={props.checkedPP}
                    onChange={props.onChange}
                />}
                {props.addIcon &&
                    <div style={{ fontSize: "28px" }}>
                        <FaQuestionCircle />
                    </div>
                }
            </div>
        </div >
    );
};

export const TextInputMultipleLine = props => {

    const onChangeValue = (event) => {
        props.callback(event.target.value);
    };

    return (
        <div>
            <label className="label-cards"
                style={{
                    marginTop: props.marginTop,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}>
                {props.label}
                <br />
            </label>
            <input
                type={props.type}
                rows={props.rows}
                style={{
                    width: props.width,
                    height: props.height,
                    backgroundColor: props.backgroundColor,
                    color: props.textColor,
                    paddingLeft: props.paddingLeft,
                    paddingRight: props.paddingRight,
                    paddingTop: props.paddingTop,
                    paddingBottom: props.paddingBottom,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                    marginTop: props.marginTop,
                    marginBottom: props.marginBottom,
                    borderRadius: props.borderRadius,
                    borderWidth: props.borderWidth,
                    borderColor: props.borderColor,
                    borderStyle: props.borderStyle,
                }}
                value={props.value}
                onChange={onChangeValue}
            >
            </input>
        </div >
    );
};

export const TextInputArea = (props) => {
    const onChangeValue = (event) => {
        props.callback(event.target.value);
    };

    return (
        <div>
            <label
                className="label-cards"
                style={{
                    fontSize: props.fontSizeLabel,
                    marginTop: props.marginTop,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}>
                {props.label}
                <br />
            </label>
            <textarea
                style={{
                    fontFamily: props.fontFamily,
                    fontSize: props.fontSize,
                    width: props.width || "100%",
                    height: props.height,
                    backgroundColor: props.backgroundColor,
                    color: props.textColor,
                    paddingLeft: props.paddingLeft,
                    paddingRight: props.paddingRight,
                    paddingTop: props.paddingTop,
                    paddingBottom: props.paddingBottom,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                    marginBottom: props.marginBottom,
                    borderRadius: props.borderRadius,
                    borderWidth: props.borderWidth,
                    borderColor: props.borderColor,
                    borderStyle: props.borderStyle,
                }}
                rows={props.rows}
                value={props.value}
                type={props.type}
                placeholder={props.placeholder}
                name="message"
                required
                id={props.id}
                onChange={onChangeValue}
            />
        </div >
    );
}

export const ShowTextArea = (props) => {

    const onChangeValue = (event) => {
        // props.callback(event.target.value);
    };


    return (
        <div>
            <label
                className="label-cards"
                style={{
                    marginTop: props.marginTop,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                    focusable: "false"
                }}>
                {props.label}
                <br />
            </label>
            <textarea
                style={{
                    fontFamily: props.fontFamily,
                    fontSize: props.fontSize,
                    width: props.width,
                    height: props.height,
                    backgroundColor: props.backgroundColor,
                    color: props.textColor,
                    paddingLeft: props.paddingLeft,
                    paddingRight: props.paddingRight,
                    paddingTop: props.paddingTop,
                    paddingBottom: props.paddingBottom,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                    marginBottom: props.marginBottom,
                    borderRadius: props.borderRadius,
                    borderWidth: props.borderWidth,
                    borderColor: props.borderColor,
                    borderStyle: props.borderStyle,
                }}
                value={props.value}
                id={props.id}
                onChange={onChangeValue}
            />

        </div >
    );
}

export const ShowOptionSwitchiBtn = (props) => {
    const onChangeValue = (event) => {
        props.callback(event.target.value);
    };

    return (
        <div >
            {/* <label
                className="label-cards"
                style={{
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}
            >
                {props.label}
                <br />
            </label> */}
            <div className="combo-cards"
            // style={{
            //     marginRight: props.marginRightSwitch
            // }}
            >
                <label
                    className="combo-cards-info"
                    // type={props.type}
                    style={{
                        width: props.width || "fit-content",
                        height: props.height || "fit-content",
                        fontWeight: props.fontWeight || "normal",
                        backgroundColor: props.backgroundColor || "white",
                        color: props.textColor || "black",
                        paddingLeft: props.paddingLeft || "0",
                        paddingRight: props.paddingRight || "0",
                        paddingTop: props.paddingTop || "0",
                        paddingBottom: props.paddingBottom || "0",
                        marginLeft: props.marginLeft || "0",
                        marginRight: props.marginRight || "8px",
                        // borderRadius: props.borderRadius,
                        // borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        // borderStyle: props.borderStyle,
                        marginBottom: props.marginBottom || "0",
                    }}
                // value={props.value}
                // placeholder={props.placeholder}
                // onChange={onChangeValue}
                >
                    <span>
                        {props.value}
                        {/* </span>
                    <span> */}
                        {props.iBtn === "true" && <InfoIcon
                            onClick={props.onClick}
                            marginLeft={props.marginLeft || "0"}
                        />}
                    </span>
                </label>


                {props.addSwitch === "true" && <ToggleSwitch
                    height={props.heightSwitch || "18px"}
                    width={props.widthSwitch || "36px"}
                    marginLeft={props.marginLeftSwitch || "16px"}
                    // marginRight={props.marginRightSwitch}
                    // paddingRight={props.paddingRight}
                    backgroundColor={props.backgroundColorSwitch || "lightgrey"}
                    colorselect={props.colorSelectSwitch || "#64dd17"}/*color of bground selected*/
                    borderWidth={props.borderWidthSwitch || "2px"}
                    borderColor={props.borderColorSwitch || "darkgrey"}
                    borderStyle={props.borderStyleSwitch || "solid"}
                    id={props.id}
                    checked={props.checkedPP}
                    onChange={props.onChange}
                />}
            </div>

        </div >
    );
}

export const PersonaButton = (props) => {

    return (
        <div
            style={{
                // display: "flex",
                // justifyContent: "flex-end",
                alignSelf: props.alignSelf,
                // right: "0",
                // float: "right"
            }}>
            <button
                className="UI-button-confined"
                style={{
                    marginLeft: props.marginLeft,
                    marginBottom: props.marginBottom,
                    marginRight: props.marginRight,
                    marginTop: props.marginTop,
                }}
                onClick={() => props.onClick()}
            >
                {/* props.onDeletePersona(props.persona, props.mpersona) */}
                {props.label}
            </button>
        </div>
    )
}

export const OKButton = (props) => {

    return (
        <button
            className="UI-button"
            style={{
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                marginBottom: props.marginBottom,
                marginTop: props.marginTop,
                marginBottom: props.marginBottom,
                height: props.height,
                width: props.width,
            }}
            onClick={() => props.onClick()}
        >
            {/* props.onDeletePersona(props.persona, props.mpersona) */}
            {props.label}
        </button>
    )
}

export const PersonaButtonChkBox = (props) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: props.marginBottom,
                marginTop: props.marginTop,
            }}>
            <button
                className="UI-button-confined"
                style={{
                    marginLeft: props.marginLeft,
                    marginRight: props.buttonMarginRight,
                }}
                onClick={() => props.onClick()}
            >
                {/* props.onDeletePersona(props.persona, props.mpersona) */}
                {props.buttonLabel}
            </button>

            <input
                name={props.chkboxLabel}
                type="checkbox"

                checked={props.checked}
                // onChange={props.onChange} 
                onChange={e => props.onChange(e.target, props.checked)}
                style={{
                    transform: "scale(1.5)",
                    marginRight: props.marginRight,
                    // marginTop: props.marginTop,
                }}
            />
            <label>
                {props.chkboxLabel}

            </label>
        </div>
    )
}

function invertColor(hex) {
    /*luminance*/
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    let r = (255 - parseInt(hex.slice(0, 2), 16))
    let g = (255 - parseInt(hex.slice(2, 4), 16))
    let b = (255 - parseInt(hex.slice(4, 6), 16))
    // 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}


export const ToggleSwitch = (props) => {
    const widthCalc = parseInt(props.height) * 2
    const [bckColr, setBckColr] = useState(props.backgroundColor)
    useEffect(() => {
        props.checked ? setBckColr(props.colorselect)
            : setBckColr(props.backgroundColor)
        return () => {
            // cleanup
        }
    }, [props.checked])

    const inputStyle = {
        backgroundColor: bckColr,
        width: widthCalc,
        borderRadius: props.height,
        height: props.height
    };

    return (
        <label
            style={inputStyle}
            className="toggle-switch"
        >
            <input
                type="checkbox"
                name={props.name}
                id={props.id}
                className="toggle-switch"
                style={{
                    height: "0",
                    width: "0",
                    display: "hidden",
                }
                }

                checked={props.checked}
                onChange={e => props.onChange(e.target, props.checked)}
            />
            <span className="switch"
                style={{
                    backgroundColor: bckColr,
                    borderRadius: props.height,
                    transform: "translate( " + widthCalc + " px 0%)",
                }}

            />
        </label >
    )
}

export const InfoIcon = (props) => {
    return (
        <FaInfoCircle
            style={{
                marginLeft: props.marginLeft,
                fontSize: "24px"
            }}
            onClick={() => props.onClick()}
        >
            {props.label}
        </FaInfoCircle>
    )
}

export const TextEmail = (props) => {

    const [email, setEmail] = useState("")
    const [warn, setWarn] = useState("")

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text.target.value) === false) {
            setEmail(text.target.value)
            setWarn("email incorrect")
            return false
        }
        else {
            setEmail(text.target.value)
            setWarn("")
            return true
        }
    }

    return (
        <div>
            <label>
                {props.label}
                <br />

                <input
                    type="email"
                    style={{
                        width: props.width,
                        height: props.height,
                        backgroundColor: props.backgroundColor,
                        color: props.textColor,
                        paddingLeft: props.paddingLeft,
                        paddingRight: props.paddingRight,
                        paddingTop: props.paddingTop,
                        paddingBottom: props.paddingBottom,
                        borderRadius: props.borderRadius,
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                    }}
                    value={email}
                    placeholder={props.placeholder}
                    onChange={validate}
                >
                </input>
                <br />
                <span style={{ color: "red" }}>{warn}</span>
            </label>
        </div>
    )
}

export const TextPhone = (props) => {
    const [intCode, setIntCode] = useState("")
    const [validIntChar, setValidIntChar] = useState(false)
    const [natCode, setNatCode] = useState("")
    const [validNatChar, setValidNatChar] = useState(false)
    const [telNum, setTelNum] = useState("")
    const [validTelNum, setValidTelNum] = useState(false)

    const chkIntCode = (event) => {
        if (validIntChar) {
            setIntCode(event.target.value)
        }
    }

    const chkNatCode = (event) => {
        if (validNatChar) {
            setNatCode(event.target.value)
        }
    }

    const chkTelNum = (event) => {
        if (validTelNum) {
            setTelNum(event.target.value)
        }
    }

    const handleKeyPressIntCode = (event) => {
        if (intCode.length < 3) {
            if (event.charCode >= 48 && event.charCode <= 57) {
                setValidIntChar(true)
            }
            else setValidIntChar(false)
        }
        else {
            setValidIntChar(false)
            alert("toolong") /*modify*/
        }
    }

    const handleKeyPressNatCode = (event) => {
        if (natCode.length < 3) {
            if (event.charCode >= 48 && event.charCode <= 57) {
                setValidNatChar(true)
            }
            else setValidNatChar(false)
        }
        else {
            setValidNatChar(false)
            alert("toolong") /*modify*/
        }
    }

    const handleKeyPressTelNum = (event) => {
        if (telNum.length < 9) {
            if (event.charCode >= 48 && event.charCode <= 57) {
                setValidTelNum(true)
            }
            else setValidTelNum(false)
        }
        else {
            setValidTelNum(false)
            alert("toolong") /*modify*/
        }
    }

    const handleKeyDownIntCode = (event) => {
        if (event.key === "Backspace" || event.key === "Delete")
            setValidIntChar(true)
    }

    const handleKeyDownNatCode = (event) => {
        if (event.key === "Backspace" || event.key === "Delete")
            setValidNatChar(true)
    }

    const handleKeyDownTelNum = (event) => {
        if (event.key === "Backspace" || event.key === "Delete")
            setValidTelNum(true)
    }

    return (
        <div>
            <label
                className="label-cards"
                style={{
                    marginTop: props.marginTop,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}
            >
                {props.label}
                <br />
            </label >
            <div className="combo-cards">
                <div className="label-cards"
                    style={{
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                        borderRadius: props.borderRadius,
                        marginBottom: props.marginBottom,
                        marginRight: props.marginRight,
                        height: props.height,
                        width: "calc(" + props.widthIntCode + " + " + props.widthNatCode +
                            " + " + props.widthTelNum +
                            " + 70px)",
                        marginLeft: props.marginLeft,
                        padding: props.padding,
                    }}>
                    <span style={{ fontSize: "xx-large" }}>+</span>
                    <input style={{
                        backgroundColor: props.backgroundColor,
                        width: props.widthIntCode,
                        height: "calc( " + props.height + " - " +
                            props.padding + " * 4 " + " )",
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                    }}
                        onKeyPress={handleKeyPressIntCode}
                        onKeyDown={handleKeyDownIntCode}
                        value={intCode}
                        onChange={chkIntCode}
                    ></input>
                    <span style={{ fontSize: "xx-large" }}>( </span>
                    <input style={{
                        backgroundColor: props.backgroundColor,
                        width: props.widthNatCode,
                        height: "calc( " + props.height + " - " +
                            props.padding + " * 4 " + " )",
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                    }}
                        onKeyPress={handleKeyPressNatCode}
                        onKeyDown={handleKeyDownNatCode}
                        value={natCode}
                        onChange={chkNatCode}
                    >

                    </input>
                    <span style={{ fontSize: "xx-large" }}> )</span>
                    <input style={{
                        backgroundColor: props.backgroundColor,
                        width: props.widthTelNum,
                        height: "calc( " + props.height + " - " +
                            props.padding + " * 4 " + " )",
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                    }}
                        onKeyPress={handleKeyPressTelNum}
                        onKeyDown={handleKeyDownTelNum}
                        value={telNum}
                        onChange={chkTelNum}
                    >

                    </input>

                </div>
                {props.addSwitch === "true" && <ToggleSwitch
                    height={props.heightSwitch}
                    // marginLeft={props.marginLeftSwitch}
                    // marginRight={props.marginRightSwitch}
                    // paddingRight={props.paddingRight}
                    backgroundColor={props.backgroundColorSwitch}
                    colorselect={props.colorSelectSwitch}/*color of bground selected*/
                    borderWidth={props.borderWidthSwitch}
                    borderColor={props.borderColorSwitch}
                    borderStyle={props.borderStyleSwitch}
                    id={props.id}
                    checked={props.checkedPP}
                    onChange={props.onChange}
                />}
            </div>
        </div >
    )
    // const [phone, setPhone] = useState("")


    // return (
    // <div>
    //     <label>
    //         {props.label}
    //         <br />

    //         <input
    //             type="email"
    //             style={{
    //                 width: props.width,
    //                 height: props.height,
    //                 backgroundColor: props.backgroundColor,
    //                 color: props.textColor,
    //                 paddingLeft: props.paddingLeft,
    //                 paddingRight: props.paddingRight,
    //                 paddingTop: props.paddingTop,
    //                 paddingBottom: props.paddingBottom,
    //             }}
    //             value={email}
    //             placeholder={props.placeholder}
    //             onChange={validate}
    //         >
    //         </input>
    //         <br />
    //         <span style={{ color: "red" }}>{warn}</span>
    //     </label>
    // </div>
    // )
}



export const SingleLineSwitch = props => {

    const onChangeValue = (event) => {
        props.callback(event.target.value);
    };

    return (
        <div >
            <label
                className="label-cards"
                style={{
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}
            >
                {props.label}
                <br />
            </label>
            <div className="combo-cards"
            // style={{
            //     marginRight: props.marginRightSwitch
            // }}
            >
                <input
                    type={props.type}
                    style={{
                        width: props.width,
                        height: props.height,
                        backgroundColor: props.backgroundColor,
                        color: props.textColor,
                        paddingLeft: props.paddingLeft,
                        paddingRight: props.paddingRight,
                        paddingTop: props.paddingTop,
                        paddingBottom: props.paddingBottom,
                        marginLeft: props.marginLeft,
                        marginRight: props.marginRight,
                        borderRadius: props.borderRadius,
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                        marginBottom: props.marginBottom,
                    }}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={onChangeValue}
                >
                </input>
                {props.addSwitch === "true" && <ToggleSwitch
                    height={props.heightSwitch}
                    // width={props.widthSwitch}
                    // marginLeft={props.marginLeftSwitch}
                    // marginRight={props.marginRightSwitch}
                    // paddingRight={props.paddingRight}
                    backgroundColor={props.backgroundColorSwitch}
                    colorselect={props.colorSelectSwitch}/*color of bground selected*/
                    borderWidth={props.borderWidthSwitch}
                    borderColor={props.borderColorSwitch}
                    borderStyle={props.borderStyleSwitch}
                    id={props.id}
                    checked={props.checkedPP}
                    onChange={props.onChange}
                />}
            </div>

        </div >
    );
};

export const ShowSingleLineSwitch = props => {

    const onChangeValue = (event) => {
        props.callback(event.target.value);
    };

    return (
        <div >
            <label
                className="label-cards"
                style={{
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}
            >
                {props.label}
                <br />
            </label>
            <div className="combo-cards"
            >
                <label
                    className="combo-cards-info"
                    // type={props.type}
                    style={{
                        width: props.width,
                        height: props.height,
                        backgroundColor: props.backgroundColor,
                        color: props.textColor,
                        paddingLeft: props.paddingLeft,
                        paddingRight: props.paddingRight,
                        paddingTop: props.paddingTop,
                        paddingBottom: props.paddingBottom,
                        marginLeft: props.marginLeft,
                        marginRight: props.marginRight,
                        borderRadius: props.borderRadius,
                        borderWidth: props.borderWidth,
                        borderColor: props.borderColor,
                        borderStyle: props.borderStyle,
                        marginBottom: props.marginBottom,
                    }}
                // value={props.value}
                // placeholder={props.placeholder}
                // onChange={onChangeValue}
                >
                    {props.value}
                </label>
                {props.addSwitch === "true" && <ToggleSwitch
                    height={props.heightSwitch}
                    // width={props.widthSwitch}
                    // marginLeft={props.marginLeftSwitch}
                    // marginRight={props.marginRightSwitch}
                    // paddingRight={props.paddingRight}
                    backgroundColor={props.backgroundColorSwitch}
                    colorselect={props.colorSelectSwitch}/*color of bground selected*/
                    borderWidth={props.borderWidthSwitch}
                    borderColor={props.borderColorSwitch}
                    borderStyle={props.borderStyleSwitch}
                    id={props.id}
                    checked={props.checkedPP}
                    onChange={props.onChange}
                />}
                {props.addIcon === "true" && <ToggleSwitch
                    height={props.heightSwitch}
                    // width={props.widthSwitch}
                    // marginLeft={props.marginLeftSwitch}
                    // marginRight={props.marginRightSwitch}
                    // paddingRight={props.paddingRight}
                    backgroundColor={props.backgroundColorSwitch}
                    colorselect={props.colorSelectSwitch}/*color of bground selected*/
                    borderWidth={props.borderWidthSwitch}
                    borderColor={props.borderColorSwitch}
                    borderStyle={props.borderStyleSwitch}
                    id={props.id}
                    checked={props.checkedPP}
                    onChange={props.onChange}
                />}
            </div>

        </div >
    );
};

export const OneLineInfo = (props) => {
    return (
        <div >
            <label
                className="combo-cards-info"
                style={{
                    width: props.width,
                    height: props.height,
                    backgroundColor: props.backgroundColor,
                    color: props.textColor,
                    fontStyle: props.fontStyle,
                    paddingLeft: props.paddingLeft,
                    paddingRight: props.paddingRight,
                    paddingTop: props.paddingTop,
                    paddingBottom: props.paddingBottom,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                    borderRadius: props.borderRadius,
                    borderWidth: props.borderWidth,
                    borderColor: props.borderColor,
                    borderStyle: props.borderStyle,
                    marginBottom: props.marginBottom,
                }}
            >
                {props.value}
            </label>
        </div>
    );
}

export const DropdownMenu = (props) => {
    const menuItems = props.menuItems
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);
    const [open, setOpen] = useState(false)

    const showItems = useRef(null)
    const showItemsRef = useRef(null)

    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])

    useEffect(() => {
        if (showItemsRef.current) {
            showItemsRef.current.scrollIntoView({ behaviour: "smooth" })
        }
    }, [dropdownItem]);

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    const dropdownItem = (item, i) => {
        return (
            <div
                ref={showItemsRef}
                key={i}
                className={"menu-item-txtinp"}
                onClick={() =>
                    item.callback()
                }
            >
                <span
                    className="menu-text-txtinp"
                >{item?.text}</span>
            </div>
        )
    }

    return (
        <div
        >
            <div
                onClick={props.onClick}
                className="menu-title-txtinp"
            >
                {props.title}
                <span
                    style={{ fontSize: "2rem" }}>
                    {props.open ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                </span>
            </div>
            {props.open &&
                <div
                    className="menu-txtinp"
                // ref={showItemsRef}
                >
                    {menuItems.map((item, i) => (
                        dropdownItem(item, i)
                    ))}
                </div>}
        </div>

    )
}


