import React, { useContext } from "react";
// import { useHistory } from "react-router-dom";
import GlobalContext from "contexts/context";

import DatabaseContext from "data/contextDatabase";
import { randomString, timestamp } from "hooks/helper";
import { useHistory } from "react-router-dom";
import { dex_action } from "data/dexUtils";

const UITopicResult = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const { globalState } = useContext(GlobalContext);
  let history = useHistory();

  const sortJoin = (A, B, Delim) => {
    return A < B ? A + Delim + B : B + Delim + A;
  };

  const subscribeTopic = () => {
    let j = {
      version: globalState.version,
      type: "w.t.join",
      source: "link",
      mtopic: props.topic.mtopic,
      smid: randomString(8),
      ts_sender: timestamp(),
      origin_sender: "registered"
    };
    props.persona && (j.persona = props.persona);
    props.mpersona && (j.mpersona = props.mpersona);
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
    props.onClose();
    if (window.location?.href?.includes("modal")) {
      history.goBack();
    }
  };

  let content = (
    <div className="UI-topic" onClick={() => subscribeTopic()}>
      <div className="UI-topic-search-list">
        <div
          className="UI-topic-search-list-item"
          style={{ textAlign: "left" }}
        >
          {props.topic.topic}
          <div>{props.topic.description}</div>
        </div>

        {/* <div style={{ color: "#333333" }}>{props.topic.persona}</div> */}
        <div style={{ textAlign: "right" }}>({props.topic.countsubs})</div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicResult, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
