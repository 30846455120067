import React, { useContext, useEffect } from "react";

import DatabaseContext from "data/contextDatabase";

import { useLiveQuery } from "dexie-react-hooks";
import { rest_api } from "connectivity/API";
import { dex_action } from "./dexUtils";
import GlobalContext from "contexts/context";
import { shouldLog } from "utils/shouldLog";

const timer = new Date().getTime();

const ListFetcher = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);

  const uid = useLiveQuery(
    () =>
      databaseState.dexAdmin &&
      databaseState.dexAdmin.store.get({ key: "user" }).then((u) => u?.uid),
    [databaseState.dexAdmin]
  );

  const jwt = useLiveQuery(
    () =>
      databaseState.dexAdmin &&
      databaseState.dexAdmin.store.get({ key: "user" }).then((u) => u?.jwt),
    [databaseState.dexAdmin]
  );

  useEffect(() => {
    shouldLog() &&
      console.log(
        "ListFetcher 1",
        jwt,
        uid,
        databaseState?.dexAdmin,
        databaseState.dexUser,
        globalState.authenticated
      );
    if (
      jwt &&
      uid &&
      databaseState?.dexAdmin &&
      databaseState.dexUser &&
      globalState.authenticated
    ) {
      let action = {
        type: "r_t_list",
        jwt: jwt,
        version: "",
        concise: "false",
        url: process.env.REACT_APP_URL
      };
      // R_T_LIST
      true &&
        rest_api(action)
          .then((r) => {
            r.topics &&
              dex_action({
                type: "DEX_UPSERT_MATCH",
                values: {
                  db: databaseState.dexAdmin,
                  table: "store",
                  match: { key: "user" },
                  function: (val) => {
                    let v = val;
                    if (val?.uid === uid && val?.muid !== r.muid)
                      v = { ...val, muid: r.muid };
                    return v;
                  }
                }
              });
            shouldLog() && console.log("ListFetcher r.topics", r.topics);
            r.topics &&
              dex_action({
                type: "DEX_PUT_IF_DIFF",
                values: {
                  db: databaseState.dexUser,
                  table: "account",
                  primaryKey: { type: "topics" },
                  doc: {
                    value: r.topics,
                    type: "topics",
                    time: Date.now()
                  }
                }
              });
          })
          .catch((e) => console.log("[ListFetcher] rest_api error", e));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, databaseState.dexUser, uid, globalState.authenticated]);

  shouldLog() && console.log("ListFetcher timer", new Date().getTime() - timer);
  return null;
};

export default React.memo(ListFetcher, (prevProps, nextProps) => {
  return true;
});
