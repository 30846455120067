import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import { randomString, timestamp } from "hooks/helper";
import { useHistory } from "react-router-dom";
import Modals from "./Modals";
import "./modals.css";
import TopicContext from "contexts/contextTopic";
import UIParticipants from "./UIParticipants";
import { useLiveParticipants } from "hooks/dexHooks";
import { dex_action } from "data/dexUtils";

const UIParticipantsModal = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { topicDispatch } = useContext(TopicContext);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(false);
  let history = useHistory();
  const [subgroup, setSubgroup] = useState();
  const [okToEvict, setOkToEvict] = useState(false);
  // const liveParticipants = useLiveParticipants(
  //   databaseState.dbMaster,
  //   props.sub.mtopic
  // );
  const liveParticipants = useLiveParticipants(
    databaseState.dexUser,
    props.sub.mtopic
  );

  useEffect(() => {
    let index = liveParticipants?.personas?.findIndex(
      (x) =>
        x.mpersona?.toLowerCase() ===
        globalState?.persona?.mpersona.toLowerCase()
    );
    if (index >= 0) {
      if (liveParticipants)
        setOkToEvict(
          liveParticipants?.personas[index]?.props?.roles?.some(
            (el) => el === "owner" || el === "admin"
          )
        );
    }
  }, [liveParticipants]);

  const menuEvict = (mpersona) => {
    let j = {
      type: "w.t.evict",
      version: globalState.version,
      smid: randomString(8),
      ts_sender: timestamp(),
      mtopic: props.sub.mtopic,
      mpersona: props.sub.mpersona,
      bparty: mpersona
    };
    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "send",
        doc: j
      }
    });
    let transform = (participantsRec) => {
      let newRec = { ...participantsRec };
      newRec.personas =
        participantsRec.personas?.filter((p) => p.mpersona !== mpersona) || [];
      newRec.count_subs = newRec.personas.length;
      return newRec;
    };
    dex_action({
      type: "DEX_MODIFY_TRANS",
      values: {
        db: databaseState.dexUser,
        table: "participants",
        filter: (i) => i.mtopic === props.sub.mtopic,
        transform: transform
      }
    });
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length > 1) history.goBack();
  };

  const handleEvict = () => {
    // Only allow eviction while online, so that it is harder for an evicted person to receive a message after eviction
    fetch(process.env.REACT_APP_PERSONA_API_URL, { method: "HEAD" })
      .then((response) => {
        globalState.logging && console.log("response", response);
        if (response.status === 403 && globalState.connected) {
          if (
            window.confirm(
              "Are you sure you want to evict all selected personas?"
            )
          ) {
            subgroup.map((item) => menuEvict(item.mpersona));
          }
        }
      })
      .catch((e) => window.alert("Evicting users only works while online."));
  };

  let modalParticipants = (
    <div id={"participants_" + props.sub.mtopic}>
      <Modals
        title="Participants"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        footer={
          <div>
            <button
              className={
                subgroup?.length > 0
                  ? "modal-button-new"
                  : "modal-button-greyed"
              }
              style={okToEvict ? {} : { display: "none" }}
              type="button"
              onClick={handleEvict}
            >
              Evict
            </button>
            <button
              className={
                subgroup?.length > 0
                  ? "modal-button-new"
                  : "modal-button-greyed"
              }
              type="button"
              onClick={() => {
                topicDispatch({
                  type: "SET_PERSONAS_RX",
                  values: { personas_rx: subgroup }
                });
                topicDispatch({
                  type: "SET_REPLY_TO",
                  values: { reply_to: undefined }
                });
                props.onClose();
              }}
            >
              Whisper
            </button>
          </div>
        }
      >
        <UIParticipants
          sub={props.sub}
          transferSubgroup={(val) => setSubgroup(val)}
        />
      </Modals>
    </div>
  );

  let content = <div>{modalParticipants}</div>;

  return content;
};;

export default React.memo(UIParticipantsModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
