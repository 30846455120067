import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "ui/UI.css";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import { timestamp, timestampZero, randomString } from "hooks/helper";
import { digestFile } from "data/descriptors";
import { imageVersions, storeImage } from "utils/image";
import { uploadFileToS3 } from "connectivity/s3";
import TopicContext from "contexts/contextTopic";
import GetFileExt from "./UIGetFileExt";
import { RiContrastDropLine, RiSendPlaneFill } from "react-icons/ri";
import CalcWhispColor from "utils/CalcWhispColor";
import UIQuoteSend from "./UIQuoteSend";
import { isMobileTablet } from "hooks/helper";
import TopicsContext from "contexts/contextTopics";
import PersonasContext from "contexts/contextPersonas";
import { FaFileDownload } from "react-icons/fa";
import { dex_action } from "data/dexUtils";
//import LiveSearch from "utils/LiveSearch";
//import { WorldTextInput } from "utils/UtilsUniversal";

const UIMessageSend = (props) => {
  const { topicsDispatch } = useContext(TopicsContext);

  const { personasState } = useContext(PersonasContext);
  const { databaseState } = useContext(DatabaseContext);
  const { globalState } = useContext(GlobalContext);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const [msgObject, setMsgObject] = useState({});
  const ref_image_thumbnail = useRef(null);
  const ref_input_image_select = useRef(null);
  const ref_msg_text = useRef(null);
  const ref_msg_send_button = useRef(null);
  const [alreadyClicked, setAlreadyClicked] = useState(false);
  const targetRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const increment = useRef(null);
  const tick = useRef();
  const cameraGalleryRef = useRef();
  const ref_input_file_select = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachingFile, setAttachingFile] = useState(false);
  const [pictureFile, setPictureFile] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [currStart, setCurrStart] = useState();
  const [currEnd, setCurrEnd] = useState();
  const [maxStickyRows, setMaxStickyRows] = useState(false);
  const matchingPersonas = useRef([]);
  const [getPrts, setGetPrts] = useState(false);
  const [atAllNames, setAtAllNames] = useState([]);
  const [currAtName, setCurrAtName] = useState("");
  const [partList, setPartList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [cursor, setCursor] = useState();
  const [listName, setListName] = useState([]);
  const [handled, setHandled] = useState(true);
  const [firstFocus, setFirstFocus] = useState(true);

  useEffect(() => {
    if (increment.current) {
      increment.current = !increment.current;
      return;
    }

    if (isActive) {
      tick.current = setInterval(() => {
        // <-- set tick ref current value
        setTimer((timer) => timer + 1);
      }, 500);
    } else {
      clearInterval(tick.current); // <-- access tick ref current value
    }

    return () => clearInterval(tick.current); // <-- clear on unmount!
  }, [isActive]);

  const getAllCompleteWords = useMemo(
    () => (s) => {
      // word preceded by @ which is preceded by comma or space or start of line
      const p = [...s.matchAll(/(([,\s\p{P}\p{S}]@)|^@)[a-zA-Z0-9-_.]+/g)];
      if (!p) return [];
      return p.map((i) => {
        let str = i[0].toLowerCase();
        let parts = str.split("@");
        return parts[parts.length - 1];
      });
    },
    []
  );

  const updateImages = useMemo(
    () => (files) => {
      let currIndex = imageList?.length;
      if (files && files.length > 0) {
        let promises = files.map(async (file, index) => {
          return await imageVersions(files[index], [
            { key: "image", maxWidth: 500, maxHeight: 500 },
            { key: "med", maxWidth: 200, maxHeight: 200 },
            { key: "thumbnail", maxWidth: 16, maxHeight: 16 }
          ])
            .then(async (i) => {
              globalState.logging && console.log("[IMAGE] lastly", i);
              if (i.image || i.thumbnail || i.icon) {
                let jImage = {
                  image: i.image,
                  thumbnail: i.thumbnail,
                  med: i.med,
                  width: i.image.w,
                  height: i.image.h
                };
                let t =
                  jImage?.thumbnail?.dataURI &&
                  jImage?.thumbnail.dataURI
                    .replace(/^data:image.+;base64,/, "")
                    .replace(/^data:,/, "");
                let iLarge =
                  jImage?.image?.dataURI &&
                  jImage?.image.dataURI
                    .replace(/^data:image.+;base64,/, "")
                    .replace(/^data:,/, "");
                let iMed =
                  jImage?.med?.dataURI &&
                  jImage?.med.dataURI
                    .replace(/^data:image.+;base64,/, "")
                    .replace(/^data:,/, "");
                let jsonDetails = {};
                // !!! rather than AWAIT, calculate what the jsonDetails will be, and then storeImage without waiting
                iLarge &&
                  (jsonDetails = await storeImage(
                    databaseState.dexUser,
                    "share",
                    iLarge
                  ));
                let jsonDetailsMed = {};
                iMed &&
                  (jsonDetailsMed = await storeImage(
                    databaseState.dexUser,
                    "share",
                    iMed
                  ));
                currIndex = currIndex + 1;
                let imageGroup = {
                  width: jImage.width,
                  height: jImage.height,
                  index: currIndex
                };
                if (jsonDetails.digest && jsonDetails.scope) {
                  imageGroup.large = jsonDetails;
                  imageGroup.name = file.name;
                }
                if (jsonDetailsMed.digest && jsonDetailsMed.scope) {
                  imageGroup.medium = jsonDetailsMed;
                  imageGroup.name = file.name;
                }
                if (t) {
                  imageGroup.thumbnail = t;
                  imageGroup.name = file.name;
                }
                return imageGroup.thumbnail ? imageGroup : false;
              } else return false;
            })
            .catch((e) => {
              return false;
            });
        });
        setIsActive(true);
        Promise.all(promises).then((r) => {
          setImageList((images) => [...r.filter((i) => i), ...images]);
          setIsActive(false);
          setTimer(0);
        });
        files.map((names, index) => {
          setPictureFile((pictureFile) => [...pictureFile, names.name]);
        });
      }
    },
    [databaseState.dexUser, imageList]
  );

  useEffect(() => {
    process.env.REACT_APP_DEBUG_THIS?.includes("UIMessageSend") &&
      console.log(
        "[UIMessageSend] messageHeight",
        targetRef?.current?.offsetHeight
      );
    if (targetRef?.current?.offsetHeight) {
      // props.msgHgt(targetRef.current.offsetHeight);
    }
  }, [props, targetRef?.current?.offsetHeight]);

  useEffect(() => {
    if (
      (topicState.personas_rx && topicState.personas_rx?.length > 0) ||
      (topicState.reply_to && topicState.reply_to?.length > 0) ||
      topicState.quote ||
      !isMobileTablet()
    )
      ref_msg_text.current.focus();
  }, [topicState.personas_rx, topicState.reply_to, topicState.quote]);

  useEffect(() => {
    // Reset height - important to shrink on delete
    // setIsEdit(msgObject.msg_idx);
    if (msgObject.msg_idx) {
      ref_msg_text.current.style.height = `${Math.min(
        ref_msg_text.current?.scrollHeight,
        90
      )}px`;
      ref_msg_text.current?.focus();
    }
  }, [msgObject]);

  useEffect(() => {
    setMsgObject((mO) => {
      mO.parameters = { ...mO.parameters, quote: topicState.quote };
      mO.parameters.reply_to = undefined;
      // topicState.quote?.parameters?.reply_to || mO.parameters.reply_to;
      mO.mpersona_rx = topicState.quote?.mpersona_rx || mO.mpersona_rx;
      return mO;
    });
  }, [topicState.quote]);

  useEffect(() => {
    // Receiving a message for editing
    if (topicState.message) {
      // console.log("[UIMessageSend] edit", topicState.message);
      let t = { ...topicState.message };
      delete t._id;
      delete t._rev;
      delete t._deleted;
      delete t.origin_sender;
      delete t.mpersona;
      delete t.name;
      setMsgObject(t);
      setImageList(t?.parameters?.images || []);
      setSelectedFiles(t?.parameters?.attachments);
      if (t.parameters.at) {
        let newObj = {};
        let newArr = [];
        t.parameters.at.forEach((el) => {
          newObj = { name: el.name, mpersona: el.mpersona };
          newArr.push(newObj);
        });
        setAtAllNames(newArr);
      }
      setPictureFile((pictureFile) => [
        ...pictureFile,
        (t?.parameters?.images && t?.parameters?.images[0]?.name) || ""
      ]);
      // topicDispatch({
      //   type: "SET_PERSONAS_RX",
      //   values: { personas_rx: t?.message?.personas_rx }
      // });
      // topicDispatch({
      //   type: "SET_REPLY_TO",
      //   values: { reply_to: t?.message?.parameters?.reply_to }
      // });
    } else setMsgObject({});
    return () => {};
  }, [topicState.message]);

  const personaDescriptor = useMemo(
    () => (mpersona) => {
      globalState.logging &&
        console.log("personasState.descriptors", personasState.descriptors);
      return (
        personasState.descriptors?.filter((d) => d.mpersona === mpersona)[0]
          ?.digest || ""
      );
    },
    [personasState.descriptors]
  );

  const sendMessage = useMemo(
    () => (mObj, quote) => {
      if (mObj.content?.body?.length > 0)
        mObj.content.body = mObj?.content?.body?.trim();
      if (
        mObj.content?.body.length > 0 ||
        mObj.parameters?.attachment ||
        mObj.parameters?.attachments ||
        imageList?.length > 0
      ) {
        if (mObj.content?.body?.length > 0) {
          mObj.content.body = mObj?.content?.body?.trim();
          mObj.content.body = mObj?.content?.body?.replace(
            /[\n][\n][\n]+/g,
            "\n\n"
          );
        }
        let j;
        let descriptor = personaDescriptor(props.mpersona);
        if (props.mdialog !== undefined) {
          j = {
            type: "w.mpersona.message",
            mpersona: props.mpersona,
            peer: props.peer,
            name: props.name,
            visibility: "PRIVATE_DIALOG",
            ts_sender: timestamp(),
            smid: randomString(8),
            status: "1",
            origin_sender: "registered",
            thumbnail: "",
            lat: "",
            lon: "",
            persona_picture: "",
            ...mObj,
            parameters: {
              ...mObj.parameters,
              descriptor: descriptor
            },
            version: globalState.version
          };
          if (props.tag !== undefined && props.tag !== "") {
            j.tag = props.tag;
          }
          if (
            props.topic_display_name !== undefined &&
            props.topic_display_name !== ""
          ) {
            j.topic_display_name = props.topic_display_name;
          }
        } else if (props.mtopic.startsWith("t_")) {
          j = {
            type: "w.t.msg",
            content: { body: "", msgtype: "w.text" },
            mpersona: props.mpersona,
            mtopic: props.mtopic,
            ts_sender: timestampZero(), // !!! TODO: this needs to be converted to UTC+0
            smid: randomString(8),
            status: "1",
            origin_sender: "registered",
            thumbnail: "",
            ...mObj,
            parameters: {
              ...mObj.parameters,
              descriptor: descriptor
              // reply_to: topicState.reply_to || topicState.personas_rx
            },
            version: globalState.version
          };
          // j.parameters.at = matchingPersonas.current;
          let newArr = [];
          atAllNames.forEach((item) => {
            if (msgObject?.content?.body?.includes("@" + item.name)) {
              newArr = [...newArr, item];
            }
          });
          j.parameters.at = newArr;
        } else if (props.mtopic !== undefined) {
          j = {
            type: "w.topic.message",
            persona: props.persona,
            mpersona: props.mpersona,
            name: props.name,
            mtopic: props.mtopic,
            topic: props.topic,
            ts_sender: timestamp(),
            smid: randomString(8),
            status: "1",
            origin_sender: "registered",
            thumbnail: "",
            lat: "",
            lon: "",
            persona_picture: "",
            ...mObj,
            version: globalState.version
          };
        }

        delete j.mpersona_rx;
        delete j.personas_rx;
        props.mtopic?.startsWith("t_") &&
          ((topicState.personas_rx && topicState.personas_rx.length > 0) ||
            (topicState.reply_to && topicState.reply_to.length > 0)) &&
          (j = {
            ...j,
            mpersona_rx: (topicState.reply_to || topicState.personas_rx).map(
              (p) => p.mpersona
            ),
            personas_rx: topicState.reply_to || topicState.personas_rx
          });
        quote && (j.parameters = { ...j.parameters, quote: quote });
        if (imageList?.length > 0) {
          j.parameters.images = imageList;
          j.thumbnail = imageList[0].thumbnail;
          // TODO: Remove next 2 lines once all apps are upgraded ... adding for backward compatibiity
          j.parameters.image = imageList[0].large;
          j.parameters.imageMed = imageList[0].medium;
        } else {
          delete j.parameters.images;
          delete j.thumbnail;
          delete j.parameters.image;
          delete j.parameters.imageMed;
        }

        if (mObj.msg_idx) {
          j = {
            ...j,
            parameters: {
              ...j.parameters,
              edited: "true",
              random: randomString(8)
            }
          };
        }
        delete j.msg_idx;
        delete j.txn_id;
        delete j._id;
        delete j._rev;

        let rowid = j.smid;
        // if (reply_to && reply_to.length > 0) {
        if (j.mtopic && j.mtopic.startsWith("t_")) {
          rowid = `${j.smid}_${j.mpersona}`;
          j.recipient = j.mpersona;
        }
        matchingPersonas.current = [];

        // console.log("!!!TX!!!", mObj, j, topicState);

        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: { ...j, recipient: undefined }
          }
        });
      }
    },
    [
      databaseState.dexAdmin,
      databaseState.dexUser,
      globalState.version,
      imageList,
      props.mdialog,
      props.mpersona,
      props.mtopic,
      props.name,
      props.peer,
      props.persona,
      props.tag,
      props.topic,
      props.topic_display_name,
      atAllNames,
      topicState.personas_rx,
      topicState.reply_to,
      msgObject
    ]
  );

  useEffect(() => {
    let currNames = (msgObject?.parameters?.attachments || []).map(
      (a) => a.name
    );
    if (selectedFiles && selectedFiles.length > 0) {
      process.env.REACT_APP_DEBUG_THIS?.includes("UIMessageSend") &&
        globalState.logging &&
        console.log("[UIMessageSend] attaching");
      setAttachingFile(true);
      let promises = selectedFiles?.map(async (file) => {
        return !currNames.includes(file.name)
          ? await digestFile(file)
              .then((d) => {
                let row = d + "/" + file.name;
                return uploadFileToS3(file, "file", props.mpersona, row)
                  .then((r) => {
                    if (r.ok) {
                      return {
                        collection: "file",
                        table: props.mpersona,
                        row: row,
                        name: file.name
                      };
                    } else {
                      alert(
                        `Attachment ${file.name} failed. Please verify that you are currently online, and try again.`
                      );
                      return false;
                    }
                  })
                  .catch((e) => {
                    alert(
                      `Attachment ${file.name} failed. Please verify that you are currently online, and try again.`
                    );
                    return false;
                  });
              })
              .catch((e) => {
                alert(
                  `Attachment ${file.name} failed. Please verify that you are currently online, and try again.`
                );
                return false;
              })
          : false;
      });
      Promise.all(promises)
        .then((atts) => {
          globalState.logging && console.log("atts", atts);
          if (atts.filter((a) => a).length > 0) {
            setMsgObject((msgObject) => ({
              ...msgObject,
              parameters: {
                ...msgObject.parameters,
                attachments: [
                  ...atts.filter((a) => a),
                  ...(msgObject?.parameters?.attachments || [])
                ],
                // TODO: Remove "attachment" from message once all apps are upgraded ... adding for backward compatibiity
                attachment: atts.filter((a) => a)[0]
              }
            }));
          } else {
            setMsgObject((msgObject) => ({
              ...msgObject,
              parameters: {
                ...msgObject.parameters,
                // TODO: Remove "attachment" from message once all apps are upgraded ... adding for backward compatibiity
                attachment: (msgObject?.parameters?.attachments || [])[0]
              }
            }));
          }
          setAttachingFile(false);
        })
        .catch((e) => {
          process.env.REACT_APP_DEBUG_THIS?.includes("UIMessageSend") &&
            console.log("[UIMessageSend] attachment failed", e);
          setAttachingFile(false);
          alert("Attachment failed");
        });
    } else {
      process.env.REACT_APP_DEBUG_THIS?.includes("UIMessageSend") &&
        console.log("[UIMessageSend] attachment none");
      setAttachingFile(false);
      setMsgObject((msgObject) => ({
        ...msgObject,
        parameters: {
          ...msgObject.parameters,
          // TODO: Remove "attachment" from message once all apps are upgraded ... adding for backward compatibiity
          attachment: (msgObject?.parameters?.attachments || [])[0]
        }
      }));
    }
    return () => {};
  }, [props.mpersona, selectedFiles]);

  useEffect(() => {
    ref_image_thumbnail.current &&
      (ref_image_thumbnail.current.src =
        "data:image/png;base64," + imageList[0]?.thumbnail);
    return () => {};
  }, [imageList]);

  const imgStyle = {
    display: "grid",
    gridTemplateColumns: "20% 70% 10%"
  };

  const removePic = (index) => {
    setImageList((names) => names.filter((_, i) => i !== index));
  };

  const removeFile = (index) => {
    setSelectedFiles((names) => names.filter((_, i) => i !== index));
  };

  let divThumbnail = useMemo(
    () => (
      <div
        id="select_thumbnail_div"
        className={imageList.length > 0 ? "attachment hide-scrollbar" : {}}
      >
        {isActive && (
          <div>
            <span>Loading...</span>
          </div>
        )}
        <div
          style={
            imageList?.length > 0
              ? {
                  display: "flex",
                  flexDirection: "column"
                }
              : { display: "none" }
          }
        >
          {imageList.map((images, index) => {
            let imgTn = "data:image/png;base64," + images.thumbnail;
            return (
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "19% 76% 5%",
                    alignContent: "center",
                    alignItems: "center",
                    position: "relative"
                  }}
                >
                  <img
                    key={index}
                    ref={ref_image_thumbnail}
                    src={imgTn}
                    id="img_thumbnail"
                    alt=""
                    width="0"
                    height="0"
                    style={{
                      paddingLeft: "8px",
                      width: "64px",
                      height: "32px",
                      objectFit: "cover"
                    }}
                  ></img>
                  <span
                    style={{
                      textAlign: "left"
                    }}
                  >
                    {images.name}
                  </span>
                  <button
                    type="button"
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0"
                    }}
                    className="close-attachment"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      removePic(index);
                    }}
                    title="Remove from list"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <hr
                  style={{
                    border: "1px solid  var(--topic_border_color)",
                    opacity: "0.2",
                    width: "95%",
                    margin: "0 2.5% 0 2.5%"
                    // marginLeft: "2.5%"
                  }}
                />
              </div>
            );
          })}
        </div>
        <span
          style={{
            textAlign: "left"
          }}
        ></span>
        <span style={{ textAlign: "right" }}></span>
      </div>
    ),
    [imageList, isActive]
  );

  let divSelectedFiles = useMemo(
    () => (
      <div
        id="select_file_div"
        className={selectedFiles?.length > 0 ? "attachment hide-scrollbar" : {}}
      >
        {attachingFile ? (
          <div>Loading...</div>
        ) : (
          <div
            style={
              selectedFiles?.length > 0
                ? {
                    display: "flex",
                    flexDirection: "column"
                  }
                : { display: "none" }
            }
          >
            {selectedFiles?.map((files, index) => {
              return (
                <div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "10% 80% 10%",
                      alignContent: "center",
                      alignItems: "center",
                      position: "relative"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        alignSelf: "center"
                      }}
                    >
                      {/* <FaFileDownload
                        style={{
                          fontSize: "1rem"
                        }}
                      /> */}
                      <GetFileExt fName={files.name} />
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        alignSelf: "end",
                        paddingBottom: "4px"
                      }}
                    >
                      {files.name}
                    </span>
                    <span style={{ textAlign: "right" }}>
                      <button
                        className="close-attachment"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          removeFile(index);
                        }}
                        title="Remove from list"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "19% 76% 5%",
                      alignContent: "center",
                      alignItems: "center",
                      position: "relative"
                    }}
                  ></div>
                  <hr
                    style={{
                      border: "1px solid  var(--topic_border_color)",
                      opacity: "0.2",
                      width: "95%",
                      margin: "0 2.5% 0 2.5%"
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    ),
    [selectedFiles, attachingFile]
  );

  const [rows, setRows] = useState(1);
  const [minRows, setMinRows] = useState(1);
  const [maxRows, setMaxRows] = useState(5);

  useEffect(() => {
    const textareaLineHeight = 18;
    ref_msg_text.current.style.height = "auto";
    // const textareaLineHeight = 18;
    const previousRows = ref_msg_text.current.rows;
    ref_msg_text.current.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(
      ref_msg_text.current.scrollHeight / textareaLineHeight
    );

    if (currentRows === previousRows) {
      ref_msg_text.current.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      ref_msg_text.current.rows = maxRows;
      ref_msg_text.current.scrollTop = ref_msg_text.current.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    return () => {};
  }, [ref_msg_text?.current?.value, msgObject?.content?.body]);

  const filterData = (data, searchText) => {
    if (!searchText || searchText.length === 0 || searchText === "")
      return data;
    else
      return data?.filter((x) =>
        x
          ?.toString()
          .toLowerCase()
          .includes(searchText?.toString().toLowerCase())
      );
  };

  const handleChange = useMemo(
    () => (event) => {
      // let completeWords = getAllCompleteWords(event);
      // let matches =
      //   topicState?.participants &&
      //   topicState?.participants.filter((p) =>
      //     completeWords?.includes(p.persona.toLowerCase())
      //   );
      // matches &&
      //   console.log(
      //     "[UIMessageSend] searchPersona",
      //     matches,
      //     topicState?.participants
      //   );
      // if (matches)
      //   matchingPersonas.current = matches.map((m) => ({
      //     mpersona: m.mpersona,
      //     persona: m.persona
      //   }));
      // let tempChk
      // if (matches)
      //   tempChk = atAllNames.map((m) => ({
      //     // mpersona: m.mpersona,
      //     persona: m.name,
      //     start: m.start,
      //     end:m.end
      //   }));

      // process.env.REACT_APP_DEBUG_THIS?.includes("UIMessageSend") &&
      //   console.log("[UIMessageSend] handleChange", event);
      // let anArr = [...newArr, atName]
      // anArr.map((item) => {
      //   let tsMatch = topicState?.participants?.filter((x) => x.persona === item.name)
      //   if (tsMatch?.length > 0) newObj = { ...item, mpersona: tsMatch[0]?.mpersona }
      //   newFirstArrtmp.push(newObj)
      // })
      // newObj = {}
      // newFirstArrtmp.map((items) => {
      //   newObj = { persona: items.name, mpersona: items.mpersona }
      //   newArrtmp.push(newObj)
      // }
      // )

      let newObj;
      // let newArrTmp = atAllNames
      // atAllNames.map((item) => {
      //   let tsMatch = topicState?.participants?.filter((x) => x.persona === item.name)
      //   if (tsMatch?.length > 0) newObj = { ...item, mpersona: tsMatch[0]?.mpersona }
      //   newArrTmp.push(newObj)
      // })
      newObj = {};
      let newArr = [];
      atAllNames.map((items) => {
        newObj = { name: items?.name, mpersona: items?.mpersona };
        newArr.push(newObj);
      });
      setMsgObject((msgObject) => ({
        ...msgObject,
        content: {
          ...msgObject.content,
          body: event,
          msgtype: "w.text"
        }
      }));
    },
    [
      topicState?.participants,
      getPrts,
      atAllNames,
      cursor,
      msgObject?.content?.body
    ]
  );

  const handleOnClick = useMemo(
    () => (msgObject, topicState, imageList, attachingFile) => {
      // Do not send if there is no image, picture file, body or attachment
      // ... so Quote cannot be sent on its own
      if (attachingFile) {
        alert(
          "Attachments are not yet ready. Please tap the 'send' arrow once that is completed."
        );
        return;
      }
      if (
        alreadyClicked ||
        // Message is empty
        !(
          pictureFile ||
          msgObject?.content?.body ||
          msgObject?.parameters?.attachment ||
          msgObject?.parameters?.attachments
        ) ||
        // Editing a message but no changes
        (msgObject.msg_idx > 0 &&
          topicState.message &&
          msgObject?.content?.body === topicState.message.content?.body &&
          msgObject?.parameters?.attachment?.row ===
            topicState.message.parameters?.attachment?.row &&
          (msgObject?.parameters?.attachments || [])
            .map((a) => a.row)
            .sort()
            .reduce((a, r) => r + a, "") ===
            (topicState.message.parameters?.attachments || [])
              .map((a) => a.row)
              .sort()
              .reduce((a, r) => r + a, "") &&
          (imageList || [])
            .map((a) => a.thumbnail)
            .sort()
            .reduce((a, r) => r + a, "") ===
            (topicState.message.parameters?.images || [])
              .map((a) => a.thumbnail)
              .sort()
              .reduce((a, r) => r + a, ""))
      )
        return;
      setAlreadyClicked(true);
      sendMessage(msgObject, topicState.quote);
      ref_msg_text.current.value = "";
      setMsgObject({});
      setAtAllNames([]);
      setGetPrts(false);
      setCurrAtName("");
      setRows(1);
      ref_input_image_select.current.value = "";
      setImageList([]);
      setSelectedFiles([]);
      setPictureFile([]);
      setAlreadyClicked(false);
      topicDispatch({
        type: "SET_MESSAGE",
        values: { message: null }
      });
      topicDispatch({
        type: "SET_QUOTE",
        values: { quote: null }
      });
      if (
        topicState.personas_rx?.length === 1 &&
        props.mpersona === topicState.personas_rx[0]?.mpersona
      ) {
        let j = { ...msgObject };
        delete j.mpersona_rx;
        delete j.personas_rx;
        setMsgObject({
          ...j
        });
        topicDispatch({
          type: "SET_PERSONAS_RX",
          values: { personas_rx: undefined }
        });
        topicDispatch({
          type: "SET_REPLY_TO",
          values: { reply_to: undefined }
        });
        setMsgObject({});
        setAtAllNames([]);
        setGetPrts(false);
        setCurrAtName("");
      }
      if (
        msgObject.msg_idx > 0 &&
        ((topicState.personas_rx && topicState.personas_rx?.length > 0) ||
          (topicState.reply_to && topicState.reply_to?.length > 0))
      ) {
        topicDispatch({
          type: "SET_PERSONAS_RX",
          values: { personas_rx: undefined }
        });
        topicDispatch({
          type: "SET_REPLY_TO",
          values: { reply_to: undefined }
        });
      }
      if (isMobileTablet()) {
        ref_msg_text.current.blur();
        setFirstFocus(true);
      }
    },
    [
      alreadyClicked,
      pictureFile,
      props,
      sendMessage,
      topicState.personas_rx,
      topicState.reply_to,
      topicDispatch
    ]
  );

  const handleContextMenu = useMemo(
    () => (event) => {
      event.preventDefault();
      ref_input_image_select?.current?.setAttribute("capture", "environment");
      ref_input_image_select?.current?.click();
    },
    []
  );

  useEffect(() => {
    process.env.REACT_APP_DEBUG_THIS?.includes("UIMessageSend") &&
      console.log("[UIMessageSend] cameraGallery", cameraGalleryRef.current);
    const ref = cameraGalleryRef.current;
    ref?.addEventListener("contextmenu", handleContextMenu);
    return () => {
      ref?.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    let s = setTimeout(() => setMaxStickyRows(false), 2000);
    return () => {
      clearTimeout(s);
    };
  }, [maxStickyRows]);

  const writeNames = useMemo(
    () => (subgroup) => {
      let i = 0;
      if (subgroup?.length > 0) {
        let str = "";
        for (i = 0; i < subgroup.length; i++) {
          str += subgroup[i]?.persona;
          if (i < subgroup.length - 1) str += ", ";
        }
        return str;
      }
      return "";
    },
    []
  );

  const calcTransparency = useMemo(
    () => () => {
      let root = document.getElementById("root");
      let tVal = root.style.getPropertyValue(
        "--whisper_background_transparency"
      );
      if (tVal === undefined || !tVal) tVal = 0.5;
      return 1 - tVal;
    },
    []
  );

  useEffect(() => {
    ref_msg_text.current.setSelectionRange(cursor, cursor);
    if (firstFocus) setFirstFocus(false);
    if (!isMobileTablet() || !firstFocus) ref_msg_text.current.focus();
  }, [msgObject?.content?.body, cursor]);

  const handleAddAtName = (atperson, lastData) => {
    // let cursorPos = document.getElementById("msg_text")?.selectionStart;
    let newObj;
    let tsMatch = topicState?.participants?.filter(
      (x) => x.persona === atperson
    );
    if (tsMatch?.length > 0)
      newObj = { name: atperson, mpersona: tsMatch[0]?.mpersona };
    setAtAllNames([...atAllNames, newObj]);
    setGetPrts(false);
    setCurrAtName("");
    document.getElementById("msg_text").blur();
    let dataStr =
      msgObject?.content?.body?.slice(0, currStart) +
      atperson +
      " " +
      msgObject?.content?.body?.slice(
        currStart + currAtName.length,
        msgObject?.content?.body?.length
      );
    setMsgObject((msgObject) => ({
      ...msgObject,
      content: {
        ...msgObject.content,
        body: dataStr,
        msgtype: "w.text"
      }
    }));
    setCursor(currStart + atperson.length + 1);
  };

  useEffect(() => {
    document.onMouseUp = () => window.getSelection();
  }, []);

  const handleInput = (e) => {
    let matches = e.target.value.matchAll(
      /(([,\s\p{P}\p{S}]@)|^@)[a-zA-Z0-9-_.]+((?=[\s,\p{P}\p{S}]|$))/g
    );
    let m = Array.from(matches).map((i) => {
      return {
        value: i[0],
        start: i.index,
        stop: i.index + i[0].length - 1,
        current:
          // selection at end
          (e.target.selectionStart === i.index + i[0].length &&
            e.target.selectionEnd === i.index + i[0].length) ||
          // selection contained
          (e.target.selectionStart >= i.index &&
            e.target.selectionEnd < i.index + i[0].length)
      };
    });

    globalState.logging &&
      console.log(
        "handleInput",
        e,
        m,
        e.target.selectionStart,
        e.target.selectionEnd,
        e.target.value,
        e.nativeEvent.data
      );
    let cursorPos = document.getElementById("msg_text")?.selectionStart;
    if (!cursorPos) cursorPos = 0;

    if (!m?.some((el) => el.current) && e.target?.value[cursorPos - 1] !== "@")
      setGetPrts(false);
    let idx = 0;
    if (m && m.length > 0) {
      idx = m.findIndex((item) => item.current);
    }
    let atName = e.target?.value?.substring(
      m[idx]?.start + 1,
      m[idx]?.stop + 1
    );
    setCurrAtName(atName);
    let tmpStr;
    tmpStr = e.target.value;
    if (getPrts) {
      if (getPrts && e.key === "@") alert("not allowed - @ already active");
      if (
        tmpStr?.charCodeAt(cursorPos - 1) === 44 ||
        tmpStr[cursorPos - 1]?.match(/\n/g)
      ) {
        setGetPrts(false);
        if (partList?.some((el) => el === atName)) {
          let newObj;
          let tsMatch = topicState?.participants?.filter(
            (x) => x.persona === atName
          );
          if (tsMatch?.length > 0)
            newObj = { name: atName, mpersona: tsMatch[0]?.mpersona };
          setAtAllNames([...atAllNames, newObj]);
        }
      }
      setFilteredData(filterData(partList, atName));
    } else if (
      e.target.value[cursorPos - 1] === "@" &&
      (cursorPos === 1 ||
        tmpStr?.charCodeAt(cursorPos - 2) === 32 ||
        tmpStr?.charCodeAt(cursorPos - 2) === 44 ||
        tmpStr[cursorPos - 2]?.match(/\n/g))
    ) {
      setGetPrts(true);
      setPartList(topicState?.participants?.map((item) => item.persona));
      setFilteredData(topicState?.participants?.map((item) => item.persona));
      setCurrStart(cursorPos);
    }
    setMsgObject((msgObject) => ({
      ...msgObject,
      content: {
        ...msgObject.content,
        body: tmpStr,
        msgtype: "w.text"
      }
    }));
    setCursor(cursorPos);
  };

  const handleTextInp = (e) => {
    //as soon as @ is typed, a list of participants can appear for selection
    if (e.keyCode === 229) setHandled(true);

    let cursorPos = document.getElementById("msg_text")?.selectionStart;
    if (!cursorPos) cursorPos = 0;
    if (
      (e.keyCode > 8 && e.keyCode <= 31) ||
      (e.keyCode >= 33 && e.keyCode <= 45)
    ) {
      if (e.key === "Enter" && getPrts) e.preventDefault();
      setHandled(true);
    } else if (e.keyCode !== 229) {
      let atName;
      switch (e.key) {
        case "Backspace":
          e.preventDefault();
          if (msgObject?.content?.body?.length > 0) {
            if (getPrts) {
              if (
                msgObject?.content?.body[cursorPos - 1] === "@" &&
                !window.getSelection().toString().length > 0
              ) {
                setGetPrts(false);
                setCurrAtName("");
              } else {
                if (window.getSelection().toString().length > 0) {
                  atName =
                    currAtName?.slice(
                      0,
                      ref_msg_text.current.selectionStart - currStart
                    ) +
                    currAtName?.slice(
                      ref_msg_text.current.selectionEnd - currStart,
                      currAtName?.length
                    );
                } else
                  atName =
                    currAtName?.slice(0, cursorPos - currStart - 1) +
                    currAtName?.slice(
                      cursorPos - currStart,
                      currAtName?.length
                    );
                setFilteredData(filterData(partList, atName));
              }
              setCurrAtName(atName);
              //when do we set atallnames?
              //when space or enter key or comma is typed
              //same with handlenewval for mobile - space, comma and enter
              //when message is edited
              //handleAddAtName- name is selected
              //so if backspace is pressed search msgobject string for occurrences of
              //names in the atallnames list - if one is not matched remove it
              let newArr = [];
              atAllNames.forEach((item) => {
                if (msgObject?.content?.body?.includes("@" + item.persona)) {
                  newArr = [...newArr, item];
                }
              });
              setAtAllNames(newArr);
              if (partList?.some((el) => el === atName)) {
                let newObj;
                let tsMatch = topicState?.participants?.filter(
                  (x) => x.persona === atName
                );
                if (tsMatch?.length > 0)
                  newObj = { name: atName, mpersona: tsMatch[0]?.mpersona };
                setAtAllNames([...atAllNames, newObj]);
              }
            }
            if (window.getSelection().toString().length > 0) {
              handleChange(
                msgObject?.content?.body?.slice(
                  0,
                  ref_msg_text.current.selectionStart
                ) +
                  msgObject?.content?.body?.slice(
                    ref_msg_text.current.selectionEnd,
                    msgObject?.content?.body?.length
                  )
              );
              cursorPos = ref_msg_text.current.selectionStart + 1;
              setCursor(cursorPos);
            } else {
              handleChange(
                msgObject?.content?.body?.slice(0, cursorPos - 1) +
                  msgObject?.content?.body?.slice(
                    cursorPos,
                    msgObject?.content?.body?.length
                  )
              );
            }
            if (cursorPos > 0) cursorPos--;
            setCursor(cursorPos);
          }
          break;
        case "Delete":
          e.preventDefault();
          if (msgObject?.content?.body?.length > 0) {
            if (getPrts) {
              if (msgObject?.content?.body[cursorPos] === "@") {
                setGetPrts(false);
                setCurrAtName("");
                let newArr = [];
                atAllNames.forEach((item) => {
                  if (msgObject?.content?.body?.includes("@" + item.persona)) {
                    newArr = [...newArr, item];
                  }
                });
                setAtAllNames(newArr);
              } else {
                if (window.getSelection().toString().length > 0) {
                  atName =
                    currAtName?.slice(
                      0,
                      ref_msg_text.current.selectionStart - currStart
                    ) +
                    currAtName?.slice(
                      ref_msg_text.current.selectionEnd - currStart,
                      currAtName?.length
                    );
                } else
                  atName =
                    currAtName?.slice(0, cursorPos - currStart) +
                    currAtName?.slice(
                      cursorPos - currStart + 1,
                      currAtName?.length
                    );
                setFilteredData(filterData(partList, atName));
              }
              setCurrAtName(atName);
              if (window.getSelection().toString().length > 0)
                handleChange(
                  msgObject?.content?.body?.slice(
                    0,
                    ref_msg_text.current.selectionStart
                  ) +
                    msgObject?.content?.body?.slice(
                      ref_msg_text.current.selectionEnd,
                      msgObject?.content?.body?.length
                    )
                );
              else
                handleChange(
                  msgObject?.content?.body?.slice(
                    0,
                    ref_msg_text.current.selectionStart
                  ) +
                    msgObject?.content?.body?.slice(
                      ref_msg_text.current.selectionEnd + 1,
                      msgObject?.content?.body?.length
                    )
                );
              setCursor(cursorPos);
            }
          }
          break;
        default:
          e.preventDefault();
          if (getPrts && e.key === "@") alert("not allowed - @ already active");
          else {
            if (
              e.key === "@" &&
              (cursorPos === 0 ||
                msgObject?.content?.body?.charCodeAt(cursorPos - 1) === 32 ||
                msgObject?.content?.body?.charCodeAt(cursorPos - 1) === 44 ||
                msgObject?.content?.body[cursorPos - 1]?.match(/\n/g))
            ) {
              setGetPrts(true);
              setPartList(
                topicState?.participants?.map((item) => item.persona)
              );
              setFilteredData(
                topicState?.participants?.map((item) => item.persona)
              );
              setCurrAtName("");
              setCurrStart(cursorPos + 1);
            }
            if (
              // e.keyCode === 32 ||
              e.keyCode === 13 ||
              e.key === ","
            ) {
              setGetPrts(false);
              if (partList?.some((el) => el === currAtName)) {
                let newObj;
                let tsMatch = topicState?.participants?.filter(
                  (x) => x.persona === currAtName
                );
                if (tsMatch?.length > 0)
                  newObj = { name: currAtName, mpersona: tsMatch[0]?.mpersona };
                setAtAllNames([...atAllNames, newObj]);
              }
              setCurrAtName("");
            }
            if (msgObject?.content?.body?.length > 0) {
              atName = "";
              if (getPrts) {
                atName =
                  currAtName?.slice(
                    0,
                    ref_msg_text.current.selectionStart - currStart
                  ) +
                  e.key +
                  currAtName?.slice(
                    ref_msg_text.current.selectionEnd - currStart,
                    currAtName?.length
                  );
                setCurrAtName(atName);
                // let newName =
                //   msgObject?.content?.body?.slice(atName?.start, cursorPos) +
                //   e.key +
                //   msgObject?.content?.body?.slice(cursorPos, atName?.end);
                setFilteredData(filterData(partList, atName));
              }
              handleChange(
                msgObject?.content?.body?.slice(0, cursorPos) +
                  e.key +
                  msgObject?.content?.body?.slice(
                    cursorPos,
                    msgObject?.content?.body?.length
                  )
              );
              cursorPos++;
              setCursor(cursorPos);
            } else {
              cursorPos++;
              setCursor(cursorPos);
              handleChange(e.key);
            }
          }
          break;
      }
      setHandled(true);
    }
  };

  const handleNewVal = (e) => {
    let atName;
    let cursorPosNV = document.getElementById("msg_text")?.selectionStart;
    if (!cursorPosNV) cursorPosNV = 0;
    let tmpStr;
    tmpStr = e.target.value;
    if (handled) {
      if (getPrts) {
        let backspace = false;
        if (msgObject?.content?.body?.length > tmpStr.length) backspace = true;
        if (getPrts && e.key === "@") alert("not allowed - @ already active");
        if (
          // tmpStr?.charCodeAt(cursorPosNV - 1) === 32 ||
          tmpStr?.charCodeAt(cursorPosNV - 1) === 44 ||
          tmpStr[cursorPosNV - 1]?.match(/\n/g)
        ) {
          atName = currAtName;
          setGetPrts(false);
          setCurrAtName("");
          if (partList?.some((el) => el === atName)) {
            let newObj;
            let tsMatch = topicState?.participants?.filter(
              (x) => x.persona === atName
            );
            if (tsMatch?.length > 0)
              newObj = { name: atName, mpersona: tsMatch[0]?.mpersona };
            setAtAllNames([...atAllNames, newObj]);
          }
        }
        if (backspace) {
          atName = currAtName.substring(0, currAtName.length - 1);
        } else atName = currAtName + tmpStr[cursorPosNV - 1];
        setCurrAtName(atName);
        setFilteredData(filterData(partList, atName));
      } else if (
        e.target.value[cursorPosNV - 1] === "@" &&
        (cursorPosNV === 1 ||
          tmpStr?.charCodeAt(cursorPosNV - 2) === 32 ||
          tmpStr?.charCodeAt(cursorPosNV - 2) === 44 ||
          tmpStr[cursorPosNV - 2]?.match(/\n/g))
      ) {
        setGetPrts(true);
        setPartList(topicState?.participants?.map((item) => item.persona));
        setFilteredData(topicState?.participants?.map((item) => item.persona));
        setCurrAtName("");
        setCurrStart(cursorPosNV);
      }
      setMsgObject((msgObject) => ({
        ...msgObject,
        content: {
          ...msgObject.content,
          body: tmpStr,
          msgtype: "w.text"
        }
      }));
    }
    setCursor(cursorPosNV);
  };

  useEffect(() => {}, [filteredData]);

  const pasteHandler = (event, str) => {
    let cursorPosPH = 0;
    cursorPosPH = document.getElementById("msg_text")?.selectionStart;
    if (!cursorPosPH) cursorPosPH = 0;
    let tempStr = event.clipboardData.getData("text");
    let tlength = tempStr.length;
    if (str)
      tempStr =
        str?.slice(0, cursorPosPH) +
        tempStr +
        str?.slice(cursorPosPH, str?.length);
    handleChange(tempStr);
    event.preventDefault();
    setCursor(cursorPosPH + tlength);
  };

  const colorBasis = useMemo(
    () =>
      topicState.reply_to && topicState.reply_to.length > 0
        ? topicState.reply_to
        : topicState.personas_rx && topicState.personas_rx.length > 0
        ? topicState.personas_rx
        : undefined,
    [topicState.personas_rx, topicState.reply_to]
  );

  const handleAddFile = (event) => {
    let listOfFiles = [...selectedFiles];
    if (event.target?.files?.length === 1)
      listOfFiles.push(event.target?.files[0]);
    else if (event.target?.files?.length > 1)
      for (let i = 0; i < event.target?.files?.length; i++)
        listOfFiles.push(event.target?.files[i]);
    setSelectedFiles(listOfFiles);
  };

  let content = (
    <div
      className="UI-message-area"
      ref={targetRef}
      style={{ overflow: "visible" }}
    >
      {colorBasis && (
        <div
          onClick={() => {
            let j = { ...msgObject };
            delete j.mpersona_rx;
            delete j.personas_rx;
            j.parameters?.reply_to && delete j.parameters.reply_to;
            topicDispatch({
              type: "SET_QUOTE",
              values: { quote: undefined }
            });
            setMsgObject({
              ...j
            });
            topicDispatch({
              type: "SET_PERSONAS_RX",
              values: { personas_rx: undefined }
            });
            topicDispatch({
              type: "SET_REPLY_TO",
              values: { reply_to: undefined }
            });
          }}
        >
          <div
            style={{
              backgroundColor: `rgba(${CalcWhispColor(colorBasis).r},
                ${CalcWhispColor(colorBasis).g},${
                CalcWhispColor(colorBasis).b
              },${calcTransparency()})`,
              fontStyle: "italic"
            }}
          >
            {topicState.personas_rx?.length === 1 &&
            props.mpersona === topicState.personas_rx[0]?.mpersona
              ? "Sticky Notes "
              : "Whisper: "}
            {writeNames(topicState.reply_to || topicState.personas_rx)}
            {" (tap to cancel)"}
          </div>
          {maxStickyRows && (
            <div style={{ fontWeight: "bold" }}>
              max lines for Sticky Note={maxRows - 1}
            </div>
          )}
        </div>
      )}

      {msgObject.msg_idx && (
        <div
          onClick={() => {
            setMsgObject({});
            topicDispatch({
              type: "SET_MESSAGE",
              values: { message: null }
            });
          }}
        >
          <div
            style={{
              fontStyle: "italic"
            }}
          >
            Edit message (tap to cancel)
          </div>
        </div>
      )}

      {topicState.quote && (
        <UIQuoteSend topicDispatch={topicDispatch} message={topicState.quote} />
      )}
      {(isActive || imageList?.length > 0) && divThumbnail}
      {divSelectedFiles}

      <div>
        <div className="UI-message-input">
          <button
            onClick={() => {
              ref_input_file_select.current.removeAttribute("capture");
              ref_input_file_select.current.click();
            }}
            className="UI-button-icon"
            type={attachingFile ? "file-throb" : "file"}
          ></button>
          <button
            id="cameraGallery"
            ref={cameraGalleryRef}
            onClick={() => {
              ref_input_image_select.current.removeAttribute("capture");
              ref_input_image_select.current.click();
            }}
            className="UI-button-icon"
            type="photo"
          ></button>

          <div className="UI-messagesend-surround">
            <textarea
              rows={rows}
              value={msgObject?.content?.body || ""}
              className="UI-input"
              wrap="none"
              type="messagesend"
              placeholder="Type message"
              name="message"
              required
              id="msg_text"
              ref={ref_msg_text}
              onKeyDown={(e) => {
                //   if (e.keyCode === 229) {
                //     setHandled(true);
                //   }
                if (e.shiftKey && e.key === "Enter") {
                  e.preventDefault();
                  ref_msg_send_button.current.click();
                  // setHandled(true);
                  setGetPrts(false);
                  setCurrAtName("");
                }
              }}
              // else if (e.key === "@" || getPrts) {
              //     handleTextInp(e);
              //   } else {
              //     setHandled(true);
              //   }
              // }}
              // onKeyUp={(e) => {}}
              // onFocus={(e) => {}}
              onChange={(e) => {
                handleInput(e);
                // handleNewVal(e);
              }}
              // onPaste={(e) => pasteHandler(e, msgObject?.content?.body || "")}
              //for @participants we need to check keyDown instead of handlechange which looks only at whole input (event)
            />
            {getPrts && (
              <ul className="at-persona-list hide-scrollbar">
                {
                  //   filteredData?.length === 1 ? (
                  //   handleAddAtName(filteredData[0], true)
                  // ) :
                  filteredData?.length > 0 ? (
                    filteredData
                      .sort((first, second) => {
                        return first.toLowerCase() >
                          second.toString().toLowerCase()
                          ? 1
                          : -1;
                      })
                      .map((item, index) => (
                        <li
                          className="at-personas"
                          key={index}
                          onClick={() => handleAddAtName(item, false)}
                        >
                          {item}
                        </li>
                      ))
                  ) : (
                    <li className="at-personas" key={"blank"}>
                      {"- No matches -"}
                    </li>
                  )
                }
              </ul>
            )}
          </div>

          <button
            id="msg_send_button"
            ref={ref_msg_send_button}
            className="UI-button-icon"
            // type="send"
            onClick={() =>
              handleOnClick(msgObject, topicState, imageList, attachingFile)
            }
            style={{
              fontSize: "1.5rem"
            }}
          >
            <RiSendPlaneFill
              style={{
                transform: "translate(-2px, 0px)"
              }}
            />
          </button>
          <input
            id="input_image_select"
            ref={ref_input_image_select}
            type="file"
            accept="image/*"
            multiple={true}
            onChange={(event) => {
              updateImages([...event.target.files]);
            }}
            style={{ display: "none" }}
          />
          <input
            id="input_file_select"
            ref={ref_input_file_select}
            type="file"
            accept="*"
            multiple={true}
            onChange={(event) => {
              handleAddFile(event);
              // setSelectedFiles([...selectedFiles, event.target?.files]);
              // event.target.value = "";
            }}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UIMessageSend, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
