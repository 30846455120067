import React, { useContext, useEffect } from "react";

import ThumbsContext from "contexts/contextThumbs";
import DatabaseContext from "data/contextDatabase";

import { useDescriptorsDex, useLiveDex, useThumbsDex } from "hooks/dexHooks";
import TopicsContext from "contexts/contextTopics";
import { applyDescriptors } from "./descriptors";
import PersonasContext from "contexts/contextPersonas";
import { shouldLog } from "utils/shouldLog";

const timer = new Date().getTime();

const DescriptorSetter = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const { liveDescriptorsB } = useLiveDex(databaseState.dexUser);
  const { liveDescriptors } = useDescriptorsDex(databaseState.dexUser);
  const { topicsDispatch } = useContext(TopicsContext);
  const { personasDispatch } = useContext(PersonasContext);

  useEffect(() => {
    shouldLog() && console.log("DescriptorSetter 1");
    let mounted = true;
    shouldLog() && console.log("liveDescriptorsB", liveDescriptorsB);
    if (liveDescriptorsB && mounted) {
      topicsDispatch({
        type: "SET_DESCRIPTORSB",
        values: { descriptorsB: liveDescriptorsB }
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveDescriptorsB]);

  useEffect(() => {
    shouldLog() && console.log("DescriptorSetter 2");
    let mounted = true;
    if (liveDescriptors && mounted) {
      personasDispatch({
        type: "SET_DESCRIPTORS",
        values: { descriptors: liveDescriptors }
      });

      databaseState.dexUser &&
        applyDescriptors(databaseState.dexUser, liveDescriptors);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveDescriptors]);

  shouldLog() && console.log("DescriptorSetter timer", new Date().getTime() - timer);
  return null;
};

export default React.memo(DescriptorSetter, (prevProps, nextProps) => {
  return true;
});
