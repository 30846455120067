import { timestamp, timestampCutoff } from "hooks/helper";
import PouchDB from "pouchdb";
import isEqual from "react-fast-compare";
import { VscDebugStepOver } from "react-icons/vsc";
import { isUnread } from "utils/isRead";
import { shouldLog } from "utils/shouldLog";

const update_meta = async (db, meta_doc, msg) => {
  let smid_removed = meta_doc.have_item_list.filter((i) => i.smid !== msg.smid);
  let idx_removed = smid_removed.map((i) => i.msg_idx);
  meta_doc.have_idx_list = [...idx_removed, msg.msg_idx].sort((a, b) => a - b);
  meta_doc.have_item_list = [
    ...smid_removed,
    { msg_idx: msg.msg_idx, smid: msg.smid }
  ].sort((a, b) => a.msg_idx - b.msg_idx);
  meta_doc.idxListUnpinned = meta_doc.idxListUnpinned
    ? meta_doc.idxListUnpinned.filter((i) => idx_removed.includes(i))
    : [];
  msg.parameters?.pin &&
    (meta_doc.idxListUnpinned = [...meta_doc.idxListUnpinned, msg.msg_idx].sort(
      (a, b) => a - b
    ));

  meta_doc.itemListUnread = meta_doc.itemListUnread.filter((i) =>
    meta_doc.have_idx_list.includes(i.msg_idx)
  );
  if (isUnread(msg))
    meta_doc.itemListUnread.push({ msg_idx: msg.msg_idx, smid: msg.smid });
  else
    meta_doc.itemListUnread = meta_doc.itemListUnread.filter(
      (i) => msg.msg_idx !== i.msg_idx
    );

  meta_doc.itemListUnread.sort((a, b) => a.msg_idx - b.msg_idx);
  meta_doc.unreadCount = meta_doc.itemListUnread.length;
  meta_doc.last_msg_idx = Math.max(...meta_doc.have_idx_list);
  meta_doc.latest = Math.max(...meta_doc.have_idx_list, meta_doc.latest);
  let read_idx_list = meta_doc.have_idx_list.filter(
    (i) => !meta_doc.itemListUnread.map((j) => j.msg_idx).includes(i)
  );
  meta_doc.latestRead = Math.max(...read_idx_list);
  if (msg.ts_read > (meta_doc.latestReadTime || "")) {
    meta_doc.latestReadTime = msg.ts_read;
  }
  meta_doc.last_visible_date =
    meta_doc.last_visible_date > (msg.ts_origin_server || msg.ts_sender)
      ? meta_doc.last_visible_date
      : msg.ts_origin_server || msg.ts_sender;
  await db.topic_metadata.put({ ...meta_doc });
};

export const dex_action = (action) => {
  switch (action.type) {
    case "DEX_PUT":
      shouldLog() && console.log("DEX_PUT", Date.now(), action);
      action.values.db &&
        action.values.table &&
        action.values.db
          .table(action.values.table)
          .put(action.values.doc)
          .then(() => action.values.cb && action.values.cb());
      break;
    case "DEX_PUT_IF_DIFF":
      shouldLog() && console.log("DEX_PUT_IF_DIFF", Date.now(), action);
      action.values.db
        .transaction("rw", action.values.table, async () => {
          let match = await action.values.db
            .table(action.values.table)
            .get(action.values.primaryKey);
          if (
            !match ||
            !isEqual(
              { ...match, time: undefined },
              { ...action.values.doc, time: undefined }
            )
          ) {
            shouldLog() &&
              console.log(
                "DEX_PUT_IF_DIFF putting",
                Date.now(),
                match,
                action.values.doc
              );
            action.values.db &&
              action.values.table &&
              action.values.db
                .table(action.values.table)
                .put(action.values.doc)
                .then(() => action.values.cb && action.values.cb());
          }
        })
        .then(() => {
          shouldLog() && console.log("DEX_UPSERT_MATCH committed");
        })
        .catch((err) => {
          console.error("DEX_UPSERT_MATCH error", err.stack);
        });
      break;
    case "DEX_UPSERT_MATCH": // uses a function to update all matching items. If none exist htn insert one with the matching function
      if (action.values.db) {
        action.values.db
          .transaction(
            "rw",
            action.values.db.table(action.values.table),
            async () => {
              let matches = await action.values.db
                .table(action.values.table)
                .where(action.values.match)
                .toArray((doc) => doc);
              shouldLog() &&
                console.log("DEX_UPSERT_MATCH_", Date.now(), matches);
              if (matches?.length > 0)
                matches.forEach((match) => {
                  let newVal = action.values.function(match);
                  !isEqual(newVal, match) &&
                    action.values.db
                      .table(action.values.table)
                      .put(action.values.function(newVal));
                });
              else {
                let newVal = action.values.function(action.values.match);
                action.values.db.table(action.values.table).put(newVal);
              }
            }
          )
          .then(() => {
            shouldLog() && console.log("DEX_UPSERT_MATCH committed");
          })
          .catch((err) => {
            console.error("DEX_UPSERT_MATCH error", err.stack);
            action.values.db
              .table(action.values.table)
              .put(action.values.function(action.values.match));
          });
      }
      break;
    case "DEX_DELETE_RECORD":
      action.values.db &&
        action.values.table &&
        action.values.db
          .table(action.values.table)
          .delete(action.values.primaryKey);
      break;
    case "DEX_UPSERT_KEYS_LATEST_TRANS":
      shouldLog() &&
        console.log(
          "DEX_UPSERT_KEYS_LATEST_TRANS",
          action.values.db.version,
          Date.now(),
          action
        );
      if (action.values.db) {
        let keysTrans = action.values.db.table(action.values.table).schema;
        let filterTrans = [
          keysTrans.primKey.name,
          ...keysTrans.indexes.map((idx) => idx.name)
        ].filter((v) => action.values.match_keys.includes(v));
        let filterObjTrans = Object.fromEntries(
          Object.entries(action.values.doc).filter(([key]) =>
            filterTrans.includes(key)
          )
        );
        shouldLog() &&
          console.log(
            "DEX_UPSERT_KEYS_LATEST_TRANS filterObj ",
            filterObjTrans
          );
        action.values.db
          .transaction(
            "rw",
            action.values.db.table(action.values.table),
            async () => {
              let doc = await action.values.db
                .table(action.values.table)
                .get(filterObjTrans);
              shouldLog() &&
                console.log("DEX_UPSERT_KEYS_LATEST_TRANS got doc", doc);
              shouldLog() &&
                console.log("DEX_UPSERT_KEYS_LATEST_TRANS new doc", {
                  ...doc,
                  ...action.values.doc
                });
              if (
                !doc ||
                action.values.doc[action.values.latest_key] >
                  doc[action.values.latest_key]
              )
                await action.values.db
                  .table(action.values.table)
                  .put({ ...doc, ...action.values.doc });
            }
          )
          .then(() => {
            shouldLog() &&
              console.log("DEX_UPSERT_KEYS_LATEST_TRANS committed");
          })
          .catch((err) => {
            console.error("DEX_UPSERT_KEYS_LATEST_TRANS error", err.stack);
            action.values.db.table(action.values.table).put(action.values.doc);
          });
      }
      break;
    case "DEX_UPDATE_TOPIC_METADATA":
      action.values.db.transaction(
        "rw",
        [
          "account",
          "message",
          "topic_metadata",
          // "topic_metadata_buffer",
          "latest_message"
        ],
        async () => {
          //???
          let msg = action.values.jMessage;
          let filter = { mtopic: msg.mtopic, mpersona: msg.recipient };
          let doc = await action.values.db.topic_metadata.get(filter);
          if (
            // msg_idx is not in have_idx_list
            doc &&
            !doc.have_idx_list.includes(msg.msg_idx)
          ) {
            await updateMetaMessage(action.values.db, doc, msg, false);
          }
        }
      );
      break;
    case "DEX_MULTI_MARK_READ":
      // TODO: DEX update the topic_metadata
      shouldLog() && console.log("DEX_MULTI_MARK_READ action", action);
      let ts_read = timestamp();
      action.values.db &&
        action.values.db
          .transaction("rw", ["message", "topic_metadata"], async () => {
            let returnVal = [];
            if (action.values.global) {
              await action.values.db.message
                .filter(
                  (m) =>
                    !["t", "true", true].includes(m.read) &&
                    (action.values.mtopics || [m.mtopic]).includes(m.mtopic)
                )
                .modify((m) => {
                  m.read = "true";
                  m.ts_read = ts_read;
                });
              await action.values.db.topic_metadata
                .filter((t) =>
                  (action.values.mtopics || [t.mtopic]).includes(t.mtopic)
                )
                .modify((t) => {
                  t.itemListUnread = [];
                  t.unreadCount = 0;
                  t.latestRead = t.have_idx_list
                    ? Math.max(...t.have_idx_list, 0)
                    : 0;
                  t.latestReadTime = ts_read;
                });
              return returnVal;
            } else {
              let keys = action.values.messages.reduce((acc, message) => {
                let msgList = [];
                if (!action.values.all) {
                  msgList = [[message.mtopic, message.recipient, message.smid]];
                } else {
                  msgList = action.values.all
                    .filter((mpersona) => mpersona)
                    .map((mpersona) => [
                      message.mtopic,
                      mpersona,
                      message.smid
                    ]);
                }
                return [...acc, ...msgList];
              }, []);
              let docs = await action.values.db.message
                .where(["mtopic", "recipient", "smid"])
                .anyOf(keys)
                .filter((d) => !["t", "true", true].includes(d.read))
                .toArray();
              shouldLog() &&
                console.log("DEX_MULTI_MARK_READ updating docs", docs);
              for (let doc of docs) {
                for (let mp of [
                  ...new Set([...action.values.all, doc.recipient])
                ]) {
                  let stored_doc = await action.values.db.message.get({
                    mtopic: doc.mtopic,
                    recipient: mp,
                    smid: doc.smid
                  });
                  if (stored_doc) {
                    stored_doc.read = "t";
                    stored_doc.ts_read = ts_read;
                    await action.values.db.message.put({ ...stored_doc });
                    //???
                    let filter = {
                      mtopic: stored_doc.mtopic,
                      mpersona: stored_doc.recipient
                    };
                    let meta_doc = await action.values.db.topic_metadata.get(
                      filter
                    );
                    if (meta_doc && stored_doc.msg_idx) {
                      await update_meta(action.values.db, meta_doc, {
                        ...stored_doc
                      });
                    }
                  }
                }
              }
              return returnVal;
            }
          })
          .then((r) => {
            shouldLog() && console.log("DEX_MULTI_MARK_READ committed", r);
            return r;
          })
          .catch((err) => {
            console.error("DEX_MULTI_MARK_READ error", err.stack);
            return [];
          });
      break;
    case "DEX_DELETE_WHERE":
      action.values.db &&
        action.values.table &&
        action.values.db
          .table(action.values.table)
          .where(action.values.where)
          .delete();
      break;
    case "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS":
      shouldLog() &&
        console.log(
          "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS",
          action.values.db.version,
          Date.now(),
          action
        );
      if (action.values.db) {
        let keysTrans = action.values.db.table(action.values.table).schema;
        action.values.db
          .transaction(
            "rw",
            action.values.db.table(action.values.table),
            async () => {
              for (let newDoc of action.values.docs) {
                let filterTrans = [
                  keysTrans.primKey.name,
                  ...keysTrans.indexes.map((idx) => idx.name)
                ].filter((v) => action.values.match_keys.includes(v));
                let filterObjTrans = Object.fromEntries(
                  Object.entries(newDoc).filter(([key]) =>
                    filterTrans.includes(key)
                  )
                );
                let doc = await action.values.db
                  .table(action.values.table)
                  .get(filterObjTrans);
                shouldLog() &&
                  console.log(
                    "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS got doc",
                    doc
                  );
                shouldLog() &&
                  console.log("DEX_MULTI_UPSERT_KEYS_LATEST_TRANS new doc", {
                    ...doc,
                    ...newDoc
                  });
                if (
                  !doc ||
                  newDoc[action.values.latest_key] >
                    doc[action.values.latest_key]
                )
                  await action.values.db
                    .table(action.values.table)
                    .put({ ...doc, ...newDoc });

                shouldLog() &&
                  console.log(
                    "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS notify",
                    newDoc.notify
                  );
              }
            }
          )
          .then(() => {
            shouldLog() &&
              console.log("DEX_MULTI_UPSERT_KEYS_LATEST_TRANS committed");
          })
          .catch((err) => {
            console.error(
              "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS error",
              err.stack
            );
            action.values.db.table(action.values.table).put(action.values.doc);
          });
      }
      break;
    case "DEX_MODIFY_TRANS":
      let dbM = action.values.db;
      let dbT = action.values.table;
      action.values.db.transaction("rw", dbM[dbT], async () => {
        let filter = action.values.filter;
        let transform = action.values.transform;
        let recs = await dbM[dbT].filter(filter).toArray((r) => r);
        let changedRecs = recs.reduce((acc, rec) => {
          let modRec = transform(rec);
          return !isEqual(modRec, rec) ? [...acc, modRec] : acc;
        }, []);
        dbM[dbT].bulkPut(changedRecs).then(function (lastKey) {});
        // add the following if we want to allow partial success
        // .catch(Dexie.BulkError, function (e) {
        //   // Explicitely catching the bulkAdd() operation makes those successful
        //   // additions commit despite that there were errors.
        //   console.error ("DEX_MODIFY_TRANS faiures", e.failures);
        //   });
      });
      break;

    case "DEX_HIDE_TOPIC_MESSAGES":
      let db = action.values.db;
      db.message
        .where(["mtopic", "recipient"])
        .equals([action.values.mtopic, action.values.mpersona])
        .modify((m) => (m.hide = true));
      db.latest_message
        .where(["mtopic", "recipient"])
        .equals([action.values.mtopic, action.values.mpersona])
        .delete();
      break;

    default:
      break;
  }
};



export const migrateCredentials = (dexAdmin) => {
  // open pouch database
  let dbUser = new PouchDB(`dbUser`, {
    size: 1,
    revs_limit: 0,
    auto_compaction: true
  });
  // fetch login credentials
  dbUser
    .allDocs({ include_docs: true })
    .then((docs) => {
      docs.rows.forEach((row) => {
        if (row.id === `client` && row.doc.cid) {
          let dexClientItem = {
            key: `client`,
            cid: row.doc.cid
          };
          shouldLog() && console.log(`pouch client`, dexClientItem);
          dexAdmin.store.put(dexClientItem).then((r) => {
            if (r === `client`) {
              dbUser.put({ ...row.doc, _deleted: true });
            }
          });
        }
        if (row.id === `user` && row.doc.uid) {
          let dexUserItem = { key: `user`, uid: row.doc.uid };
          row.doc.password && (dexUserItem.password = row.doc.password);
          row.doc.key && (dexUserItem.muid = row.doc.key);
          row.doc.key && (dexUserItem.dbKey = row.doc.key);
          row.doc.jwt && (dexUserItem.jwt = row.doc.jwt);
          row.doc.uid && (dexUserItem.uid = row.doc.uid);
          if (dexUserItem.uid) {
            shouldLog() && console.log(`pouch user`, dexUserItem);
            dexAdmin.store.put(dexUserItem).then((r) => {
              if (r === dexUserItem.key) {
                // dbUser
                // .destroy()
                // .then(() => {
                //   shouldLog() && console.log(`deleted pouch dbUser`);
                // })
                // .catch(function (err) {
                //   console.log(err);
                // });
                dbUser.put({ ...row.doc, _deleted: true });
              }
            });
          }
        }
      });
    })
    .catch(function (err) {
      console.log(`pouch error`, err);
    });

  try {
    indexedDB?.databases &&
      indexedDB.databases().then((r) => {
        shouldLog() && console.log(`delete r pouchdb`, r);
        r.forEach((db) => {
          shouldLog() &&
            console.log(
              `delete plain pouchdb`,
              db.name?.slice(0, 7),
              db.name.slice(7)
            );
          if (
            db.name !== `_pouch_dbUser` &&
            db.name?.slice(0, 7) === `_pouch_`
          ) {
            new PouchDB(db.name.slice(7))
              .destroy()
              .then(() => {
                shouldLog() && console.log(`deleted pouchdb`, db.name.slice(7));
              })
              .catch(function (err) {
                console.log(err);
              });
          }
        });
      });
  } catch {
    console.log("failed database cleanup");
  }

  dbUser
    .changes({
      filter: (doc) => doc._deleted
    })
    .on("change", (change) => {
      shouldLog() && console.log(`pouch deleting`, change.id);
      dexAdmin
        .transaction("rw", "store", async () => {
          let docPouch = await dexAdmin.store.get(`pouch`);
          shouldLog() && console.log(`pouch docPouch`, docPouch, change.id);
          let pouchDBname = change.id.replace(`54db4`, ``);
          await dexAdmin.store.put({
            key: `pouch`,
            value: [...new Set([...(docPouch?.value || []), pouchDBname])]
          });

          shouldLog() && console.log(`pouch name`, pouchDBname);
          let pouchList = [
            pouchDBname,
            `${pouchDBname}_master`,
            `${pouchDBname}_latest`,
            `${pouchDBname}_unread`,
            `${pouchDBname}_messages`
          ];
          pouchList.forEach((p) =>
            new PouchDB(p)
              .destroy()
              .then(() => {
                shouldLog() && console.log(`deleted stored pouchdb`, p);
              })
              .catch(function (err) {
                console.log(err);
              })
          );

          return;
        })
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
      return;
    });
  // fetch cid (to reuse or cancel server)
  // fetch historical user names to delete databases
  // store in dexAdmin
  // delete pouch databases
};

export const setMeta = (db, mtopic, mpersona, key, transform) => {
  // get transaction on meta table
  // list consists of db, table, {mtopic, recipient, key}, function
  db.transaction("rw", db.topic_metadata, async () => {
    let doc = await db.topic_metadata
      .get({
        mtopic: mtopic,
        mpersona: mpersona
      })
      .then(
        (d) =>
          d || {
            mtopic: mtopic,
            mpersona: mpersona
          }
      )
      .catch((e) => {
        return {
          mtopic: mtopic,
          mpersona: mpersona
        };
      });
    doc[key] = transform(doc[key]);
    // console.log("DEX_META newVal", doc);
    await db.topic_metadata.put(doc);
  })
    .then(() => {
      // console.log("DEX_META committed");
    })
    .catch((err) => {
      console.error("DEX_META error", err.stack);
    });
};

export const setMetaAll = (db, mtopic, key, transform) => {
  // get transaction on meta table
  // list consists of db, table, {mtopic, recipient, key}, function
  db.transaction("rw", db.topic_metadata, async () => {
    let docs = await db.topic_metadata
      .where({
        mtopic: mtopic
      })
      .toArray((d) => d);
    // console.log("DEX_META docs", docs);
    for (const doc of docs) {
      doc[key] = transform(doc[key]);
      // console.log("DEX_META newVal", doc);
      await db.topic_metadata.put(doc);
    }
  })
    .then(() => {
      // console.log("DEX_META committed");
    })
    .catch((err) => {
      console.error("DEX_META error", err.stack);
    });
};

export const updateMetaTopic = async (db, topic) => {
  db.message
    .where({ mtopic: topic.mtopic, recipient: topic.mpersona })
    .toArray((doc) => doc)
    .then((messages) => {
      let metadata = messages.reduce(
        (acc, message) => {
          if (message.msg_idx && isUnread(message)) {
            acc.itemListUnread.push({
              msg_idx: parseInt(message.msg_idx, 10),
              smid: message.smid
            });
            acc.itemListUnread.sort((a, b) => a.msg_idx - b.msg_idx);
            acc.unreadCount = acc.itemListUnread?.length || acc.unreadCount;
          } else {
            parseInt(message.msg_idx, 10) > acc.latestRead &&
              (acc.latestRead = parseInt(message.msg_idx, 10));
          }
          if (message.ts_read > (acc.latestReadTime || "")) {
            acc.latestReadTime = message.ts_read;
          }
          message.msg_idx &&
            acc.have_idx_list.push(parseInt(message.msg_idx, 10));
          acc.have_idx_list.sort((a, b) => a - b);
          message.msg_idx &&
            acc.have_item_list.push({
              msg_idx: parseInt(message.msg_idx, 10),
              smid: message.smid
            });
          acc.have_item_list.sort((a, b) => a.msg_idx - b.msg_idx);
          if (
            message.msg_idx &&
            !message.parameters?.pin &&
            !message.hide &&
            !["false", false].includes(message.parameters?.display) &&
            !message._deleted
          )
            acc.idxListUnpinned.push(parseInt(message.msg_idx, 10));
          acc.idxListUnpinned.sort((a, b) => a - b);
          message.msg_idx &&
            parseInt(message.msg_idx, 10) > acc.latest &&
            (acc.latest = parseInt(message.msg_idx, 10));
          message.msg_idx &&
            (message?.ts_origin_server || "1970") > acc.last_visible_date &&
            (acc.last_visible_date = message?.ts_origin_server || "1970");
          return acc;
        },
        {
          mtopic: topic.mtopic,
          mpersona: topic.mpersona,
          latestRead: 0,
          latestReadTime: undefined,
          latest: 0,
          unreadCount: 0,
          itemListUnread: [],
          have_idx_list: [],
          have_item_list: [],
          visible_idx: topic.visible_idx,
          idxListUnpinned: [],
          pinned: topic.pinned,
          archived: topic?.subprops?.archived,
          last_msg_idx: topic.last_msg_idx,
          last_visible_date: topic.last_visible_date || "1970"
        }
      );
      db.topic_metadata.put(metadata);
    });
};

const update_as_read = async (db, mtopic, mpersona, indices) => {
  // mark all messages prior to msg_idx as "read"
  let keys = indices.map((i) => [mtopic, mpersona, i.smid]);
  console.log("!!! update_as_read", db, mtopic, mpersona, indices, keys);
  db.message
    .where(["mtopic", "recipient", "smid"])
    .anyOf(keys)
    .modify((m) => (m.read = true));
  // for (let idx of indices) {
  //   let msg = await db.message.get({
  //     mtopic: mtopic,
  //     recipient: mpersona,
  //     smid: idx.smid,
  //     msg_idx: idx.msg_idx
  //   });
  //   if (msg && isUnread(msg)) {
  //     msg.read = true;
  //     await db.message.put(msg);
  //   }
  // }
};

export const updateMetaMessage = async (db, meta_doc, msg, serviceWorker) => {
  let smid_removed = meta_doc.have_item_list.filter((i) => i.smid !== msg.smid);
  let idx_removed = smid_removed.map((i) => parseInt(i.msg_idx, 10));
  meta_doc.have_idx_list = [...idx_removed, parseInt(msg.msg_idx, 10)].sort(
    (a, b) => a - b
  );
  meta_doc.have_item_list = [
    ...smid_removed,
    { msg_idx: parseInt(msg.msg_idx, 10), smid: msg.smid }
  ].sort((a, b) => a.msg_idx - b.msg_idx);
  if (msg.parameters?.pin) {
    meta_doc.pinned = [
      ...new Set([
        ...meta_doc.pinned.filter((i) => meta_doc.have_idx_list.includes(i)),
        parseInt(msg.msg_idx, 10)
      ])
    ].sort((a, b) => a - b);
  } else {
    meta_doc.idxListUnpinned = [
      ...new Set([
        ...meta_doc.idxListUnpinned.filter((i) =>
          meta_doc.have_idx_list.includes(i)
        ),
        parseInt(msg.msg_idx, 10)
      ])
    ].sort((a, b) => a - b);
  }
  meta_doc.itemListUnread = meta_doc.itemListUnread.filter((i) =>
    meta_doc.have_idx_list.includes(parseInt(i.msg_idx, 10))
  );
  if (msg.msg_idx && isUnread(msg))
    meta_doc.itemListUnread.push({
      msg_idx: parseInt(msg.msg_idx, 10),
      smid: msg.smid
    });
  else {
    let indices_to_mark_read = meta_doc.itemListUnread.filter(
      (i) => i.msg_idx <= parseInt(msg.msg_idx || 0, 10)
    );

    meta_doc.itemListUnread = meta_doc.itemListUnread.filter(
      (i) => !(i.msg_idx < parseInt(msg.msg_idx, 10) && i.smid === msg.smid)
    );
    await update_as_read(db, msg.mtopic, msg.mpersona, indices_to_mark_read);
  }

  meta_doc.itemListUnread.sort((a, b) => a.msg_idx - b.msg_idx);
  meta_doc.unreadCount = meta_doc.itemListUnread.length;
  meta_doc.latest = Math.max(...meta_doc.have_idx_list, meta_doc.latest);
  let read_idx_list = meta_doc.have_idx_list.filter(
    (i) => !meta_doc.itemListUnread.map((j) => j.msg_idx).includes(i)
  );
  meta_doc.latestRead = Math.max(...read_idx_list);
  if (msg.ts_read > (meta_doc.latestReadTime || "")) {
    meta_doc.latestReadTime = msg.ts_read;
  }
  meta_doc.last_visible_date =
    !msg.msg_idx ||
    meta_doc.last_visible_date > (msg.ts_origin_server || msg.ts_sender)
      ? meta_doc.last_visible_date
      : msg.ts_origin_server || msg.ts_sender;
  msg.msg_idx &&
    (meta_doc.visible_idx = [
      ...new Set([...meta_doc.visible_idx, msg.msg_idx])
    ].sort((a, b) => a - b));
  serviceWorker && (await db.topic_metadata_buffer.put({ ...meta_doc })); // needed for when sw populates
  await db.topic_metadata.put({ ...meta_doc });
  // must the preview message change?

  let shouldStore =
    (msg.msg_idx &&
      msg.parameters?.pin &&
      msg.msg_idx >= Math.max(...(meta_doc.pinned || []), 0)) ||
    (msg.msg_idx >= Math.max(...(meta_doc?.have_idx_list || []), 0) &&
      !(meta_doc?.pinned?.length > 0));

  if (shouldStore) {
    await db.latest_message.put(msg).then((r) => {});
  }
  // and now for the topic record
  if (msg.msg_idx) {
    let topics = await db.account.get({ type: "topics" });
    if (topics.value) {
      let item = topics.value.reduce(
        (acc, t) =>
          t.mpersona === msg.recipient && t.mtopic === msg.mtopic
            ? { ...t }
            : acc,
        undefined
      );
      let change = false;
      if (item && parseInt(item.last_msg_idx, 10) < msg.msg_idx) {
        item.last_msg_idx = parseInt(msg.msg_idx, 10);
        change = true;
      }
      if (item && item.last_visible_date < meta_doc.last_visible_date) {
        item.last_visible_date = meta_doc.last_visible_date;
        change = true;
      }
      if (item && !item.visible_idx.includes(msg.msg_idx)) {
        item.visible_idx.push(msg.msg_idx);
        change = true;
      }
      if (item && msg.parameters?.pin && !item.pinned.includes(msg.msg_idx)) {
        item.pinned.push(msg.msg_idx);
        change = true;
      }
      if (change) {
        topics.value = [
          ...topics.value.filter(
            (t) => !(t.mpersona === msg.recipient && t.mtopic === msg.mtopic)
          ),
          item
        ];
        await db.account.put(topics);
      }
    }
  }
};

export const cleanMessages = (
  dexUser,
  topic_metadata,
  waitAtLeastMinutes,
  logging
) =>
  new Promise((resolve, reject) => {
    let f = async () => {
      let count = 0;
      if (dexUser?.message) {
        let maintenance = await dexUser.account.get("maintenance");
        if (
          !maintenance?.last_clean ||
          maintenance?.last_clean < timestampCutoff(waitAtLeastMinutes)
        ) {
          let metaTopicsToTrim = topic_metadata.filter((m) => {
            logging && console.log("[cleanMessages] metaTopicsToTrim loop", m);
            let trimLength =
              m.last_visible_date < timestampCutoff(28 * 24 * 60)
                ? 1
                : m.visible_idx.length;
            return (
              (m.have_idx_list.filter((i) => !m.pinned?.includes(i))?.length ||
                0) > trimLength
            );
          });
          logging &&
            console.log("[cleanMessages] metaTopicsToTrim", metaTopicsToTrim);
          if (metaTopicsToTrim.length > 0) {
            metaTopicsToTrim.sort(
              (a, b) => a.last_visible_date > b.last_visible_date
            );
            for (let t of metaTopicsToTrim) {
              let trimLength =
                t.last_visible_date < timestampCutoff(28 * 24 * 60)
                  ? 1
                  : t.visible_idx.length;
              logging &&
                console.log(
                  "[cleanMessages] vis",
                  trimLength,
                  t.last_visible_date,
                  timestampCutoff(28 * 24 * 60),
                  t
                );
              let maxIdx = Math.max(...t.visible_idx, ...t.pinned, 0);
              // if (window.focus) break;
              await dexUser.message
                .where(["mtopic", "recipient"])
                .equals([t.mtopic, t.mpersona])
                .filter(
                  (m) =>
                    ![
                      ...t.visible_idx
                        .filter((i) => !t.pinned.includes(i))
                        .slice(-trimLength),
                      ...t.pinned
                    ].includes(m.msg_idx) &&
                    (m.msg_idx || 0) < maxIdx &&
                    !isUnread(m)
                )
                .delete();
              // update the topic_metadata
              await updateMetaTopic(dexUser, t);
              count++;
              logging &&
                console.log("[cleanMessages]", t.mtopic, t.mpersona, maxIdx);
            }
            logging && console.log("[cleanMessages] stop", timestamp());
          }
          maintenance = maintenance || { type: "maintenance" };
          maintenance.last_clean = timestamp();
          await dexUser.account.put(maintenance);
        }
      }
      return count;
    };
    f().then((r) => resolve(r));
  });
