import React, { useContext, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import "ui/UIPersonaCard.css";
import IconDelete from "ui/Dropdown/iconDelete";
// import IconDropDown from "ui/Dropdown/iconDropDown";
import GlobalContext from "contexts/context";
import plusLogo from "./icons/plusonly.png"
import PersonasContext from "contexts/contextPersonas";

const CardHeader = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const { preTitle, title, image, size } = props.header;

  let mpersona = props.header.item.mpersona;
  const enlargeImg = (img) => {
    if (img) {
      img.style.transform = `scale(${56 / 48})`;
      // Animation effect 
      img.style.transition = "transform 0.25s ease";
      img.style.filter = "grayscale(0)"
    }
  }

  const reduceImg = (img) => {
    if (img) {
      img.style.transform = `scale(${1})`;
      // Animation effect 
      img.style.transition = "transform 0.25s ease";
      img.style.filter = "grayscale(1)"
    }
  }

  const handleThisOnClick = () => {
    reduceImg(document.getElementById(globalState.persona?.mpersona))
    let newPers = personasState.personas?.filter((persona) =>
      persona.mpersona === props.header?.item?.mpersona
    )
    enlargeImg(document.getElementById(props.header?.item?.mpersona))
    if (newPers[0])
      globalDispatch({
        type: "SET_PERSONA",
        values: { persona: newPers[0] }
      });
  }

  useEffect(() => {
    if (globalState.persona?.mpersona === props.header.item?.mpersona)
      enlargeImg(document.getElementById(props.header?.item?.mpersona))
  }, []
  )

  return (
    <header id={"header_" + mpersona}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}>
        <div className="dummy-wrapper-position-img-and-topiccnt"
          style={{
            display: "flex",
            position: "relative"
          }}
        >
          <div
            id={mpersona}
            className="persona-card-header-wrapper"
            // {globalState.persona?.mpersona === props.header.item?.mpersona ? {} :
            // "persona-card-header-wrapper"}
            style={
              globalState.persona?.mpersona === props.header.item?.mpersona
                ? {
                  position: "relative",
                  filter: "grayscale(0)"
                }
                : {
                  position: "relative",
                  filter: "grayscale(1)"
                }}
            onClick={() => handleThisOnClick()}
          >
            <img
              id={"image_" + mpersona}
              className="persona-card-header-unselected"
              //   globalState.persona?.mpersona === props.header.item?.mpersona
              //     ? "persona-card-header-selected"
              //     : "persona-card-header-unselected"
              // }
              src={(props.header.item.mpersona === "") ? plusLogo : image}
              alt="Logo"
              height={40}
              style={{
                zIndex: "5",
              }}
            />
          </div>
          <div
            className={props.header.item.topicCnt
              ? "persona-topic-count"
              : "persona-topic-count-invisible"}
            style={{
              position: "absolute",
              zIndex: "10",
              top: "0",
              right: "0"
            }}
          >
            {
              props.header.item.topicCnt
                ? props.header.item.topicCnt
                : "0"
            }
          </div>
        </div>
      </div>
      <p className="persona-card-header--pre-title" type={size}>
        {preTitle || ""}
      </p>
      {title && (
        <h4 className="persona-card-header--title" type={size}>
          {title}
        </h4>
      )}
    </header>
  );
};

const Button = (props) => {
  const { button, size } = props;
  return (
    <button
      className="persona-button button-primary"
      type={size}
      onClick={button?.onClick}
    >
      <i>
        <FaChevronRight />
      </i>
      {button.text}
    </button>
  );
};

const CardBody = (props) => {
  const { body, size, background_Colour } = props;
  const { title, text, button } = body;
  return (
    <div
      className="persona-card-body"
      type={size}
      style={{ backgroundColor: background_Colour }}
    >
      <h2 className={props.selected ? "persona_name_selected" : "persona_name"}>{title || ""}</h2>
    </div>
  );
};

const UIPersonaCard = (props) => {
  const { header, body, content, size, onClick } = props;
  let menuItems = [
    {
      icon: <IconDelete />,
      callback: () =>
        props.onDeletePersona(props.item.persona, props.item.mpersona),
    },
  ];

  let article = (
    <div>
      <article
        className={
          props.selected ? "persona-card persona-card-selected" : "persona-card"
        }
        type={size}
        //   {...useLongPress(() => props.item.onLongPress(), 2000)}
        onClick={props.selected ? props.item.onSecondClick : props.item.onClick}
      >
        {header && <CardHeader header={{ size: size, ...header, ...props }} />}
        {body && <CardBody body={body} size={size} selected={props.selected} />}
        {content}
      </article>
      {/* <IconDropDown className=".UI-icondropdown" menuItems={menuItems} /> */}
    </div>
  );

  return article;
};

export default UIPersonaCard;
