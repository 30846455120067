import worker from "workerize-loader!workers/rest.js"; // eslint-disable-line import/no-webpack-loader-syntax

export const workerPost = (url, key, data, fields) => {
  return new Promise((resolve, reject) => {
    const workerInstance = worker();

    workerInstance.addEventListener("message", (message) => {
      process.env.REACT_APP_DEBUG_THIS?.includes("dbl") &&
        console.log("[interfaceRest] workerPost message", message);
      switch (message.data.type) {
        case "response":
          message.data?.data && resolve(message.data.data);
          workerInstance.terminate();
          break;
        case "error":
          message.data?.data && resolve(message.data.data);
          workerInstance.terminate();
          break;
        default:
          //   reject(undefined);
          break;
      }
    });

    workerInstance.timeoutPost(url, key, data, fields);
  });
};

export const workerGet = (url, key, params) => {
  return new Promise((resolve, reject) => {
    const workerInstance = worker();
    workerInstance.addEventListener("message", (message) => {
      switch (message.data.type) {
        case "response":
          message.data?.data && resolve(message.data.data);
          workerInstance.terminate();
          break;
        case "error":
          message.data?.data && resolve(message.data.data);
          workerInstance.terminate();
          break;
        default:
          //   reject(undefined);
          break;
      }
    });

    workerInstance.timeoutGet(url, key, params);
  });
};

export const workerPut = (url, api_key, authorization, data) => {
  return new Promise((resolve, reject) => {
    const workerInstance = worker();

    workerInstance.addEventListener("message", (message) => {
      // console.log("[interfaceRest] workerPut message", message, data);
      if (
        data.type === "multipart/form-data" ||
        data.type.startsWith("application") ||
        data.type.startsWith("image") ||
        data.type.startsWith("text") ||
        data.type.startsWith("video") ||
        data.type.startsWith("audio")
      )
        switch (message.data?.type) {
          case "RPC":
            break;
          case "response":
            message.data?.data && resolve(message.data.data);
            workerInstance.terminate();
            break;
          case "error":
            message.data?.data && resolve(message.data.data);
            workerInstance.terminate();
            break;
          default:
            reject(undefined);
            workerInstance.terminate();
            break;
        }
      else {
        resolve(message.data);
        workerInstance.terminate();
      }
    });

    workerInstance.timeoutPut(url, api_key, authorization, data);
  });
};
