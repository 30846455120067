import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "ui/react-datepicker-override.css";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createTheme, ThemeProvider } from '@material-ui/core';
import "ui/UI.css";

const theme = createTheme({
  typography: {
    fontFamily: 'Segoe UI',
    // fontSize: 'var(--service_message_fontsize)',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Segoe UI';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#6A148E',
        textTransform: 'uppercase',
      },
      dayLabel: {
        textTransform: 'uppercase',
      },
    },
    // MuiPickersUtilsProvider: {
    //   styleOverrides: `
    //   @font-face {
    //     font-family: 'Segoe UI';
    //     font-style: italic;
    //     font-display: swap;
    //     font-weight: 400;
    //   }
    // `,
    // },
  },
});

const DateSelector = (props) => {
  // const [startDate, setStartDate] = useState(props.selected);
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const refDate = useRef(null);

  const handleChange = (e) => {
    setStartDate(e);
    props.onChange(e);
  };

  useEffect(() => {
    if (props.selected && props.selected !== "" && props.selected !== undefined)
      setStartDate(props.selected)
    else setStartDate(null)
  },
    [props.selected]
  )

  return (
    <div
      onClick={() => {
        setIsOpen(true);
      }}
      style={{
        direction: "ltr",
        display: isOpen ? true : false,
        marginLeft: "0.75rem"
      }}
    >
      {
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <DateTimePicker
              variant="dialog"
              placeholder="Enter date and time"
              sdisableToolbar
              format={props.dateFormat}
              ampm={false}
              label={props.label}
              value={startDate}
              disablePast={props.usePast == true ? false : true}
              disableFuture={props.usePast == true ? true : false}
              onChange={handleChange}
              onOpen={() =>
                props.setClickOutsideActive &&
                props.setClickOutsideActive(false)
              }
              onClose={() =>
                props.setClickOutsideActive && props.setClickOutsideActive(true)
              }
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      }
    </div>
  );
};

export default DateSelector;
