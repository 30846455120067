import React from "react";
import isEqual from "react-fast-compare";

const UIReceipt = (props) => {
  const { msg_idx, receipts } = props;
  let summary = receipts
    ? Object.keys(receipts)?.reduce(
        (acc, key) => {
          let r = {
            read:
              receipts[key]?.read === msg_idx &&
              key !== props.sender &&
              key !== props.recipient
                ? [...acc.read, receipts[key]?.persona]
                : acc.read,

            delivered:
              receipts[key]?.delivered === msg_idx &&
              receipts[key]?.read !== msg_idx &&
              key !== props.sender &&
              key !== props.recipient
                ? [...acc.delivered, receipts[key]?.persona]
                : acc.delivered
          };
          return r;
        },
        { read: [], delivered: [] }
      )
    : undefined;

  let receipt = <span></span>;

  const lim = 3;
  // visualisation idea: pie chart showing empty circle if not delivered/read
  if (summary?.read.length || summary?.delivered.length) {
    receipt = (
      <span>
        <pre>{`  ${
          summary?.read.length ? "read by: " + summary.read.slice(0, lim) : ""
        }${summary.read.length > lim ? "..." : ""}
        ${
          summary?.delivered.length
            ? "delivered to: " + summary.delivered.slice(0, lim)
            : ""
        }${summary.delivered.length > lim ? "..." : ""}`}</pre>
      </span>
    );
  }

  return receipt;
};

export default React.memo(UIReceipt, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
