import React, { useState, useContext, useRef, useEffect } from "react";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
import "./UI.css"
import { workerPost, workerGet } from "workers/interfaceRest";
import { randomString } from "hooks/helper";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import UserContext from "contexts/contextUser";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { WorldTextInput } from "utils/UtilsUniversal";
import { dex_action } from "data/dexUtils";

const UICreateNewPersona = (props) => {
  const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
  const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { userState } = useContext(UserContext);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [focusVal, setFocusVal] = useState(true);
  const [newPersonaName, setNewPersonaName] = useState("");
  let history = useHistory();

  const onCreatePersona = async (uid, password, accessToken, persona) => {
    const response = await workerPost(urlPersona, keyPersona, {
      type: "addpersona",
      uid: uid,
      pwd: password,
      persona: persona,
      token: accessToken,
      tag: "added from pwa",
      case_sensitive: "true",
      msgid: randomString(8)
    });
    workerGet(urlPersona, keyPersona, {
      type: "personalist",
      uid: uid,
      pwd: password,
      token: accessToken,
      msgid: randomString(8),
      version: globalState.version
    })
      .then((personaList) => {
        personaList?.list === undefined
          ? console.log()
          : dex_action({
              type: "DEX_PUT",
              values: {
                db: databaseState.dexUser,
                table: "account",
                doc: { type: "personas", value: personaList.list }
              }
            });
      })
      .catch((err) => {
        console.log("CATCH", err);
      });
    return response;
  };

  // const handleNewPersona = (e) => {
  //     setNewPersona(e.target.value);
  // }

  // useEffect(() => {
  //     addPersonaField.current.focus()
  //     return () => {
  //     }
  // }, [])

  useEffect(() => {
    window.addEventListener("popstate", props.cb);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.cb);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    // setModalClose(true)
    props.cb();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleNewPersonaName = (e) => {
    setNewPersonaName(e);
  };

  let modal = (
    <div id="create_persona_modal">
      <Modals
        title="New Persona name"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        //  switchScrollOff={}
        footer={
          <button
            className="modal-button-new"
            type="login"
            onClick={() => {
              onCreatePersona(
                globalState.uid,
                globalState.password,
                userState?.accessToken,
                newPersonaName
              );
              if (history.length >= 1) history.goBack();
              props.cb();
            }}
          >
            Create new Persona
          </button>
        }
        // footer={<p>footer</p>}
      >
        <div>
          {/* <div
                        className="UI-login-container"
                    >
                        <h4>New Persona name</h4>
                    </div> */}
          <div className="UI-login-container">
            {/*  style={{
                             minWidth: "150px",
                         }}> */}
            <WorldTextInput
              identity="createnewpersona"
              focus={focusVal}
              removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleNewPersonaName(e)}
              label={"New Persona name"}
              // required={true}
              placeholder="Choose a new persona name"
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={56}
              // active={worldTestTA?.length > 0 ? "true" : "false"}
              value={newPersonaName}
              // regex={/^[\w\.\- ]+$/i}
            />
            {/* <input
                            className="UI-input"
                            type="uid"
                            placeholder="Choose a new persona name"
                            name="persona_input"
                            required
                            ref={addPersonaField}
                            id="persona_input"
                            onChange={(e) => {
                                handleNewPersona(e)
                            }}
                        /> */}
          </div>
        </div>
      </Modals>
    </div>
  );
  return modal;
};

export default React.memo(UICreateNewPersona, (prevProps, nextProps) => {
    return prevProps === nextProps;
});
