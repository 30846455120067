import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "ui/UI.css";
import UILogin from "ui/UILogin";
import UIPersonaInfo from "ui/UIPersonaInfo";
import UITopicFrame from "./UITopicFrame";
import { isMobileTablet } from "hooks/helper";
import DatabaseContext from "data/contextDatabase";
import UIProfileModal from "./UIProfileModal";
import GlobalContext from "contexts/context";
import FocusContext from "contexts/contextFocus";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";
import { dex_action } from "data/dexUtils";

const homeURL = process.env.REACT_APP_URL || "app.enabled.world";

const UserInterface = (props) => {
  //-----------------------------
  const { databaseState } = useContext(DatabaseContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { focusState, focusDispatch } = useContext(FocusContext);

  useEffect(() => {
    var hidden, visibilityChange;

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    const onBlur = () => {
      focusDispatch({
        type: "SET_HASFOCUS",
        values: { hasFocus: false }
      });
    };

    const onFocus = () => {
      focusDispatch({
        type: "SET_HASFOCUS",
        values: { hasFocus: true }
      });
    };

    const checkVisibility = () => {
      if (document[hidden]) {
        onBlur();
      } else {
        onFocus();
      }
    };

    checkVisibility();

    if (isMobileTablet()) {
      document.addEventListener(visibilityChange, checkVisibility);
    } else {
      document.addEventListener(visibilityChange, checkVisibility);
      // window.addEventListener("focus", onFocus);
      // window.addEventListener("blur", onBlur);
    }
    return () => {
      document.removeEventListener(visibilityChange, checkVisibility);
    };
  }, [globalDispatch]);

  useEffect(() => {
    // clearing notifications when app has focus
    try {
      focusState.hasFocus &&
        navigator.serviceWorker &&
        navigator.serviceWorker
          .getRegistration(`https://${homeURL}/`)
          .then((reg) => {
            databaseState.dexUser &&
              databaseState.dexUser.notifications
                .filter((doc) => doc.opts)
                .toArray()
                .then((pending) => {
                  globalState.logging &&
                    console.log("[UserInterface] notifications", pending);
                  let promises = pending.map((notification) => {
                    globalState.logging &&
                      console.log("[UserInterface] notification", notification);
                    return reg.showNotification(
                      notification.title,
                      notification.opts
                    );
                  });
                  Promise.all(promises).then((results) => {
                    for (let t of [1000, 2000, 4000, 8000, 16000]) {
                      setTimeout(() => {
                        reg
                          .getNotifications()
                          .then((notifications) => {
                            notifications.forEach((n) => {
                              n.close();
                            });
                          })
                          .catch((err) => {
                            console.log("CATCH", err);
                          });
                        databaseState.dexUser &&
                          databaseState.dexUser.notifications.clear();
                      }, t);
                    }
                  });
                })
                .catch((err) => {
                  console.log("CATCH", err);
                });
          })
          .catch((err) => {
            // console.log("CATCH", err);
          });
    } catch (err) {
      console.log(err);
    }
  }, [databaseState.dexUser, focusState.hasFocus]);

  useEffect(() => {
    let logging = shouldLog();
    globalDispatch({
      type: "SET_LOGGING",
      values: { logging: logging }
    });
    databaseState.dexAdmin &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          doc: { key: "logging", value: logging },
          cb: () => {}
        }
      });
    return () => {};
  }, [databaseState.dexAdmin, globalDispatch]);

  useEffect(() => {
    if (globalState.devMode === undefined) {
      let dmode = localStorage.getItem("devmode");
      if (!dmode) dmode = false;
      globalDispatch({
        type: "SET_DEV_MODE",
        values: { devMode: dmode }
      });
    }
  }, []);

  //-----------------------------

  let content = (
    // <div style={{ height: "100%" }}>
    <div style={{ height: "0" }}>
      <Router>
        <main
          style={{
            position: "absolute",
            // height: "calc(100% - 80px)",
            // top: "80px",
            width: "100%"
          }}
        >
          <Switch>
            <Route path="/imageDisplay" component={UIProfileModal} />
            <Route path="/UILogin" exact component={UILogin} />
            <Route path="/UIMessageList" exact component={UITopicFrame} />
            <Route path="/UILogin" component={UILogin} />
            <Route
              path="/UIPersonaInfo"
              component={
                globalState?.persona?.mpersona ? UIPersonaInfo : UILogin
              }
            />
            <Route path="/" component={UILogin} />
          </Switch>
        </main>
      </Router>
    </div>
  );

  return content;
};

// memo acts like shouldComponentUpdate ... can use without the function, or with a function that returns false if it SHOULD rerender
export default React.memo(UserInterface, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
