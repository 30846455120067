
function CalcWhispColor(mpersArr) {

    const colorsArr = [
        { r: 255, g: 175, b: 255 },//MAGENTA
        { r: 255, g: 150, b: 255 },
        { r: 255, g: 125, b: 255 },
        { r: 255, g: 100, b: 255 },
        { r: 255, g: 200, b: 200 },//RED
        { r: 255, g: 175, b: 175 },
        { r: 255, g: 150, b: 150 },
        { r: 255, g: 125, b: 125 },
        { r: 255, g: 175, b: 50 },//RED-ORANGE
        { r: 255, g: 150, b: 50 },
        { r: 255, g: 120, b: 50 },
        { r: 255, g: 80, b: 50 },
        { r: 255, g: 200, b: 50 },//ORANGE
        { r: 255, g: 175, b: 50 },
        { r: 255, g: 150, b: 50 },
        { r: 255, g: 120, b: 50 },
        { r: 180, g: 150, b: 50 },//BROWN
        { r: 180, g: 125, b: 50 },
        { r: 180, g: 100, b: 50 },
        { r: 180, g: 75, b: 50 },
        { r: 255, g: 255, b: 150 },//YELLOW
        { r: 250, g: 250, b: 100 },
        { r: 225, g: 225, b: 50 },
        { r: 200, g: 200, b: 0 },
        { r: 155, g: 205, b: 200 },//YELLOW-GREEN
        { r: 155, g: 205, b: 150 },
        { r: 155, g: 205, b: 100 },
        { r: 155, g: 205, b: 50 },
        { r: 150, g: 250, b: 200 },//GREEN
        { r: 150, g: 250, b: 150 },
        { r: 150, g: 250, b: 100 },
        { r: 150, g: 250, b: 50 },
        { r: 200, g: 230, b: 230 },//TURQUOISE
        { r: 150, g: 230, b: 230 },
        { r: 100, g: 230, b: 230 },
        { r: 50, g: 230, b: 230 },
        { r: 125, g: 175, b: 250 },//BLUE
        { r: 125, g: 175, b: 250 },
        { r: 100, g: 150, b: 250 },
        { r: 75, g: 120, b: 250 },
        { r: 200, g: 190, b: 255 },//VIOLET
        { r: 200, g: 160, b: 255 },
        { r: 200, g: 130, b: 255 },
        { r: 200, g: 100, b: 255 },
        { r: 220, g: 90, b: 220 },//PURPLE
        { r: 190, g: 70, b: 190 },
        { r: 170, g: 50, b: 170 },
        { r: 150, g: 30, b: 150 },
        { r: 0, g: 250, b: 250 },//TEAL
        { r: 0, g: 225, b: 225 },
        { r: 0, g: 200, b: 200 },
        { r: 0, g: 175, b: 175 },
        { r: 0, g: 150, b: 150 },//DARK TEAL
        { r: 0, g: 125, b: 125 },
        { r: 0, g: 100, b: 100 },
        { r: 0, g: 75, b: 75 },
        { r: 0, g: 220, b: 130 },//GREEN TEAL
        { r: 0, g: 180, b: 120 },
        { r: 0, g: 150, b: 210 },
        { r: 0, g: 130, b: 100 },
        { r: 150, g: 150, b: 150 },//GREY
        { r: 120, g: 120, b: 120 },
        { r: 90, g: 90, b: 90 },
        { r: 60, g: 60, b: 60 }

    ]


    if (mpersArr.length > 0) {
        let result = parseInt(mpersArr[0]?.mpersona?.substring(0, 3), 16)
        for (let i = 1; i < mpersArr.length; i++) {
            let dec1 = parseInt(mpersArr[i].mpersona.substring(0, 3), 16)
            result = (result ^ dec1)
        }
        let arrIndex = result % 64
        return (
            colorsArr[arrIndex]
        )
    }
    return null

}

export default CalcWhispColor;
