import {
  s3ToDescriptor,
  s3ToImage,
  s3ToTheme,
  s3ToSuite,
} from "connectivity/s3";
import { timestamp } from "hooks/helper";
import { shouldLog } from "utils/shouldLog";
// var deepEqual = require("deep-equal");
const isEqual = require("react-fast-compare");
// const fs = require("fs");

const compareDocs = (a, b) => {
  let c = { ...a, _rev: "", _deleted: a.undeleted };
  let d = { ...b, _rev: "", _deleted: b.undeleted };
  return isEqual(c, d);
};

export const upsertID = async (db, table, doc) =>
  db
    .table(table)
    .put(doc)
    .then((r) => r)
    .catch((e) => console.error(e, table, doc));
// db
//   .upsert(doc._id, (existingDoc) =>
//     compareDocs(existingDoc, doc) ? false : doc
//   )
//   .then((r) => {
//     // console.log(
//     //   "upsertID storeImageScope",
//     //   doc,
//     //   new TextEncoder().encode(JSON.stringify(doc)).length / 1024,
//     //   r
//     // );
//     // if doc is of type topic_descriptor then delete all other records for this topic
//     if (doc._id.startsWith("54topic_descriptor")) {
//       // console.log("54topic_descriptor", doc._id);

//       // eslint-disable-next-line no-control-regex
//       let start = doc._id.match(/[^]*[^]*/g);
//       // console.log("54topic_descriptor start", start);
//       // ensure we are only risking topic descriptor records
//       shouldLog() && console.log("54topic_descriptor start", start, doc);
//       start &&
//         start[1] &&
//         start[0].startsWith("54topic_descriptor") &&
//         db
//           .allDocs({
//             include_docs: true,
//             startkey: start[0],
//             endkey: `${start[0]}z`
//           })
//           .then((allDocs) => {
//             // console.log("topic_descriptor ", allDocs);
//             allDocs.rows
//               .filter((row) => row.doc._id !== doc._id)
//               .forEach((row) => {
//                 try {
//                   let d = { ...row.doc };
//                   d._deleted = true;
//                   db.put(d).catch((e) =>
//                     console.log("[descriptors] error", row, e)
//                   );
//                 } catch (err) {
//                   console.log(err);
//                 }
//               });
//           })
//           .catch((err) => {
//             console.log("CATCH", err);
//           });
//     }
//     return true;
//   })
//   .catch((err) => {
//     console.log("upsertID", err);
//     if (err?.name === "conflict") {
//       return false;
//     } else {
//       return false;
//     }
//   });

export const getID = (db, id) =>
  db
    .get(id)
    .then((d) => {
      return d;
    })
    .catch(function (err) {
      console.log(err);
      return undefined;
    });

export const storeDescriptor = (db, table, descriptor) => {
  return upsertID(db, table, descriptor);
};

export const storeImage = (db, descriptor) => {
  let doc = {
    ...descriptor,
    storedate: timestamp(),
    scope: "image"
  };
  return descriptor.digest && upsertID(db, "cache", doc);
};

export const getDescriptorFromCache = async (db, table, mtopic, digest) => {
  db.table(table)
    .get({ mtopic: mtopic, digest: digest })
    .then((d) => {
      if (d) return d;
      return undefined;
    })
    .catch(async (e) => {
      shouldLog() && console.log("getDescriptor err", e);
      return undefined;
    });
};

export const getDescriptor = async (db, table, id, digest) => {
  let filter = { mtopic: id, digest: digest };
  if (table === "descriptor") filter = { mpersona: id, digest: digest };
  return db
    .table(table)
    .get(filter)
    .then(async (d) => {
      if (d) return d;
      return await cacheS3Descriptor(db, table, id, digest);
    })
    .catch(async (e) => {
      shouldLog() && console.log("getDescriptor err", e);
      return undefined;
    });
};

export const cacheS3Theme = async (db, mpersona, digest) => {
  let theme = await s3ToTheme(mpersona, digest);
  //   await storeDescriptor(db, "upload_descriptor", mpersona, digest, descriptor);
  theme && (await storeDescriptor(db, "themes", theme));
  return theme;
};

export const cacheS3Descriptor = async (db, table, mtopic, digest) => {
  let descriptor = await s3ToDescriptor(table, mtopic, digest);
  //   await storeDescriptor(db, "upload_descriptor", mpersona, digest, descriptor);
  descriptor &&
    !isEqual(descriptor, {}) &&
    descriptor !== "error" &&
    (await storeDescriptor(db, table, descriptor));
  return descriptor;
};

export const storeImageB64 = async (db, table, b64, extras) => {
  let imgdigest = await digestCalc(b64);
  let doc = {
    digest: imgdigest,
    b64: b64,
    ...extras
  };
  return await upsertID(db, table, doc);
};

export const storeImageScope = async (db, table, scope, b64, imgdigest) => {
  // let imgdigest = await digestCalc(b64);
  let doc = {
    digest: imgdigest,
    b64: b64,
    scope: scope,
    storedate: timestamp()
  };
  return await upsertID(db, table, doc);
};

export const digestCalc = async (item) => {
  const msgUint8 = new TextEncoder().encode(item);
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export const digestFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (evt) => {
      // console.log(evt.target.result);
      const hashBuffer = await crypto.subtle.digest("SHA-1", evt.target.result);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
      // console.log("hashHex", hashHex);
      resolve(hashHex);
    };
    reader.readAsArrayBuffer(file);
  });
};

// const readChunked = (file, chunkCallback, endCallback) => {
//   var fileSize = file.size;
//   var chunkSize = 4 * 1024 * 1024; // 4MB
//   var offset = 0;

//   var reader = new FileReader();
//   reader.onload = function () {
//     if (reader.error) {
//       endCallback(reader.error || {});
//       return;
//     }
//     offset += reader.result.length;
//     // callback for handling read chunk
//     // TODO: handle errors
//     chunkCallback(reader.result, offset, fileSize);
//     if (offset >= fileSize) {
//       endCallback(null);
//       return;
//     }
//     readNext();
//   };

//   reader.onerror = function (err) {
//     endCallback(err || {});
//   };

//   const readNext = () => {
//     var fileSlice = file.slice(offset, offset + chunkSize);
//     reader.readAsBinaryString(fileSlice);
//   };

//   readNext();
// };

export const assignDescriptor = async (db, descriptor) => {
  // local storage of which descriptor my mpersona should use
  let currentDescriptorsRec = await db.user.get({ key: "descriptors" });
  let newDescriptors = currentDescriptorsRec
    ? [
        ...currentDescriptorsRec.value.filter(
          (r) => r.mpersona !== descriptor.mpersona
        ),
        descriptor
      ]
    : [descriptor];
  return await upsertID(db, "user", {
    key: "descriptors",
    value: newDescriptors
  });
};

export const mpersonaDescriptor = async (db, mpersona) => {
  // local storage of which descriptor my mpersona should use
  return await db.user
    .get({ key: "descriptors" })
    .then((r) =>
      r && r.length > 0
        ? r.reduce((acc, d) => (d.mpersona === mpersona ? d.digest : acc), "")
        : ""
    )
    .catch((err) => {
      return "";
    });
};

export const getImage64 = async (db, digest) => {
  let image64 = await db.cache
    .get({ digest: digest }) // allow for any scope, as long as digest matches
    .then((d) => {
      if (d?.b64 || d?.i64) return d.b64 || d.i64; // logo images are currently "i64"
      return false;
    })
    .catch(async (e) => {
      return false;
    });
  return image64;
};

export const existsImage64 = async (db, digest) => {
  let image64Exists = await db.cache
    .get({
      digest: digest,
      scope: "image"
    })
    .then((image) => {
      return image ? true : false;
    })
    .catch(async (e) => {
      return false;
    });
  return image64Exists;
};

export const applyDescriptors = async (db, descriptors) => {
  const getMpersonaDescriptor = async (mpersona, digest) => {
    let descriptor = await db.descriptor
      .get({
        mpersona: mpersona,
        digest: digest
      })
      .then(async (d) => {
        // DEX console.log("DESCRIPTOR", d);
        if (d) return d;
        // DEX console.log("DESCRIPTOR DOES NOT EXIST");
        let fetched = await s3ToDescriptor("descriptor", mpersona, digest);
        // DEX console.log("DESCRIPTOR fetched", fetched);
        fetched?.digest && storeDescriptor(db, "descriptor", fetched);
        return fetched;
      })
      .catch(async (e) => {
        console.log(e);
      });
    return descriptor;
  };

  const bulkCacheMpersonaThumb = (db, thumbs) => {
    shouldLog() && console.log("[descriptors] bulkThumbCache ", thumbs);
    let storedate = timestamp();
    let records = thumbs
      .filter((r) => r?.mpersona && r.image64)
      .map((thumb) => {
        return {
          mpersona: thumb.mpersona,
          thumb: thumb.image64,
          storedate: storedate
        };
      });
    db.thumb
      .filter((t) => !records.map((r) => r.mpersona).includes(t.mpersona))
      .delete();
    records.forEach((r) => {
      db.thumb.put(r);
    });
    return records;
  };

  shouldLog() && console.log("!!! bulkThumbCache descriptors", descriptors);
  let bulkThumbCache = descriptors.map(async (drec) => {
    try {
      let descriptor = await getMpersonaDescriptor(drec.mpersona, drec.digest);
      if (!descriptor?.thumbdigest) {
        return { mpersona: drec.mpersona, thumb: undefined };
      }
      let image64 = await getImage64(db, descriptor.thumbdigest);

      if (!image64 && descriptor?.thumbdigest) {
        let i = await s3ToImage(drec.mpersona, descriptor.thumbdigest);
        shouldLog() && console.log("!!! bulkThumbCache s3ToImage", i);
        if (i?.b64) {
          // cacheMpersonaThumb(drec.mpersona, i?.b64);
          storeImage(db, i);
          let element =
            document && document.getElementById("image_" + drec.mpersona);
          element && (element.src = i?.b64);
          return { mpersona: drec.mpersona, thumb: i?.b64 };
        }
      } else if (image64) {
        // cacheMpersonaThumb(drec.mpersona, image64);
        let element =
          document && document.getElementById("image_" + drec.mpersona);
        element && (element.src = image64);
        return { mpersona: drec.mpersona, image64: image64 };
      }
    } catch (err) {
      return undefined;
    }
  });

  Promise.all(bulkThumbCache).then((r) => bulkCacheMpersonaThumb(db, r));
};

export const commitDescriptor = (db, muid, mp, newDesc) => {
  delete newDesc["_id"];
  delete newDesc["_deleted"];
  delete newDesc["_rev"];
  delete newDesc["digest"];
  delete newDesc["check"];
  digestCalc(JSON.stringify(newDesc)).then(async (descriptorDigest) => {
    newDesc.digest = descriptorDigest;
    let check = await digestCalc(muid + mp + descriptorDigest);
    newDesc.check = check;
    // save and push descriptor
    if (descriptorDigest) {
      storeDescriptor(db, "upload_descriptor", newDesc);
      storeDescriptor(db, "descriptor", newDesc);
      await assignDescriptor(db, mp, descriptorDigest);
    }
  });
};

export const applyTopicDescriptors = async (db, descriptors) => {
  const bulkLogoCache = (logos) => {
    // shouldLog() &&
    shouldLog() && console.log("[descriptors] bulkLogoCache ", logos);
    let storedate = timestamp();
    let records = logos
      .filter((r) => r?.mtopic && r?.digest && r?.image64)
      .map((r) => {
        return {
          descriptor: r.descriptor,
          mtopic: r.mtopic,
          digest: r.digest,
          b64: r.image64,
          storedate: storedate
        };
      });
    db &&
      records.forEach((r) =>
        db.topic_logo
          .get({ mtopic: r.mtopic, digest: r.digest })
          .then((exists) => !exists && db.topic_logo.put(r))
      );
  };

  shouldLog() && console.log("[descriptors] descriptors ", descriptors);
  let bulkLogo = descriptors
    // filter out descriptors for dialogs until we handle shared logos instead
    .filter((d) => d?.mtopic && d?.mtopic.split("_").length !== 3)
    .map(async (drec) => {
      try {
        if (drec.logo?.thumbdigest) {
          let image64 = await getImage64(db, drec.logo.thumbdigest);
          if (!image64) {
            let scope = drec.logo.thumbpath.split("/")[1];
            shouldLog() &&
              console.log("[descriptors] scope ", scope, drec.logo);
            let i = await s3ToImage(scope, drec.logo.thumbdigest);
            if (i?.b64) {
              // cacheLogoThumb(drec.mtopic, drec.logo.thumbdigest, i?.b64);
              storeImage(db, i);
              // let element =
              //   document &&
              //   document.getElementById(
              //     "logo_" +
              //       (drec.mtopic.split("_").length < 3
              //         ? drec.mtopic
              //         : `${drec.mtopic}_${drec.mpersona}`)
              //   );
              // element && (element.src = i?.b64);
              return {
                descriptor: drec.digest,
                mtopic:
                  drec.mtopic.split("_").length < 3
                    ? drec.mtopic
                    : `${drec.mtopic}_${drec.mpersona}`,
                digest: drec.logo.thumbdigest,
                image64: i?.b64
              };
            }
          } else if (image64) {
            // console.log("[descriptors] logo ", image64);
            // cacheLogoThumb(drec.mtopic, drec.logo.thumbdigest, image64);
            let element =
              document &&
              document.getElementById(
                "logo_" +
                  (drec.mtopic.split("_").length < 3
                    ? drec.mtopic
                    : `${drec.mtopic}_${drec.mpersona}`)
              );
            element && (element.src = image64);
            return {
              descriptor: drec.digest,
              mtopic:
                drec.mtopic.split("_").length < 3
                  ? drec.mtopic
                  : `${drec.mtopic}_${drec.mpersona}`,
              digest: drec.logo.thumbdigest,
              image64: image64
            };
          }
        }
      } catch (err) {
        console.log(err);
        return undefined;
      }
    });
  Promise.all(bulkLogo).then((r) => bulkLogoCache(r));
};
