import React, { useEffect, useContext, useState, useMemo } from "react";
import "ui/UI.css";
import "ui/UIButtons.css";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";

import UITopicList from "ui/UITopicList";
import UIPersonaManager from "ui/UIPersonaManager";
// import cardItems from "dummy/cards";

import StyleContext from "contexts/contextStyle";
import UIShareModal from "ui/UIShareModal";
import UIWorldIDInfo from "ui/UIWorldIDInfo";
import UISearchMyTopics from "ui/UISearchMyTopics";
import { CgArrowsMergeAltV, CgArrowsShrinkV } from "react-icons/cg";
import UIAlertModal from "./UIAlertModal";

const UILogin = (props) => {
  const { globalState } = useContext(GlobalContext);
  // const { locationState, locationDispatch } = useContext(LocationContext);
  const { styleState, styleDispatch } = useContext(StyleContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modal, setModal] = useState(undefined);
  const [collectionDisplay, setCollectionDisplay] = useState("current");

  useEffect(() => {
    setCollectionDisplay("current");
  }, [globalState?.persona?.mpersona]);

  useEffect(() => {
    databaseState.dbMaster &&
    styleState?.theme?.colors &&
    styleState?.theme?.fonts
      ? styleDispatch({
          type: "RESTORE_STYLE",
          values: { style: styleState?.theme }
        })
      : styleDispatch({
          type: "SET_DEFAULT",
          values: {}
        });
    return () => {};
  }, [styleDispatch, styleState?.theme, databaseState.dbMaster]);

  const showShare = useMemo(
    () => (title, link) => {
      setModal(
        <UIShareModal
          onClose={() => setModal(undefined)}
          title={title}
          link={link}
        ></UIShareModal>
      );
    },
    []
  );

  useEffect(() => {
    setModal(
      globalState.prevent_sending ? (
        <UIAlertModal
          secure={true}
          title={"Cleaning up your database, please wait"}
          onClose={() => {}}
        ></UIAlertModal>
      ) : undefined
    );
    return () => {};
  }, [globalState.prevent_sending]);

  const [intFrameHeight, setIntFrameHeight] = useState(window.innerHeight);
  const [UIFrameHeight, setUIFrameHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setIntFrameHeight(window.innerHeight);
      setUIFrameHeight(window.innerHeight);
      // setChangeHgt(true)
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let content = useMemo(
    () => (
      <div
        className="UI"
        style={{
          height: intFrameHeight ? intFrameHeight : window.innerHeight,
          backgroundColor: globalState.displaying_archive
            ? "var(--main_screen_background_archive_color)"
            : "var(--main_screen_background_color)"
        }}
      >
        <div
          className="UI-frame"
          style={
            UIFrameHeight
              ? { height: UIFrameHeight }
              : { height: window.innerHeight }
          }
        >
          <UIPersonaManager setModal={setModal} />
          {globalState.showWorldIDScreen && <UIWorldIDInfo />}
          <UITopicList
            key={collectionDisplay}
            share={showShare}
            collectionDisplay={collectionDisplay}
            setCollectionDisplay={setCollectionDisplay}
          />{" "}
          {globalState.isLoggedIn && (
            <div className="UI-main-screen-fab-area">
              <div
                className="UI-main-screen-fab"
                title="Condense all topics to collections / folders"
                onClick={() => setCollectionDisplay("collapse")}
              >
                <span>
                  <CgArrowsMergeAltV
                    style={{ width: "1.5rem", height: "1.5rem" }}
                  />
                </span>
              </div>
              <div
                className="UI-main-screen-fab"
                title="Expand all collections / folders to show topic"
                onClick={() => setCollectionDisplay("expand")}
              >
                <span>
                  <CgArrowsShrinkV
                    style={{ width: "1.5rem", height: "1.5rem" }}
                  />
                </span>
              </div>
              <UISearchMyTopics />
            </div>
          )}
        </div>
        {modal}
      </div>
    ),
    [
      UIFrameHeight,
      collectionDisplay,
      globalState.displaying_archive,
      globalState.isLoggedIn,
      globalState.showWorldIDScreen,
      intFrameHeight,
      modal,
      showShare
    ]
  );

  return content;
};

export default React.memo(UILogin, (prevProps, nextProps) => {
  return true; //prevProps === nextProps;
});
