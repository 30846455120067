import React, { useState, useEffect } from "react";
import { DropdownMenu, OKButton } from "./TextInput";
import { NameForm, IDNumForm, PhnNumForm, EmailForm } from "./IDInfo";
import "../modals.css";

const AttributeModal = (props) => {
  const [showCanc, setShowCanc] = useState(false);
  const [showCancForm, setShowCancForm] = useState(false);
  const [entry, setEntry] = useState(false);
  const [noSubmit, setNoSubmit] = useState(false);

  const [open, setOpen] = useState(true);
  const [openAttrModal, setOpenAttrModal] = useState(true);
  const [formsName, setFormsName] = useState(false);
  const [formsID, setFormsID] = useState(false);
  const [formsPhn, setFormsPhn] = useState(false);
  const [formsEmail, setFormsEmail] = useState(false);
  const [formInPlace, setFormInPlace] = useState(false);
  const [title, setTitle] = useState("Available Attributes");

  const handleNamesForm = () => {
    setFormsName(!formsName);
    setOpen(false);
    setTitle("Name");
    setFormInPlace(true);
    setFormsID(false);
    setFormsPhn(false);
    setFormsEmail(false);
  };

  const handleIDForm = () => {
    setFormsID(!formsID);
    setOpen(false);
    setTitle("Identity Number");
    setFormInPlace(true);
    setFormsName(false);
    setFormsPhn(false);
    setFormsEmail(false);
  };

  const handlePhnForm = () => {
    setFormsPhn(!formsPhn);
    setOpen(false);
    setTitle("Phone");
    setFormInPlace(true);
    setFormsName(false);
    setFormsID(false);
    setFormsEmail(false);
  };

  const handleEmailForm = () => {
    setFormsEmail(!formsName);
    setOpen(false);
    setTitle("Email");
    setFormInPlace(true);
    setFormsName(false);
    setFormsID(false);
    setFormsPhn(false);
  };

  const handleOK = () => {
    props.onClose();
  };

  useEffect(() => {
    // effect
    setTimeout(() => setNoSubmit(false), 1000);
    return () => {
      // cleanup
    };
  }, [noSubmit]);

  const menuItems = [
    {
      text: "Name",
      selected: false,
      callback: handleNamesForm,
      key: 1
    },
    {
      text: "Identity Number",
      selected: false,
      callback: handleIDForm,
      key: 2
    },
    {
      text: "Phone number",
      selected: false,
      callback: handlePhnForm,
      key: 3
    },
    {
      text: "Email",
      selected: false,
      callback: handleEmailForm,
      key: 4
    }
    // {
    //   text: "Religion",
    //   selected: false,
    //   key: 5,
    // },
    // {
    //   text: "Create Attribute",
    //   selected: false,
    //   key: 6,
    // }
  ];

  const handleClose = () => {
    // if (showCanc) {
    setFormsName(false);
    setFormsID(false);
    setFormsPhn(false);
    setFormsEmail(false);
    setShowCanc(false);
    setFormInPlace(false);
    setTitle("Available Attributes");
    setOpen(true);
    setShowCancForm(false);
    // }
    // if (formsPhn) {
    //   setFormsPhn(false)
    //   setFormInPlace(false)
    //   setTitle("Available Attributes")
    //   setOpen(true)
    // }
  };

  const handleCancel = () => {
    setShowCanc(!showCanc);
  };

  const handleCancelForm = () => {
    setShowCancForm(!showCancForm);
  };

  const handleOpenDropdown = () => {
    if (formInPlace) handleCancel();
    else setOpen(!open);
  };

  let content = (
    <div className="popup-box">
      <div className="box" type="attributes">
        {props.children}
        <button
          type="button"
          className="close-modal"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
          title="Close Modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        {/* <div className="box-inner"> */}

        {openAttrModal && (
          <div>
            <span className={formInPlace ? "dispblk" : "dispnone"}>
              Attribute Type
            </span>
            <DropdownMenu
              title={title}
              menuItems={menuItems}
              open={open}
              onClick={() => handleOpenDropdown()}
              onClose={props.onClose}
              key="21"
            ></DropdownMenu>
            {showCanc && (
              <div className="confirm-cancel">
                current data may be lost continue?
                <button
                  className="modal-button"
                  type="small"
                  onClick={handleClose}
                >
                  Yes
                </button>
                <button
                  className="modal-button"
                  type="small"
                  onClick={handleCancel}
                >
                  No
                </button>
              </div>
            )}
            {formsName && (
              <NameForm onClose={props.onClose} menuItems={menuItems} />
            )}
            {formsID && (
              <IDNumForm onClose={props.onClose} menuItems={menuItems} />
            )}
            {formsPhn && (
              <PhnNumForm
                onClose={props.onClose}
                menuItems={menuItems}
                cancel={handleClose}
              />
            )}
            {formsEmail && (
              <EmailForm onClose={props.onClose} menuItems={menuItems} />
            )}
            {formInPlace && (
              <div>
                <OKButton
                  marginLeft="16px"
                  marginBottom="0px"
                  marginTop="16px"
                  height="40px"
                  width="100px"
                  onClick={handleCancelForm}
                  label="Cancel"
                />
                <OKButton
                  marginLeft="16px"
                  marginBottom="0px"
                  marginTop="16px"
                  height="40px"
                  width="100px"
                  onClick={handleOK}
                  label="OK"
                />
              </div>
            )}
            {showCancForm && (
              <div className="confirm-cancel">
                current data may be lost continue?
                <button
                  className="modal-button"
                  type="small"
                  onClick={handleClose}
                >
                  Yes
                </button>
                <button
                  className="modal-button"
                  type="small"
                  onClick={handleCancel}
                >
                  No
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return content;
};

export default AttributeModal;
