export const sanitizeUserId = (text) => {
  return text
    .toLowerCase()
    .replace(/[^abcdefghijklmnopqrstuvwxyz0123456789_\-.]/g, "");
};

export const sharedStart = (array) => {
  // https://stackoverflow.com/questions/1916218/find-the-longest-common-starting-substring-in-a-set-of-strings
  var A = array.concat().sort(),
    a1 = A[0],
    a2 = A[A.length - 1],
    L = a1.length,
    i = 0;
  // VERY clever while statement ;-)
  while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
  return a1.substring(0, i);
};

export const getAll = (obj, id, accumulater) => {
  if (typeof obj !== "object") return accumulater;

  if (Array.isArray(obj)) {
    return obj.reduce((acc, item) => getAll(item, id, acc), accumulater);
  }

  let keys = Object.keys(obj);
  return keys.reduce((acc, key) => {
    if (typeof obj[key] === "object") return getAll(obj[key], id, acc);
    else return key === id ? [...acc, obj[key]] : acc;
  }, accumulater);
};
