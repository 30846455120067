import React, { useContext, useState, useRef } from "react";
import "./UI.css"
import UIPersonaPicManager from "./UIPersonaPicManager";
import GlobalContext from "contexts/context";
import UserContext from "contexts/contextUser";
import DatabaseContext from "data/contextDatabase";
import {
    ShowSingleLineSwitch,
    ShowTextArea,
    // ShowOptionSwitch,
    PersonaButton,
    PersonaButtonChkBox,
    ShowOptionSwitchiBtn,
    OneLineInfo,
    DropdownMenu,
    TextInputSingleLine,
    TextInputArea,
    ToggleSwitch,
    TextEmail,
    TextPhone,
    SingleLineSwitch,
} from "./Forms/TextInput";

const isEqual = require("react-fast-compare");


const UIWorldIDInfo = (props) => {
    const { globalState, globalDispatch } = useContext(GlobalContext);

    const generalWidth = "300px";
    const generalPadding = "4px";
    const generalHeight = "40px";
    const generalBorderWidth = "1px";
    const generalBorderColor = "var(--modal_border_color)";
    const generalBorderStyle = "solid";
    const generalBorderRadius = "var(--border_radius_medium)";
    const generalBackgroundColor = "var(--modal_background_color)";
    const generalMarginTop = "8px";
    const generalMarginBottom = "8px";
    const generalTextColor = "var(--modal_text_color)";
    const generalFontSize = "1rem";
    const generalFontSizeLabel = "0.8rem";
    const generalMarginLeft = "12px";
    const generalMarginRight = "16px";
    const generalHeightSwitch = "20px";
    const generalBackgroundColorSwitch = "var(--toggle_switch_background_color)";
    const generalColorSelectSwitch = "#64DD17";
    const generalborderWidthSwitch = "1px";
    const generalborderColorSwitch = "var(--toggle_switch_border_color)";
    const generalborderStyleSwitch = "solid";
    const generalmarginRightSwitch = "16px";
    const generalButtonMarginLeft = "16px";
    const generalButtonMarginBottom = "0px";
    const generalButtonMarginRight = "8px";
    const generalButtonButtonMarginRight = "16px";

    /*Persona Name*/
    const [worldIDName, setWorldIDName] = useState({
        value: globalState.uid,
        source: "initial",
    });
    const handleChangeWorldIDName = (newValue) => {
        setWorldIDName({ value: newValue, source: "input" });
    };
    /*SA Phone Number*/
    const [SAPhn, setSAPhn] = useState("");
    const [checkedSAPhn, setCheckedSAPhn] = useState(false);
    const handleChangeSAPhn = (newValue) => {
        setSAPhn(newValue);
    };
    const onChangeSAPhn = () => {
        setCheckedSAPhn(!checkedSAPhn);
    };


    let content = (
        <div
            className="persona-scroll full no-scrollbar persona-screen-layout">
            {/* style={{ marginTop: "8px" }}> */}
            {/* Hello WORLD */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "12px",
                    marginLeft: generalMarginLeft,
                }}
            >
                <span
                    style={{
                        marginRight: "8px",
                    }}
                >
                    {/* <UIPersonaFlag mpersona={livePersonas[getIndex()].mpersona} /> */}
                </span>
                {/* <span>{livePersonas[getIndex()].mpersona}</span> */}
            </div>
            <UIPersonaPicManager></UIPersonaPicManager>

            <TextInputSingleLine
                // genStyle={genStyle}
                label="WORLD ID"
                labelPosition="inside"
                fontSizeLabel={generalFontSizeLabel}
                fontSize={generalFontSize}
                mandatory={false}
                type="input"
                value={worldIDName?.value || ""}
                width={generalWidth}
                height={generalHeight}
                paddingLeft={generalPadding}
                paddingRight={generalPadding}
                marginLeft={generalMarginLeft}
                marginRight={generalMarginRight}
                marginTop={generalMarginTop}
                marginBottom="0px"
                borderWidth={generalBorderWidth}
                borderColor={generalBorderColor}
                borderStyle={generalBorderStyle}
                borderRadius={generalBorderRadius}
                textColor={generalTextColor}
                placeholder="Persona name"
                callback={(v) => {
                    handleChangeWorldIDName(v);
                }}
                addSwitch="false"
                key="1a"
            />

            <PersonaButton
                marginLeft={generalButtonMarginLeft}
                marginBottom={generalButtonMarginBottom}
                marginTop={generalMarginTop}
                onClick={() => {

                }}
                label="Change Password"
                key="2"
            />

            <PersonaButton
                marginLeft={generalButtonMarginLeft}
                marginBottom={generalButtonMarginBottom}
                marginTop={generalMarginTop}
                onClick={() => {

                }}
                label="Delete WORLD ID"
                key="2"
            />

            <OneLineInfo
                width="100%"
                height={generalHeight}
                fontWeight="bold"
                paddingLeft={generalPadding}
                paddingRight={generalPadding}
                marginLeft={generalMarginLeft}
                marginRight={generalMarginRight}
                marginTop="0px"
                marginBottom="0px"
                backgroundColor={generalBackgroundColor}
                textColor="var(--main_screen_text_color)"
                fontStyle="italic"
                value="Below is a list of your attributes"
                key="12"
            />


            <TextInputSingleLine
                label="SA Mobile Phone number"
                fontSizeLabel={generalFontSizeLabel}
                fontSize={generalFontSize}
                mandatory={false}
                type="input"
                width={generalWidth}
                height={generalHeight}
                paddingLeft={generalPadding}
                paddingRight={generalPadding}
                marginLeft={generalMarginLeft}
                marginRight={generalMarginRight}
                marginTop={generalMarginTop}
                marginBottom="0px"
                borderWidth={generalBorderWidth}
                borderColor={generalBorderColor}
                borderStyle={generalBorderStyle}
                borderRadius={generalBorderRadius}
                backgroundColor={generalBackgroundColor}
                textColor={generalTextColor}
                placeholder="Mobile phone"
                value={SAPhn}
                callback={handleChangeSAPhn}
                addSwitch="false"
                addIcon={true}
                key="6"
            />

        </div>



    );
    return content;
}
export default React.memo(UIWorldIDInfo, (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
});

