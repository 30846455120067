import GlobalContext from "contexts/context";
import React, { useMemo, useContext, useState, useEffect, useRef } from "react";
import { workerPost } from "workers/interfaceRest";
const isEqual = require("react-fast-compare");

//"finance:payment:supplier"

const ServiceCheckBoxes = (props) => {
  const { item, svcMsgState, onChange, message } = props;
  const { globalState } = useContext(GlobalContext);

  const convtoUpper = (text) => {
    return text.toUpperCase();
  };

  const [boxGroup, setBoxGroup] = useState(<div></div>);

  // useEffect(() => {
  //   setSelect(props, item, parent, svcMsgState, filterResult, svcMsgDispatch);
  // }, [props, item, parent, svcMsgState, filterResult, svcMsgDispatch]);

  useEffect(() => {
    // setSelect(sel(props, item));
    item.dynamicBoxes &&
      globalState?.jwt &&
      workerPost(item.dynamicBoxes.url, item.dynamicBoxes.key, {
        "action": item.dynamicBoxes.action,
        "start_position": item.dynamicBoxes.start_position,
        "jwt": globalState?.jwt,
        "mtopicOrig": message.mtopic,
        "topicOrig": message.topic,
        "mpersonaOrig": message.mpersona,
        "personaOrig": message.persona
      }).then((urlResult) => {
        console.log("!!!urlResult ", urlResult, item);
        item.boxes = (item.staticBoxes || []).concat(urlResult);
      });

    return () => {};
  }, [item?.dynamicBoxes, globalState?.jwt]);

  useEffect(() => {
    let boxes = (props, item) => (
      <div>
        {Array.isArray(item?.boxes) &&
          item?.boxes.map((box) => (
            <div
              className={
                props.type === "right"
                  ? "right-align-label"
                  : "left-align-label"
              }
              key={"div_" + props.message.smid + "_" + box.id}
            >
              <label>
                <input
                  type="checkbox"
                  id={props.message.smid + "_" + box.id}
                  checked={
                    svcMsgState.response.filter(
                      (r) => r.id === props.message.smid + "_" + box.id
                    )[0]?.value || false
                  }
                  onChange={(event) => onChange(event, box)}
                />
                {box.title}
              </label>
            </div>
          ))}
      </div>
    );
    setBoxGroup(boxes(props, item));
    return () => {};
  }, [item, item.boxes, onChange, props, svcMsgState.response]);

  return boxGroup;
};

export default React.memo(ServiceCheckBoxes, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
