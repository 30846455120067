import React, { useContext, useEffect, useRef, useMemo } from "react";

import GlobalContext from "contexts/context";
import TopicsContext from "contexts/contextTopics";
import PersonasContext from "contexts/contextPersonas";
import CountContext from "contexts/contextCount";
import DatabaseContext from "data/contextDatabase";
import UserContext from "contexts/contextUser";
import LiveCacheContext from "./contextLiveCache";

import {
  useAccountDex,
  useCollectionStateDex,
  // useCountsDex,
  useDescriptorsDex,
  useLiveDex,
  useMuteDex,
  usePersonasDex,
  usePrefsDex,
  useThemeDex,
  useThemesDex,
  useTopicMetaDex,
  useTopicsDex
} from "hooks/dexHooks";

import { timestamp, randomString, shuffle } from "hooks/helper";
import { workerGet, workerPost } from "workers/interfaceRest";
import { s3ToImage, uploadToS3 } from "connectivity/s3";
import {
  applyDescriptors,
  existsImage64,
  getDescriptor,
  storeImage
} from "data/descriptors";

import StyleContext from "contexts/contextStyle";
import { rest_api } from "connectivity/API";
import { isHide, isRead, isUnread, lastReadMessage } from "utils/isRead";
import { cleanMessages, dex_action, updateMetaTopic } from "./dexUtils";

const isEqual = require("react-fast-compare");
const timer = new Date().getTime();

const LiveData = (props) => {
  // console.log("[LiveData]props", props);
  const { userState } = useContext(UserContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { personasState, personasDispatch } = useContext(PersonasContext);
  const { countDispatch } = useContext(CountContext);
  const { styleDispatch } = useContext(StyleContext);

  const { liveMissing, liveCount, liveLatestVisible, liveMetaTopics } =
    useTopicMetaDex(databaseState.dexUser);

  const { liveClient, liveUser } = useAccountDex(databaseState.dexAdmin);

  const { livePrefs } = usePrefsDex(databaseState.dexUser);

  const { livePersonas } = usePersonasDex(databaseState.dexUser);

  const {
    liveTopics,
    liveTopicChanges,
    liveArchiveChanges,
    liveDearchiveChanges
  } = useTopicsDex(databaseState.dexUser);

  const { liveMuteState } = useMuteDex(databaseState.dexUser);

  const { liveCollectionState } = useCollectionStateDex(databaseState.dexUser);

  const { liveThemes } = useThemesDex(databaseState.dexUser);

  const { liveTheme } = useThemeDex(databaseState.dexUser);

  const { liveCacheDispatch } = useContext(LiveCacheContext);

  const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
  const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
  const keyTheme = process.env.REACT_APP_JWT_S3_API_KEY;
  const urlTheme = process.env.REACT_APP_JWT_S3_API_URL;

  const jwt = useRef(undefined);

  useEffect(() => {
    globalState.logging && console.log("LiveData 1");
    if (!topicsState.topics) {
      return;
    }

    const ReadAll = (t) => {
      // TODO: This runs unnecessarily often
      t &&
        t.length > 0 &&
        dex_action({
          type: "DEX_MULTI_MARK_READ",
          values: {
            db: databaseState.dexUser,
            global: true,
            mtopics: t
          }
        });
    };

    let mList = Object.keys(topicsState.topics).reduce((acc, i) => {
      let mtopic =
        topicsState.topics[i].subprops.archived &&
        topicsState.topics[i].subprops.archived === "true" &&
        topicsState.topics[i]?.mpersona &&
        topicsState.topics[i]?.mpersona === globalState.persona?.mpersona &&
        topicsState.topics[i].mtopic;
      return mtopic ? [...acc, mtopic] : acc;
    }, []);
    globalState.logging && console.log("[UITopicList]ReadAll", mList);
    ReadAll(mList);
  }, [globalState.persona?.mpersona, topicsState.topics]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 2");
    if (liveDearchiveChanges.notFirst) {
      liveDearchiveChanges.value.forEach((topic) => {
        globalState.logging &&
          console.log("liveDearchiveChanges dearchive updateMetaTopic", topic);
        updateMetaTopic(databaseState.dexUser, topic);
        // trimMessages(databaseState.dexUser, topic.mtopic, topic.mpersona);
      });
    }
    return () => {};
  }, [liveDearchiveChanges]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 3");
    const ReadAll = (t) => {
      // TODO: This runs unnecessarily often
      t &&
        t.length > 0 &&
        dex_action({
          type: "DEX_MULTI_MARK_READ",
          values: {
            db: databaseState.dexUser,
            global: true,
            mtopics: t
          }
        });
    };

    if (
      liveDearchiveChanges.notFirst &&
      liveArchiveChanges?.value &&
      liveArchiveChanges.value.length > 0
    ) {
      globalState.logging &&
        console.log("[LiveData] liveArchiveChanges", liveArchiveChanges);
      let mList = Object.keys(liveArchiveChanges.value).reduce((acc, i) => {
        let mtopic =
          liveArchiveChanges.value[i].subprops?.archived &&
          ["t", "true", true].includes(
            liveArchiveChanges.value[i].subprops.archived
          ) &&
          liveArchiveChanges.value[i].mtopic;
        return mtopic ? [...acc, mtopic] : acc;
      }, []);
      globalState.logging && console.log("[UITopicList]ReadAll", mList);
      ReadAll(mList);
    }
  }, [databaseState.dexUser, liveArchiveChanges]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 4");
    globalState.logging &&
      console.log("[LiveData] liveTopicChanges changed", liveTopicChanges);
    liveTopicChanges.forEach((topic) => {
      updateMetaTopic(databaseState.dexUser, topic);
      // trimMessages(databaseState.dexUser, topic.mtopic, topic.mpersona);
    });
  }, [databaseState.dexUser, liveTopicChanges]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 6");
    databaseState.dexAdmin &&
      databaseState.dexAdmin.store
        .put({ key: "logging", value: !!globalState.logging })
        .then((r) => {});
  }, [globalState.logging, databaseState.dexAdmin]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 8");
    let mounted = true;
    if (liveTheme && mounted) {
      globalState.logging && console.log("[LiveData] SET_THEME", liveTheme);
      styleDispatch({
        type: "SET_THEME",
        values: { theme: liveTheme }
      });
      document.documentElement.style.setProperty(
        "--basefont",
        liveTheme.fonts?.body_typeface_font?.substring(
          0,
          liveTheme.fonts?.body_typeface_font.length - 3
        )
      );
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveTheme]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 9");
    let mounted = true;
    if (liveThemes && mounted) {
      // console.log("[LiveData] SET_THEMES", liveThemes);
      styleDispatch({
        type: "SET_THEMES",
        values: { themes: liveThemes }
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveThemes]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 12");
    let mounted = true;

    let recurse = (dexUser, topics) =>
      setTimeout(
        () =>
          topics?.length > 0 &&
          mounted &&
          getDescriptorsRecursive(dexUser, [...topics]),
        100
      );

    let getDescriptorsRecursive = (dexUser, topics) => {
      if (topics && topics.length > 0) {
        let topic = topics.splice(0, 1)[0];
        if (
          globalState.connected &&
          topic?.mtopic &&
          topic?.props?.descriptor?.digest
        )
          getDescriptor(
            dexUser,
            "topic_descriptor",
            topic.mtopic,
            topic.props.descriptor.digest
          )
            .then(async (d) => {
              // console.log("getDescriptorsRecursive d", d);
              if (d.mtopic) {
                // if (mounted) {
                try {
                  let image64 = await existsImage64(
                    dexUser,
                    d.logo?.thumbdigest
                  );
                  if (!image64 && d.logo?.thumbdigest) {
                    let scope = d.logo.thumbpath.split("/")[1];
                    let i = await s3ToImage(scope, d.logo.thumbdigest);
                    if (i?.b64) {
                      storeImage(dexUser, i);
                    }
                  } else {
                  } // console.log("had img!_!_!", image64);
                  recurse(dexUser, [...topics]);
                } catch (err) {
                  console.log(err);
                  recurse(dexUser, [...topics]);
                }
                // }
              } else recurse(dexUser, [...topics]);
            })
            .catch((e) => {
              console.log(e);
              recurse(dexUser, [...topics]);
            });
        else recurse(dexUser, [...topics]);
      }
    };

    liveTopics &&
      globalState.connected &&
      databaseState.dexUser &&
      getDescriptorsRecursive(
        databaseState.dexUser,
        shuffle([...liveTopics.filter((t) => t.props?.descriptor)])
      );

    return () => {
      mounted = false;
    };
  }, [liveTopics, globalState.connected, databaseState.dexUser]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 13");
    globalDispatch({
      type: "SET_PREFS",
      values: { prefs: livePrefs }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livePrefs]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 14");
    let mounted = true;
    globalState.logging &&
      console.log("[LiveData] useffect", [
        globalState.connected,
        globalState.authenticated,
        // globalState.version,
        liveMissing,
        liveUser?.jwt,
        // globalState.displaying_topic,
        // globalState?.prefs?.clientMute,
        databaseState.dexAdmin,
        databaseState.dexUser,
        databaseState.dbReady,
        personasState.personas
      ]);

    const getMessagesRecursive = (dexUser, mpersonas, topicsMissing) => {
      globalState.logging &&
        console.log(
          "[LiveData] topicsMissing",
          topicsMissing.map((i) => {
            return { ...i };
          })
        );
      let top = topicsMissing.splice(0, 1);
      let action = {
        type: "r_t_hist",
        topics: top,
        jwt: liveUser.jwt
      };
      rest_api(action)
        .then(async (r) => {
          globalState.logging &&
            console.log("[LiveData] rest_api getMessagesRecursive", r, action);
          if (r?.messages[0]?.mtopic) {
            let lastR = await lastReadMessage(
              dexUser,
              r.messages[0].mtopic,
              r.messages[0].recipient
            );
            let latest = r?.messages[0] ? lastR : 0;
            r.messages.forEach((m) => {
              m.read = isRead(m, mpersonas, latest);
              m.hide = isHide(m);
              dex_action({
                type: "DEX_UPSERT_KEYS_LATEST_TRANS",
                values: {
                  db: databaseState.dexUser,
                  table: "message",
                  latest_key: "msg_idx",
                  match_keys: ["mtopic", "recipient", "smid"],
                  doc: m,
                  notify:
                    isUnread(m) &&
                    !globalState.displaying_topic &&
                    !globalState?.prefs?.clientMute
                }
              });
            });
          }
          setTimeout(
            () =>
              topicsMissing?.length > 0 &&
              mounted &&
              getMessagesRecursive(dexUser, mpersonas, topicsMissing),
            100
          );
        })
        .catch((e) => {
          console.log("[LiveData] rest_api error", e);
          setTimeout(
            () =>
              topicsMissing?.length > 0 &&
              mounted &&
              getMessagesRecursive(dexUser, mpersonas, topicsMissing),
            100
          );
        });
    };

    if (
      globalState.connected &&
      globalState.authenticated &&
      databaseState.dexAdmin &&
      databaseState.dbReady &&
      liveMissing &&
      databaseState.dexUser &&
      Array.isArray(liveMissing) &&
      liveMissing?.length > 0
    ) {
      // R_T_HIST
      if (true && databaseState.dexUser && liveUser?.jwt) {
        // globalState.logging &&
        globalState.logging &&
          console.log("[LiveData] liveMissing", liveMissing);
        mounted &&
          getMessagesRecursive(
            databaseState.dexUser,
            personasState.personas.map((p) => p?.mpersona),
            [...liveMissing]
          );
      } else if (true) {
        // W.T.HIST
        let j = {
          type: "w.t.hist",
          topics: liveMissing,
          smid: "w.t.hist" // randomString(8),
        };
        globalState.logging && console.log("[LiveData] w.t.hist", j);
        // W.T.HIST
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "send",
              doc: j
            }
          });
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.connected,
    globalState.authenticated,
    // globalState.version,
    liveMissing,
    liveUser?.jwt,
    // globalState.displaying_topic,
    // globalState?.prefs?.clientMute,
    databaseState.dexAdmin,
    databaseState.dexUser,
    databaseState.dbReady,
    personasState.personas
  ]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 15");
    globalState.logging && console.log("mute", liveMuteState);
    liveCacheDispatch({
      type: "SET",
      values: {
        cache: {
          mute: liveMuteState
        }
      }
    });
    return () => {};
  }, [liveCacheDispatch, liveMuteState]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 16");
    globalState.logging &&
      console.log("!!!liveCollectionState", liveCollectionState);
    liveCacheDispatch({
      type: "SET",
      values: {
        cache: {
          collectionState: liveCollectionState
        }
      }
    });
    return () => {};
  }, [liveCacheDispatch, liveCollectionState]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 17");
    // TODO : DEXify
    let isMounted = true;
    if (
      globalState.connected &&
      globalState.uid &&
      (globalState.password || userState?.accessToken) &&
      databaseState.dexAdmin
    ) {
      // !!!TOKEN require a jwt with mpersonas for jwt.current (used for uploading themes)

      workerGet(urlPersona, keyPersona, {
        type: "getJWT",
        uid: globalState.uid,
        pwd: globalState.password,
        token: userState?.accessToken,
        entity: "muid",
        msgid: randomString(8),
        version: globalState.version
      })
        .then((response) => {
          globalState.logging && console.log("JWT! response", response);
          if (isMounted) {
            jwt.current = response?.result?.jwt;
            dex_action({
              type: "DEX_UPSERT_MATCH",
              values: {
                db: databaseState.dexAdmin,
                table: "store",
                match: { key: "user" },
                function: (val) => {
                  return response?.result?.jwt &&
                    response?.result?.jwt !== val?.jwt
                    ? { ...val, jwt: response?.result?.jwt }
                    : val;
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log("CATCH JWT", err);
        });

      let req = {
        type: "getJWT",
        uid: globalState.uid,
        pwd: globalState.password,
        token: userState?.accessToken,
        entity: "mpersonas",
        msgid: randomString(8),
        version: globalState.version
      };

      // console.log("JWT! req", req, urlPersona, keyPersona);
      workerGet(urlPersona, keyPersona, req)
        .then((response) => {
          // console.log("JWT! response", response);
          if (isMounted) {
            jwt.current = response?.result?.jwt;
          }
        })
        .catch((err) => {
          console.log("CATCH JWT", err);
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    databaseState.dexAdmin,
    databaseState.dexUser,
    globalState.password,
    globalState.uid,
    globalState.version,
    globalState.connected,
    userState?.accessToken,
    keyPersona,
    keyTheme,
    urlPersona,
    urlTheme
  ]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 18");
    globalState.logging && console.log("[LiveData] livePersonas", livePersonas);
    personasDispatch({
      type: "SET_PERSONAS",
      values: { personas: livePersonas }
    });
    return () => {};
  }, [personasDispatch, livePersonas]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 19");
    topicsDispatch({
      type: "SET_TOPICS",
      values: { topics: liveTopics }
    });
    return () => {};
  }, [topicsDispatch, liveTopics]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 20");
    // CountState holds summaries per per mtopic_recipient (unread, latest, activity)
    globalState.logging && console.log("[LiveData]liveCount", liveCount);
    liveCount &&
      countDispatch({
        type: "SET_COUNT",
        values: {
          count: liveCount
        }
      });
    return () => {};
  }, [countDispatch, liveCount]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 21");
    // CountState holds summaries per per mtopic_recipient (unread, latest, activity)
    globalState.logging &&
      console.log("[LiveData]liveLatestVisible", liveLatestVisible);
    liveLatestVisible &&
      countDispatch({
        type: "SET_LAST_VISIBLE_DATE",
        values: {
          last_visible_date: liveLatestVisible
        }
      });
    return () => {};
  }, [countDispatch, liveLatestVisible]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 22");
    // if uid changes, request a list of personas via REST
    // store them in account data
    // use the first persona to join any dialog indicated in the url
    if (
      globalState.connected &&
      databaseState.dexAdmin &&
      databaseState.dbReady &&
      globalState.uid &&
      (globalState.password || userState?.accessToken)
    ) {
      const sortJoin = (A, B, Delim) => {
        return A < B ? A + Delim + B : B + Delim + A;
      };

      databaseState.dbReady &&
        databaseState.dexAdmin &&
        workerGet(urlPersona, keyPersona, {
          type: "personalist",
          uid: globalState.uid,
          pwd: globalState.password,
          token: userState?.accessToken,
          msgid: randomString(8),
          version: globalState.version
        })
          .then((personaList) => {
            globalState.logging && console.log("get personaList", personaList);
            personaList?.list === undefined
              ? globalState.logging && console.log()
              : dex_action({
                  type: "DEX_PUT",
                  values: {
                    db: databaseState.dexUser,
                    table: "account",
                    doc: { type: "personas", value: personaList.list }
                  }
                });
            // use 1st persona to subscribe to t_ topics
            try {
              let topicList = JSON.parse(sessionStorage.getItem("topiclist"));
              topicList.forEach((t) => {
                let jt = {
                  type: "w.t.join",
                  version: props.version,
                  smid: randomString(8),
                  ts_sender: timestamp(),
                  mtopic: t,
                  mpersona: personaList.list[0].mpersona,
                  origin_sender: "registered"
                };
                dex_action({
                  type: "DEX_PUT",
                  values: {
                    db: databaseState.dexUser,
                    table: "send",
                    doc: jt
                  }
                });
              });
              sessionStorage.removeItem("topiclist");
            } catch {}
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
    }
    return () => {};
  }, [
    // databaseState.dexAdmin,
    // databaseState.dexUser,
    databaseState.dbReady,
    globalState.password,
    globalState.key,
    globalState.uid,
    globalState.version,
    globalState.connected,
    keyPersona,
    // props.version,
    // urlPersona,
    globalState.persona,
    userState?.accessToken
  ]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 23");
    if (
      globalState.uid === undefined ||
      (globalState.password === undefined && !userState?.accessToken)
    ) {
    } else {
      dex_action({
        type: "DEX_UPSERT_MATCH",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          match: { key: "user" },
          function: (val) => {
            let v = val;
            if (val.uid === globalState.uid)
              v = { ...val, password: globalState.password };
            globalState.logging && console.log("update_user", v);
            return v;
          }
        }
      });
      // and timestamp the associated database
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexAdmin,
          table: "dexNames",
          doc: {
            name: globalState.uid,
            timeStamp: timestamp()
          }
        }
      });
    }
    return () => {};
  }, [
    databaseState?.dexAdmin,
    globalState?.password,
    globalState?.uid,
    userState?.accessToken
  ]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 24");
    // If dbUser exists, attempt to log in with stored user
    globalState.logging && console.log("DEX LOGIN", liveUser, liveClient);

    globalDispatch({
      type: "ACCOUNTDB",
      values: {
        initiated: liveClient !== null && liveUser !== null
      }
    });

    return () => {};
  }, [globalDispatch, liveUser, liveClient]);
  useEffect(() => {
    globalState.logging && console.log("LiveData 24.5");
    // If dbUser exists, attempt to log in with stored user
    liveClient?.cid &&
      liveUser?.uid &&
      liveUser?.password &&
      globalDispatch({
        type: "LOGIN",
        values: {
          uid: liveUser?.uid,
          password: liveUser?.password,
          muid: undefined,
          cid: liveClient?.cid
        }
      });
    return () => {};
  }, [globalDispatch, liveUser?.uid, liveUser?.password, liveClient?.cid]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 25");
    globalState.logging &&
      console.log(
        "AUTO LOGIN",
        globalState?.key,
        userState?.parsedToken?.worldid,
        liveClient,
        liveUser
      );
    liveClient?.cid &&
      globalState?.key &&
      (userState?.parsedToken?.worldid || liveUser?.uid) &&
      !liveUser?.password &&
      globalDispatch({
        type: "LOGIN",
        values: {
          uid: userState?.parsedToken?.worldid || liveUser?.uid,
          password: undefined,
          muid: globalState?.key,
          cid: liveClient?.cid
        }
      });
  }, [
    globalDispatch,
    globalState?.key,
    userState?.parsedToken?.worldid,
    liveClient,
    liveUser
  ]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 26");
    let topiclist = [];
    let tasklist = [];
    let firstTargetType;
    const pathArray = window.location.pathname.split("/");
    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i].startsWith("t_")) {
        !firstTargetType && (firstTargetType = "t_");
        let mtopic = pathArray[i];
        topiclist = [...topiclist, mtopic];
      }
      if (pathArray[i] === "logging_on") {
        localStorage.setItem("logging", "true");
        globalDispatch({
          type: "SET_LOGGING",
          values: { logging: true }
        });
      }
      if (pathArray[i] === "logging_off") {
        localStorage.setItem("logging", "false");
        globalDispatch({
          type: "SET_LOGGING",
          values: { logging: false }
        });
      }
    }

    // const paramsArray = window.location.search.substr(1).split("&");
    // let params = {};
    // for (let i = 0; i < paramsArray.length; ++i) {
    //   let param = paramsArray[i].split("=", 2);
    //   if (param.length !== 2) continue;
    //   params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, " ")).split(
    //     ","
    //   );
    // }

    // if (params.t !== null && params.t !== undefined) {
    //   for (let t = 0; t < params.t.length; ++t) {
    //     let j = {
    //       version: globalState.version,
    //       type: "w.topic.subscribe.mtopic",
    //       source: "link",
    //       mtopic: params.t[t],
    //       smid: randomString(8)
    //     };
    //     tasklist = [...tasklist, j];
    //   }
    // }

    tasklist?.length > 0 &&
      sessionStorage.setItem("tasklist", JSON.stringify(tasklist));

    // if (
    //   params.d !== null &&
    //   params.d !== undefined &&
    //   params.n !== null &&
    //   params.n !== undefined &&
    //   params.d.length === params.n.length
    // ) {
    //   // console.log("params: ", params);

    // }
    topiclist?.length > 0 &&
      sessionStorage.setItem("topiclist", JSON.stringify(topiclist));

    firstTargetType === "t_" &&
      sessionStorage.setItem("targetmtopic", topiclist[0]);

    if (topiclist?.length > 0) window.history.replaceState(null, "", "/");

    return () => {};
  }, [globalState.version]);

  useEffect(() => {
    globalState.logging && console.log("LiveData 27");
    if (databaseState.dexUser && liveMetaTopics?.length > 0) {
      let swExists = false;
      try {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          globalState.logging &&
            console.log("[LiveData] service workers", registrations);
          swExists = registrations.length > 1;
        });
      } catch (e) {
        console.log(e);
      }
      !swExists && globalState.logging && console.log("No service worker");
      !swExists &&
        liveMetaTopics?.length > 0 &&
        cleanMessages(
          databaseState.dexUser,
          liveMetaTopics,
          30, // Must be at least 30 minutes since last attemt at cleaning
          globalState.logging
        );
    }
    return () => {};
  }, [databaseState.dexUser, liveMetaTopics]);

  globalState.logging &&
    console.log("LiveData timer", new Date().getTime() - timer);
  return null;
};;;

export default React.memo(LiveData, (prevProps, nextProps) => {
  return true;
});
