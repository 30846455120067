import React, {
  useEffect,
  useContext,
  // useRef,
  useState,
  // useCallback,
  useMemo
} from "react";
import UITopic from "ui/UITopic";
// import UIDialog from "ui/UIDialog";
import { useHistory } from "react-router-dom";
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
// import DialogsContext from "contexts/contextDialogs";
import CountContext from "contexts/contextCount";
// import SubscriptionsContext from "contexts/contextSubscriptions";
import TopicsContext from "contexts/contextTopics";
import LiveCacheContext from "data/contextLiveCache";
import {
  randomString,
  timestamp,
  timestampCutoff,
  timestampZero,
  timestampCalculation
} from "hooks/helper";
// import UISuitePreview from "ui/UISuitePreview";
import PersonasContext from "contexts/contextPersonas";
// import SuitesContext from "contexts/contextSuites";
import {
  BsArchive,
  BsFolderMinus,
  BsFolderPlus,
  BsThreeDotsVertical
} from "react-icons/bs";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";
import { VscFolderOpened } from "react-icons/vsc";
import GetInitials from "../utils/GetInitials";
import StyleContext from "contexts/contextStyle";
import CalcStringColor from "../utils/CalcStringColor";
import GrabCharsFromWords from "../utils//GrabCharsFromWords";
import "ui/UIButtons.css";
// import { init } from "linkifyjs";
import { List, AutoSizer } from "react-virtualized";
import isEqual from "react-fast-compare";
import { dex_action } from "data/dexUtils";
// const isEqual = require("react-fast-compare");

const UITopicList = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  const { topicsState } = useContext(TopicsContext);
  const { countState } = useContext(CountContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  let history = useHistory();
  let targetdialog = sessionStorage.getItem("targetdialog");
  let targetmtopic = sessionStorage.getItem("targetmtopic");
  const [collectionCount, setCollectionCount] = useState({});
  const MAX_PINS = 5;
  const [topicDisplayList, setTopicDisplayList] = useState([]);

  const itemHeight = 68;
  const [scrollTop, setScrollTop] = useState(0);

  const sortArr = (data) => {
    data.sort((a, b) => {
      // const dateA = ((
      //   a.subprops &&
      //   a.subprops?.pinned === "true" &&
      //   timestampCalculation(
      //     a?.subprops?.pindate ||
      //     countState.last_visible_date?.[
      //     `${a.mtopic}_${a.mpersona}`
      //     ] ||
      //     a.last_visible_date ||
      //     timestamp(),
      //     "y",
      //     10
      //   )
      // )
      //   ||
      //   countState.last_visible_date?.[
      //   `${a.mtopic}_${a.mpersona}`
      //   ] ||
      //   a.last_visible_date ||
      //   a?.props?.creationdate ||
      //   "1970")
      const dateA =
        a.subprops?.pindate ||
        a.last_visible_date ||
        a.props?.creationdate ||
        "1970-01-01";
      // const dateB = ((
      //   b.subprops &&
      //   b.subprops?.pinned === "true" &&
      //   timestampCalculation(
      //     b?.subprops?.pindate ||
      //     countState.last_visible_date?.[
      //     `${b.mtopic}_${b.mpersona}`
      //     ] ||
      //     b.last_visible_date ||
      //     timestamp(),
      //     "y",
      //     10
      //   )
      // )
      //   ||
      //   countState.last_visible_date?.[
      //   `${b.mtopic}_${b.mpersona}`
      //   ] ||
      //   b.last_visible_date ||
      //   b?.props?.creationdate ||
      //   "1970")
      const dateB =
        b.subprops?.pindate ||
        b.last_visible_date ||
        b.props?.creationdate ||
        "1970-01-01";
      // Compare by the "pinned" field first then compare by the date fields in order of priority
      if (a.subprops?.pinned && !b.subprops?.pinned) {
        return -1;
      } else if (!a.subprops?.pinned && b.subprops?.pinned) {
        return 1;
      } else if (a.subprops?.pinned && b.subprops?.pinned) {
        return new Date(dateB) - new Date(dateA);
      } else return new Date(dateB) - new Date(dateA);
    });
    return data;
  };

  const toggleArchive = useMemo(
    () => () => {
      // setOpen(false);
      globalDispatch({
        type: "TOGGLE_ARCHIVE"
      });
    },
    []
  );

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScrnHgt(window.innerHeight);
  //     setChangeHgt(true);
  //   };
  //   window.addEventListener("resize", handleResize());
  //   return () => {
  //     window.removeEventListener("resize", handleResize());
  //   };
  // }, []);

  const activeAreaButton = useMemo(
    () => (display) => {
      // let displayHow = globalState.isLoggedIn ? display : "none";
      return (
        <div
          className="UI-collection"
          type="ezist"
          style={{
            // display: `${displayHow}`,
            backgroundColor: "var(--modal_footer_background_color)",
            cursor: "pointer"
          }}
          onClick={() => {
            toggleArchive();
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                width: "4rem",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                className="avatar-string"
                style={{ color: "black", backgroundColor: "white" }}
              >
                {globalState?.prefs?.showFolders ? (
                  "AR"
                ) : globalState?.displaying_archive ? (
                  <RiInboxUnarchiveLine />
                ) : (
                  <RiInboxArchiveLine />
                )}
              </div>
            </div>
          </div>
          <div className="UI-topic-inner" style={{ width: "100%" }}>
            <div className="UI-topic-tdn hide-scrollbar">
              <span>
                {globalState?.displaying_archive ? "ACTIVE" : "ARCHIVED"}
              </span>
            </div>
            <span className="mute-icon"></span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {globalState?.displaying_archive ? (
                globalState?.prefs?.showFolders ? (
                  <VscFolderOpened
                    style={{ width: "1.5em", height: "1.5em" }}
                  />
                ) : (
                  ""
                )
              ) : globalState?.prefs?.showFolders ? (
                <BsArchive style={{ width: "1.5em", height: "1.5em" }} />
              ) : (
                ""
              )}
            </div>
            <div className="UI-collection-count"></div>
          </div>
          <span className="UI-icon-dropdown">
            <BsThreeDotsVertical style={{ opacity: "0" }} />
          </span>
        </div>
      );
    },
    [
      globalState?.displaying_archive,
      globalState?.prefs?.showFolders,
      toggleArchive
    ]
  );

  // const folderHeader = (tag) => {
  //   let initials = getCollectionName(tag);
  //   let barColor =
  //     getHeaderBarColor(tag) || "var(--modal_footer_background_color)";
  //   return (
  //     <div
  //       className="UI-collection"
  //       type={tag.toLowerCase()}
  //       style={{
  //         backgroundColor: `${barColor}`,
  //         cursor: "pointer"
  //       }}
  //       onClick={(e) => {
  //         e.preventDefault();
  //         toggleCollectionState(tag, globalState.persona?.mpersona);
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: "4rem",
  //           display: "flex",
  //           justifyContent: "center"
  //         }}
  //       >
  //         <GetInitials str={initials} head_list={false}></GetInitials>
  //       </div>
  //       <div className="UI-topic-inner" style={{ width: "100%" }}>
  //         <div className="UI-topic-tdn hide-scrollbar">
  //           <span>{tag.toUpperCase()}</span>
  //         </div>
  //         <span></span>
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center"
  //           }}
  //         >
  //           <BsFolderMinus style={{ width: "1.5em", height: "1.5em" }} />
  //         </div>
  //       </div>
  //       <span className="UI-icon-dropdown">
  //         <BsThreeDotsVertical style={{ opacity: "0" }} />
  //       </span>
  //     </div>
  //   );
  // };

  useEffect(() => {
    if (props.collectionDisplay === "current") {
      return;
    }
    //let collectionState = liveCacheState.collectionState;
    if (!globalState.persona?.mpersona) {
      process.env?.REACT_APP_DEBUG_THIS?.includes("UITopicList") &&
        console.log("[UITopicList] do not know mpersona", globalState);
      return;
    }
    let collections = possibleCollections(globalState.persona?.mpersona);
    let collectionState = { ...liveCacheState?.collectionState } || {};
    process.env.REACT_APP_DEBUG_THIS &&
      console.log("[UITopicList] collectionState before", collectionState);

    collections.forEach((key) => {
      if (!collectionState[key]) {
        collectionState = {
          ...collectionState,
          [`${key}`]: {
            [`${globalState.persona.mpersona}`]: {
              open: props.collectionDisplay === "collapse" ? true : false
            }
          }
        };
      } else if (!collectionState[key][globalState.persona.mpersona]) {
        collectionState[key] = {
          ...collectionState[key],
          [`${globalState.persona.mpersona}`]: {
            open: props.collectionDisplay === "collapse" ? true : false
          }
        };
      } else {
        collectionState[key][globalState.persona.mpersona] = {
          open: props.collectionDisplay === "collapse" ? true : false
        };
      }
    });
    liveCacheDispatch({
      type: "SET",
      values: {
        cache: { collectionState: collectionState }
      }
    });
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: { key: "collectionstate", value: collectionState }
        }
      });
    props.setCollectionDisplay("current");

    process.env.REACT_APP_DEBUG_THIS &&
      console.log("[UITopicList] collectionState after", collectionState);
  }, [
    // props.collectionDisplay,
    databaseState.dexUser,
    props,
    // globalState,
    possibleCollections,
    liveCacheState?.collectionState,
    liveCacheDispatch,
    databaseDispatch
  ]);

  // useEffect(() => {
  //   topicsState.topicDescriptors &&
  //     applyTopicDescriptors(
  //       databaseState.dexUser,
  //       databaseDispatch,
  //       topicsState.topicDescriptors
  //     );
  //   return () => { };
  // }, [databaseDispatch, databaseState.dexUser, topicsState.topicDescriptors]);

  // useEffect(() => {
  //   let fontNameTmp = styleState?.theme?.fonts?.topic_name_main_screen_font;
  //   if (fontNameTmp) {
  //     setFontFam(fontNameTmp.substring(0, fontNameTmp.length - 3));
  //     setFontWeight(
  //       fontNameTmp.substring(fontNameTmp.length - 3, fontNameTmp.length)
  //     );
  //   }
  // }, [styleState?.theme]);

  // useEffect(() => {
  //   const sameLists = (a, b) => {
  //     return a?.length === b?.length && a?.every((e, i) => b.includes(e));
  //   };
  //   let list = [...(topicsState.topics || []).map((t) => t.mtopic)];
  //   !sameLists(list, allMtopics) && setAllMtopics([...list]);
  //   return () => {};
  // }, [allMtopics, topicsState.topics]);

  useEffect(() => {
    if (targetmtopic) {
      topicsState.topics &&
        topicsState.topics.map((sub) => {
          if (sub.mtopic && sub.mtopic === targetmtopic) {
            sessionStorage.removeItem("targetmtopic");
            sessionStorage.removeItem("targetdialog");
            // if targetmtopic exists, navigate directly to that topic
            // switch to the firsctt subscribtion's persona
            let mpersona = sub.mpersona;
            let persona =
              mpersona &&
              personasState.personas.filter((p) => p.mpersona === mpersona)[0]
                ?.persona;
            persona &&
              globalDispatch({
                type: "SET_PERSONA",
                values: { persona: { mpersona: mpersona, persona: persona } }
              });
            history.push("/UIMessageList", {
              subscription: sub
            });
          }
        });
      // subscriptionsState.subscriptions &&
      //   subscriptionsState.subscriptions.map((sub) => {
      //     if (sub.mtopic && sub.mtopic === targetmtopic) {
      //       sessionStorage.removeItem("targetmtopic");
      //       sessionStorage.removeItem("targetdialog");
      //       // if targetmtopic exists, navigate directly to that topic
      //       history.push("/UIMessageList", {
      //         subscription: sub
      //       });
      //     }
      //   });
    }
    // else if (targetdialog) {
    //   dialogsState.dialogs &&
    //     dialogsState.dialogs.map((dialog) => {
    //       if (dialog.peer && dialog.peer === targetdialog) {
    //         sessionStorage.removeItem("targetdialog");
    //         // if targetdialog exists, navigate directly to that dialog
    //         history.push("/UIMessageList", {
    //           dialog: dialog
    //         });
    //       }
    //     });
    // }

    return () => {};
  }, [
    // subscriptionsState.subscriptions,
    // dialogsState.dialogs,
    personasState.personas,
    targetdialog,
    targetmtopic,
    topicsState.topics,
    history,
    globalDispatch
  ]);

  const [listItems, setListItems] = useState([]);
  // const [listCategories, setListCategories] = useState([]);

  const getIdentity = (sub) => {
    let theIdentity =
      sub?.props?.topiccollection ||
      sub?.props?.tag ||
      sub?.visibility ||
      sub?.props?.visibility;
    theIdentity = theIdentity.toString();
    theIdentity = theIdentity.toLowerCase();
    return theIdentity;
  };

  const possibleCollections = useMemo(
    () => (mpersona) => {
      let list = [
        ...(topicsState.topics || [])
          .filter(
            (t) =>
              t.mpersona === mpersona &&
              (t?.subprops?.archived || "false") ===
                (globalState.displaying_archive ? "true" : "false")
          )
          .map((t) => getIdentity(t))
        // ...(subscriptionsState.subscriptions || [])
        // .filter(
        //   (t) =>
        //     t.mpersona === mpersona &&
        //     (t?.subprops?.archived || "false") ===
        //       (globalState.displaying_archive ? "true" : "false")
        // )
        // .map((t) => getIdentity(t))
        // ...(dialogsState.dialogs || [])
        //   .filter(
        //     (t) =>
        //       t.mpersona === mpersona &&
        //       (t?.subprops?.archived || "false") ===
        //         (globalState.displaying_archive ? "true" : "false")
        //   )
        //   .map((t) => getIdentity(t))
      ];
      return [...new Set(list)];
    },
    [
      // dialogsState.dialogs,
      globalState.displaying_archive,
      // subscriptionsState.subscriptions,
      topicsState.topics
    ]
  );

  const assignCollection = (sub) => {
    if (liveCacheState?.collectionState[getIdentity(sub)])
      if (
        !liveCacheState?.collectionState[getIdentity(sub)][
          globalState.persona.mpersona
        ]?.open ||
        sub.subprops?.pinned === "true"
      )
        return true;
      else return false;
    else return true;
  };

  const toggleCollectionState = useMemo(
    () => (topiccollection, mpersona) => {
      let collectionState = { ...liveCacheState?.collectionState } || {};
      collectionState[topiccollection] = collectionState[topiccollection] || {};
      collectionState[topiccollection][mpersona] = {
        open: !collectionState[topiccollection][mpersona]?.open
      };
      liveCacheDispatch({
        type: "SET",
        values: { cache: { collectionState: collectionState } }
      });
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "user",
            doc: { key: "collectionstate", value: collectionState }
          }
        });
      props.setCollectionDisplay("current");
    },
    [
      databaseDispatch,
      databaseState.dexUser,
      liveCacheDispatch,
      liveCacheState?.collectionState,
      props.setCollectionDisplay
    ]
  );

  useEffect(() => {
    let data =
      topicsState.topics &&
      globalState.persona?.mpersona &&
      topicsState.topics.filter(
        (item) => item.mpersona === globalState.persona?.mpersona
      );
    if (data) {
      let pinnedList = data
        .filter(
          (i) =>
            i &&
            (!i?.subprops?.archived || "false") ===
              (globalState.displaying_archive !== undefined
                ? globalState.displaying_archive
                  ? "true"
                  : "false"
                : "false")
        )
        .filter((i) => i.subprops?.pinned === "true");
      pinnedList = sortArr(pinnedList);
      let tagList = [];
      let listArea = data
        .filter(
          (i) =>
            i &&
            (i?.subprops?.archived || "false") ===
              (globalState.displaying_archive !== undefined
                ? globalState.displaying_archive
                  ? "true"
                  : "false"
                : "false")
        )
        .filter((i) => i.subprops?.pinned !== "true");
      listArea.forEach((a) => {
        let tagId = (
          a.props?.topiccollection ||
          a.props?.tag ||
          a.visibility ||
          a.props?.visibility ||
          "no_tag"
        ).toLowerCase();
        !(a.pinned === "true") &&
          tagId !== "no_tag" &&
          !tagList.includes(tagId) &&
          tagList.push(tagId);
      });
      let allFolders = [];
      tagList
        .sort((a, b) => {
          return a < b ? 1 : -1;
        })
        .forEach((tag) => {
          let folder = [];
          if (
            !liveCacheState?.collectionState?.[tag]?.[
              globalState?.persona?.mpersona
            ]?.open
          )
            folder = listArea.filter((a) => {
              if (
                tag ===
                  (
                    a.props?.topiccollection ||
                    a.props?.tag ||
                    a.visibility ||
                    a.props?.visibility ||
                    "no_tag"
                  ).toLowerCase() &&
                a.subprops?.pinned !== "true"
              ) {
                return a;
              }
            });
          let newObj = {
            id: "folder_" + tag,
            mpersona: globalState.persona?.mpersona,
            folderTag: tag
          };
          // .sort((a, b) => {
          //   if (a.startsWith("3000-") && b.startsWith("3000-")) {
          //     return a > b ? 1 : -1;
          //   } else {
          //     return a < b ? 1 : -1;
          //   }let
          // });

          allFolders = [newObj, ...folder, ...allFolders];
        });
      // concat the pins + folders
      allFolders = [...pinnedList, ...allFolders];
      setListItems(allFolders);
    }
  }, [
    topicsState.topics,
    globalState.persona?.mpersona,
    globalState.displaying_archive,
    globalState?.prefs?.showFolders,
    props.share,
    liveCacheState?.collectionState,
    JSON.stringify(countState.last_visible_date)
  ]);

  const togglePin = useMemo(
    () => (topic) => {
      process.env?.REACT_APP_DEBUG_THIS?.includes("UITopicList") &&
        console.log("[UITopicList] toggle pin for topic", topic);
      let pinnedValue = topic.subprops?.pinned === "true" ? "false" : "true";
      let pinDate = pinnedValue === "true" ? timestamp() : "";
      let j = {
        type: "w.t.subprops.set",
        mtopic: topic.mtopic,
        mpersona: topic.mpersona,
        new_props: { pinned: pinnedValue, pindate: pinDate },
        version: globalState.version,
        smid: randomString(8),
        ts_sender: timestampZero(),
        status: "1",
        origin_sender: "registered"
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value?.forEach((t) => {
            if (t.mpersona === topic.mpersona && t.mtopic === topic.mtopic) {
              t.subprops.pinned = pinnedValue;
              t.subprops.pindate = pinDate;
            }
          });
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
    },
    [databaseDispatch, databaseState.dexUser, globalState.version]
  );

  useEffect(() => {
    let pinnedList =
      topicsState?.topics?.filter((topic) => {
        return (
          topic.mpersona === globalState.persona?.mpersona &&
          topic.subprops?.pinned &&
          topic.subprops?.pinned === "true"
        );
      }) || [];
    if (pinnedList?.length > MAX_PINS) {
      let sortedPins = pinnedList.sort((a, b) => {
        return a.subprops?.pindate > b.subprops?.pindate ? 1 : -1;
      });
      // unpin the oldest if there are more than MAX_PINS
      togglePin(sortedPins[0]);
    }
    return () => {};
  }, [topicsState?.topics]);

  useEffect(() => {
    let countSummary = {};
    topicsState?.topics &&
      topicsState.topics.forEach((t) => {
        // must exclude if topic is in a Suite
        let exclude = [];
        let tag = getIdentity(t);
        if (
          t.mpersona === globalState.persona?.mpersona &&
          !exclude.includes(t.mtopic) &&
          t.subprops?.pinned !== "true" // pinned topics will not be included in collections
        ) {
          if (
            countState?.count &&
            countState?.count[`${t.mtopic}_${t.mpersona}`]
          ) {
            if (countSummary[tag]) {
              countSummary[tag].unread =
                (countSummary[tag]?.unread ? countSummary[tag].unread : 0) +
                (countState?.count[`${t.mtopic}_${t.mpersona}`].unread > 0
                  ? 1
                  : 0);
              countSummary[tag].activity = countState?.count[
                `${t.mtopic}_${t.mpersona}`
              ]?.activity
                ? countState?.count[`${t.mtopic}_${t.mpersona}`]?.activity >
                  countSummary[tag]?.activity
                  ? countState?.count[`${t.mtopic}_${t.mpersona}`]?.activity
                  : countSummary[tag]?.activity
                : "1970";
            } else {
              countSummary[tag] = {
                unread:
                  countState?.count[`${t.mtopic}_${t.mpersona}`]?.unread > 0
                    ? 1
                    : 0,
                activity:
                  countState?.count[`${t.mtopic}_${t.mpersona}`]?.activity ||
                  "1970"
              };
            }
          }
        }
      });

    // sort by date ascending
    let x;
    let dateArr = Object.entries(countSummary)
      .map((entry) => (x = entry[1].activity))
      .sort()
      .reverse();
    // rebuild collection count summary
    let sortedByAge = {};
    dateArr.forEach((dateEntry) => {
      Object.keys(countSummary).forEach((key) => {
        if (countSummary[key].activity === dateEntry)
          sortedByAge[key] = JSON.parse(JSON.stringify(countSummary[key]));
      });
    });

    setCollectionCount({ ...sortedByAge });
    process.env?.REACT_APP_DEBUG_THIS?.includes("UITopicList") &&
      console.log("[UITopicList] sorted collections", sortedByAge);
    return () => {};
  }, [
    // dialogsState.dialogs,
    // subscriptionsState.subscriptions,
    topicsState.topics,
    JSON.stringify(countState.count),
    liveCacheState.collectionState,
    // suitesState.suites,
    globalState.persona?.mpersona
  ]);

  const getCollectionName = (key) => {
    switch (key.toUpperCase()) {
      case "PRIVATE_DIALOG":
        return "DIALOG";
      case "PRIVATE":
        return "PRIVATE";
      case "HIDDEN":
        return "HIDDEN";
      default:
        return key;
    }
  };

  const calcCollectionLogoTransparency = () => {
    let root = document.getElementById("root");
    let tVal = root.style.getPropertyValue("--logo_background_transparency");
    if (tVal === undefined || !tVal) tVal = 0.5;
    tVal = 1 - tVal;
    tVal = tVal / 10 + 0.1;
    return tVal;
  };

  const getHeaderBarColor = (tag, initialsStr = "") => {
    let backColorProp =
      tag?.toUpperCase() === "PRIVATE_DIALOG" ? "dialog" : tag.toLowerCase();
    backColorProp =
      "--main_screen_topic_type_" + backColorProp + "_background_color";
    let currentColorProp = document
      .getElementById("root")
      .style.getPropertyValue(backColorProp);
    if (currentColorProp.length === 0) {
      initialsStr.length === 0 &&
        (initialsStr = GrabCharsFromWords(
          getCollectionName(tag),
          2,
          0,
          1,
          "."
        ).toUpperCase());
      const [red, green, blue] = CalcStringColor(initialsStr);
      backColorProp = `rgba(${red}, ${green}, ${blue}, ${calcCollectionLogoTransparency()})`;
    } else {
      backColorProp = currentColorProp;
    }
    return backColorProp;
  };

  useEffect(() => {
    let data =
      topicsState.topics &&
      globalState.persona?.mpersona &&
      topicsState.topics.filter(
        (item) => item.mpersona === globalState.persona?.mpersona
      );
    // if (data) {
    //   !globalState?.prefs?.showFolders &&
    //     // ? buildFolderViewList(data)
    //     // :
    //     setListItems(
    //       data
    //         .filter(
    //           (i) =>
    //             i?.item &&
    //             (i?.item?.props?.sub?.subprops?.archived || "false") ===
    //             (globalState.displaying_archive ? "true" : "false")
    //         )
    //       // .sort((a, b) => {
    //       //   if (a.order.startsWith("3000-") && b.order.startsWith("3000-")) {
    //       //     return a.order > b.order ? 1 : -1;
    //       //   } else {
    //       //     return a.order < b.order ? 1 : -1;
    //       //   }
    //       // })
    //     );
    // }
    if (data && !globalState?.prefs?.showFolders) {
      // if (data) {
      data = sortArr(data);
      // data.sort((a, b) => {
      //   const dateA = a.subprops.pindate || a.last_visible_date || a.props.creationdate || '1970-01-01';
      //   const dateB = b.subprops.pindate || b.last_visible_date || b.props.creationdate || '1970-01-01';
      //   // Compare by the "pinned" field first then compare by the date fields in order of priority
      //   if (a.subprops.pinned && !b.subprops.pinned) {
      //     return -1;
      //   } else if (!a.subprops.pinned && b.subprops.pinned) {
      //     return 1;
      //   }
      //   else if (a.subprops.pinned && b.subprops.pinned) {
      //     return new Date(dateB) - new Date(dateA);
      //   }
      //   else
      //     return new Date(dateB) - new Date(dateA);
      // });
      let displayList;
      if (globalState.displaying_archive === true) {
        displayList = data.filter((item) => item.subprops?.archived === "true");
      } else {
        displayList = data.filter((item) => item.subprops?.archived !== "true");
      }
      displayList = displayList.filter((item) => assignCollection(item));

      let possible = possibleCollections(globalState.persona?.mpersona);
      let keysCollection = liveCacheState?.collectionState
        ? Object.keys(liveCacheState?.collectionState)
            .filter((key) => !key.startsWith("_"))
            .filter((key) => possible.includes(key))
        : [];
      // test if the planned collection correlates with the chronologically sorted collection.
      // simple test but efficient enough to point out bugs (chron order will be broken)
      let keysToUse = [];
      if (
        keysCollection &&
        keysCollection.length === Object.keys(collectionCount)?.length
      ) {
        keysToUse = Object.keys(collectionCount);
      } else {
        keysToUse = keysCollection;
      }

      let myTestObj = {};
      keysToUse.forEach((key) => {
        if (
          liveCacheState?.collectionState[key] &&
          liveCacheState?.collectionState[key][globalState.persona?.mpersona] &&
          liveCacheState?.collectionState[key][globalState.persona?.mpersona]
            .open
        ) {
          myTestObj = {
            id: "collection_" + key,
            key: key,
            mpersona: globalState.persona?.mpersona,
            name: getCollectionName(key).toUpperCase()
          };
          displayList = [...displayList, myTestObj];
        }
      });
      setTopicDisplayList(displayList);
    } else {
      if (data) {
        setTopicDisplayList(listItems);
      }
    }
  }, [
    topicsState.topics,
    globalState.persona?.mpersona,
    globalState.displaying_archive,
    globalState?.prefs?.showFolders,
    props.share,
    liveCacheState?.collectionState,
    JSON.stringify(countState.last_visible_date),
    // listCategories
    listItems
  ]);

  //tedtest dunno why I put this here last commit - and it was commented then as well?
  // useEffect(() => {
  // if (uitopiclistRef.current && innerRef.current) {
  //   const height = uitopiclistRef.current.clientHeight - 60;
  //   innerRef.current.style.height = `${height}`;
  // }
  // if (uitopiclistRef.current) {
  //   setContentHeight(uitopiclistRef.current.clientHeight - 60);
  // innerRef.current.style.height = `${height}`;
  //   }
  // }, []);

  const DisplayCollection = (props) => {
    // console.log("tedtest1 displaycollection")
    return (
      <div>
        <div
          className="UI-topic_forwithpreview"
          key={props.collKey}
          // className="UI-collection"
          type={
            props?.collKey?.toUpperCase() === "PRIVATE_DIALOG"
              ? "dialog"
              : props.collKey
          }
          style={{
            backgroundColor: getHeaderBarColor(props.collKey)
          }}
          onClick={(e) => {
            e.preventDefault();
            toggleCollectionState(
              props?.collKey,
              globalState.persona?.mpersona
            );
          }}
        >
          <div
            className="avatar"
            style={{
              width: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <GetInitials
              str={getCollectionName(props?.collKey)}
              head_list={false}
              type="topic"
            ></GetInitials>
          </div>

          <div className="UI-topic-link-with-t">
            <div className="UI-topic-inner">
              <div className="UItopic-tdn hide-scrollbar">
                {getCollectionName(props?.collKey).toUpperCase()}
              </div>
            </div>
          </div>

          <div className="rgtContainer">
            <span className="mute-icon"></span>
            {/* this must be blank */}
            <span className="mute-icon"></span>
            {/* this must be blank */}
            {globalState?.prefs?.showFolders ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <BsFolderPlus style={{ width: "1.5em", height: "1.5em" }} />
              </div>
            ) : (
              <label className="UI-topics-icon">collection</label>
            )}
            <div className="UI-topic-count" type="collection">
              {collectionCount[props.collKey]?.unread &&
              collectionCount[props.collKey].unread > 0 ? (
                collectionCount[props.collKey].unread
              ) : (
                <></>
              )}
            </div>
          </div>
          <ClickOutsideDetector caller="iconDropDown">
            <div className="UI-icondropdown"></div>
          </ClickOutsideDetector>
        </div>
      </div>
    );
  };

  const DisplayFolderPanel = (props) => {
    let initials = getCollectionName(props.tag);
    let barColor =
      getHeaderBarColor(props.tag) || "var(--modal_footer_background_color)";

    return (
      <div
        className="UI-topic_forwithpreview"
        // className="UI-collection"
        type={props.tag.toLowerCase()}
        style={{
          backgroundColor: `${barColor}`,
          cursor: "pointer"
        }}
        onClick={(e) => {
          e.preventDefault();
          toggleCollectionState(props.tag, globalState.persona?.mpersona);
        }}
      >
        <div
          style={{
            width: "4rem",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <GetInitials str={initials} head_list={false}></GetInitials>
        </div>
        <div className="UI-topic-inner" style={{ width: "100%" }}>
          <div className="UI-topic-tdn hide-scrollbar">
            <span>{props.tag.toUpperCase()}</span>
          </div>
          <span></span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <BsFolderMinus style={{ width: "1.5em", height: "1.5em" }} />
          </div>
        </div>
        <span className="UI-icon-dropdown">
          <BsThreeDotsVertical style={{ opacity: "0" }} />
        </span>
      </div>
    );
  };

  useEffect(() => {
    // Restore scroll position and selected index after returning to the page
    const storedScrollTop = sessionStorage.getItem("listScrollTop");
    if (storedScrollTop) {
      try {
        setScrollTop(parseInt(storedScrollTop));
      } catch (error) {
        console.error("Error parsing stored index:", error);
      }
    }
  }, []);

  const handleScroll = ({ scrollTop }) => {
    setScrollTop(scrollTop);
  };

  useEffect(() => {
    sessionStorage.setItem("listScrollTop", scrollTop);
  }, [scrollTop]);

  let content = useMemo(() => {
    const rowRenderer = ({ index, key, style }) => {
      const item = topicDisplayList[index];
      return (
        <div
          key={key}
          style={{
            ...style,
            overflow: "hidden",
            marginBottom: "5px"
          }}
        >
          {globalState.persona?.mpersona === item.mpersona ? (
            // assignCollection(item) &&
            item.id?.includes("collection_") ? (
              <DisplayCollection collKey={item.key} />
            ) : item.id?.includes("folder_") ? (
              <DisplayFolderPanel tag={item.folderTag} />
            ) : (
              <UITopic
                key={index}
                sub={item}
                share={props.share}
                togglePin={togglePin}
              ></UITopic>
            )
          ) : null}
        </div>
      );
    };
    return (
      <div
        className={
          globalState.showPersonaScreen || globalState.showWorldIDScreen
            ? "UI-topic-list-container-hidden"
            : "UI-topic-list-container"
        }
      >
        <div id="list_holder" className="UI-topic-list">
          <div
            style={{
              position: "relative",
              maxHeight: "100%",
              height: "inherit"
            }}
          >
            {topicDisplayList && (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    overscanRowCount={10}
                    rowCount={topicDisplayList.length}
                    rowHeight={itemHeight}
                    width={width}
                    height={height}
                    rowRenderer={rowRenderer}
                    onScroll={handleScroll} // attach onScroll handler to the List component
                    scrollTop={scrollTop}
                  ></List>
                )}
              </AutoSizer>
            )}
          </div>
        </div>
        {activeAreaButton(globalState?.displaying_archive ? "none" : "")}
      </div>
    );
  }, [
    activeAreaButton,
    globalState?.displaying_archive,
    globalState.persona?.mpersona,
    globalState.showPersonaScreen,
    globalState.showWorldIDScreen,
    topicDisplayList,
    props.share,
    scrollTop,
    togglePin
  ]);

  return content;
};

export default React.memo(UITopicList, (prevProps, nextProps) => {
  return true; //isEqual(prevProps, nextProps);
});
