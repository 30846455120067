import React, {
  useState,
  useContext,
  createRef,
  useEffect,
  useRef,
  useMemo,
} from "react";
import "ui/UI.css";
import DatabaseContext from "data/contextDatabase";
// import LiveCacheContext from "data/contextLiveCache";
import GlobalContext from "contexts/context";
import TopicContext from "contexts/contextTopic";
import UIMessage from "ui/UIMessage";
import { onScrollChange } from "utils/scrollTransition";
import { ratioOverlap } from "utils/percentWithin";
import { cacheS3Theme, storeImage, getImage64 } from "data/descriptors";
import LiveCacheContext from "data/contextLiveCache";
import { secondsSinceEpoch } from "hooks/helper";
import UIGoToButton from "ui/UIGoToButton";
import TopicsContext from "contexts/contextTopics";
import { rest_api } from "connectivity/API";
import PersonasContext from "contexts/contextPersonas";
import { s3ToImage } from "connectivity/s3";
import { moveAttachments } from "utils/attach";
import { useAccountDex } from "hooks/dexHooks";
import { shouldLog } from "utils/shouldLog";
import { useLiveQuery } from "dexie-react-hooks";
import { isHide, isRead, isUnread, lastReadMessage } from "utils/isRead";
import { dex_action } from "data/dexUtils";

const isEqual = require("react-fast-compare");
var lastUnread = 0;

let notifySound = new Audio("/icons/pluck.mp3");
notifySound.volume = 0.1;

const UIMsgList = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const { personasState } = useContext(PersonasContext);
  const { liveCacheState } = useContext(LiveCacheContext);
  const { globalState } = useContext(GlobalContext);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const [refs, setRefs] = useState([]);
  const [initHistReq, setInitHistReq] = useState(true);
  const topic = props.topic;
  const topicPrefix = topic.mtopic || topic.mdialog;
  const [liveMessages, setLiveMessages] = useState(undefined);
  const [liveItems, setLiveItems] = useState(undefined);
  const [lastMessage, setLastMessage] = useState(undefined);
  const [lastMsgInView, setLastMsgInView] = useState(false);
  const [sendMessages, setSendMessages] = useState([]);
  const [descriptors, setDescriptors] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const avatarTimeLapse = 1800;
  const [uiMessages, setUiMessages] = useState(undefined);
  const [dimensions, setDimensions] = useState({});
  const [origHgt, setOrigHgt] = useState(0);
  const pinVal = 80;
  const chatVal = 100;
  const [changePins, setChangePins] = useState(10);
  const [changeChat, setChangeChat] = useState(20);
  const [visible_all, setVisible_all] = useState(undefined);
  const [readOnOpen, setReadOnOpen] = useState(true);

  const calcDateTimeDifference = (dt1, dt2) => {
    //dt1 and dt2 in format YYYY-MM-DD HH:MM:SS (SS in dec)  eg.2022-03-21 15:40:49.407629"
    let diffSeconds = (Date.parse(dt2) - Date.parse(dt1)) / 1000;
    return diffSeconds;
  };

  const [initMissing, setInitMissing] = useState(true);

  const jwt = useLiveQuery(
    () =>
      databaseState.dexAdmin &&
      databaseState.dexAdmin.store.get({ key: "user" }).then((u) => u?.jwt),
    [databaseState.dexAdmin]
  );

  const liveSendMessages = useLiveQuery(
    () =>
      databaseState.dexUser &&
      databaseState.dexUser.send
        .filter(
          (s) =>
            s.type === "w.t.msg" &&
            s.mtopic === topic.mtopic &&
            s.mpersona === topic.mpersona
        )
        .toArray((d) => d),
    [databaseState.dexUser, topic.mtopic, topic.mpersona],
    []
  );

  const meta = useLiveQuery(
    () =>
      databaseState.dexUser &&
      databaseState.dexUser.topic_metadata_buffer.get({
        mtopic: topic.mtopic,
        mpersona: topic.mpersona
      }),
    [databaseState.dexUser, topic.mtopic, topic.mpersona],
    []
  );

  useEffect(() => {
    notifySound.volume = globalState?.prefs?.clientMute ? 0 : 0.1;
  }, [globalState?.prefs?.clientMute]);

  useEffect(() => {
    process.env.REACT_APP_DEBUG_THIS?.includes("dbl") &&
      console.log("[UIMsgList] liveSendMessages", liveSendMessages);
    if (liveMessages && liveSendMessages) {
      let smidMsgs = liveMessages.map((m) => m.smid) || [];
      liveSendMessages
        .filter((m) => smidMsgs.includes(m.smid))
        .forEach((m) => {
          topicsDispatch({
            type: "REMOVE_SEND",
            values: { smid: m.smid }
          });
        });
    }
    setSendMessages(
      liveSendMessages?.filter((m) => m.mtopic === topic.mtopic) || []
    );
    return () => {};
  }, [liveMessages, liveSendMessages]);

  const handleBackBrowserBtn = () => {
    sessionStorage.removeItem("imgycoord");
  };

  useEffect(() => {
    window.addEventListener("popstate", handleBackBrowserBtn);
    return () => {
      window.removeEventListener("popstate", handleBackBrowserBtn);
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    topicState?.messages?.items &&
      mounted &&
      setLiveItems(topicState.messages.items);
    props.pin === 1 &&
      topicState?.messages?.pinned &&
      mounted &&
      setLiveMessages(topicState.messages.pinned);
    props.pin === 0 &&
      topicState?.messages?.unpinned &&
      mounted &&
      setLiveMessages(topicState.messages.unpinned);
    return () => {
      mounted = false;
    };
  }, [props.pin, topicState?.messages]);

  const dateOnlyFormat = (s) => {
    let newstr = s.replace(" ", "T");
    const d = new Date(newstr + "Z");
    return (
      d.toLocaleDateString(window.navigator.language, { weekday: "short" }) +
      " " +
      d.toLocaleDateString()
    );
  };

  const timestamp = (pref1, pref2, pref3) => {
    if (pref1 && pref1 !== "" && pref1 !== "undefined") {
      return pref1;
    } else if (pref2 && pref2 !== "" && pref2 !== "undefined") {
      return pref2;
    } else if (pref3 && pref3 !== "" && pref3 !== "undefined") {
      return pref3;
    }
    return "";
  };

  useEffect(() => {
    liveMessages &&
      setRefs(
        liveMessages
          .filter(
            (m) =>
              !(
                m.parameters?.display === false ||
                m.parameters?.display === "false"
              )
          )
          .reduce((acc, m) => {
            let ext =
              m.mpersona_rx && m.mpersona_rx.length > 0
                ? "_" + m.recipient
                : "";
            acc[
              topicPrefix +
                "-" +
                (m.msgid || m.smid) +
                "-" +
                (m.msg_idx || m.msgid || m.smid) +
                ext
            ] = createRef();
            return acc;
          }, {})
      );
    return () => {};
  }, [liveMessages, setRefs, topicPrefix]);

  const firstUnread = useRef(null);
  const topRef = useRef(null);
  const lastRef = useRef(null);
  const scrollToLast = useRef(false);
  const msgContainer = useRef(null);
  // const msgListContainer = useRef(null)

  useEffect(() => {
    // reverse the message list
    // for each message, check if a persona has that msg read or delivered
    // if so, add the persona to the list for that message and remove the persona's item
    let mounted = true;
    let summary = topicState.participants?.reduce((acc, p) => {
      let max_read =
        p &&
        liveMessages?.reduce(
          (max_idx, m) =>
            m.msg_idx <= p?.props?.subscription_state?.read?.msg_idx &&
            m.msg_idx > max_idx
              ? //   &&
                // p.mpersona !== m.mpersona &&
                // p.mpersona !== m.recipient
                m.msg_idx
              : max_idx,
          -1
        );
      let max_delivered =
        p &&
        liveMessages?.reduce(
          (max_idx, m) =>
            m.msg_idx <= p?.props?.subscription_state?.delivered?.msg_idx &&
            m.msg_idx > max_idx &&
            (!m.mpersona_rx || m.mpersona_rx.includes(p.mpersona))
              ? //   &&
                // p.mpersona !== m.mpersona &&
                // p.mpersona !== m.recipient
                m.msg_idx
              : max_idx,
          -1
        );
      acc[p.mpersona] = {
        persona: p.persona,
        read: max_read,
        delivered: max_delivered
      };
      return acc;
    }, {});
    mounted &&
      topicDispatch({
        type: "SET_RECEIPTS",
        values: { receipts: summary }
      });
    return () => {
      topicDispatch({
        type: "SET_RECEIPTS",
        values: { receipts: {} }
      });
      mounted = false;
    };
  }, [liveMessages, topicDispatch, topicState.participants]);

  const addTheme = useMemo(
    () => (themeBody) => {
      let bodyItems = themeBody.split("#");
      let themeDigest = bodyItems[1].trim().split("/");
      cacheS3Theme(databaseState.dexUser, themeDigest[1], themeDigest[2]);
    },
    [databaseState.dexUser]
  );

  // *** Code to ref the firstUnread and the last messages
  useEffect(() => {
    if (liveMessages?.length > 0) {
      let last =
        liveMessages &&
        liveMessages[liveMessages.length - 1] &&
        topicPrefix +
          "-" +
          (liveMessages[liveMessages.length - 1].msgid ||
            liveMessages[liveMessages.length - 1].smid) +
          "-" +
          (liveMessages[liveMessages.length - 1].msg_idx ||
            liveMessages[liveMessages.length - 1].msgid ||
            liveMessages[liveMessages.length - 1].smid);
      lastRef.current = last;

      // Find the first unread message and mark it for scrolling
      // If all messages are already read, then mark the last msg for scrolling
      let firstUn = liveMessages.filter((m) => m.msg_idx && isUnread(m))[0];
      let ext =
        firstUn?.mpersona_rx && firstUn.mpersona_rx.length > 0
          ? "_" + firstUn.recipient
          : "";
      let ext2 =
        liveMessages[liveMessages.length - 1]?.mpersona_rx &&
        liveMessages[liveMessages.length - 1]?.mpersona_rx.length > 0
          ? "_" + liveMessages[liveMessages.length - 1]?.recipient
          : "";

      let func =
        liveMessages.length > 0 &&
        (firstUn
          ? {
              ref:
                topicPrefix +
                "-" +
                (firstUn.msgid || firstUn.smid) +
                "-" +
                (firstUn.msg_idx || firstUn.msgid || firstUn.smid) +
                ext,
              used: firstUnread.current?.used || false
            }
          : {
              ref:
                topicPrefix +
                "-" +
                (liveMessages[liveMessages.length - 1].msgid ||
                  liveMessages[liveMessages.length - 1].smid) +
                "-" +
                (liveMessages[liveMessages.length - 1].msg_idx ||
                  liveMessages[liveMessages.length - 1].msgid ||
                  liveMessages[liveMessages.length - 1].smid) +
                ext2,
              used: firstUnread.current?.used || false
            });

      firstUnread.current = func;
    }

    return () => {};
  }, [liveMessages]);
  // *** end of code to ref the firstUnread and the last messages

  useEffect(() => {
    liveMessages &&
      liveMessages[liveMessages.length - 1]?.msg_idx &&
      topic.mpersona != liveMessages[liveMessages.length - 1].mpersona &&
      !liveMessages[liveMessages.length - 1].read &&
      lastUnread != liveMessages[liveMessages.length - 1].msg_idx &&
      (lastUnread = liveMessages[liveMessages.length - 1].msg_idx) &&
      !(
        liveCacheState?.mute &&
        liveCacheState?.mute[topic?.mtopic || topic?.mdialog]?.mute >
          secondsSinceEpoch()
      ) &&
      notifySound.play();

    return () => {};
  }, [liveMessages]);

  const checkBottom = useMemo(
    () => () => {
      if (lastMessage) {
        let parent = document.getElementById(
          topicPrefix +
            "-" +
            "message-list" +
            (props.pin ? "-pin-" + props.pin : "")
        );

        let exts =
          lastMessage.mpersona_rx && lastMessage.mpersona_rx.length > 0
            ? "_" + lastMessage.recipient
            : "";
        let element = document.getElementById(
          topicPrefix +
            "-" +
            (lastMessage.msgid || lastMessage.smid) +
            "-" +
            (lastMessage.msg_idx || lastMessage.msgid || lastMessage.smid) +
            exts
        );

        let itemInView = {
          item: lastMessage,
          idx: element?.getAttribute("idx"),
          overlap: element && ratioOverlap(parent, element)
        };

        let atBottom =
          lastMessage && itemInView.overlap?.heightRatioOfPossible > 0.7;
        // if at bottom then scroll to new messages
        scrollToLast.current = atBottom;
        setLastMsgInView(atBottom);
      }

      // lastMessage is needed to prevent the test being performed on the last message in the list,
      // which would be the newly arrived message

      liveMessages?.length &&
        setLastMessage(liveMessages[liveMessages.length - 1]);
    },
    [uiMessages, props.pin, topicPrefix]
  );

  useEffect(() => {
    // Should we scroll? And if so, where to?
    // If bottom is in view, scroll to next (lower) unread message

    let mounted = true;
    // console.log("[scroll]", scrollToLast.current, firstUnread.current);
    if (!sessionStorage.getItem("imgycoord")) {
      if (refs && uiMessages) {
        if (
          !firstUnread.current?.used &&
          firstUnread.current?.ref &&
          refs[firstUnread.current?.ref] &&
          refs[firstUnread.current?.ref]?.current
        ) {
          refs[firstUnread.current?.ref]?.current.scrollIntoView({
            behavior: "auto",
            block: "end",
            inline: "end"
          });
          if (mounted) firstUnread.current.used = true;
        }
        if (
          scrollToLast.current &&
          firstUnread.current?.ref &&
          refs[firstUnread.current?.ref] &&
          refs[firstUnread.current?.ref]?.current
        ) {
          refs[firstUnread.current?.ref]?.current.scrollIntoView({
            behavior: "auto",
            block: "start"
          });
          if (mounted) firstUnread.current.used = true;
        }
        // If top is in view and new (history) messages arrive, scroll to previous top
        if (
          topRef?.current &&
          refs[topRef.current] &&
          refs[topRef.current]?.current
        ) {
          refs[topRef.current]?.current?.scrollIntoView({
            behavior: "auto",
            block: "start"
          });
          topRef.current = undefined;
        }
      }

      checkBottom();
    }

    return () => {
      mounted = false;
    };
    // }, []);
  }, [uiMessages, refs, firstUnread.current?.ref, checkBottom]);

  const flagAsRead = useMemo(
    () => (items) => {
      if (items && items.length > 0 && personasState?.personas) {
        let mpersonas = personasState.personas.map((p) => p.mpersona);
        dex_action({
          type: "DEX_MULTI_MARK_READ",
          values: {
            db: databaseState.dexUser,
            messages: items,
            all: mpersonas
          }
        });
      }
    },
    [databaseState.dexUser, personasState?.personas]
  );

  const requestMissing = useMemo(
    () => (action, mtopic, mpersona) => {
      globalState.logging &&
        console.log("[UIMsgList] requestMissing", action, mtopic, mpersona);
      let mpersonas = personasState.personas.map((p) => p.mpersona);

      lastReadMessage(databaseState.dexUser, mtopic, mpersona).then(
        (latest) => {
          rest_api(action)
            .then((r) => {
              globalState.logging && console.log("[UIMsgList] r_t_hist", r);
              if (r?.messages[0]?.mtopic) {
                r.messages.forEach((m) => {
                  m.read = isRead(m, mpersonas, latest);
                  m.hide = isHide(m);
                  m.notify =
                    !m.read &&
                    !globalState.displaying_topic &&
                    !globalState?.prefs?.clientMute;
                });
              }
              globalState.logging &&
                console.log(
                  "[UIMsgList] r_t_hist messages to upsert",
                  r.messages
                );
              dex_action({
                type: "DEX_MULTI_UPSERT_KEYS_LATEST_TRANS",
                values: {
                  db: databaseState.dexUser,
                  table: "message",
                  latest_key: "msg_idx",
                  match_keys: ["mtopic", "recipient", "smid"],
                  docs: r.messages
                }
              });
            })
            .catch((e) => console.log("[UIMsgList] rest_api error", e));
        }
      );
    },
    [
      databaseState.dexUser,
      globalState.displaying_topic,
      globalState.logging,
      globalState?.prefs?.clientMute,
      personasState.personas,
      liveMessages
    ]
  );

  useEffect(() => {
    let mounted = true;
    if (meta?.visible_all) {
      setVisible_all(meta.visible_all);
    } else {
      if (
        meta?.mtopic &&
        topic.mpersona &&
        topic.mtopic &&
        jwt &&
        databaseState.dexUser &&
        !visible_all
      ) {
        let req = {
          type: "r_t_visible",
          mtopic: topic.mtopic,
          mpersona: topic.mpersona,
          jwt: jwt
        };
        return rest_api(req).then((r) => {
          globalState.logging &&
            console.log("[UITopicContainer] r_t_visible", r);
          if (r?.visible_idx) {
            mounted && setVisible_all(r?.visible_idx);
            mounted &&
              databaseState.dexUser.topic_metadata
                .where(["mtopic", "mpersona"])
                .equals([topic.mtopic, topic.mpersona])
                .modify((meta) => (meta.visible_all = r.visible_idx));
          }
        });
      }
    }
    return () => {
      mounted = false;
    };
  }, [databaseState.dexUser, jwt, meta, topic.mpersona, topic.mtopic]);

  useEffect(() => {
    if (liveItems && meta?.have_idx_list && initMissing && visible_all) {
      globalState.logging &&
        console.log(
          "!!!liveItems",
          liveItems,
          meta?.have_idx_list,
          visible_all
        );
      // are there any items in r that are not in my have_idx_list? if so, fetch them
      let liveHave = liveItems.map((i) => i?.msg_idx);
      let have_idx = [
        ...new Set([...(meta?.have_idx_list || []), ...liveHave])
      ];
      let have_idx_unpinned = have_idx.filter((i) => !meta.pinned.includes(i));
      let numBack = Math.max(20 - have_idx_unpinned.length, 0);
      let maxHave = Math.max(0, ...have_idx);
      let minHave = Math.min(Infinity, ...have_idx_unpinned);
      let missing = (visible_all || []).filter(
        (i) => i <= maxHave && i >= minHave && !have_idx.includes(i)
      );
      let missingPinned = (meta.pinned || []).filter(
        (i) => !have_idx.includes(i)
      );
      missing = [...missing, ...missingPinned].sort((a, b) => a - b);
      let extraToRequest = Math.max(
        20 - have_idx.length - missing.length + numBack,
        0
      );
      globalState.logging &&
        console.log(
          "!!!missing a",
          extraToRequest,
          maxHave,
          minHave,
          extraToRequest
        );
      if (extraToRequest) {
        missing = [
          ...missing,
          ...visible_all
            .sort((a, b) => a - b)
            .filter((i) => ![...have_idx, ...missing].includes(i))
            .splice(-extraToRequest)
        ];
      }
      globalState.logging &&
        console.log("!!!missing", have_idx, visible_all, missing);
      if (missing && missing.length > 0) {
        let action = {
          type: "r_t_hist",
          topics: [
            { mpersona: topic.mpersona, mtopic: topic.mtopic, missing: missing }
          ],
          jwt: jwt
        };
        requestMissing(action, topic.mtopic, topic.mpersona);
      }
      setInitMissing(false);
    }
    return () => {};
  }, [
    liveItems,
    meta,
    topic.mpersona,
    topic.mtopic,
    jwt,
    requestMissing,
    visible_all
  ]);

  // *** Mark as read any messages visible on render
  useEffect(() => {
    let isMounted = true;
    if (readOnOpen) {
      let parent = document.getElementById(
        topicPrefix +
          "-" +
          "message-list" +
          (props.pin ? "-pin-" + props.pin : "")
      );
      let itemsInView = liveMessages
        ? liveMessages
            .map((item) => {
              let exti =
                item.mpersona_rx && item.mpersona_rx.length > 0
                  ? "_" + item.recipient
                  : "";
              let element = document.getElementById(
                topicPrefix +
                  "-" +
                  (item.msgid || item.smid) +
                  "-" +
                  (item.msg_idx || item.msgid || item.smid) +
                  exti
              );
              return {
                item: item,
                idx: element?.getAttribute("idx"),
                msg_idx: item.msg_idx,
                overlap: element && ratioOverlap(parent, element)
              };
            })
            .filter(
              (i) =>
                i.overlap &&
                (i.overlap.heightRatioOfPossible >= 0.98 ||
                  i.overlap.topIsVisible ||
                  i.overlap.bottomIsVisible)
            )
        : [];

      let highestMsgIdxInView = itemsInView.reduce(
        (acc, i) => (i.msg_idx > acc ? i.msg_idx : acc),
        0
      );
      let itemsToFlagAsRead = (liveMessages || []).reduce(
        (acc, i) =>
          (i.msg_idx || 0) <= highestMsgIdxInView &&
          !(props.pin && props.pin !== i.parameters?.pin) &&
          !["t", "true", true].includes(i.read)
            ? [...acc, i]
            : acc,
        []
      );
      if (isMounted && itemsToFlagAsRead && itemsToFlagAsRead.length > 0) {
        globalState.logging &&
          console.log("itemsToFlagAsRead", itemsToFlagAsRead);
        flagAsRead(itemsToFlagAsRead);
        setReadOnOpen(false);
      }
    }
    return () => {
      isMounted = false;
    };
  });
  // *** End of mark as read any messages visible on render

  // *** If bottom in view, set scrollToBottom
  useEffect(() => {
    // use previous last message
    checkBottom();

    return () => {};
  }, [checkBottom, liveMessages]);
  // *** end: If bottom in view

  // *** Detect if scrolling
  useEffect(() => {
    let isMounted = true;
    let parent = document.getElementById(
      topicPrefix +
        "-" +
        "message-list" +
        (props.pin ? "-pin-" + props.pin : "")
    );

    let onStop = () => {
      if (isMounted) {
        let itemsInView = liveMessages
          .map((item) => {
            let exts =
              item.mpersona_rx && item.mpersona_rx.length > 0
                ? "_" + item.recipient
                : "";
            let element = document.getElementById(
              topicPrefix +
                "-" +
                (item.msgid || item.smid) +
                "-" +
                (item.msg_idx || item.msgid || item.smid) +
                exts
            );
            return {
              item: item,
              idx: element?.getAttribute("idx"),
              msg_idx: item.msg_idx,
              overlap: element && ratioOverlap(parent, element)
            };
          })
          .filter(
            (i) =>
              i.overlap &&
              (i.overlap.heightRatioOfPossible >= 0.98 ||
                i.overlap.topIsVisible ||
                i.overlap.bottomIsVisible)
          );

        if (isMounted && itemsInView) {
          let highestMsgIdxInView = itemsInView.reduce(
            (acc, i) => (i.msg_idx > acc ? i.msg_idx : acc),
            0
          );
          let itemsToFlagAsRead = (liveMessages || []).reduce(
            (acc, i) =>
              (i.msg_idx || 0) <= highestMsgIdxInView &&
              !(props.pin && props.pin !== i.parameters?.pin) &&
              !["t", "true", true].includes(i.read)
                ? [...acc, i]
                : acc,
            []
          );
          globalState.logging &&
            console.log("itemsToFlagAsRead", itemsToFlagAsRead);
          isMounted && flagAsRead(itemsToFlagAsRead);
        }

        itemsInView.forEach((i) => {
          // Any theme messages in view?
          i.item?.content?.body &&
            i.item?.content?.body.startsWith("Add theme # ") &&
            addTheme(i.item?.content?.body);
        });

        let sortedByidx = liveMessages
          .filter((m) => m.msg_idx)
          .sort((a, b) =>
            a.ts_origin_server && b.ts_origin_server
              ? a.ts_origin_server > b.ts_origin_server
              : parseInt(a.msg_idx || 0, 10) - parseInt(b.msg_idx || 0, 10)
          );
        let firstItem =
          sortedByidx && sortedByidx.length > 0 ? sortedByidx[0] : undefined;

        checkBottom();

        let extt = "";
        let atTop =
          itemsInView[0] &&
          parseInt(itemsInView[0]?.idx, 10) === 0 &&
          itemsInView[0].overlap.topIsVisible;

        if (atTop) {
          extt =
            itemsInView[0].mpersona_rx && itemsInView[0].mpersona_rx.length > 0
              ? "_" + itemsInView[0].recipient
              : "";

          topRef.current =
            topicPrefix +
            "-" +
            (itemsInView[0].item.msgid || itemsInView[0].item.smid) +
            "-" +
            (itemsInView[0].item.msg_idx ||
              itemsInView[0].item.msgid ||
              itemsInView[0].item.smid) +
            extt;

          if (meta) {
            let earliestUnpinnedVisible = itemsInView[0].item.msg_idx;
            let request_idx = (visible_all || [])
              .filter(
                (i) => i < earliestUnpinnedVisible && !meta.pinned.includes(i)
              )
              .sort((a, b) => a - b)
              .splice(-10);
            globalState.logging &&
              console.log("scrolled to top, requesting ", request_idx);
            if (request_idx && request_idx.length > 0) {
              let action = {
                type: "r_t_hist",
                topics: [
                  {
                    mpersona: topic.mpersona,
                    mtopic: topic.mtopic,
                    missing: request_idx
                  }
                ],
                jwt: jwt
              };
              requestMissing(action, topic.mtopic, topic.mpersona);
            }
          }
          //);
        }
      }
    };

    let onStart = () => {
      // don't autoScroll to last message while otherwise scrolling
      if (isMounted) scrollToLast.current = false;
    };

    // The onScrollChange is only set 1 second after the topic is opened
    // seeing as liveMessages triggers this a couple of times, very quickly
    // which marks the oldest 3 new messages as read
    // and hence breaks the scroll to first new message
    // All bow your heads in prayer. Lord, spare us from such rubbish code.
    // Mea culpa
    let timer = setTimeout(
      () => isMounted && onScrollChange(parent, 50, onStart, onStop),
      100
    );

    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [
    liveMessages,
    flagAsRead,
    addTheme,
    topicPrefix,
    props.requestHistory,
    props.pin,
    checkBottom,
    topicState?.messages?.pinned,
    topicState?.messages?.unpinned,
    topicState?.messages?.items,
    meta,
    jwt
  ]);
  // *** End of detect if scrolling

  useEffect(() => {
    if (liveMessages) {
      let descriptorSet = [
        ...new Set(
          liveMessages
            .filter((f) => f.parameters?.descriptor)
            .map((d) => d.parameters?.descriptor)
        )
      ];

      setDescriptors((descriptors) => {
        let ds = topicsState.descriptorsB.filter((d) =>
          descriptorSet.includes(d.digest)
        );
        return !isEqual(ds, descriptors) ? ds : descriptors;
      });
    }
    return () => {};
  }, [liveMessages, topicsState.descriptorsB]);

  useEffect(() => {
    globalState.logging && console.log("[UIMsgList]descriptors", descriptors);
    let mounted = true;
    let imageCachePromiseArray = descriptors.map(async (descriptor) => {
      let image64 = await getImage64(
        databaseState.dexUser,
        descriptor.thumbdigest
      )
        .then(async (image) => {
          if (image) {
            return image;
          } else {
            let image = await s3ToImage(
              descriptor.mpersona,
              descriptor.thumbdigest
            )
              .then((image) => {
                if (image?.b64) {
                  storeImage(databaseState.dexUser, image);
                  return image?.b64;
                }
              })
              .catch((err) => {
                console.log("CATCH", err);
                return undefined;
              });
            return image;
          }
        })
        .catch((err) => {
          console.log("CATCH", err);
          return undefined;
        });
      return { digest: descriptor.thumbdigest, image: image64 };
    });

    Promise.all(imageCachePromiseArray).then((r) => setAvatars(r));
    return () => {
      mounted = false;
    };
  }, [databaseState.dexUser, descriptors]);

  useEffect(() => {
    let mounted = true;
    const checkDates = (date1, date2) => {
      if (dateOnlyFormat(timestamp(date1)) !== dateOnlyFormat(timestamp(date2)))
        return false;
      else return true;
    };

    setUiMessages(
      (liveMessages || []).concat(
        sendMessages.filter(
          (s) =>
            !liveMessages?.map((m) => m.smid).includes(s.smid) &&
            !(props.pin && props.pin > 0)
        )
      ) === undefined || !refs ? (
        <p></p>
      ) : (
        (liveMessages || [])
          .concat(
            sendMessages.filter(
              (s) =>
                !liveMessages?.map((m) => m.smid).includes(s.smid) &&
                !(props.pin && props.pin > 0)
            )
          )
          .map((m, i, a) => {
            let desc = descriptors.filter(
              (d) => d.digest === m.parameters?.descriptor
            )[0];
            let avatar = avatars.filter(
              (d) => d.digest === desc?.thumbdigest
            )[0];
            // let previousItem = a[i - 1];
            // console.log("uimsglst i=", i, " m=", m, " a[", i, "]=", a[i])
            // { i > 0 ? console.log("uimsglst i=", i, " m=", m, " a[", i - 1, "]=", a[i - 1]) : console.log("iless than 0") }
            let ext =
              m.mpersona_rx && m.mpersona_rx.length > 0
                ? "_" + m.recipient
                : "";
            let id =
              topicPrefix +
              "-" +
              (m.msgid || m.smid) +
              "-" +
              (m.msg_idx || m.msgid || m.smid) +
              ext;
            let prevOriginatorSame =
              i > 0 &&
              m.mpersona === a[i - 1]?.mpersona &&
              !a[i - 1]?.parameters?.system_msg
                ? true
                : false;
            let shouldDisplayAvatar =
              (!prevOriginatorSame ||
                calcDateTimeDifference(
                  i > 0 &&
                    timestamp(
                      a[i - 1].ts_server,
                      a[i - 1].ts_origin_server,
                      a[i - 1].ts_sender || 0
                    ),
                  m.ts_server || m.ts_origin_server || m.ts_sender
                ) > avatarTimeLapse) &&
              topic.mpersona !== m.mpersona;
            return m.parameters?.display === false ||
              m.parameters?.display === "false" ? undefined : (
              <div
                ref={refs[id]}
                key={id}
                id={id}
                msgid={m.msgid}
                smid={m.smid}
                msg_idx={m.msg_idx || 0}
                isread={m.read ? "true" : "false"}
                idx={i}
                className={"UI-message-holder"}
              >
                <div
                  className={
                    (i > 0 &&
                      checkDates(
                        m.ts_server || m.ts_origin_server || m.ts_sender,
                        a[i - 1]?.ts_server ||
                          a[i - 1]?.ts_origin_server ||
                          a[i - 1]?.ts_sender
                      )) ||
                    props.pin > 0
                      ? "UImessage-display-none"
                      : "UI-message-datecenter"
                  }
                >
                  {dateOnlyFormat(
                    timestamp(m.ts_server, m.ts_origin_server, m.ts_sender)
                  )}
                </div>
                <UIMessage
                  // ref={msgListContainer}
                  prevOriginatorSame={prevOriginatorSame}
                  pinsPresent={props.pin > 0}
                  message={m}
                  muid={globalState.muid}
                  version={globalState.version}
                  you={topic.mpersona}
                  persona={topic.persona}
                  selected={props.selected}
                  roles={topic.roles}
                  containerWidth={msgContainer.current.offsetWidth}
                  containerHeight={msgContainer.current.offsetHeight}
                  topicType={topic.props?.topictype || "not_dialog"}
                  descriptor={shouldDisplayAvatar && desc}
                  avatar={shouldDisplayAvatar && avatar?.image}
                  sda={shouldDisplayAvatar}
                />
              </div>
            );
          })
      )
    );
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liveMessages,
    globalState.muid,
    globalState.version,
    props.pin,
    props.selected,
    sendMessages,
    topic.mpersona,
    topic.persona,
    topic.props?.topictype,
    topic.roles,
    topicPrefix,
    avatars
  ]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());
    if (origHgt === 0) setOrigHgt(getWindowDimensions.height);
  }, []);

  useEffect(() => {
    setChangePins(pinVal);
    setChangeChat(chatVal);
    function handleResize() {
      if (windowDimensions.height * 0.9 > window.innerHeight) {
        setChangePins(
          parseInt((window.innerHeight / windowDimensions.height) * changePins)
        );
        setChangeChat(
          parseInt((window.innerHeight / windowDimensions.height) * changeChat)
        );
      } else {
        setChangePins(pinVal);
        setChangeChat(chatVal);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let content = (
    <div
      ref={msgContainer}
      className={props.pin && props.pin > 0 ? "pins" : "messages"}
      style={props.pin && props.pin > 0 ? { maxHeight: `${changePins}vh` } : {}}
      // : { maxHeight: `${changeChat}vh` }}
      key={props.pin ? "msgList-" + props.pin : "msgList"}
    >
      <div
        className="UI-msg-list-container"
        onClick={() =>
          props.selectTopic &&
          props.selectTopic(topic?.mtopic || topic?.mdialog)
        }
      >
        <div
          className={"UI-message-list"}
          // ref={msgListContainer}
          id={
            topicPrefix +
            "-" +
            "message-list" +
            (props.pin ? "-pin-" + props.pin : "")
          }
        >
          {uiMessages}
          {
            // FIXME: Unpredictable behaviour when button is displayed in pinned and unpinned lists
            props.pin === 0 && (
              <UIGoToButton
                refs={refs}
                lastMsgInView={lastMsgInView}
                firstUnreadKey={firstUnread}
                lastMsgKey={lastRef}
              ></UIGoToButton>
            )
          }
        </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UIMsgList, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
