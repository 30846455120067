import React, { useState, useContext, useEffect } from "react";
import { isMobileTablet } from "hooks/helper";
import "ui/UI.css";
import PersonasContext from "contexts/contextPersonas";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";

const UIAlertModal = (props) => {
  const { personasState } = useContext(PersonasContext);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  let history = useHistory();

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  let modalShare = (
    <div id="share_modal">
      <Modals
        title="Information"
        buttonStyle={props.secure && { display: "none" }}
        onClose={() => props.secure || handleClose()}
        onClickOutside={() => props.secure || handleClickOutside()}
        clickOutsideActive={!props.secure}
        switchScrollOff={true}
        footer={
          <div
            style={{
              marginBottom: "1rem",
              marginRight: "0.5rem",
              marginTop: "1rem"
            }}
          ></div>
        }
      >
        {/* <label className="modal-label-new">Information</label> */}
        <h4
          style={{
            color: "var(--modal_text_color)",
            textDecoration: "none",
            // wordWrap: "normal",
            textAlign: "center",
            marginLeft: "50%",
            transform: "translate(-50%)",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            wordBreak: "break-all",
            width: "100%",
            overflow: "hidden"
          }}
          id="share_modal_link_title"
        >
          {props.title}
        </h4>
        {/* <h4
          style={{
            color: "var(--modal_text_color)",
            textAlign: "center",
            textDecoration: "none",
            // wordWrap: "break-word",
            backgroundColor: "#FFFFFF",
            borderRadius: "var(--border_radius_medium)",
            padding: "4px",
            marginBottom: "16px",
            wordBreak: "break-all",
            fontWeight: "inherit",
          }}
          id="share_modal_link"
        ></h4> */}
      </Modals>
    </div>
  );

  let content = <div>{modalShare}</div>;

  return content;
};

export default React.memo(UIAlertModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
