import React from "react";
import UIVote from "ui/UIVote";

const isEqual = require("react-fast-compare");

const UIMessagePreview = (props) => {
  const isColour = (colour) =>
    colour && colour.startsWith("#") && colour.length < 8;
  const textColour = (bubbcolor) =>
    isColour(bubbcolor)
      ? "#" + contrastingColor(bubbcolor.replace("#", ""))
      : "";

  const contrastingColor = (color) => {
    return luma(color) >= 135 ? "000" : "fff";
  };
  const luma = (
    color // color can be a hx string or an array of RGB values 0-255
  ) => {
    var rgb = typeof color === "string" ? hexToRGBArray(color) : color;
    return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
  };
  const hexToRGBArray = (color) => {
    if (color.length === 3)
      color =
        color.charAt(0) +
        color.charAt(0) +
        color.charAt(1) +
        color.charAt(1) +
        color.charAt(2) +
        color.charAt(2);
    else if (color.length !== 6) throw "Invalid hex color: " + color;
    var rgb = [];
    for (var i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
  };

  let content = (
    <div className="UI-message_preview"
    >
      {((props.message.parameters?.images &&
        props.message.parameters?.images[0]?.thumbnail !== "") ||
        (props.message.thumbnail && props.message.thumbnail !== "")) && (
        <img
          className="thumbnail"
          src={
            "data:image/png;base64," +
            (props.message.thumbnail ||
              props.message.parameters?.images[0].thumbnail)
          }
          alt={props.message.name}
          onClick={() => {}}
        />
      )}
      <span
        style={
          textColour(props.message?.parameters?.bubbcolor) !== ""
            ? {}
            : {
                whiteSpace: "pre-wrap",
                color: textColour(props.message?.parameters?.bubbcolor),
                wordBreak: "break-word"
              }
        }
      >
        {props.message.content.body}
      </span>
      {props &&
      props.message &&
      props.message.parameters &&
      props.message.parameters.choices ? (
          <UIVote //will this happen - and if so, shoudl this be UIVorePreview?
          message={props.message}
          persona={props.persona}
          mpersona={props.you}
          muid={props.muid}
        />
      ) : (
        <div></div>
      )}
    </div>
  );

  return content;
};

export default React.memo(UIMessagePreview, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
