import React, { useState, useContext, useEffect, useRef } from "react";
import DatabaseContext from "data/contextDatabase";
import { randomString } from "hooks/helper";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { sanitizeUserId } from "utils/textUtils";
import { workerGet } from "workers/interfaceRest";
import { WorldTextInput } from "utils/UtilsUniversal";
import { shouldLog } from "utils/shouldLog";
import { dex_action } from "data/dexUtils";

const UILoginModal = (props) => {
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  let uid;
  let password;
  // const enterUserName = useRef();
  // const passwordRef = useRef();
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  // const [selectedPersona, setSelectedPersona] = useState(false);
  let history = useHistory();
  const [changeHgt, setChangeHgt] = useState(false);
  const [scrnHgt, setScrnHgt] = useState(0);
  // const [origScrnHgt, setOrigScrnHgt] = useState(window.innerHeight);
  // const [passwordShown, setPasswordShown] = useState(false);
  // const [pFocus, setPFocus] = useState(false);
  const [focusVal, setFocusVal] = useState(true);
  const [userNameInp, setuserNameInp] = useState("");
  const [passwordInp, setPasswordInp] = useState("");

  useEffect(() => {
    function handleResize() {
      // if (window.innerWidth < 1024) {
      setScrnHgt(window.innerHeight * 0.9);
      setChangeHgt(true);
      // }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (pFocus) passwordRef.current.focus();
  // }, [passwordShown]);

  const onSubmitCredentials = (username, password) => {
    const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
    const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
    workerGet(urlPersona, keyPersona, {
      type: "personalist",
      uid: sanitizeUserId(username),
      pwd: password,
      token: undefined,
      msgid: randomString(8),
      version: props.version
    })
      .then((personaList) => {
        shouldLog() && console.log("[login] personaList", personaList);
        if (personaList.result === "personalist") {
          databaseDispatch({
            type: "MASTER_DB_READY",
            values: { ready: false }
          });
          dex_action({
            type: "DEX_UPSERT_MATCH",
            values: {
              db: databaseState.dexAdmin,
              table: "store",
              match: { key: "client" },
              function: (val) => {
                return {
                  cid: randomString(10),
                  ...val // this will keep the old cid if it already exists
                };
              }
            }
          });
          dex_action({
            type: "DEX_UPSERT_MATCH",
            values: {
              db: databaseState.dexAdmin,
              table: "store",
              match: { key: "user" },
              function: (val) => {
                let v = {
                  // ...val, // removing this because logging in should remove any jwt, muid etc
                  key: "user",
                  uid: sanitizeUserId(username),
                  muid: personaList.muid,
                  dbKey: personaList.muid,
                  password: password
                };
                shouldLog() && console.log("update_user", v);
                return v;
              }
            }
          });
          if (history.length >= 1) {
            history.goBack();
          }
          props.onClose();
        } else {
          alert(
            "Sorry, that login attempt failed. Please check your login credentials and connectivity."
          );
        }
      })
      .catch((err) => {
        console.log("[login] err", err);
      });
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (

    //   document.getElementById("uid_input").value === undefined ||
    //   document.getElementById("uid_input").value === ""
    // )
    if (userNameInp === undefined || userNameInp === "") {
      alert("UserName cannot be blank");
      // enterUserName.current?.focus();
    } else if (passwordInp === undefined || passwordInp === "") {
      // if (
      // document.getElementById("password_input").value === undefined ||
      // document.getElementById("password_input").value === ""
      // )
      // {
      alert("Password cannot be blank");
      // passwordRef.current?.focus();
    } else {
      // if (history.length >= 1) {
      //   history.goBack();
      // }
      uid = userNameInp;
      // uid = document.getElementById("uid_input").value;
      password = passwordInp;
      // password = document.getElementById("password_input").value;
      onSubmitCredentials(uid, password);
    }
  };

  // const togglePassword = () => {
  //   setPasswordShown(!passwordShown);
  //   setPFocus(true);
  // };

  const handlepasswordInp = (e) => {
    setPasswordInp(e);
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleuserNameInp = (e) => {
    setuserNameInp(e);
  };

  let content = (
    <div id="login_modal">
      <form onSubmit={handleSubmit}>
        <Modals
          loginModal={true}
          title="Sign in"
          onClose={() => handleClose()}
          onClickOutside={() => handleClickOutside()}
          clickOutsideActive={true}
          style={
            changeHgt && window.innerWidth <= 1024 ? { maxHeight: scrnHgt } : {}
          }
          // mainStyle={{
          //   position: "relative",
          //   backgroundColor: "rgba(0, 0, 0, 1)"
          // }}
          styleBody={
            changeHgt && window.innerWidth <= 1024
              ? { maxHeight: scrnHgt * 0.5 }
              : {}
          }
          // buttonStyle={{ display: "none" }}
          footer={
            <button
              className="modal-button-new"
              type="submit"
              onClick={handleSubmit}
            >
              Sign in
            </button>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <p
              style={{
                textAlign: "left",
                margin: "0"
              }}
            >
              If you do not already have an account then go to{" "}
            </p>
            <button
              className="UI-button-confined"
              style={{ marginLeft: "auto" }}
              type="button"
              onClick={() => {
                props.onClose();
                props.callback();
              }}
            >
              Register
            </button>
            <WorldTextInput
              identity="login"
              focus={focusVal}
              removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleuserNameInp(e)}
              label="Username"
              required={true}
              placeholder="Enter WORLD ID"
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={56}
              // active={worldTestTA?.length > 0 ? "true" : "false"}
              value={userNameInp}
              active="true"
              autoComplete="off"
              autoCapitalize="none"
            />
            <WorldTextInput
              identity="loginpword"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="password"
              // title={tagTooltip}
              callback={(e) => handlepasswordInp(e)}
              label="Password"
              required={true}
              placeholder="Enter Password"
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={56}
              // active={worldTestTA?.length > 0 ? "true" : "false"}
              value={passwordInp}
              active="true"
            />
          </div>
          {/* </ClickOutsideDetector> */}
        </Modals>
      </form>
    </div>
  );
  return content;
};

export default React.memo(UILoginModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
