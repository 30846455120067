import TopicStateProvider from "contexts/TopicStateProvider";
import React, { useEffect, useState, useMemo } from "react";
import { shouldLog } from "utils/shouldLog";
import UITopicContainer from "./UITopicContainer";

const isEqual = require("react-fast-compare");

const UITopicFrame = (props) => {
  // console.log(`!@!_UITopicFrame`, props);
  let key =
    "UITopicFrame_" +
    (props.location?.state?.dialog?.mdialog ||
      props.location?.state?.subscription?.mtopic ||
      props.location?.state?.topic?.mtopic);
  let s = props.location.state;

  // let intFrameHeight = window.innerHeight;
  // let UIFrameHeight = window.innerHeight

  // const [intFrameHeight, setIntFrameHeight] = useState(window.innerHeight)
  // const [UIFrameHeight, setUIFrameHeight] = useState(window.innerHeight)
  // const [intFrameWidth, setIntFrameWidth] = useState(window.innerWidth)
  // const [UIFrameWidth, setUIFrameWidth] = useState(window.innerWidth)
  const [frame, setFrame] = useState({
    intHeight: window.innerHeight,
    uiHeight: window.innerHeight,
    intWidth: window.innerWidth,
    uiWidth: window.innerWidth
  });

  useEffect(() => {
    const handleResize = () => {
      shouldLog() && console.log("[UITopicFrame] handleResize");
      setFrame({
        intHeight: window.innerHeight,
        uiHeight: window.innerHeight,
        intWidth: window.innerWidth,
        uiWidth: window.innerWidth
      });
      // setIntFrameHeight(window.innerHeight)
      // setUIFrameHeight(window.innerHeight)
      // setIntFrameWidth(window.innerWidth)
      // setUIFrameWidth(window.innerWidth)
      // setChangeHgt(true)
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styleWidth = {
    height: frame.uiHeight,
    // Adding media query..
    "@media (maxWidth: 40rem)": {
      height: frame.uiHeight,
      width: frame.uiWidth
    }
  };

  const topicContainer = useMemo(() => {
    return (
      <TopicStateProvider>
        <UITopicContainer
          // height={frame.intHeight}
          showback={true}
          metadata={s}
          key={key}
        />
      </TopicStateProvider>
    );
  }, [key, s]);

  let content = (
    <div style={{ flex: "1" }}>
      <div
        className="UI"
        style={{
          height: frame.intHeight
          // width: intFrameWidth,
        }}
      >
        <div
          className="UI-frame"
          style={styleWidth}
          //   {
          //   height: UIFrameHeight,
          //   width: UIFrameWidth,
          // }}
        >
          {topicContainer}
        </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicFrame, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
