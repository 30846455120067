import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
  useMemo
} from "react";
import UICarousel from "ui/UICarousel";
import "./UIPersonaCard.css";
import "ui/UI.css";
import "ui/modals.css";
import plusLogo from "./icons/plusonly.png";
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import useClickPreventionOnDoubleClick from "../utils/useClickPreventionOnDoubleClick";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import { getDescriptor, getImage64, storeImage } from "data/descriptors";
import { s3ToImage } from "connectivity/s3";
import {
  imageVersions,
  storeAvatars,
  changeAvatar,
  deleteAvatar
} from "utils/image";
import PersonasContext from "contexts/contextPersonas";
import { useHistory } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import ThumbsContext from "contexts/contextThumbs";
import Closecross from "./icons/Closecross";
// import DragMove from "utils/DragMove";

const UIPersonaPicManager = (props) => {
  const inputFile = useRef(null);
  const forRgtClk = document.getElementById("Pictures");
  const [clickedIndex, setClickedIndex] = useState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const [avatars, setAvatars] = useState([]);
  const [items, setItems] = useState([]);
  const picturesRef = useRef();
  const [picArr, setPicArr] = useState([]);
  const { personasState } = useContext(PersonasContext);
  const [thisDescriptor, setThisDescriptor] = useState();
  let mpersona = globalState?.persona?.mpersona;
  const [plusInFront, setPlusInFront] = useState(1); //if Plus placed at end set this to 0
  // const [changedPic, setChangedPic] = useState(props.changedPic)
  let arrWithBtn;
  const { thumbsDispatch, thumbsState } = useContext(ThumbsContext);
  // const [translate, setTranslate] = useState({
  //   x: 0,
  //   y: 0
  // });

  useEffect(() => {
    if (props.changedPic) setPicArr([]);
    props.setChangedPic(false);
  }, [props.changedPic]);

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      if (event.srcElement.id === "image_btn_add_persona") setShow(false);
      else setShow(true);
    },
    [setAnchorPoint, setShow]
  );

  useEffect(() => {
    // If the selected mpersona or the liveDescriptors change,
    // then we select the appropriate descriptor
    let mounted = true;
    // let mpersona = globalState?.persona?.mpersona;
    if (personasState.descriptors) {
      let desc = personasState.descriptors.filter(
        (d) => d?.mpersona === mpersona
      )[0];
      desc?.digest
        ? getDescriptor(
            databaseState.dexUser,
            "descriptor",
            mpersona,
            desc.digest
          ).then((x) => {
            if (!x) {
              setAvatars([]);
            } else {
              setThisDescriptor(x);
              mounted &&
                // This sets the "list" of avatars to the single avatar currently in use
                // When we change to a full list of images, they will be pulled from the descriptor and inserted here
                setAvatars([
                  {
                    thumbdigest: x.thumbdigest,
                    thumbpath: x.thumbpath,
                    imgdigest: x.imgdigest,
                    imgpath: x.imgpath
                  }
                ]);
              if (
                (!x.picsarray || x.picsarray?.length <= 0) &&
                x.thumbdigest &&
                x.thumbpath &&
                x.imgdigest &&
                x.imgpath
              ) {
                setPicArr([
                  {
                    thumbdigest: x.thumbdigest,
                    thumbpath: x.thumbpath,
                    imgdigest: x.imgdigest,
                    imgpath: x.imgpath
                  }
                ]);
              } else {
                setPicArr(x.picsarray);
              }
            }
          })
        : setAvatars([]);
    }
    return () => {
      mounted = false;
    };
  }, [databaseState.dexUser, mpersona, personasState.descriptors]);

  // PUT THIS BACK when committing!!!!!
  useEffect(() => {
    document.getElementById("Pictures");
    const ref = picturesRef.current;
    ref?.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.getElementById("Pictures");
      ref?.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  const deletePicture = (index) => {
    globalState.logging &&
      console.log("!!!descriptor_deletePicture", index, picArr, thisDescriptor);
    let newPicArr = [];
    if (
      avatars &&
      avatars[0] &&
      avatars[0].thumbdigest === picArr[index].thumbdigest
    ) {
      //avatar is the selected pic
      if (index > 0) {
        //avatar and selected are not in 1st place
        newPicArr = picArr.filter(
          (mp) => mp.thumbdigest !== picArr[index].thumbdigest
        );
        changeAvatar(
          databaseState.dexUser,
          picArr[0],
          newPicArr,
          globalState.muid,
          globalState.persona.mpersona,
          picArr[0].thumb64
        );
        let j = thumbsState.thumbs;
        j[mpersona] = picArr[0].thumb64;
        thumbsDispatch({
          type: "SET_THUMBS",
          values: { thumbs: j }
        });
        setPicArr(newPicArr);
      } else if (index === 0 && picArr.length > 1) {
        //avatar and selected in 1st place more than one pic")
        newPicArr = picArr.filter(
          (mp) => mp.thumbdigest !== picArr[index].thumbdigest
        );
        changeAvatar(
          databaseState.dexUser,
          picArr[index + 1],
          newPicArr,
          globalState.muid,
          globalState.persona.mpersona,
          picArr[index + 1].thumb64
        );
        let j = thumbsState.thumbs;
        j[mpersona] = picArr[index + 1].thumb64;
        thumbsDispatch({
          type: "SET_THUMBS",
          values: { thumbs: j }
        });
        setPicArr(newPicArr);
      } else if (index === 0) {
        //avatar and selected in 1st place no other pics
        setPicArr([]);
        let newAvatar = {
          imgdigest: null,
          imgpath: null,
          thumbdigest: null,
          thumbpath: null
        };
        deleteAvatar(
          databaseState.dexUser,
          newAvatar,
          newPicArr,
          globalState.muid,
          mpersona,
          undefined
        );
        let j = thumbsState.thumbs;
        j[mpersona] = undefined;
        thumbsDispatch({
          type: "SET_THUMBS",
          values: { thumbs: j }
        });
      }
    } else {
      newPicArr = picArr.filter(
        (mp) => mp.thumbdigest !== picArr[index].thumbdigest
      );
      deleteAvatar(
        databaseState.dexUser,
        thisDescriptor,
        newPicArr,
        globalState.muid,
        mpersona,
        picArr[index].thumb64
      );
    }

    // !!! remove picture from descriptor
    // !!! set selected avatar to the first one in the carousel
    // !!! store descriptor
    setShow(false);
  };

  const onImageSelect = useMemo(
    () => (event) => {
      globalState.logging &&
        console.log("!!!onImageSelect", event, globalState);
      const file = event.target.files[0];
      // Process the image into 2 sizes and then store as Avatar
      // Note: the storeAvatar also updates the user's active descriptor
      // When we implement the full carousel, this will also have to adjust the list of available images in the descriptor
      imageVersions(file, [
        { key: "image", maxWidth: 500, maxHeight: 500 },
        { key: "thumbnail", maxWidth: 200, maxHeight: 200 }
      ]).then((r) => {
        if (
          picArr.filter((e) => e.thumb64 === r.thumbnail.dataURI).length > 0
        ) {
          alert("picture exists");
        } else {
          storeAvatars(
            databaseState.dexUser,
            globalState.muid,
            globalState.persona.mpersona,
            r.image.dataURI,
            r.thumbnail.dataURI,
            picArr,
            file.name
          ).then(() => {});
          let j = thumbsState.thumbs;
          j[globalState.persona.mpersona] = r.thumbnail.dataURI;
          thumbsDispatch({
            type: "SET_THUMBS",
            values: { thumbs: j }
          });
        }
      });
    },
    [databaseState.dexUser, picArr, thumbsDispatch, thumbsState.thumbs]
  );

  // const handleDragMove = (e) => {
  //   setTranslate({
  //     x: translate.x + e.movementX,
  //     y: translate.y + e.movhandleClickementY
  //   });
  // };

  const ClickableBox = ({ onClick, onDoubleClick, index, item, image64 }) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
      onClick,
      onDoubleClick
    );
    return (
      // <DragMove onDragMove={handleDragMove}>
      <div
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        style={{
          display: "flex"
        }}
      >
        <img
          id={"image_" + (item.thumbdigest || item.key)}
          className={
            item.key === "btn_add_persona"
              ? "persona-card-header-unselected"
              : "pics-carousel"
          }
          src={image64 ? image64 : plusLogo}
          alt="Logo"
          height={40}
        />
        {item.key !== "btn_add_persona" && (
          <div
            className="carousel-pics-close"
            style={{ zIndex: "999" }}
            title="delete"
            onClick={(e) => {
              deletePicture(index);
              e.stopPropagation();
            }}
          >
            <Closecross
              strokewidth="10%"
              strokecolor="red"
              fillcolor="red"
            ></Closecross>
            {/* <AiOutlineClose size="xs" /> */}
          </div>
        )}
        {/* Click or double click */}
      </div>
      // </DragMove>
    );
  };

  useEffect(() => {
    // When the "llst" of avatars changes, we update the list of items passed to the UICarousel
    let mounted = true;
    const btn_add_persona = {
      key: "btn_add_persona",
      id: "btn_add_persona",
      persona: "Add Persona",
      mpersona: ""
    };
    setItems([]);
    // this needs to be async to wait for the images to be fetched from db or s3
    // So the final setItems waits for all Promises to resolve
    if (plusInFront === 1) {
      if (picArr) arrWithBtn = [btn_add_persona].concat(picArr);
      else arrWithBtn = [btn_add_persona];
    } else arrWithBtn = picArr.concat(btn_add_persona);
    let i = arrWithBtn.map(async (item, index) => {
      item?.thumbdigest &&
        (await getImage64(databaseState.dexUser, item.thumbdigest)
          .then((thumbnail) => {
            if (thumbnail) {
              if (!picArr[index - plusInFront].thumb64) {
                let tmpitem = picArr[index - plusInFront];
                tmpitem.thumb64 = thumbnail;
                if (picArr.length === 1) setPicArr([tmpitem]);
                else {
                  setPicArr((vals) => {
                    return [
                      ...vals.slice(0, index - plusInFront),
                      tmpitem,
                      ...vals.slice(index)
                    ];
                  });
                }
              }
            } else {
              s3ToImage(mpersona, item.thumbdigest).then((i) => {
                if (i?.b64) {
                  storeImage(databaseState.dexUser, i);
                }
              });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          }));
      return (
        <div
          className={
            item.key === "btn_add_persona"
              ? "persona-card-header-wrapper"
              : "persona-pics-internal"
          }
          key={item.thumbdigest || item.key}
          onClick={() => {
            item.key === "btn_add_persona"
              ? inputFile.current?.click()
              : globalState.logging && console.log("click image=", item);
          }}
          onContextMenu={() => setClickedIndex(index - plusInFront)}
        >
          <ClickableBox
            onClick={() => {
              if (item.key !== "btn_add_persona")
                history.push("/imageDisplay", {
                  avatar: true,
                  picAttach: false,
                  // thumb: item.image64,
                  // thumb: item.image,
                  thumb: picArr[index - plusInFront].thumb64,
                  // descriptor: item,
                  descriptor: picArr[index - plusInFront],
                  scope: mpersona,
                  // id: item.imgdigest,
                  imgdigest: picArr[index - plusInFront].imgdigest,
                  picArr: picArr,
                  index: index
                });
            }}
            onDoubleClick={() => {
              changeAvatar(
                databaseState.dexUser,
                item,
                picArr,
                globalState.muid,
                globalState.persona.mpersona,
                item.image64
              );
              let j = thumbsState.thumbs;
              j[mpersona] = picArr[index - plusInFront].thumb64;
              thumbsDispatch({
                type: "SET_THUMBS",
                values: { thumbs: j }
              });
            }}
            index={index - plusInFront}
            item={item}
            image64={item.thumb64}
          />
        </div>
      );
    });

    Promise.all(i).then((results) => {
      // console.log("PROMISE!!!", globalState?.persona?.mpersona, avatars);
      mounted && setItems(results);
    });
    return () => {
      mounted = false;
    };
  }, [picArr]);

  const picStyle = {
    marginLeft: "0",
    width: "95%"
  };

  let content = (
    <div className="persona-frame-pic" id="Pictures" ref={picturesRef}>
      <UICarousel
        orig="UIPersonaPicManager"
        ref={forRgtClk}
        items={items}
        style={picStyle}
      />
      <input
        style={{
          display: "none"
        }}
        type="file"
        ref={inputFile}
        accept="image/*"
        onChange={(event) => onImageSelect(event)}
      />
      <ClickOutsideDetector
        caller="UIPersonaPicManager"
        listen
        onClickOutside={() => {
          setShow(false);
        }}
        onClick={() => {}}
      >
        {show ? (
          <ul
            className="menu-rgt-click"
            style={{
              top: anchorPoint.y,
              left: anchorPoint.x,
              width: "fit-content",
              cursor: "pointer"
            }}
          >
            <li onClick={() => deletePicture(clickedIndex)}>Delete</li>
            <li
              onClick={() => {
                changeAvatar(
                  databaseState.dexUser,
                  picArr[clickedIndex],
                  picArr,
                  globalState.muid,
                  globalState.persona.mpersona,
                  picArr[clickedIndex].thumb64
                );
                let j = thumbsState.thumbs;
                j[mpersona] = picArr[clickedIndex].thumb64;
                thumbsDispatch({
                  type: "SET_THUMBS",
                  values: { thumbs: j }
                });

                setShow(false);
              }}
            >
              set as Avatar
            </li>
            {/* <li>menu item</li> */}
          </ul>
        ) : (
          <> </>
        )}
      </ClickOutsideDetector>
    </div>
  );
  return content;
};

export default React.memo(UIPersonaPicManager, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
