import React, { useEffect, useState, useRef } from 'react'
import "./modals.css"
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import { useHistory } from "react-router-dom";

const Modals = (props) => {
    let history = useHistory()
    const [hover, setHover] = useState(false)
    const [OKToHover, setOKToHover] = useState(false)
    const titleW = useRef(null)

    useEffect(() => {
        if (titleW.current?.offsetWidth < titleW.current?.scrollWidth)
            setOKToHover(true)
        else setOKToHover(false)
        return () => {
        }
    }, [])


    const toggleHover = () => {
        setHover(!hover)
    }

    // let mleft =
    //     (-props.title.length * 1).toString()


    const hoverStyle =
        props.title
            ? { marginLeft: (-props.title.length * 1).toString() + "px" }
            : { marginLeft: "0px" }
    // marginLeft: mleft + "px",


    return (
        <div>
            <div className="modals modal-new"

                style={props.mainStyle}>
                <ClickOutsideDetector
                    caller="Modals"
                    listen
                    onClickOutside={() => {
                        props.onClickOutside()
                        props.clickOutsideActive && props.onClose()
                    }}
                    onClick={() => {
                    }}
                >
                    <div
                        className={props.overWritemncn ? {} : "modal-content-new"}
                        style={props.style}
                    >
                        <div
                            className="modal-header-new"
                            style={props.noBckgrnd
                                ? { backgroundColor: "transparent" }
                                : {}}>
                            <h2
                                ref={titleW}
                                className="modal-title-new"
                                style={hover ? OKToHover ? hoverStyle
                                    : {} : {}}
                                onMouseEnter={toggleHover}
                                onMouseLeave={toggleHover}
                            >
                                {props.title}
                            </h2>
                            <button
                                type="button"
                                className="close-modal"
                                style={props.buttonStyle}
                                data-dismiss="modal" //bootstrap??
                                aria-label="Close"
                                onClick={() => {
                                    props.onClose()
                                    if (!props.noHistoryBack) history.goBack()
                                    if (props.setclickOutsideActive)
                                        props.setclickOutsideActive(true)

                                }}
                                title="Close Modal"
                            >
                                <span aria-hidden="true">&times; </span>
                            </button>
                        </div>

                        <div
                            className={props.switchScrollOff ? "modal-body-new hide-scrollbar" : "modal-body-new"}
                            style={props.styleBody}>
                            {/* this is modal content */}
                            {props.children}
                        </div>
                        <div className="modal-footer-new"
                            style={props.footer ?
                                props.noBckgrnd ? {
                                    minHeight: "2.58rem",
                                    backgroundColor: "transparent"
                                }
                                    : { minHeight: "2.58rem", }
                                : {}}>
                            {/* style={props.noBckgrnd ? { backgroundColor: "transparent" } : {}}> */}
                            {props.footer}
                        </div>
                    </div>
                </ClickOutsideDetector>
            </div>

        </div>
    )
}

export default Modals
