import React from "react";

const UIQuoteSend = (props) => {
  return (
    <div
      onClick={() => {
        props.topicDispatch({
          type: "SET_QUOTE",
          values: {
            quote: null
          }
        });
      }}
      style={{
        fontStyle: "italic"
      }}
    >
      {"Quote: "}
      {props.message.thumbnail && (
        <img
          id="quote_thumbnail"
          alt=""
          src={"data:image/png;base64," + props.message.thumbnail}
          style={{
            paddingLeft: "8px",
            width: "64px",
            height: "32px",
            objectFit: "cover"
          }}
        ></img>
      )}
      {props.message.content.body.substring(0, 50)}
      {" (tap to cancel)"}
    </div>
  );
};

export default React.memo(UIQuoteSend, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
