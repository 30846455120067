import React, { useState, useContext, useEffect } from "react";
// import DatabaseContext from "data/contextDatabase";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
// import GlobalContext from "contexts/context";
import { isMobileTablet } from "hooks/helper";
import "ui/UI.css";
import PersonasContext from "contexts/contextPersonas";
// import UISettings from "ui/UISettings";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";

const UIShareModal = (props) => {
  const { personasState } = useContext(PersonasContext);
  const [modalClose, setModalClose] = useState(false)
  const [outClick, setOutClick] = useState(false)
  let history = useHistory()

  useEffect(() => {
    let populateShare = (link, title) => {
      document.getElementById("share_modal_link_title").innerHTML = title;
      document.getElementById("share_modal_link").innerHTML = link;
      let elSMS = document.getElementById("hrefLinkSMS");
      let elMail = document.getElementById("hrefLinkEmail");

      elSMS &&
        (
          document.title == "EZist" 
          ?
          (elSMS.href =
            "sms:?&body=The EZist app simplifies community living.%0D%0A%0D%0A" +
            encodeURIComponent(link))
          :
          (elSMS.href =
            "sms:?&body=WORLD is small chat app with a big heart.%0D%0A%0D%0A" +
            encodeURIComponent(link)
          )
        );

      elMail &&
        document.title == "EZist"
        ?
          (elMail.href =
            "mailto:?subject=Welcome to EZist&body=" +
            "The EZist app simplifies community living.%0D%0A%0D%0A" +
            "Follow the link to begin your new community experience.%0D%0A%0D%0A" +
            encodeURIComponent(link) +
            "%0D%0A%0D%0AEZist - Community living made easy")
        :
          (elMail.href =
            "mailto:?subject=Welcome to WORLD&body=" +
            "WORLD is a small chat app that works across many platforms and devices.%0D%0A%0D%0A" +
            "Follow the link to begin your new chat experience.%0D%0A%0D%0A" +
            encodeURIComponent(link) +
            "%0D%0A%0D%0A");
    };

    let activePersona = ""
    let activemPersona = ""
    personasState.personas?.forEach((persona) => {
      let testPersona = document.getElementById("image_" + persona.mpersona);
      testPersona && (testPersona.className == "persona-card-header-selected") && 
        (activePersona = persona.persona) &&
        (activemPersona = persona.mpersona)
      //console.log("xxx", activePersona, activemPersona)
    })

    if (activePersona.length == 0) {activePersona = document.title}

    props.title && props.link
      ? populateShare(props.link, props.title)
      : populateShare(
        personasState.personas
          ? window.location.origin +
          "/dialog/" +
          activemPersona +
          "/" +
          `${activePersona}`
          : window.location.origin, `${activePersona}`
      );
    return () => { };
  }, [personasState.personas, props.link, props.title]);

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick])

  const handleClose = () => {
    setModalClose(true)
    props.onClose()
  }

  const handleClickOutside = () => {
    setOutClick(true)
    if (history.length >= 1) history.goBack()
  }

  let modalShare = (

    <div id="share_modal">
      <Modals
        title="Share"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        switchScrollOff={true}
        footer={
          <div style={{
            marginBottom: "1rem",
            marginRight: "0.5rem",
            marginTop: "1rem",
          }}>
            {isMobileTablet() && (
              <a
                id="hrefLinkSMS"
                href={window.location.origin}
                className="UI-button-confined"
                onClick={() => {
                  if (history.length >= 1) history.goBack()
                  props.onClose()
                }}
              >
                Share via SMS
              </a>
            )}
            {true && (
              <a
                id="hrefLinkEmail"
                href={window.location.origin}
                className="UI-button-confined"

                onClick={() => {
                  if (history.length >= 1) history.goBack()
                  props.onClose()
                }}
              >
                Share via email
              </a>

            )}
          </div>
        }
      >
        <label className="modal-label-new">
          Share this link to start a chat with
        </label>
        <h2
          style={{
            color: "var(--modal_text_color)",
            textDecoration: "none",
            // wordWrap: "normal",
            textAlign: "center",
            marginLeft: "50%",
            transform: "translate(-50%)",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            wordBreak: "break-all",
            width: "100%",
            overflow: "hidden",
          }}
          id="share_modal_link_title"
        ></h2>
        <h4
          style={{
            color: "blue",
            textAlign: "left",
            textDecoration: "none",
            // wordWrap: "break-word",
            backgroundColor: "#FFFFFF",
            borderRadius: "var(--border_radius_medium)",
            padding: "4px",
            marginBottom: "16px",
            wordBreak: "break-all",
            fontWeight: "inherit",
          }}
          id="share_modal_link"
        ></h4>
      </Modals>
    </div >


  );

  let content = <div>{modalShare}</div>;

  return content;
};

export default React.memo(UIShareModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
