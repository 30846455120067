import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LIVEMESSAGES":
      let lm = {};
      lm[action.values.mtopic] = action.values.liveMessages;
      let newLiveMessages = { ...state.liveMessages, ...lm };
      if (isEqual(state.liveMessages, newLiveMessages)) return state;
      return {
        ...state,
        liveMessages: { ...state.liveMessages, ...lm }
      };
    case "REMOVE_SEND":
      if (
        isEqual(
          state.liveSendMessages,
          state.liveSendMessages.filter((m) => m.smid !== action.values.smid)
        )
      )
        return state;
      return {
        ...state,
        liveSendMessages: state.liveSendMessages.filter(
          (m) => m.smid !== action.values.smid
        )
      };
    case "ADD_SEND":
      let lsm = state.liveSendMessages || [];
      let smidList = lsm.map((m) => m.smid);
      let newLiveSendMessages = lsm.concat(
        action.values.liveSendMessages.filter((m) => !smidList.includes(m.smid))
      );
      if (isEqual(state.liveSendMessages, newLiveSendMessages)) return state;
      return {
        ...state,
        liveSendMessages: lsm.concat(
          action.values.liveSendMessages.filter(
            (m) => !smidList.includes(m.smid)
          )
        )
      };
    case "SET_DESCRIPTORSB":
      shouldLog() && console.log("SET_DESCRIPTORSB", action);
      if (isEqual(state.descriptorsB, action.values.descriptorsB)) return state;
      else
        return {
          ...state,
          descriptorsB: action.values.descriptorsB
        };
    case "SET_TOPICDESCRIPTORS":
      if (isEqual(state.topicDescriptors, action.values.topicDescriptors))
        return state;
      else
        return {
          ...state,
          topicDescriptors: action.values.topicDescriptors
        };
    case "SET_TOPICS":
      if (isEqual(state.topics, action.values.topics)) return state;
      else
        return {
          ...state,
          topics: action.values.topics
        };
    case "SET_LOGOS":
      if (isEqual(state.logos, action.values.logos)) return state;
      else
        return {
          ...state,
          logos: { ...state.logos, ...action.values.logos }
        };
    case "SET_LOGO":
      if (
        state.logos &&
        isEqual(state.logos[action.values.mtopic], action.values.logo)
      )
        return state;
      else {
        let logo = {};
        logo[action.values.mtopic] = action.values.logo;
        let newState = {
          ...state,
          logos: { ...state.logos, ...logo }
        };
        // console.log("SET_LOGO!!!", action.values, newState);
        return newState;
      }
    default: {
      return state;
    }
  }
};

const useTopicsState = (state) => {
  const [topicsState, topicsDispatch] = useReducer(reducer, state);
  return { topicsState, topicsDispatch };
};

export default useTopicsState;
